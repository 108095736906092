import React, { Component } from "react";
import { StoreService } from "../../services/store.service";
import Translate from "../../functions/utilFunctions/translate";
import Table from "../../devkit/Table/Table";
import ShowMore from "../../devkit/ShowMore/ShowMore";
import CustomButton from "../../devkit/Form/CustomButton/CustomButton";
import { PlusIcon } from "../../components/Icons/Icons";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import ApiService from "../../services/api.service";
import { Loader } from "../../devkit/Loader/Loader";
import Paginate from "../../devkit/Pagination/Pagination";
import { parseDate } from "functions/utilFunctions/parsingFunctions";

class Clients extends Component<any> {
    state = {
        customersTableHeads: [
            {
                value: "FIRST_NAME",
                sortable: true,
                sortableName: "first_name",
            },
            {
                value: "LAST_NAME",
                sortable: true,
                sortableName: "last_name",
            },
            {
                value: "DATE_OF_BIRTH",
                sortable: true,
                sortableName: "date_of_birth",
            },
            {
                value: "EMAIL",
                sortable: true,
                sortableName: "email",
            },
            {
                value: "DRIVING_LICENCE_NUMBER",
                sortable: false,
                sortableName: "licence_id",
            },
            {
                value: "ID_NUMBER",
                sortable: false,
                sortableName: "personal_id",
            },
            {
                value: "PHONE_NUMBER",
                sortable: false,
                sortableName: "phone",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        customers: [],
        showDeleteModal: false,
        showDeleteFailedModal: false,
        showDeleteSuccessModal: false,
        deleteID: "",
        showLoader: true,
        totalPages: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 20,
        sorterName: "",
        sorterDirection: "",
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;

        ApiService.getAllCustomers({ limit: perPage, offset }, token).then(
            (response) => {
                if (response && response.success) {
                    const customers = response.data;
                    this.setState({
                        customers,
                        totalPages: response.total,
                        showLoader: false,
                    });
                }
            }
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return { currentPage: nextProps.match.params.pageNo };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.sorterName &&
            this.state.sorterName !== prevState.sorterName
        ) {
            this.setState({ sorterName: "", sorterDirection: "" });
        }
        if (prevState.currentPage !== this.state.currentPage) {
            const token = StoreService.getStoreProperty("token");
            const {
                currentPage,
                perPage,
                sorterDirection,
                sorterName,
            } = this.state;
            const offset = (parseInt(currentPage) - 1) * perPage;
            const data: any = { limit: perPage, offset };

            if (sorterDirection) {
                data.sorter_direction = sorterDirection;
            }
            if (sorterName) {
                data.sorter = sorterName;
            }

            ApiService.getAllCustomers(data, token).then((response) => {
                if (response && response.success) {
                    const clients = response.data;
                    this.setState({
                        customers: clients,
                        totalPages: response.total,
                        showLoader: false,
                    });
                }
            });
        }
    }

    handleDelete = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });

        const {
            deleteID,
            currentPage,
            perPage,
            sorterName,
            sorterDirection,
        } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const token = StoreService.getStoreProperty("token");
        const data: any = { limit: perPage, offset };

        if (sorterDirection) {
            data.sorter_direction = sorterDirection;
        }
        if (sorterName) {
            data.sorter = sorterName;
        }

        ApiService.deleteCustomer(deleteID, token).then((response) => {
            if (response.success === true) {
                ApiService.getAllCustomers(data, token).then((response) => {
                    if (response && response.success) {
                        this.setState({
                            customers: response.data,
                            showDeleteSuccessModal: true,
                        });
                    }
                });
            } else {
                this.setState({
                    showDeleteFailureModal: true,
                });
            }
        });
    };

    handleRedirect = (id) => {
        this.props.history.push(`/clients/details/${id}/basic-info`);
    };

    handleAddNew = () => {
        this.props.history.push(`/new-client/basic-info`);
    };

    toggleModal = (name) => {
        this.setState({
            [name]: !this.state[name],
        });
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleSort = (sortableName) => {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage, sorterDirection } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";

        ApiService.getAllCustomers(
            {
                limit: perPage,
                offset,
                sorter: sortableName,
                sorter_direction: sorter_direction,
            },
            token
        ).then((response) => {
            if (response && response.success) {
                const customers = response.data;
                this.setState({
                    customers,
                    totalPages: response.total,
                    showLoader: false,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                });
            }
        });
    };

    handlePageClick = (pageNumber) => {
        this.setState({
            showLoader: true,
        });
        this.props.history.push(`/clients/${pageNumber}`);
    };

    render() {
        const { totalPages, currentPage, perPage } = this.state;
        return (
            <div>
                <Modal
                    modalName="showDeleteModal"
                    toggleModal={this.toggleModal}
                    className={this.state.showDeleteModal ? "visible" : ""}
                    modalWrapperClasses="w-600 big-warning padding-10"
                >
                    <ModalContent
                        type="warning"
                        modalName="showDeleteModal"
                        text="ARE_YOU_SURE_DELETE_CLIENT"
                        title="DELETE_CUSTOMER"
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDelete}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    modalName="showDeleteFailedModal"
                    toggleModal={this.toggleModal}
                    className={
                        this.state.showDeleteFailedModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="DELETE_CUSTOMER_FAILED"
                        modalName="showDeleteFailedModal"
                        title="FAILED"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    modalName="showDeleteSuccessModal"
                    toggleModal={this.toggleModal}
                    className={
                        this.state.showDeleteSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        text="DELETE_CUSTOMER_SUCCESSFUL"
                        modalName="showDeleteSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <div className="d-flex w-100-perc border-b-1 pb-15">
                    <span className="pageHeader">
                        <Translate text="CLIENTS" />
                    </span>
                </div>
                <div className="d-flex pb-40 py-20 justify-content-end">
                    <CustomButton
                        className="btn-primary justify-content-center"
                        type="button"
                        onClick={() => this.handleAddNew()}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PlusIcon
                                width={14}
                                className="icon hover pointer mr-10 whiteFill"
                            />
                            <Translate text={"ADD_CLIENT"} />
                        </div>
                    </CustomButton>
                </div>
                <div className="mt-40 f-s-14">
                    {this.state.showLoader ? (
                        <div className="loaderContainer">
                            <Loader width={200} />
                        </div>
                    ) : (
                        <div>
                            <div>
                                <Table
                                    theads={this.state.customersTableHeads}
                                    theadsClassname="customThead"
                                    className={`${
                                        !this.props.template
                                            ? "actionsTable"
                                            : "templateTable"
                                    }`}
                                    handleSort={this.handleSort}
                                >
                                    {!!this.state.customers &&
                                        this.state.customers.map(
                                            (e: any, i) => {
                                                return (
                                                    <tr
                                                        className="pointer"
                                                        onClick={() =>
                                                            this.handleRedirect(
                                                                e.id
                                                            )
                                                        }
                                                        key={i}
                                                    >
                                                        <td>{e.first_name}</td>
                                                        <td>{e.last_name}</td>
                                                        <td>
                                                            {parseDate(
                                                                e.date_of_birth
                                                            )}
                                                        </td>
                                                        <td>{e.email}</td>
                                                        <td>{e.licence_id}</td>
                                                        <td>{e.personal_id}</td>
                                                        <td>{e.phone}</td>
                                                        <td>
                                                            <ShowMore>
                                                                <span
                                                                    className="one-line-text d-flex justify-content-center"
                                                                    onClick={() =>
                                                                        this.handleRedirect(
                                                                            e.id.toString()
                                                                        )
                                                                    }
                                                                >
                                                                    <Translate text="CHANGE_DATA" />
                                                                </span>
                                                                <span
                                                                    className="one-line-text d-flex justify-content-center"
                                                                    onClick={() =>
                                                                        this.handleDeleteModal(
                                                                            e.id.toString()
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="danger">
                                                                        <Translate text="DELETE" />
                                                                    </span>
                                                                </span>
                                                            </ShowMore>
                                                        </td>
                                                    </tr>
                                                );
                                            }
                                        )}
                                </Table>
                                <Paginate
                                    totalItemsCount={totalPages}
                                    onChange={this.handlePageClick}
                                    activePage={parseInt(currentPage)}
                                    perPage={perPage}
                                    pageRangeDisplayed={5}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default Clients;
