import React, { Component } from "react";
import { LeftArrow, UploadIcon } from "../../components/Icons/Icons";
import Translate from "../../functions/utilFunctions/translate";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import ApiService from "../../services/api.service";
import { StoreService } from "../../services/store.service";
import { UtilService } from "../../services/util.service";
import { Loader } from "../../devkit/Loader/Loader";
import { validateEmail } from "../../functions/utilFunctions/validations";
import { parseDateForMySQL } from "../../functions/utilFunctions/parsingFunctions";
import { RouteComponentProps, NavLink, Switch, Route } from "react-router-dom";
import BasicInfo from "./BasicInfo";
import ClientsContracts from "./ClientsContracts";
import { CustomButton, CustomFileInput } from "devkit/Form/Form";
class ClientsDetails extends Component<
    RouteComponentProps<{ id: string }>,
    any
> {
    state = {
        form: {
            id: {
                value: "",
                errors: [],
            },
            first_name: {
                value: "",
                errors: [],
            },
            last_name: {
                value: "",
                errors: [],
            },
            date_of_birth: {
                value: null,
                errors: [],
            },
            email: {
                value: "",
                errors: [],
            },
            country: {
                value: "",
                errors: [],
            },
            licence_id: {
                value: "",
                errors: [],
            },
            licence_id_expiration_date: {
                value: null,
                errors: [],
            },
            personal_id: {
                value: "",
                errors: [],
            },
            personal_id_expiration_date: {
                value: null,
                errors: [],
            },
            phone: {
                value: "",
                errors: [],
            },
            updated_at: {
                value: "",
                errors: [],
            },
            created_at: {
                value: "",
                errors: [],
            },
            remark: {
                value: "",
                errors: [],
            },
            documents: [
                { path: "", id: null, updated_at: null, created_at: null },
            ],
        },
        document: {
            value: null,
        },
        documents: [{ path: "", id: null, updated_at: null, created_at: null }],
        showSaveFailedModal: false,
        showSaveSuccessfulModal: false,
        showEditFailedModal: false,
        showEditSuccessfulModal: false,
        isCreateNew: !this.props.match.params.id,
        currentCustomerID: this.props.match.params.id,
        currentCustomer: {},
        showLoader: true,
        completed: 0,
        isOver: false,
        fileUploadModal: false,
        fileUploadErrorModal: false,
        uploading: false,
    };

    componentDidMount() {
        if (!this.state.isCreateNew) {
            const customerID = this.props.match.params.id;
            const token = StoreService.getStoreProperty("token");
            ApiService.getCustomer(customerID, token).then((response) => {
                const currentCustomer = response.data;
                if (currentCustomer) {
                    delete currentCustomer.created_at;
                    delete currentCustomer.updated_at;
                    delete currentCustomer.unregistered;
                    currentCustomer.remark =
                        currentCustomer.remark === null
                            ? ""
                            : currentCustomer.remark;
                }

                const customerFormated: any = {};

                if (currentCustomer) {
                    Object.keys(currentCustomer).forEach((e) => {
                        if (
                            e === "date_of_birth" ||
                            e === "personal_id_expiration_date" ||
                            e === "licence_id_expiration_date"
                        ) {
                            customerFormated[e] = {
                                value: new Date(currentCustomer[e]),
                                errors: [],
                            };
                        } else {
                            customerFormated[e] = {
                                value: currentCustomer[e],
                                errors: [],
                            };
                        }
                    });
                }

                this.setState((prevState: any) => ({
                    ...prevState,
                    currentCustomer: currentCustomer,
                    documents: response.data.files,
                    form: {
                        ...customerFormated,
                    },
                    showLoader: false,
                    isLoading: false,
                }));
            });
        }

        if (this.state.isCreateNew) {
            this.setState({ showLoader: false });
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.document.value !== this.state.document.value &&
            this.state.document.value
        ) {
            this.setState({ uploading: true });
            this.handleAddDocument();
        }
    }
    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                },
            },
        }));
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    toggleModal = (name) => {
        this.setState({
            [name]: !this.state[name],
        });
        if (
            name === "showSaveSuccessfulModal" ||
            name === "showEditSuccessfulModal"
        ) {
            this.handleSaveSuccess();
        }
    };

    handleUpdateAndCreate = (e) => {
        e.preventDefault();

        const { isCreateNew, currentCustomer, form } = this.state;
        const customerID = parseInt(this.props.match.params.id);
        const token = StoreService.getStoreProperty("token");

        const currentForm: any = form;
        const formErrors = {};

        let customerFormated: any = currentCustomer;

        delete customerFormated.created_at;
        delete customerFormated.updated_at;
        delete customerFormated.type;
        Object.keys(currentForm).forEach((e) => {
            if (!currentForm[e].value) {
                if (
                    e === "created_at" ||
                    e === "updated_at" ||
                    e === "id" ||
                    e === "type" ||
                    e === "remark" ||
                    e === "documents"
                ) {
                    return;
                } else {
                    formErrors[e] = {
                        value: currentForm[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            }

            if (e === "licence_id_expiration_date") {
                if (currentForm[e].value < new Date()) {
                    formErrors[e] = {
                        value: currentForm[e].value,
                        errors: ["LICENCE_DATE_EXPIRED"],
                    };
                }
            }

            if (e === "personal_id_expiration_date") {
                if (currentForm[e].value < new Date()) {
                    formErrors[e] = {
                        value: currentForm[e].value,
                        errors: ["PERSONAL_ID_DATE_EXPIRED"],
                    };
                }
            }

            if (e === "email" && !validateEmail(form[e].value)) {
                formErrors[e] = {
                    value: form[e].value,
                    errors: ["EMAIL_INVALID"],
                };
            }
        });

        if (Object.keys(formErrors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...formErrors,
                },
            }));
        } else {
            const newDetails = {
                first_name: currentForm.first_name.value,
                last_name: currentForm.last_name.value,
                date_of_birth: parseDateForMySQL(
                    currentForm.date_of_birth.value
                ),
                email: currentForm.email.value,
                country: currentForm.country.value,
                licence_id: currentForm.licence_id.value,
                licence_id_expiration_date: parseDateForMySQL(
                    currentForm.licence_id_expiration_date.value
                ),
                personal_id: currentForm.personal_id.value,
                personal_id_expiration_date: parseDateForMySQL(
                    currentForm.personal_id_expiration_date.value
                ),
                phone: currentForm.phone.value,
                id: customerID,
                remark: currentForm.remark.value,
            };

            if (isCreateNew) {
                ApiService.createCustomer(newDetails, token).then(
                    (response) => {
                        if (response.success) {
                            this.setState({
                                showSaveSuccessfulModal: true,
                            });
                        } else {
                            if (response.message.includes("EMAIL_TAKEN")) {
                                this.setState((prevState: any) => ({
                                    form: {
                                        ...prevState.form,
                                        email: {
                                            ...prevState.form.email,
                                            errors: ["EMAIL_TAKEN"],
                                        },
                                    },
                                }));
                            }
                            if (response.message.includes("LICENCE_ID_TAKEN")) {
                                this.setState((prevState: any) => ({
                                    form: {
                                        ...prevState.form,
                                        licence_id: {
                                            ...prevState.form.licence_id,
                                            errors: ["LICENCE_ID_TAKEN"],
                                        },
                                    },
                                }));
                            }
                            if (
                                response.message.includes("PERSONAL_ID_TAKEN")
                            ) {
                                this.setState((prevState: any) => ({
                                    form: {
                                        ...prevState.form,
                                        personal_id: {
                                            ...prevState.form.personal_id,
                                            errors: ["PERSONAL_ID_TAKEN"],
                                        },
                                    },
                                }));
                            }
                            this.setState({ showSaveFailedModal: true });
                        }
                    }
                );
            } else {
                if (
                    !UtilService.areObjectsEqual(currentCustomer, {
                        ...newDetails,
                    })
                ) {
                    ApiService.updateCustomer({ ...newDetails }, token).then(
                        (response) => {
                            if (response.success === true) {
                                this.setState({
                                    showEditSuccessfulModal: true,
                                });
                            } else {
                                this.setState({
                                    showEditFailedModal: true,
                                });
                            }
                        }
                    );
                } else return;
            }
        }
    };

    handleSaveSuccess = () => {
        this.props.history.push("/clients/1");
    };

    handleAddDocument = () => {
        const document: any = this.state.document.value;
        const token = StoreService.getStoreProperty("token");
        const formData = new FormData();
        const id = this.props.match.params.id;
        formData.append("customer_id", id);
        formData.append("file", document);
        formData.append("type", "customer");
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadFile(formData, token, config).then((res) => {
            if (res.success) {
                this.setState((prevState: any) => ({
                    documents: [...prevState.documents, res.data],
                    completed: 0,
                    uploadComplete: false,
                    uploading: false,
                    fileUploadModal: false,
                    document: {
                        value: null,
                        errors: [],
                    },
                    form: {
                        ...prevState.form,
                        documents: [prevState.form.documents, res.data],
                    },
                }));
            } else {
                this.setState({
                    fileUploadErrorModal: true,
                    document: {
                        value: null,
                        errors: [],
                    },
                });
            }
        });
    };
    deleteFile = (index) => {
        const token = StoreService.getStoreProperty("token");

        ApiService.deleteFile(
            {
                id: this.state.documents[index].id,
            },
            token
        ).then((response) => {
            if (response.success) {
                const files = this.state.documents.filter(
                    (e, i) => i !== index
                );
                this.setState({
                    documents: files,
                });
            }
        });
    };
    handleFileChange = (name, e, drop?) => {
        this.setState({
            [name]: UtilService.handleAddFile(name, e, drop),
        });
    };
    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };
    render() {
        const { form, showLoader, isCreateNew, currentCustomerID } = this.state;
        return (
            <div>
                <Modal
                    modalName="showFailedModal"
                    toggleModal={this.toggleModal}
                    className={this.state.showSaveFailedModal ? "visible" : ""}
                    modalWrapperClasses="width-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="ADD_CLIENT_FAILED"
                        modalName="showSaveFailedModal"
                        title="FAILED"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    modalName="showSaveSuccessfulModal"
                    toggleModal={this.toggleModal}
                    className={
                        this.state.showSaveSuccessfulModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        text="ADD_CLIENT_SUCCESSFUL"
                        modalName="showSaveSuccessfulModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    modalName="showEditFailedModal"
                    toggleModal={this.toggleModal}
                    className={this.state.showEditFailedModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="EDIT_CLIENT_FAILED"
                        modalName="showEditFailedModal"
                        title="FAILED"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    modalName="showEditSuccessfulModal"
                    toggleModal={this.toggleModal}
                    className={
                        this.state.showEditSuccessfulModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        text="EDIT_CLIENT_SUCCESSFUL"
                        modalName="showEditSuccessfulModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    modalName="fileUploadModal"
                    toggleModal={this.toggleModal}
                    className={this.state.fileUploadModal ? "visible" : ""}
                    modalWrapperClasses="fileUploadModal"
                >
                    {!this.state.uploading && (
                        <div className="pa-20 h-100-perc">
                            <div
                                onDrop={(e) =>
                                    this.handleFileChange("document", e, true)
                                }
                                className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                    this.state.isOver ? "dotted" : ""
                                }`}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    this.changeBorderType(true);
                                }}
                                onDragLeave={() => this.changeBorderType(false)}
                            >
                                <div className="text d-flex flex-column justify-content-center align-items-center">
                                    <span className="d-block mb-10">
                                        <UploadIcon
                                            width={40}
                                            className="uploadIcon"
                                        />
                                    </span>
                                    <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                        <Translate text="DRAG_AND_DROP" />
                                    </span>
                                    <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">
                                        <Translate text="OR" />
                                    </span>
                                </div>
                                <CustomFileInput
                                    name="document"
                                    className="mb-10"
                                    handleInput={this.handleFileChange}
                                    value={this.state.document.value}
                                    label={"CHOOSE_FILE"}
                                    labelClass="f-s-16"
                                />
                            </div>
                        </div>
                    )}
                    {this.state.uploading && (
                        <div className="progress pa-20 h-100perc text-center border-radius-4">
                            <span className="f-s-20 mb-20">
                                <Translate text="UPLOAD_IN_PROGRESS" />
                            </span>
                            <span className="wrapper d-block">
                                <Loader width={200} />
                                <span className="loader-numbers">
                                    {this.state.completed}%
                                </span>
                            </span>
                        </div>
                    )}
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="fileUploadErrorModal"
                    className={
                        this.state.fileUploadErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="FILE_UPLOAD_ERROR"
                        modalName="fileUploadErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <div className="d-flex justify-content-between border-b-1 pb-20 mb-40">
                    <div className="pageHeader d-flex align-items-center">
                        <span
                            onClick={this.handleBack}
                            className="pointer d-flex align-items-center goBack mr-10"
                        >
                            <LeftArrow
                                width={18}
                                className="d-flex align-items-center fill"
                            />
                        </span>
                        <div className="pageHeader ">
                            {isCreateNew ? (
                                <Translate text={"ADD_CLIENT"} />
                            ) : (
                                <Translate text={"MODIFY_CLIENT_DATA"} />
                            )}
                        </div>
                    </div>
                    <div>
                        <CustomButton
                            className="btn-primary"
                            type="button"
                            onClick={this.handleUpdateAndCreate}
                        >
                            <Translate text={"SAVE"} />
                        </CustomButton>
                    </div>
                </div>
                {!isCreateNew ? (
                    <>
                        <nav className="pl-10 mt-30 d-flex">
                            <NavLink
                                exact
                                to={`/clients/details/${this.props.match.params.id}/basic-info`}
                                className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                activeClassName="active"
                            >
                                <span>
                                    <Translate text="BASIC_INFO" />
                                </span>
                            </NavLink>
                            <NavLink
                                exact
                                to={`/clients/details/${this.props.match.params.id}/contracts/1`}
                                className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                activeClassName="active"
                            >
                                <span>
                                    <Translate text="CONTRACTS" />
                                </span>
                            </NavLink>
                        </nav>
                        <Switch>
                            <Route
                                exact
                                path={`/clients/details/${currentCustomerID}/basic-info`}
                            >
                                {showLoader ? (
                                    <div className="d-flex justify-content-between align-items-center h-400">
                                        <Loader className="w-200"></Loader>
                                    </div>
                                ) : (
                                    <BasicInfo
                                        form={form}
                                        handleInput={this.handleInput}
                                        toggleModal={this.toggleModal}
                                        documents={this.state.documents}
                                        isCreateNew={this.state.isCreateNew}
                                        deleteFile={this.deleteFile}
                                    ></BasicInfo>
                                )}
                            </Route>
                            <Route
                                exact
                                path={`/clients/details/${this.props.match.params.id}/contracts/:pageNo`}
                                render={(props) => {
                                    return (
                                        <>
                                            {showLoader ? (
                                                <div className="d-flex justify-content-between align-items-center h-400">
                                                    <Loader className="w-200"></Loader>
                                                </div>
                                            ) : (
                                                <ClientsContracts
                                                    clientId={
                                                        this.props.match.params
                                                            .id
                                                    }
                                                    {...props}
                                                ></ClientsContracts>
                                            )}
                                        </>
                                    );
                                }}
                            />
                        </Switch>
                    </>
                ) : (
                    <BasicInfo
                        form={form}
                        handleInput={this.handleInput}
                        toggleModal={this.toggleModal}
                        documents={this.state.documents}
                        isCreateNew={this.state.isCreateNew}
                        deleteFile={this.deleteFile}
                    ></BasicInfo>
                )}
            </div>
        );
    }
}

export default ClientsDetails;
