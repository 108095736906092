import React, { Component } from "react";
import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import {
    CustomButton,
    CustomCheckbox,
    CustomDatePicker,
    CustomDropdown,
} from "devkit/Form/Form";
import {
    parseDate,
    parseDateForMySQL,
} from "functions/utilFunctions/parsingFunctions";
import { StoreService } from "services/store.service";
import ApiService from "services/api.service";
import { Loader } from "devkit/Loader/Loader";
import Paginate from "devkit/Pagination/Pagination";
import FilterSidebar from "components/FilterSidebar/FilterSidebar";
import { FilterOptions } from "functions/utilFunctions/FilterOptions";
import { NavLink } from "react-router-dom";

class CarsAvailability extends Component<any> {
    state = {
        carsTableHeads: [
            {
                value: "MANUFACTURER",
                sortable: true,
                sortableName: "manufacturer",
            },
            {
                value: "MODEL",
                sortable: true,
                sortableName: "model",
            },
            {
                value: "CATEGORY",
                sortable: true,
                sortableName: "category_id",
            },
            {
                value: "REGISTRATION_PLATE",
                sortable: true,
                sortableName: "licence_plate",
            },
            {
                value: "REGISTRATION_DATE",
                sortable: true,
                sortableName: "registration_date",
            },
            {
                value: "UNIQUE_NUMBER",
                sortable: false,
                sortableName: "unique_number",
            },
            {
                value: "FUEL_TYPE",
                sortable: true,
                sortableName: "fuel_type",
            },
            {
                value: "KILOMETERS",
                sortable: true,
                sortableName: "mileage",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        filterForm: {
            category_id: {
                value: "",
                errors: [],
            },
            manufacturer: {
                value: "",
                errors: [],
            },
            model: {
                value: "",
                errors: [],
            },
            mileage: {
                value: "",
                errors: [],
            },
        },
        dateFilterForm: {
            start_date: {
                value: null,
                errors: [],
            },
            end_date: {
                value: null,
                errors: [],
            },
            type: false,
            in_range: false,
            finished: 0,
        },
        relativeTimeFilter: "no_filter",
        relativeNextOptions: FilterOptions.getRelativeDateOptions("next"),
        carFilters: [],
        categoryOptions: [],
        manufacturerOptions: [],
        modelOptions: [],
        mileageOptions: [],
        filters: {},
        cars: [],
        categories: [],
        showFilterSidebar: false,
        carsFormated: [],
        showDeleteModal: false,
        showDeleteErrorModal: false,
        showDeleteSuccessModal: false,
        deleteID: "",
        showLoader: true,
        showFilterLoader: false,
        totalPages: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 20,
        sorterName: "",
        sorterDirection: "",
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;

        ApiService.getAllCategories({ limit: 1000, offset: 0 }, token).then(
            (response) => {
                if (response.success) {
                    const categories = response.data;

                    ApiService.getAllCars(
                        { limit: perPage, offset },
                        token
                    ).then((response) => {
                        if (response && response.success) {
                            const cars = response.data;
                            let updatedCars = cars.map((e: any) => {
                                let carCategory: any = categories.find(
                                    (category: any) => {
                                        return (
                                            category &&
                                            parseInt(category.id) ===
                                                parseInt(e.category_id)
                                        );
                                    }
                                );
                                let updatedCar = {
                                    ...e,
                                    title: carCategory && carCategory.name,
                                };
                                return updatedCar;
                            });

                            this.setState({
                                totalPages: response.total,
                                categories,
                                cars: updatedCars,
                            });

                            ApiService.getCarFilters(token).then((response) => {
                                if (response && response.success) {
                                    this.setState({
                                        categoryOptions: FilterOptions.getCarsOptions(
                                            response.data,
                                            "category_id",
                                            "ALL_CATEGORIES",
                                            categories
                                        ),

                                        manufacturerOptions: FilterOptions.getCarsOptions(
                                            response.data,
                                            "manufacturer",
                                            "ALL_MANUFACTURERS"
                                        ),
                                        modelOptions: FilterOptions.getCarsOptions(
                                            response.data,
                                            "model",
                                            "ALL_MODELS"
                                        ),
                                        mileageOptions: FilterOptions.getMileageOptions(),
                                        carFilters: response.data,
                                        showLoader: false,
                                        showFilterLoader: false,
                                    });
                                }
                            });
                        }
                    });
                }
            }
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        const token = StoreService.getStoreProperty("token");
        const {
            currentPage,
            perPage,
            filters,
            carFilters,
            sorterName,
            sorterDirection,
            dateFilterForm: { start_date, end_date },
        } = this.state;

        const filtersSubmitted = prevState.filters !== this.state.filters;

        const currentPageChanged =
            prevState.currentPage !== this.state.currentPage;

        // NGEDJE SE getAllCategories POZIVA 2 PUTA ZA REDOM

        if (prevState.filterForm !== this.state.filterForm) {
            ApiService.getAllCategories({ limit: 1000, offset: 0 }, token).then(
                (response) => {
                    if (response && response.success) {
                        const categories = response.data;
                        const currFilters: any = {};

                        for (let filter in this.state.filterForm) {
                            if (this.state.filterForm[filter].value !== "") {
                                currFilters[filter] = this.state.filterForm[
                                    filter
                                ].value;
                            }
                        }

                        const newCarManufacturerFilters = carFilters.filter(
                            (el: any) => {
                                if (currFilters.category_id) {
                                    if (
                                        currFilters.category_id ===
                                        el.category_id
                                    ) {
                                        return el;
                                    } else {
                                        return null;
                                    }
                                } else {
                                    return el;
                                }
                            }
                        );

                        const newCarModelFilters = carFilters.filter(
                            (el: any) => {
                                if (
                                    currFilters.category_id &&
                                    !currFilters.manufacturer
                                ) {
                                    return (
                                        el.category_id ===
                                        currFilters.category_id
                                    );
                                } else if (
                                    !currFilters.category_id &&
                                    currFilters.manufacturer
                                ) {
                                    return (
                                        el.manufacturer ===
                                        currFilters.manufacturer
                                    );
                                } else if (
                                    currFilters.category_id &&
                                    currFilters.manufacturer
                                ) {
                                    return (
                                        el.category_id ===
                                            currFilters.category_id &&
                                        el.manufacturer ===
                                            currFilters.manufacturer
                                    );
                                } else {
                                    return el;
                                }
                            }
                        );

                        this.setState({
                            categoryOptions: FilterOptions.getCarsOptions(
                                carFilters,
                                "category_id",
                                "ALL_CATEGORIES",
                                categories
                            ),

                            manufacturerOptions: FilterOptions.getCarsOptions(
                                newCarManufacturerFilters,
                                "manufacturer",
                                "ALL_MANUFACTURERS"
                            ),
                            modelOptions: FilterOptions.getCarsOptions(
                                newCarModelFilters,
                                "model",
                                "ALL_MODELS"
                            ),
                            showFilterLoader: false,
                        });
                    }
                }
            );
        }

        if (currentPageChanged || filtersSubmitted) {
            let offset;

            if (typeof currentPage !== "undefined") {
                offset = (parseInt(currentPage) - 1) * perPage;
            } else {
                offset = 0;
            }

            ApiService.getAllCategories({ limit: 1000, offset: 0 }, token).then(
                (response) => {
                    if (response && response.success) {
                        const categories = response.data;

                        const data: any = {
                            limit: perPage,
                            offset,
                            ...filters,
                        };
                        if (sorterDirection) {
                            data.sorter_direction = sorterDirection;
                        }
                        if (sorterName) {
                            data.sorter = sorterName;
                        }

                        if (start_date.value && end_date.value) {
                            ApiService.getDateRangeCars(
                                {
                                    limit: perPage,
                                    offset,
                                    ...filters,
                                },
                                token
                            ).then((response) => {
                                if (response.success) {
                                    const cars = response.cars;
                                    let updatedCars = cars.map((e: any) => {
                                        let carCategory: any = categories.find(
                                            (category: any) => {
                                                return (
                                                    category &&
                                                    parseInt(category.id) ===
                                                        parseInt(e.category_id)
                                                );
                                            }
                                        );
                                        let updatedCar = {
                                            ...e,
                                            title:
                                                carCategory && carCategory.name,
                                        };
                                        return updatedCar;
                                    });
                                    this.setState({
                                        cars: updatedCars,
                                        showLoader: false,
                                        totalPages: response.total,
                                    });
                                }
                            });
                        } else {
                            ApiService.getAllCars(data, token).then(
                                (response) => {
                                    if (response && response.success) {
                                        const cars = response.data;
                                        let updatedCars = cars.map((e: any) => {
                                            let carCategory: any = categories.find(
                                                (category: any) => {
                                                    return (
                                                        category &&
                                                        parseInt(
                                                            category.id
                                                        ) ===
                                                            parseInt(
                                                                e.category_id
                                                            )
                                                    );
                                                }
                                            );
                                            let updatedCar = {
                                                ...e,
                                                title:
                                                    carCategory &&
                                                    carCategory.name,
                                            };
                                            return updatedCar;
                                        });

                                        this.setState({
                                            cars: updatedCars,
                                            showLoader: false,
                                            totalPages: response.total,
                                        });
                                    }
                                }
                            );
                        }
                    }
                }
            );
        }

        if (this.state.sorterName !== prevState.sorterName) {
            this.setState({ sorterName: "", sorterDirection: "" });
        }
    }

    handleRedirect = (id) => {
        this.props.history.push(`/car/${id}/basic-info`);
    };

    handleSort = (sortableName) => {
        const { currentPage, perPage, sorterDirection } = this.state;
        const token = StoreService.getStoreProperty("token");
        const offset = (parseInt(currentPage) - 1) * perPage;
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";

        ApiService.getAllCategories({ limit: 1000, offset: 0 }, token).then(
            (response) => {
                if (response && response.success) {
                    const categories = response.data;
                    ApiService.getAllCars(
                        {
                            limit: perPage,
                            offset,
                            sorter: sortableName,
                            sorter_direction,
                        },
                        token
                    ).then((response) => {
                        if (response && response.success) {
                            const cars = response.data;
                            let updatedCars = cars.map((e: any) => {
                                let carCategory: any = categories.find(
                                    (category: any) => {
                                        return (
                                            category &&
                                            parseInt(category.id) ===
                                                parseInt(e.category_id)
                                        );
                                    }
                                );
                                let updatedCar = {
                                    ...e,
                                    title: carCategory && carCategory.name,
                                };
                                return updatedCar;
                            });
                            this.setState({
                                cars: updatedCars,
                                totalPages: response.total,
                                showLoader: false,
                                sorterDirection: sorter_direction,
                                sorterName: sortableName,
                            });
                        }
                    });
                }
            }
        );
    };

    handleFilterChange = (name, data) => {
        const { filterForm } = this.state;
        const filterFormKeys = Object.keys(filterForm);

        if (filterFormKeys.indexOf(name) !== filterFormKeys.length - 1) {
            const resetFrom = filterFormKeys.indexOf(name);

            for (let key of filterFormKeys) {
                let keyIndex = filterFormKeys.indexOf(key);
                if (keyIndex > resetFrom) {
                    filterForm[key] = {
                        value: "",
                        errors: [],
                    };
                }
            }
        }
        this.setState({
            filterForm: {
                ...filterForm,
                [name]: {
                    value: data.value,
                    errors: [],
                },
            },
            showFilterLoader: true,
        });
    };

    handleDateFilters = (name, data) => {
        if (name === "relativeTimeFilter") {
            this.setState({ relativeTimeFilter: data.value });

            let start_date: Date | null = new Date();
            let end_date: Date | null = null;

            if (data.value === "no_filter") {
                start_date = null;
                end_date = null;
            } else {
                end_date =
                    data.value === "today"
                        ? new Date()
                        : data.value === "this_week"
                        ? new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                        : new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
            }

            this.setState((prevState: any) => ({
                ...prevState,
                dateFilterForm: {
                    ...prevState.dateFilterForm,
                    start_date: {
                        value: start_date,
                        errors: [],
                    },
                    end_date: {
                        value: end_date,
                        errors: [],
                    },
                },
            }));
        } else {
            this.setState((prev: any) => ({
                ...prev,
                dateFilterForm: {
                    ...prev.dateFilterForm,
                    [name]: {
                        value: data.value,
                        errors: [],
                    },
                },
            }));
        }
    };

    handleCheckbox = (name, checked) => {
        if (name === "finished") {
            this.setState((prev: any) => ({
                ...prev,
                dateFilterForm: {
                    ...prev.dateFilterForm,
                    [name]: checked ? 1 : 0,
                },
            }));
        } else {
            this.setState((prev: any) => ({
                ...prev,
                dateFilterForm: {
                    ...prev.dateFilterForm,
                    [name]: checked,
                },
            }));
        }
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handlePageClick = (pageNumber) => {
        this.props.history.push(`/cars-availability/${pageNumber}`);
    };

    toggleFilterSidebar = (e) => {
        e.preventDefault();
        this.setState({ showFilterSidebar: !this.state.showFilterSidebar });
    };

    submitFilters = (e) => {
        e.preventDefault();

        const filters = {};
        const filterForm = {
            ...this.state.filterForm,
            ...this.state.dateFilterForm,
        };
        for (let filter in filterForm) {
            if (filter === "type" && filterForm[filter]) {
                filters[filter] = 2;
            } else if (filter === "finished" || filter === "in_range") {
                filters[filter] = filterForm[filter];
            } else if (/date/.test(filter)) {
                filters[filter] = parseDateForMySQL(filterForm[filter].value);
            } else if (filterForm[filter].value !== "") {
                filters[filter] = filterForm[filter].value;
            }
        }

        this.setState({ filters, showLoader: true, currentPage: "1" }, () => {
            this.props.history.push("/cars-availability/1");
        });
    };

    resetFilters = (e) => {
        e.preventDefault();
        this.setState({
            filterForm: {
                category_id: {
                    value: "",
                    errors: [],
                },
                manufacturer: {
                    value: "",
                    errors: [],
                },
                model: {
                    value: "",
                    errors: [],
                },
                mileage: {
                    value: "",
                    errors: [],
                },
            },
            dateFilterForm: {
                start_date: {
                    value: null,
                    errors: [],
                },
                end_date: {
                    value: null,
                    errors: [],
                },
                in_range: true,
                finished: 0,
            },
            relativeTimeFilter: "no_filter",
            filters: {},
            showLoader: true,
        });
    };

    render() {
        const {
            showLoader,
            showFilterLoader,
            currentPage,
            totalPages,
            perPage,
            showFilterSidebar,
            filterForm,
            categoryOptions,
            manufacturerOptions,
            modelOptions,
            mileageOptions,
            cars,
            dateFilterForm,
            relativeNextOptions,
        } = this.state;
        return (
            <div>
                <FilterSidebar
                    onClose={this.toggleFilterSidebar}
                    submitFilters={this.submitFilters}
                    resetFilters={this.resetFilters}
                    sidebarClass={showFilterSidebar ? "filter-sidebar-on" : ""}
                    wrapperClass={
                        showFilterSidebar ? "filter-sidebar-wrapper-on" : ""
                    }
                    titleMargin="mb-10"
                >
                    <div className="mb-30">
                        {showFilterLoader && (
                            <div className="loader-wrapper">
                                <Loader className="w-150 loader-sidebar"></Loader>
                            </div>
                        )}
                        <CustomDropdown
                            data={categoryOptions}
                            name="category_id"
                            label="CATEGORY"
                            value={categoryOptions.find((el: any) => {
                                return (
                                    filterForm.category_id.value === el.value
                                );
                            })}
                            handleChange={this.handleFilterChange}
                            placeholder={<Translate text="CHOOSE_CATEGORY" />}
                            className="text-center"
                        />
                    </div>
                    <div className="mb-30">
                        <CustomDropdown
                            data={manufacturerOptions}
                            name="manufacturer"
                            label="MANUFACTURER"
                            value={manufacturerOptions.find((el: any) => {
                                return (
                                    filterForm.manufacturer.value === el.value
                                );
                            })}
                            handleChange={this.handleFilterChange}
                            placeholder={
                                <Translate text="CHOOSE_MANUFACTURER" />
                            }
                            className="text-center"
                        />
                    </div>
                    <div className="mb-30">
                        <CustomDropdown
                            data={modelOptions}
                            name="model"
                            label="MODEL"
                            value={modelOptions.find((el: any) => {
                                return filterForm.model.value === el.value;
                            })}
                            handleChange={this.handleFilterChange}
                            placeholder={<Translate text="CHOOSE_MODEL" />}
                            className="text-center"
                        />
                    </div>
                    <div className="mb-25">
                        <CustomDropdown
                            data={mileageOptions}
                            name="mileage"
                            label="MILEAGE"
                            handleChange={this.handleFilterChange}
                            placeholder={<Translate text="CHOOSE_MILEAGE" />}
                            className="text-center"
                        />
                    </div>

                    <h2 className="mb-25 f-s-18">Dostupnost vozila</h2>
                    <div className="mb-30">
                        <CustomDropdown
                            name="relativeTimeFilter"
                            value={relativeNextOptions.find(
                                (option: any) =>
                                    option.value ===
                                    this.state.relativeTimeFilter
                            )}
                            data={relativeNextOptions}
                            handleChange={this.handleDateFilters}
                            label="RELATIVE_DATE"
                            className="text-center"
                        ></CustomDropdown>
                    </div>
                    <div className="mb-30">
                        <CustomDatePicker
                            name="start_date"
                            label="START_DATE"
                            value={dateFilterForm.start_date.value}
                            handleInput={this.handleDateFilters}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomDatePicker
                            name="end_date"
                            label="END_DATE"
                            value={dateFilterForm.end_date.value}
                            handleInput={this.handleDateFilters}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-250 mb-10">
                        <span className="f-s-16 mr-20">
                            <Translate text="IN_RANGE" />
                        </span>
                        <CustomCheckbox
                            name="in_range"
                            checked={dateFilterForm.in_range}
                            handleChange={this.handleCheckbox}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-250 mb-10">
                        <span className="f-s-16 mr-20">
                            <Translate text="ONLY_CONTRACTS" />
                        </span>
                        <CustomCheckbox
                            name="type"
                            checked={!!dateFilterForm.type}
                            handleChange={this.handleCheckbox}
                        />
                    </div>
                    <div className="d-flex align-items-center justify-content-between w-250">
                        <span className="f-s-16 mr-20">
                            <Translate text="FINISHED_CONTRACTS" />
                        </span>
                        <CustomCheckbox
                            name="finished"
                            checked={!!dateFilterForm.finished}
                            handleChange={this.handleCheckbox}
                        />
                    </div>
                </FilterSidebar>
                <div className="d-flex w-100-perc border-b-1">
                    <nav className="d-flex">
                        <NavLink
                            to="/cars/1"
                            className={`${
                                this.props.match.url ===
                                    `/cars/${this.props.match.params.pageNo}` &&
                                "active"
                            } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                            activeClassName=""
                        >
                            <Translate text="CARS_OVERVIEW" />
                        </NavLink>
                        <NavLink
                            to="/cars-availability/1"
                            className={`${
                                this.props.match.url ===
                                    `/cars-availability/${this.props.match.params.pageNo}` &&
                                "active"
                            } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                            activeClassName=""
                        >
                            <Translate text="CARS_AVAILABILITY" />
                        </NavLink>
                    </nav>
                </div>
                <div className="d-flex py-20 justify-content-end">
                    <CustomButton
                        className="filter-btn mr-10"
                        type="button"
                        onClick={this.toggleFilterSidebar}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <Translate text="FILTERS" />
                        </div>
                    </CustomButton>
                </div>
                <div className="mt-20 f-s-14">
                    {showLoader ? (
                        <Loader className="w-200" />
                    ) : (
                        <>
                            <Table
                                theads={this.state.carsTableHeads}
                                theadsClassname="customThead"
                                handleSort={this.handleSort}
                            >
                                {!!cars &&
                                    cars.map((e: any, i) => {
                                        return (
                                            <tr
                                                className="pointer"
                                                onClick={() =>
                                                    this.handleRedirect(e.id)
                                                }
                                                key={i}
                                            >
                                                <td>{e.manufacturer}</td>
                                                <td>{e.model}</td>
                                                <td>
                                                    {e.title !== "" ? (
                                                        e.title
                                                    ) : (
                                                        <Translate text="NO_CATEGORY"></Translate>
                                                    )}
                                                </td>
                                                <td>{e.licence_plate}</td>
                                                <td>
                                                    {parseDate(
                                                        e.registration_date
                                                    )}
                                                </td>
                                                <td>{e.unique_number}</td>
                                                <td>{e.fuel_type}</td>
                                                <td>{e.mileage}</td>
                                            </tr>
                                        );
                                    })}
                            </Table>
                            {cars && cars[0] && (
                                <div className="d-flex justify-content-center mt-30">
                                    <Paginate
                                        totalItemsCount={totalPages}
                                        onChange={this.handlePageClick}
                                        activePage={parseInt(currentPage)}
                                        perPage={perPage}
                                        pageRangeDisplayed={5}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default CarsAvailability;
