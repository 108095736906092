export const crpsForm = {
    naziV_DRUSTVA: { value: "", errors: [] },
    skracenI_NAZIV_DRUSTVA: { value: "", errors: [] },
    adresA_PRIJEM: { value: "", errors: [] },
    adresA_SJEDISTE: { value: "", errors: [] },
    broJ_PROMJENE: { value: "", errors: [] },
    datuM_PROMJENE: { value: "", errors: [] },
    datuM_REGISTRACIJE: { value: "", errors: [] },
    djelatnost: { value: "", errors: [] },
    iD_PREDMET: { value: "", errors: [] },
    maticnI_BROJ_DRUSTVA: { value: "", errors: [] },
    obliK_ORGANIZOVANJA: { value: "", errors: [] },
    opstinA_PRIJEM: { value: "", errors: [] },
    opstinA_SJEDISTE: { value: "", errors: [] },
    rbr: { value: "", errors: [] },
    reG_BROJ: { value: "", errors: [] },
    sifrA_DJELATNOSTI: { value: "", errors: [] },
    statuS_DRUSTVA: { value: "", errors: [] },
    ukupaN_KAPITAL: { value: "", errors: [] },
};

export const form = {
    PDV: {
        value: "",
        errors: [],
    },
    PIB: {
        value: "",
        errors: [],
    },
    address: {
        value: "",
        errors: [],
    },
    email: {
        value: "",
        errors: [],
    },
    id: {
        value: "",
        errors: [],
    },
    name: {
        value: "",
        errors: [],
    },
    phone: {
        value: "",
        errors: [],
    },
    remark: {
        value: "",
        errors: [],
    },
    documents: [{ path: "", id: null, updated_at: null, created_at: null }],
    full_name: {
        value: "",
        errors: [],
    },
    mailing_address: {
        value: "",
        errors: [],
    },
    address2: {
        value: "",
        errors: [],
    },
    change_number: {
        value: "",
        errors: [],
    },
    update_date: {
        value: "",
        errors: [],
    },
    founding_date: {
        value: "",
        errors: [],
    },
    activity_name: {
        value: "",
        errors: [],
    },
    id_subject: {
        value: "",
        errors: [],
    },
    types_of_organization: {
        value: "",
        errors: [],
    },
    mailing_city: {
        value: "",
        errors: [],
    },
    city: {
        value: "",
        errors: [],
    },
    reg_no: {
        value: "",
        errors: [],
    },
    reg_no2: {
        value: "",
        errors: [],
    },
    activity_code: {
        value: "",
        errors: [],
    },
    status: {
        value: "",
        errors: [],
    },
    capital: {
        value: "",
        errors: [],
    },
    staff: [],
};
