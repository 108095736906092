import { SidebarItems } from "services/sidebar.items.servise";

/*
 *  * Usage & Purpose *
 *
 *  `ConstantsService` handles important variables used globally across the Application.
 */
export const ConstantsService = {
    PROD_MODE: false,
    URL_BASE: "https://api.fleet.rent/api", //https://rentapi.oykos.dev/api
    DEFAULT_LANGUAGE: "srb",
    LOCAL_STORAGE_KEY: "sektorRentACarOnlineStorage",
    COOKIE_KEY_LATEST_LOG_IN: "sektorRentACarLatestLogin",
    FILE_STACK_ID: "AELTjULEzScSWWJMbNLTzz",
    REDIRECTION_STORE_PROPERTY: "redirectionPath",
    THUMBNAIL_PLACEHOLDER_WIDTH: 270,
    POLL_INTERVAL: 30000,
    DEBUG_LOG_MODE: 0,
    SAFARI: false,
    SIDEBAR_ITEMS: SidebarItems,
    DEBUG_LOG_COLORS: {
        3: {
            color: "#23bc5d",
            background: "#333",
        },
        2: {
            color: "#23bcab",
            background: "#333",
        },
        1: {
            color: "#104091",
            background: "#fff",
        },
    },
    CAR_SERVICES: {
        REGULAR_SERVICE: 1,
        UNPLANNED_SERVICE: 2,
        DAMAGE_SERVICE: 3,
    },
    CONTRACTS_TYPES: {
        RESERVATIONS: 1,
        CONTRACTS: 2,
    },
};
