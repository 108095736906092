import { CustomDateTimePicker } from "devkit/Form/Form";
import React, { Component } from "react";

import CustomForm from "../../../../devkit/Form/CustomForm/CustomForm";
import CustomInput from "devkit/Form/CustomInput/CustomInput";
import CustomTextArea from "../../../../devkit/Form/CustomTextArea/CustomTextArea";
import Translate from "../../../../functions/utilFunctions/translate";

class RentInformation extends Component<any> {
    render() {
        return (
            <div>
                <CustomForm>
                    <div>
                        <span className="subtitles">
                            <Translate text="RENT_START" />
                        </span>
                        <div className="mb-29 mt-10">
                            <CustomInput
                                name="rent_start_place"
                                label="LOCATION"
                                handleInput={this.props.handleInput}
                                type="text"
                                value={
                                    this.props.rentInfo.rent_start_place.value
                                }
                                errors={
                                    this.props.rentInfo.rent_start_place.errors
                                }
                            />
                        </div>
                    </div>
                    <div className="mb-29">
                        <CustomDateTimePicker
                            name="rent_start_date"
                            label="DATE"
                            handleInput={this.props.handleInput}
                            errors={this.props.rentInfo.rent_start_date.errors}
                            value={this.props.rentInfo.rent_start_date.value}
                        />
                    </div>
                    <div className="mb-50">
                        <CustomTextArea
                            name="rent_start_remark"
                            label="REMINDER"
                            handleInput={this.props.handleInput}
                            value={this.props.rentInfo.rent_start_remark.value}
                            // errors={
                            //     this.props.rentInfo.rent_start_remark
                            //         .errors
                            // }
                        />
                    </div>
                    <div>
                        <span className="subtitles">
                            <Translate text="RENT_END" />
                        </span>

                        <div className="mb-29 mt-10">
                            <CustomInput
                                name="rent_end_place"
                                label="LOCATION"
                                handleInput={this.props.handleInput}
                                type="text"
                                value={this.props.rentInfo.rent_end_place.value}
                                errors={
                                    this.props.rentInfo.rent_end_place.errors
                                }
                            />
                        </div>
                    </div>
                    <div className="mb-29">
                        <CustomDateTimePicker
                            name="rent_end_date"
                            label="DATE"
                            handleInput={this.props.handleInput}
                            errors={this.props.rentInfo.rent_end_date.errors}
                            value={this.props.rentInfo.rent_end_date.value}
                        />
                    </div>
                    <div>
                        <CustomTextArea
                            name="rent_end_remark"
                            label="REMINDER"
                            handleInput={this.props.handleInput}
                            value={this.props.rentInfo.rent_end_remark.value}
                        />
                    </div>
                </CustomForm>
            </div>
        );
    }
}
export default RentInformation;
