import React, { Component } from "react";
import Translate from "functions/utilFunctions/translate";
import { StoreService } from "services/store.service";
import { CustomButton, CustomInput, CustomForm } from "devkit/Form/Form";
import { LeftArrow } from "../../components/Icons/Icons";
import Modal from "devkit/Modal/Modal";
import { Loader } from "devkit/Loader/Loader";
import { ModalContent } from "devkit/Modal/ModalContent";
import { Link } from "react-router-dom";
import ApiService from "services/api.service";
import { UtilService } from "services/util.service";
class AdditionalEquipmentDetails extends Component<any> {
    state = {
        form: {
            id: {
                value: "",
                errors: [],
            },
            name: {
                value: "",
                errors: [],
            },
            price: {
                value: "",
                errors: [],
            },
            created_at: {
                value: null,
                errors: [],
            },
            updated_at: {
                value: null,
                errors: [],
            },
        },
        showLoader: true,
        showUpdateErrorModal: false,
        showUpdateSuccessModal: false,
        showCreateErrorModal: false,
        showCreateSuccessModal: false,
        isNewEquipment: !this.props.match.params.id,
        currentEquipment: { name: "", price: "" },
    };

    componentDidMount() {
        if (!this.state.isNewEquipment) {
            const equipmentID = this.props.match.params.id;
            const token = StoreService.getStoreProperty("token");
            ApiService.getEquipment(equipmentID, token).then((response) => {
                const currentEquipment = response.data;
                const equipmentFormated: any = {};
                if (currentEquipment) {
                    Object.keys(currentEquipment).forEach((e) => {
                        equipmentFormated[e] = {
                            value: currentEquipment[e],
                            errors: [],
                        };
                    });
                }
                this.setState((prevState: any) => ({
                    ...prevState,
                    currentEquipment,
                    form: {
                        ...equipmentFormated,
                    },
                    showLoader: false,
                }));
            });
        } else {
            this.setState({ showLoader: false });
        }
    }

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                },
            },
        }));
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
        if (
            name === "showCreateSuccessModal" ||
            name === "showUpdateSuccessModal"
        ) {
            this.props.history.goBack();
        }
    };

    handleSave = (e) => {
        e.preventDefault();
        const { name, price } = this.state.form;
        const { currentEquipment } = this.state;
        const data = { name, price };
        const errors = {};
        const isNewEquipment = this.state.isNewEquipment;
        const token = StoreService.getStoreProperty("token");
        Object.keys(data).forEach((d) => {
            if (!data[d].value) {
                errors[d] = {
                    value: data[d].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });
        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            const currentEq = {
                name: currentEquipment.name,
                price: currentEquipment.price,
            };
            const changedEquipment = {
                name: name.value,
                price: price.value,
            };
            const equal = UtilService.areObjectsEqual(
                currentEq,
                changedEquipment
            );

            if (isNewEquipment) {
                ApiService.createEquipment(
                    {
                        name: this.state.form.name.value,
                        price: this.state.form.price.value,
                    },
                    token
                ).then((response) => {
                    if (response.success === true) {
                        this.setState({ showCreateSuccessModal: true });
                    } else {
                        this.setState({ showCreateErrorModal: true });
                    }
                });
            } else {
                if (!equal) {
                    ApiService.updateEquipment(
                        {
                            id: this.state.form.id.value,
                            name: this.state.form.name.value,
                            price: this.state.form.price.value,
                        },
                        token
                    ).then((response) => {
                        if (response.success === true) {
                            this.setState({ showUpdateSuccessModal: true });
                        } else {
                            this.setState({ showUpdateErrorModal: true });
                        }
                    });
                } else return;
            }
        }
    };

    handleModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
        }));
        if (
            name === "showCreateSuccessModal" ||
            name === "showUpdateSuccessModal"
        ) {
            this.props.history.goBack();
        }
    };

    handleSaveSuccess = () => {
        this.props.history.push("/equipment");
    };
    render() {
        const { form, showLoader } = this.state;
        return (
            <div>
                <Modal
                    modalName="showCreateErrorModal"
                    toggleModal={this.handleModal}
                    className={this.state.showCreateErrorModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="ADD_EQUIPMENT_FAILED"
                        modalName="showCreateErrorModal"
                        title="FAILED"
                        toggleModal={this.handleModal}
                    ></ModalContent>
                </Modal>
                <Modal
                    modalName="showCreateSuccessModal"
                    toggleModal={this.handleModal}
                    className={
                        this.state.showCreateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        text="ADD_EQUIPMENT_SUCCESSFUL"
                        modalName="showCreateSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.handleModal}
                    ></ModalContent>
                </Modal>
                <Modal
                    modalName="showUpdateErrorModal"
                    toggleModal={this.handleModal}
                    className={this.state.showUpdateErrorModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="EDIT_EQUIPMENT_FAILED"
                        modalName="showUpdateErrorModal"
                        title="FAILED"
                        toggleModal={this.handleModal}
                    ></ModalContent>
                </Modal>
                <Modal
                    modalName="showUpdateSuccessModal"
                    toggleModal={this.handleModal}
                    className={
                        this.state.showUpdateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        text="EDIT_EQUIPMENT_SUCCESSFUL"
                        modalName="showUpdateSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.handleModal}
                    ></ModalContent>
                </Modal>
                <div className="d-flex padding-20 justify-content-between border-b-1">
                    <div className="d-flex row align-items-center justify-content-center">
                        <Link to="/equipment">
                            <span
                                onClick={this.handleBack}
                                className="pointer d-flex align-items-center goBack mr-10"
                            >
                                <LeftArrow width={18} className="" />
                            </span>
                        </Link>
                        <div className="pageHeader">
                            {this.state.isNewEquipment ? (
                                <Translate text={"ADD_EQUIPMENT"}></Translate>
                            ) : (
                                <Translate text={"EDIT_EQUIPMENT"}></Translate>
                            )}
                        </div>
                    </div>
                    <div>
                        <CustomButton
                            className="btn-primary justify-content-center"
                            type="button"
                            onClick={this.handleSave}
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="f-s-15 text-left px-10">
                                    {this.state.isNewEquipment ? (
                                        <Translate text={"SAVE"}></Translate>
                                    ) : (
                                        <Translate
                                            text={"SAVE_CHANGES"}
                                        ></Translate>
                                    )}
                                </div>
                            </div>
                        </CustomButton>
                    </div>
                </div>
                {showLoader ? (
                    <div className="loaderContainer">
                        <Loader width={200} />
                    </div>
                ) : (
                    <div className="max-width-400 mt-50 d-flex justify-content-center pl-10">
                        <CustomForm className="w-100-perc d-flex flex-column align-items-center border-radius-20">
                            <div className="mb-30 w-100-perc">
                                <CustomInput
                                    name="name"
                                    type="text"
                                    label="EQUIP_NAME"
                                    value={form.name.value}
                                    errors={form.name.errors}
                                    handleInput={this.handleInput}
                                ></CustomInput>
                            </div>
                            <div className="mb-30 w-100-perc">
                                <CustomInput
                                    name="price"
                                    type="number"
                                    label="EQUIP_PRICE"
                                    value={form.price.value}
                                    errors={form.price.errors}
                                    handleInput={this.handleInput}
                                ></CustomInput>
                            </div>
                        </CustomForm>
                    </div>
                )}
            </div>
        );
    }
}

export default AdditionalEquipmentDetails;
