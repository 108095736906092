import {
    CustomForm,
    CustomInput,
    CustomButton,
    CustomDatePicker,
} from "devkit/Form/Form";
import React, { Component } from "react";
import Translate from "functions/utilFunctions/translate";

class PersonalAccountAfter extends Component<any> {
    render() {
        const {
            email,
            phone,
            date_of_birth,
            date_of_employment,
            first_name,
            last_name,
        } = this.props.data;

        return (
            <CustomForm className="d-flex h-100perc flex-column justify-content-around">
                <div className="d-flex  space-between mb-30">
                    <div className="mr-10">
                        <CustomInput
                            name="first_name"
                            label="FIRST_NAME"
                            value={first_name.value}
                            type="text"
                            errors={first_name.errors}
                            handleInput={this.props.handleInput}
                            required={true}
                            edit={this.props.edit}
                        />
                    </div>
                    <div className="">
                        <CustomInput
                            name="last_name"
                            label="LAST_NAME"
                            value={last_name.value}
                            type="text"
                            errors={last_name.errors}
                            handleInput={this.props.handleInput}
                            required={true}
                            edit={this.props.edit}
                        />
                    </div>
                </div>

                <div className="mb-30">
                    <CustomInput
                        name="email"
                        label="EMAIL"
                        value={email.value}
                        type="email"
                        errors={email.errors}
                        handleInput={this.props.handleInput}
                        required={true}
                        edit={this.props.edit}
                    />
                </div>
                <div className="mb-30">
                    <CustomInput
                        name="phone"
                        label="PHONE"
                        value={phone.value ? phone.value : ""}
                        type="number"
                        errors={phone.errors}
                        handleInput={this.props.handleInput}
                        edit={this.props.edit}
                    />
                </div>

                <div className="mb-30">
                    <CustomDatePicker
                        name="date_of_birth"
                        handleInput={this.props.handleInput}
                        value={date_of_birth.value}
                        errors={date_of_birth.errors}
                        label="DATE_OF_BIRTH"
                    />
                </div>
                <div className="mb-20">
                    <CustomDatePicker
                        name="date_of_employment"
                        handleInput={this.props.handleInput}
                        value={date_of_employment.value}
                        errors={date_of_employment.errors}
                        label="EMPLOYMENT_DATE"
                    />
                </div>
                <div className="d-flex justify-content-center mt-10">
                    <CustomButton
                        className="btn-save"
                        type="button"
                        onClick={this.props.handleSubmit}
                    >
                        <Translate text="SAVE" />
                    </CustomButton>
                </div>
            </CustomForm>
        );
    }
}

export default PersonalAccountAfter;
