import { LeftArrow, Avatar, UploadIcon } from "components/Icons/Icons";
import {
    CustomButton,
    CustomDatePicker,
    CustomDropdown,
    CustomFileInput,
    CustomForm,
    CustomInput,
    CustomTextArea,
} from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";
import React, { Component } from "react";
import { StoreService } from "services/store.service";
import ApiService from "services/api.service";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import { parseDateForMySQL } from "functions/utilFunctions/parsingFunctions";
import { Loader } from "devkit/Loader/Loader";
import { UtilService } from "services/util.service";
import PrivateFiles from "../PersonalAccount/PrivateFiles";
class ManagerDetails extends Component<any> {
    state = {
        test: "",
        form: {
            password: {
                value: "",
                errors: [],
            },
            pin: {
                value: "",
                errors: [],
            },
            first_name: {
                value: "",
                errors: [],
            },
            last_name: {
                value: "",
                errors: [],
            },
            role: {
                value: 0,
                errors: [],
            },
            email: {
                value: "",
                errors: [],
            },
            phone: {
                value: "",
                errors: [],
            },
            date_of_birth: {
                value: null,
                errors: [],
            },
            date_of_employment: {
                value: null,
                errors: [],
            },
            profile_image: {
                value: null,
                errors: [],
            },
            remark: {
                value: "",
                errors: [],
            },
            new_password: {
                value: "",
                errors: [],
            },
            repeat_new_password: {
                value: "",
                errors: [],
            },
            new_pin: {
                value: "",
                errors: [],
            },
            repeat_new_pin: {
                value: "",
                errors: [],
            },
            public_files: [
                { path: "", id: null, updated_at: null, created_at: null },
            ],
            admin_files: [
                { path: "", id: null, updated_at: null, created_at: null },
            ],
        },
        roles: [
            { value: 3, label: <Translate text="ADMIN" /> },
            { value: 2, label: <Translate text="MANAGER" /> },
        ],
        public_file: {
            value: null,
        },
        admin_file: {
            value: null,
        },
        showSaveFailedModal: false,
        showSaveSuccessfulModal: false,
        showEditFailedModal: false,
        showEditSuccessfulModal: false,
        isCreateNew: !this.props.match.params.id,
        currentManager: {},
        photo: {
            value: null,
            errors: [],
        },
        tempPhoto: "",
        public_files: [
            { path: "", id: null, updated_at: null, created_at: null },
        ],
        admin_files: [
            { path: "", id: null, updated_at: null, created_at: null },
        ],
        uploading: false,
        changeType: "",
        showPasswordModal: false,
        showSuccessModal: false,
        showSuccessModalData: false,
        showFailedModal: false,
        showPinModal: false,
        modalSuccessText: "",
        modalFailedText: "",
        showSameValue: false,
        completed: 0,
        isOver: false,
        imageUploadModal: false,
        fileUploadModal: false,
        fileUploadErrorModal: false,
        showImageUploadFailedModal: false,
        // showTakenMailModal: false,
        isLoading: true,
        currentTab: "personalAccount",
        role: 3,
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        const token = StoreService.getStoreProperty("token");
        if (this.state.isCreateNew) {
            this.setState({
                isLoading: false,
            });
        }
        ApiService.getUser(id, token).then((response) => {
            if (response && response.success) {
                const currentManager = response.data;
                delete currentManager.updated_at;
                delete currentManager.created_at;
                currentManager.remark = currentManager.remark || "";
                this.setState({
                    currentManager: currentManager,
                    admin_files: response.data.admin_files,
                    public_files: response.data.public_files,
                    isLoading: false,
                    role: StoreService.getStoreProperty("user").role,
                });
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentManager !== this.state.currentManager) {
            const currentManager = this.state.currentManager;
            const currentManagerFormatted = {};
            if (currentManager) {
                Object.keys(currentManager).forEach((e) => {
                    if (e === "date_of_birth" || e === "date_of_employment") {
                        currentManagerFormatted[e] = {
                            value: new Date(currentManager[e]),
                            errors: [],
                        };
                    } else {
                        currentManagerFormatted[e] = {
                            value: currentManager[e],
                            errors: [],
                        };
                    }
                });
            }
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...currentManagerFormatted,
                },
            }));
        }
        if (
            prevState.photo.value !== this.state.photo.value &&
            this.state.photo.value
        ) {
            this.setState({ uploading: true });
            this.handleUploadImage();
        }
        if (
            prevState.admin_file.value !== this.state.admin_file.value &&
            this.state.admin_file.value
        ) {
            this.setState({ uploading: true });
            this.handleAddDocument();
        }
        if (
            prevState.public_file.value !== this.state.public_file.value &&
            this.state.public_file.value
        ) {
            this.setState({ uploading: true });
            this.handleAddDocument();
        }
    }
    handleBack = () => {
        this.props.history.goBack();
    };

    handleInput = (name, data) => {
        if (name === "pin") {
            if (!data.value) {
                data.value = "";
            } else {
                let isNum = data.value[data.value.length - 1].match(/[0-9]/g);
                if (!isNum)
                    data.value = data.value.substring(0, data.value.length - 1);
            }
        }
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleSave = (e) => {
        e.preventDefault();
        const { currentManager } = this.state;
        const {
            first_name,
            last_name,
            role,
            email,
            phone,
            date_of_birth,
            date_of_employment,
        } = this.state.form;
        const data: any = {
            first_name,
            last_name,
            role,
            email,
            phone,
            date_of_birth,
            date_of_employment,
        };
        let managerFormated: any = currentManager;

        const { password, pin } = this.state.form;
        const passAndPin = { password, pin };
        const errors: any = {};

        Object.keys(data).forEach((d) => {
            if (!data[d].value) {
                errors[d] = {
                    value: data[d].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });

        if (this.state.isCreateNew) {
            Object.keys(passAndPin).forEach((p) => {
                if (
                    !passAndPin[p].value ||
                    (p === "pin" && passAndPin[p].value.length !== 4)
                ) {
                    errors["pin"] = {
                        value: passAndPin[p].value,
                        errors: ["PIN_LENGTH_NOT_VALID"],
                    };
                }
                if (
                    !passAndPin[p].value ||
                    (p === "password" && passAndPin[p].value.length < 6)
                ) {
                    errors["password"] = {
                        value: passAndPin[p].value,
                        errors: ["PASSWORD_LENGTH_NOT_VALID"],
                    };
                }
            });
        }

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            const token = StoreService.getStoreProperty("token");
            if (this.state.isCreateNew) {
                const formData = new FormData();
                Object.keys(this.state.form).forEach((e) => {
                    if (e === "date_of_birth" || e === "date_of_employment") {
                        formData.append(
                            e,
                            parseDateForMySQL(this.state.form[e].value)
                        );
                    } else if (e === "profile_image") {
                        if (this.state.form[e].value !== null) {
                            formData.append(e, this.state.form[e].value!);
                        }
                    } else {
                        formData.append(e, this.state.form[e].value);
                    }
                });

                ApiService.createUser(formData, token).then((response) => {
                    if (response && response.success) {
                        this.setState({ showSaveSuccessfulModal: true });
                    } else {
                        if (response.message.includes("EMAIL_TAKEN")) {
                            this.setState((prevState: any) => ({
                                form: {
                                    ...prevState.form,
                                    email: {
                                        ...prevState.form.email,
                                        errors: ["EMAIL_TAKEN"],
                                    },
                                },
                            }));
                        }
                        this.setState({ showSaveFailedModal: true });
                    }
                });

                // ApiService.createUser(formData, token).then((response) => {
                //     if (response && response.success) {
                //         this.setState({ showSaveSuccessfulModal: true });
                //     } else {
                //         if (response.message === "EMAIL_TAKEN") {
                //             this.setState({ showTakenMailModal: true });
                //         } else {
                //             this.setState({ showSaveFailedModal: true });
                //         }
                //     }
                // });
            } else {
                const data = {
                    first_name: this.state.form.first_name.value,
                    last_name: this.state.form.last_name.value,
                    email: this.state.form.email.value,
                    phone: this.state.form.phone.value,
                    date_of_birth: parseDateForMySQL(
                        this.state.form.date_of_birth.value
                    ),
                    date_of_employment: parseDateForMySQL(
                        this.state.form.date_of_employment.value
                    ),
                    role: this.state.form.role.value,
                    id: this.props.match.params.id,
                    remark: this.state.form.remark.value,
                };
                delete managerFormated.email_verified_at;
                delete managerFormated.profile_image;
                delete managerFormated.files;
                managerFormated.id = data.id.toString();
                managerFormated.date_of_employment = parseDateForMySQL(
                    managerFormated.date_of_employment
                );
                managerFormated.date_of_birth = parseDateForMySQL(
                    managerFormated.date_of_birth
                );
                const equal = !UtilService.areObjectsEqual(
                    managerFormated,
                    data
                );

                if (equal) {
                    ApiService.updateUser(data, token).then((response) => {
                        if (response && response.success) {
                            this.setState({ showEditSuccessfulModal: true });
                        } else {
                            this.setState({ showEditFailedModal: true });
                        }
                    });
                }
            }
        }
    };
    handleUploadImage = () => {
        const id = this.props.match.params.id;
        const token = StoreService.getStoreProperty("token");
        const photo = this.state.photo.value || "";
        const formData = new FormData();
        formData.append("profile_image", photo);
        formData.append("id", id);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        if (!this.state.isCreateNew) {
            ApiService.uploadUserImage(formData, token, config).then((res) => {
                if (res.success === true) {
                    this.setState((prevState: any) => ({
                        form: {
                            ...prevState.form,
                            profile_image: {
                                value: res.data.profile_image,
                                errors: [],
                            },
                        },
                        completed: 0,
                        uploadComplete: false,
                        imageUploadModal: false,
                        uploading: false,
                        profile_image: {
                            value: null,
                            errors: [],
                        },
                    }));
                } else {
                    this.setState({
                        showImageUploadFailedModal: true,
                    });
                }
            });
        } else {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    profile_image: {
                        value: photo,
                        errors: [],
                    },
                },
                completed: 0,
                uploadComplete: false,
                imageUploadModal: false,
                uploading: false,
                profile_image: {
                    value: null,
                    errors: [],
                },
            }));
        }
        if (this.state.isCreateNew) {
            this.showTemporaryPhoto();
        }
    };
    // F-ja za PIN i PSW:
    handleDataChange = (e) => {
        e.preventDefault();
        const id = this.props.match.params.id;
        const { changeType } = this.state;
        let passwords;
        let resetFields;
        let newItem;
        let repeatNewItem;

        if (changeType === "password") {
            newItem = this.state.form.new_password;
            repeatNewItem = this.state.form.repeat_new_password;
            passwords = {
                new_password: newItem,
                repeat_new_password: repeatNewItem,
            };

            resetFields = {
                new_password: {
                    value: "",
                    errors: [],
                },
                repeat_new_password: {
                    value: "",
                    errors: [],
                },
            };
        } else {
            newItem = this.state.form.new_pin;
            repeatNewItem = this.state.form.repeat_new_pin;

            passwords = {
                new_pin: newItem,
                repeat_new_pin: repeatNewItem,
            };
            resetFields = {
                new_pin: {
                    value: "",
                    errors: [],
                },
                repeat_new_pin: {
                    value: "",
                    errors: [],
                },
            };
        }

        const errors = {};
        Object.keys(passwords).forEach((e) => {
            if (!passwords[e].value) {
                errors[e] = {
                    value: passwords[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            } else if (
                changeType === "password" &&
                passwords[e].value.length < 6
            ) {
                errors[e] = {
                    value: passwords[e].value,
                    errors: ["PASSWORD_LENGTH_NOT_VALID"],
                };
            } else if (
                changeType === "password" &&
                newItem.value !== repeatNewItem.value
            ) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        new_password: {
                            value: prevState.form.new_password.value,
                            errors: ["PSW_DONT_MATCH"],
                        },

                        repeat_new_password: {
                            value: prevState.form.new_password.value,
                            errors: ["PSW_DONT_MATCH"],
                        },
                    },
                }));
            } else if (
                changeType !== "password" &&
                passwords[e].value.length !== 4
            ) {
                errors[e] = {
                    value: passwords[e].value,

                    errors: ["PIN_LENGTH_NOT_VALID"],
                };
            } else if (
                changeType !== "password" &&
                newItem.value !== repeatNewItem.value
            ) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        new_pin: {
                            value: passwords[e].value,
                            errors: ["PIN_DONT_MATCH"],
                        },
                        repeat_new_pin: {
                            value: prevState.form.new_password.value,
                            errors: ["PIN_DONT_MATCH"],
                        },
                    },
                }));
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            if (newItem.value !== repeatNewItem.value) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                    },
                }));
            } else {
                let data;
                let url;
                if (changeType === "password") {
                    data = {
                        new_password: newItem.value,
                        id: id,
                    };
                    url = "users/updatePassword";
                } else {
                    data = { new_pin: newItem.value, id: id };
                    url = "users/updatePin";
                }
                const token = StoreService.getStoreProperty("token");

                ApiService.updateData(data, token, url).then((response) => {
                    if (response.success === true) {
                        this.setState((prevState: any) => ({
                            showPasswordModal: false,
                            showPinModal: false,
                            showSuccessModal: true,
                            form: {
                                ...prevState.form,
                                ...resetFields,
                            },
                        }));
                    } else if (
                        (response.success === false &&
                            response.message === "Old pin is not correct") ||
                        response.message === "Old password is not correct"
                    ) {
                        this.setState((prevState: any) => ({
                            showFailedModal: true,
                            form: {
                                ...prevState.form,
                                ...resetFields,
                            },
                        }));
                    } else {
                        this.setState(() => ({
                            showSameValue: true,
                        }));
                    }
                });
            }
        }
    };
    toggleModal = (name) => {
        if (this.state.uploading && name === "showImageUploadFailedModal") {
            this.setState({ uploading: false });
        }
        let resetFields;
        let changeType = "";
        if (name === "showPasswordModal") {
            changeType = "password";
        } else if (name === "showPinModal") {
            changeType = "pin";
        }
        resetFields = {
            new_password: {
                value: "",
                errors: [],
            },
            repeat_new_password: {
                value: "",
                errors: [],
            },
            new_pin: {
                value: "",
                errors: [],
            },
            repeat_new_pin: {
                value: "",
                errors: [],
            },
        };
        this.setState((prevState: any) => ({
            [name]: !this.state[name],
            modalSuccessText: name,
            modalFailedText: name,
            passwordsDontMatch: name,
            changeType,
            form: {
                ...prevState.form,
                ...resetFields,
            },
        }));
    };

    renderPasswordAndPin = () => {
        if (this.state.isCreateNew) {
            return (
                <div className="w-100-perc mt-30">
                    <div className="w-100-perc px-30">
                        <CustomInput
                            name="password"
                            type="password"
                            label="PASSWORD"
                            value={this.state.form.password.value}
                            errors={this.state.form.password.errors}
                            handleInput={this.handleInput}
                            errorClass="error-s"
                        ></CustomInput>
                    </div>
                    <div className="w-100-perc px-30 py-30">
                        <CustomInput
                            name="pin"
                            type="password"
                            label="PIN_CODE"
                            maxLength={4}
                            value={this.state.form.pin.value}
                            errors={this.state.form.pin.errors}
                            handleInput={this.handleInput}
                            errorClass="error-s"
                        ></CustomInput>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="d-flex flex-column border-top-lightgray w-100-perc mt-30">
                    <div
                        className="d-flex justify-content-between pointer modal-hover padding-h-10"
                        onClick={() => this.toggleModal("showPasswordModal")}
                    >
                        <div className="d-flex flex-column pin-pass ">
                            <Translate text="PASSWORD" />
                            <span>********</span>
                        </div>
                        <div className="d-flex padding-r-10">
                            <i
                                className="fas fa-chevron-right align-self-center"
                                style={{
                                    color: "gray",
                                }}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-column border-top-lightgray w-100-perc">
                        <div
                            className="pin-password border-radius-bl-30 border-radius-br-30 d-flex justify-content-between pointer modal-hover padding-h-10"
                            onClick={() => this.toggleModal("showPinModal")}
                        >
                            <div className="d-flex flex-column pin-pass">
                                <Translate text="PIN_CODE" />
                                <span>********</span>
                            </div>
                            <div className="d-flex padding-r-10">
                                <i
                                    className="fas fa-chevron-right align-self-center"
                                    style={{
                                        color: "gray",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    };
    renderRole = (role) => {
        if (parseInt(role) === 2) {
            return <Translate text="MANAGER" />;
        }
        if (parseInt(role) === 3) {
            return <Translate text="ADMIN" />;
        }
    };

    handleTabs = (name) => {
        this.setState({ currentTab: name });
    };

    renderTabs = () => {
        const { admin_files, currentTab } = this.state;
        const adminIsLoggedIn = !!admin_files;
        return (
            <div className="d-flex justify-content-center">
                <div onClick={() => this.handleTabs("personalAccount")}>
                    <span
                        className={`${
                            currentTab === "personalAccount" && "active"
                        } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                    >
                        <Translate text="MANAGER_ACCOUNT" />
                    </span>
                </div>
                {adminIsLoggedIn && (
                    <div onClick={() => this.handleTabs("admin_files")}>
                        <span
                            className={`${
                                currentTab === "admin_files" && "active"
                            } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                        >
                            <Translate text="PRIVATE_DOCUMENTS" />
                        </span>
                    </div>
                )}
                <div onClick={() => this.handleTabs("public_files")}>
                    <span
                        className={`${
                            currentTab === "public_files" && "active"
                        } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                    >
                        <Translate text="PUBLIC_DOCUMENTS" />
                    </span>
                </div>
            </div>
        );
    };
    handleAddDocument = () => {
        const { currentTab } = this.state;
        const condition = currentTab === "admin_files";
        const file_type = condition ? "3" : "1";
        const file = condition ? "admin_file" : "public_file";
        const files = condition ? "admin_files" : "public_files";
        const document: any = this.state[file].value;
        const token = StoreService.getStoreProperty("token");
        const formData = new FormData();
        const id = this.props.match.params.id;
        formData.append("id", id);
        formData.append("file", document);
        formData.append("type", "user");
        formData.append("file_type", file_type);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadFile(formData, token, config).then((res) => {
            if (res.success) {
                this.setState((prevState: any) => ({
                    [files]: [...prevState[files], res.data],
                    completed: 0,
                    uploadComplete: false,
                    uploading: false,
                    fileUploadModal: false,
                    [file]: {
                        value: null,
                        errors: [],
                    },
                    form: {
                        ...prevState.form,
                        [files]: [prevState.form[files], res.data],
                    },
                }));
            } else {
                this.setState({
                    fileUploadErrorModal: true,
                    [file]: {
                        value: null,
                        errors: [],
                    },
                });
            }
        });
    };
    deleteFile = (index) => {
        const token = StoreService.getStoreProperty("token");
        const { currentTab } = this.state;
        const condition = currentTab === "admin_files";
        const files = condition ? "admin_files" : "public_files";
        ApiService.deleteFile(
            {
                id: this.state[files][index].id,
            },
            token
        ).then((response) => {
            if (response.success) {
                const documents = this.state[files].filter(
                    (e, i) => i !== index
                );
                this.setState({
                    [files]: documents,
                });
            }
        });
    };
    handleFileChange = (name, e, drop?) => {
        this.setState({
            [name]: UtilService.handleAddFile(name, e, drop),
        });
    };
    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };
    // //? Render image from array buffer
    showTemporaryPhoto = () => {
        let img: any = this.state.photo.value;
        if (this.state.photo.value !== null) {
            let blob = new Blob([img], {
                type: "image/png",
            });
            let imageUrl = URL.createObjectURL(blob);
            this.setState({ tempPhoto: imageUrl });
        }
    };
    renderInputsModal = (type) => {
        const condition = type === "password";
        const modalName = condition ? "showPasswordModal" : "showPinModal";
        const translate = condition ? "CHANGE_PASSWORD" : "CHANGE_PIN";
        const new_item = condition ? "new_password" : "new_pin";
        const repeat_new_item = condition
            ? "repeat_new_password"
            : "repeat_new_pin";
        return (
            <Modal
                toggleModal={this.toggleModal}
                modalName={modalName}
                className={this.state[modalName] ? "visible" : ""}
                modalWrapperClasses="w-400 padding-20"
            >
                <div className="">
                    <div className="d-flex  justify-content-between mb-30">
                        <p className="d-flex align-self-center password-title">
                            <Translate text={translate} />
                        </p>
                        <i
                            className="fas fa-times fa-2x align-self-center pointer"
                            style={{ color: "#43425D" }}
                            onClick={() => this.toggleModal(modalName)}
                        />
                    </div>
                    <div className="mb-30">
                        <CustomForm className="d-flex flex-column justify-content-center">
                            <div className="margin-bottom-30">
                                <CustomInput
                                    name={new_item}
                                    label={new_item}
                                    type="password"
                                    handleInput={this.handleInput}
                                    value={this.state.form[new_item].value}
                                    errors={this.state.form[new_item].errors}
                                    maxLength={condition ? 20 : 4}
                                    required={true}
                                />
                            </div>
                            <div>
                                <CustomInput
                                    name={repeat_new_item}
                                    label={repeat_new_item}
                                    type="password"
                                    handleInput={this.handleInput}
                                    value={
                                        this.state.form[repeat_new_item].value
                                    }
                                    errors={
                                        this.state.form[repeat_new_item].errors
                                    }
                                    maxLength={condition ? 20 : 4}
                                    required={true}
                                />
                            </div>
                        </CustomForm>
                    </div>
                    <div className="d-flex justify-content-center">
                        <CustomButton
                            className="btn-save"
                            type="button"
                            onClick={this.handleDataChange}
                        >
                            <Translate text="SAVE" />
                        </CustomButton>
                    </div>
                </div>
            </Modal>
        );
    };
    render() {
        const { currentTab, admin_files, isCreateNew } = this.state;
        const adminIsLoggedIn = !!admin_files;
        const condition = currentTab === "admin_files";
        if (this.state.isLoading) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div className="managers">
                    <Modal
                        modalName="showSaveFailedModal"
                        toggleModal={this.toggleModal}
                        className={
                            this.state.showSaveFailedModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="ADD_MANAGER_FAILED"
                            modalName="showSaveFailedModal"
                            title="FAILED"
                            toggleModal={this.toggleModal}
                        ></ModalContent>
                    </Modal>
                    <Modal
                        modalName="showSaveSuccessfulModal"
                        toggleModal={this.toggleModal}
                        className={
                            this.state.showSaveSuccessfulModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="success"
                            text="ADD_MANAGER_SUCCESSFUL"
                            modalName="showSaveSuccessfulModal"
                            title="SUCCESS"
                            toggleModal={this.handleBack}
                        ></ModalContent>
                    </Modal>
                    <Modal
                        modalName="showEditFailedModal"
                        toggleModal={this.toggleModal}
                        className={
                            this.state.showEditFailedModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="EDIT_MANAGER_FAILED"
                            modalName="showEditFailedModal"
                            title="FAILED"
                            toggleModal={this.toggleModal}
                        ></ModalContent>
                    </Modal>
                    <Modal
                        modalName="showEditSuccessfulModal"
                        toggleModal={this.toggleModal}
                        className={
                            this.state.showEditSuccessfulModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="success"
                            text="EDIT_MANAGER_SUCCESSFUL"
                            modalName="showEditSuccessfulModal"
                            title="SUCCESS"
                            toggleModal={this.handleBack}
                        ></ModalContent>
                    </Modal>
                    <Modal
                        modalName="imageUploadModal"
                        toggleModal={this.toggleModal}
                        className={this.state.imageUploadModal ? "visible" : ""}
                        modalWrapperClasses="fileUploadModal"
                    >
                        {!this.state.uploading && (
                            <div className="pa-20 h-100-perc">
                                <div
                                    onDrop={(e) =>
                                        this.handleFileChange("photo", e, true)
                                    }
                                    className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                        this.state.isOver ? "dotted" : ""
                                    }`}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        this.changeBorderType(true);
                                    }}
                                    onDragLeave={() =>
                                        this.changeBorderType(false)
                                    }
                                >
                                    <div className="text d-flex flex-column justify-content-center align-items-center">
                                        <span className="d-block mb-10">
                                            <UploadIcon
                                                width={40}
                                                className="uploadIcon"
                                            />
                                        </span>
                                        <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                            <Translate text="DRAG_AND_DROP" />
                                        </span>
                                        <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">
                                            <Translate text="OR" />
                                        </span>
                                    </div>
                                    <CustomFileInput
                                        name="photo"
                                        handleInput={this.handleFileChange}
                                        value={this.state.photo.value}
                                        label={"CHOOSE_PHOTO"}
                                    />
                                </div>
                            </div>
                        )}

                        {this.state.uploading && (
                            <div className="progress pa-20 h-100perc text-center border-radius-4">
                                <span className="f-s-20 mb-20">
                                    <Translate text="UPLOAD_IN_PROGRESS" />
                                </span>
                                <span className="wrapper d-block">
                                    <Loader width={200} />
                                    <span className="loader-numbers">
                                        {this.state.completed}%
                                    </span>
                                </span>
                            </div>
                        )}
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showImageUploadFailedModal"
                        className={
                            this.state.showImageUploadFailedModal
                                ? "visible error"
                                : "error"
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            title="FAILED"
                            text="IMAGE_UPLOAD_FAILURE"
                            modalName="showImageUploadFailedModal"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    {/* <Modal
                        modalName="showTakenMaildModal"
                        toggleModal={this.toggleModal}
                        className={
                            this.state.showTakenMailModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="EMAIL_TAKEN"
                            modalName="showTakenMailModal"
                            title="FAILED"
                            toggleModal={this.toggleModal}
                        ></ModalContent>
                    </Modal> */}
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showSuccessModal"
                        className={this.state.showSuccessModal ? "visible" : ""}
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="success"
                            title="SUCCESSFULLY_CHANGED"
                            text={
                                this.state.modalSuccessText ===
                                "showPasswordModal"
                                    ? "PSW_SUCCESS"
                                    : "PIN_SUCCESS"
                            }
                            modalName="showSuccessModal"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showFailedModal"
                        className={
                            this.state.showFailedModal
                                ? "visible error"
                                : "error"
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            title="FAILED"
                            text={
                                this.state.modalFailedText ===
                                "showPasswordModal"
                                    ? "OLD_PSW_WRONG"
                                    : "OLD_PIN_WRONG"
                            }
                            modalName="showFailedModal"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showSameValue"
                        className={
                            this.state.showSameValue ? "visible error" : "error"
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            title="FAILED"
                            text={
                                this.state.modalFailedText ===
                                "showPasswordModal"
                                    ? "SAME_VALUE_PSW"
                                    : "SAME_VALUE_PIN"
                            }
                            modalName="showFailedModal"
                            toggleModal={() =>
                                this.toggleModal("showSameValue")
                            }
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showSuccessModalData"
                        className={
                            this.state.showSuccessModalData ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="success"
                            title="SUCCESSFULLY_CHANGED"
                            text={"DATA_CHANGE_SUCCESS"}
                            modalName="showSuccessModalData"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        modalName="fileUploadModal"
                        toggleModal={this.toggleModal}
                        className={this.state.fileUploadModal ? "visible" : ""}
                        modalWrapperClasses="fileUploadModal"
                    >
                        {!this.state.uploading && (
                            <div className="pa-20 h-100-perc">
                                <div
                                    onDrop={(e) =>
                                        this.handleFileChange(
                                            currentTab === "admin_files"
                                                ? "admin_file"
                                                : "public_file",
                                            e,
                                            true
                                        )
                                    }
                                    className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                        this.state.isOver ? "dotted" : ""
                                    }`}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        this.changeBorderType(true);
                                    }}
                                    onDragLeave={() =>
                                        this.changeBorderType(false)
                                    }
                                >
                                    <div className="text d-flex flex-column justify-content-center align-items-center">
                                        <span className="d-block mb-10">
                                            <UploadIcon
                                                width={40}
                                                className="uploadIcon"
                                            />
                                        </span>
                                        <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                            <Translate text="DRAG_AND_DROP" />
                                        </span>
                                        <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">
                                            <Translate text="OR" />
                                        </span>
                                    </div>
                                    <CustomFileInput
                                        name={
                                            currentTab === "admin_files"
                                                ? "admin_file"
                                                : "public_file"
                                        }
                                        className="mb-10"
                                        handleInput={this.handleFileChange}
                                        value={
                                            currentTab === "admin_files"
                                                ? this.state.admin_file.value
                                                : this.state.public_file.value
                                        }
                                        label={"CHOOSE_FILE"}
                                        labelClass="f-s-16"
                                    />
                                </div>
                            </div>
                        )}
                        {this.state.uploading && (
                            <div className="progress pa-20 h-100perc text-center border-radius-4">
                                <span className="f-s-20 mb-20">
                                    <Translate text="UPLOAD_IN_PROGRESS" />
                                </span>
                                <span className="wrapper d-block">
                                    <Loader width={200} />
                                    <span className="loader-numbers">
                                        {this.state.completed}%
                                    </span>
                                </span>
                            </div>
                        )}
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="fileUploadErrorModal"
                        className={
                            this.state.fileUploadErrorModal
                                ? "visible bg-info-3"
                                : ""
                        }
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="FILE_UPLOAD_ERROR"
                            modalName="fileUploadErrorModal"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    {this.renderInputsModal(this.state.changeType)}
                    <div className="d-flex justify-content-between pb-20 border-b-1">
                        <div className="pageHeader d-flex align-items-center">
                            <span
                                className="pointer d-flex align-items-center goBack mr-10"
                                onClick={() => {
                                    this.handleBack();
                                }}
                            >
                                <LeftArrow width={18} className="fill" />
                            </span>
                            <span className="pageHeader">
                                {this.state.isCreateNew ? (
                                    <Translate text="ADD_MANAGER"></Translate>
                                ) : (
                                    <Translate text="EDIT_MANAGER"></Translate>
                                )}
                            </span>
                        </div>
                        {this.state.role === 3 && (
                            <div>
                                <CustomButton
                                    className="btn-primary justify-content-center"
                                    type="button"
                                    onClick={this.handleSave}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <div className="f-s-16 text-left px-10">
                                            {this.state.isCreateNew ? (
                                                <Translate
                                                    text={"SAVE"}
                                                ></Translate>
                                            ) : (
                                                <Translate
                                                    text={"SAVE_CHANGES"}
                                                ></Translate>
                                            )}
                                        </div>
                                    </div>
                                </CustomButton>
                            </div>
                        )}
                    </div>
                    {!isCreateNew && this.renderTabs()}
                    {this.state.currentTab === "personalAccount" && (
                        <CustomForm className="d-flex flex-column align-items-center w-100-perc row">
                            <div className="d-flex justify-content-between col-12 col-lg-10 col-xl-6 py-30 min-w-800">
                                <div className="min-w-320 left d-flex flex-column align-items-center justify-content-start margin-h-20">
                                    <div className="d-flex w-100-perc shadow-light border-radius-30 flex-column align-items-center justify-content-center pt-30 margin-h-20">
                                        <div
                                            id="personal"
                                            className="d-flex justify-content-center p-r margin-t-50 margin-b-30"
                                        >
                                            {this.state.form.profile_image
                                                .value !== null &&
                                            this.state.tempPhoto === "" ? (
                                                <img
                                                    className="image"
                                                    src={`https://rentapi.oykos.dev/storage/${this.state.form.profile_image.value}`}
                                                    alt={""}
                                                />
                                            ) : this.state.tempPhoto !== "" ? (
                                                <img
                                                    className="image"
                                                    src={this.state.tempPhoto}
                                                    alt={""}
                                                />
                                            ) : (
                                                <Avatar className="image" />
                                            )}

                                            <div
                                                className="d-flex camera-icon"
                                                onClick={() =>
                                                    this.toggleModal(
                                                        "imageUploadModal"
                                                    )
                                                }
                                            >
                                                <i
                                                    className="fas fa-camera fa-2x align-self-center margin-t--1"
                                                    style={{
                                                        color: "white",
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        {!isCreateNew && (
                                            <div className="w-100-perc d-flex flex-column my-10">
                                                <span className="d-flex justify-content-center align-items-center user">
                                                    {
                                                        this.state.form
                                                            .first_name.value
                                                    }{" "}
                                                    {
                                                        this.state.form
                                                            .last_name.value
                                                    }
                                                </span>
                                                <span className="d-flex justify-content-center align-items-center admin">
                                                    {this.renderRole(
                                                        this.state.form.role
                                                            .value
                                                    )}
                                                </span>
                                            </div>
                                        )}

                                        {this.renderPasswordAndPin()}
                                    </div>
                                </div>
                                <div className="right w-100-perc d-flex flex-column align-items-center justify-content-center padding-30 margin-h-20 shadow-light border-radius-30">
                                    <div className="w-100-perc mb-30 min-w-350">
                                        <CustomInput
                                            name="first_name"
                                            type="text"
                                            label="FIRST_NAME"
                                            required={true}
                                            value={
                                                this.state.form.first_name.value
                                            }
                                            errors={
                                                this.state.form.first_name
                                                    .errors
                                            }
                                            handleInput={this.handleInput}
                                        ></CustomInput>
                                    </div>
                                    <div className="w-100-perc mb-30 min-w-350">
                                        <CustomInput
                                            name="last_name"
                                            type="text"
                                            label="LAST_NAME"
                                            required={true}
                                            value={
                                                this.state.form.last_name.value
                                            }
                                            errors={
                                                this.state.form.last_name.errors
                                            }
                                            handleInput={this.handleInput}
                                        ></CustomInput>
                                    </div>
                                    <div className="w-100-perc mb-30 min-w-350">
                                        <CustomDropdown
                                            data={this.state.roles}
                                            errors={this.state.form.role.errors}
                                            value={this.state.roles.find(
                                                (e: any) =>
                                                    e.value ===
                                                    this.state.form.role.value
                                            )}
                                            name="role"
                                            required={true}
                                            handleChange={this.handleInput}
                                            placeholder={
                                                <Translate text="CHOOSE_ROLE"></Translate>
                                            }
                                            label="ROLE"
                                        ></CustomDropdown>
                                    </div>
                                    <div className="w-100-perc mb-30 min-w-350">
                                        <CustomInput
                                            name="email"
                                            type="email"
                                            label="EMAIL"
                                            value={this.state.form.email.value}
                                            errors={
                                                this.state.form.email.errors
                                            }
                                            handleInput={this.handleInput}
                                        ></CustomInput>
                                    </div>
                                    <div className="w-100-perc mb-30 min-w-350">
                                        <CustomInput
                                            name="phone"
                                            type="text"
                                            label="PHONE"
                                            value={this.state.form.phone.value}
                                            errors={
                                                this.state.form.phone.errors
                                            }
                                            handleInput={this.handleInput}
                                        ></CustomInput>
                                    </div>
                                    <div className="w-100-perc mb-30 min-w-350">
                                        <CustomDatePicker
                                            name="date_of_birth"
                                            value={
                                                this.state.form.date_of_birth
                                                    .value
                                            }
                                            errors={
                                                this.state.form.date_of_birth
                                                    .errors
                                            }
                                            handleInput={this.handleInput}
                                            label="DATE_OF_BIRTH"
                                        ></CustomDatePicker>
                                    </div>
                                    <div className="w-100-perc mb-30 min-w-350">
                                        <CustomDatePicker
                                            name="date_of_employment"
                                            value={
                                                this.state.form
                                                    .date_of_employment.value
                                            }
                                            errors={
                                                this.state.form
                                                    .date_of_employment.errors
                                            }
                                            handleInput={this.handleInput}
                                            label="EMPLOYMENT_DATE"
                                        ></CustomDatePicker>
                                    </div>
                                    <div className="w-100-perc min-w-350">
                                        <CustomTextArea
                                            name="remark"
                                            value={this.state.form.remark.value}
                                            label="REMARK"
                                            handleInput={this.handleInput}
                                            errors={
                                                this.state.form.remark.errors
                                            }
                                        ></CustomTextArea>
                                    </div>
                                </div>
                            </div>
                        </CustomForm>
                    )}
                    {this.state.currentTab === "public_files" && (
                        <div className="managerFilesBox d-flex justify-content-center row mt-10">
                            <PrivateFiles
                                noShadow
                                isPrivate={condition}
                                documents={this.state.public_files}
                                deleteFile={this.deleteFile}
                                toggleModal={this.toggleModal}
                                wrapperClasses="fileContainerManager"
                            />
                        </div>
                    )}
                    {adminIsLoggedIn &&
                        this.state.currentTab === "admin_files" && (
                            <div className="managerFilesBox d-flex justify-content-center row mt-10">
                                <PrivateFiles
                                    noShadow
                                    isPrivate={condition}
                                    documents={this.state.admin_files}
                                    deleteFile={this.deleteFile}
                                    toggleModal={this.toggleModal}
                                    wrapperClasses="fileContainerManager"
                                />
                            </div>
                        )}
                </div>
            );
        }
    }
}

export default ManagerDetails;
