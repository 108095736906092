import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Loader } from "devkit/Loader/Loader";
import {
    parseDate,
    parseDateForMySQL,
} from "functions/utilFunctions/parsingFunctions";
import Translate from "functions/utilFunctions/translate";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import { CustomButton } from "devkit/Form/Form";
import { LeftArrow } from "components/Icons/Icons";
import ContractInfoTab from "./ContractInfoTab";

interface IState {
    currentMonth: Date;
    currentYear: Date;
    date: Date;
    // renderingDayIterator: number;
    year: number;
    month: number;
    total: number;
    firstOfMonth: number;
    todayDate: string;
    monthIndex: number;
    monthIndexNew: number;
    id: number;
    showLoader: boolean;
    contracts: any[];
}
interface IProps extends RouteComponentProps {}

class ContractCalendar extends Component<IProps, IState> {
    state: IState = {
        showLoader: true,
        id: 0,
        currentMonth: new Date(),
        currentYear: new Date(),
        date: new Date(),
        // renderingDayIterator: 1,
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        total: new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
        ).getDate(),
        firstOfMonth: new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            1
        ).getDay(),
        todayDate: String(new Date().getDate()).padStart(2, "0"),
        monthIndex: new Date().getMonth(),
        monthIndexNew: new Date().getMonth(),
        contracts: [],
    };

    renderingDayIterator = 0;

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");

        const date = new Date();
        const year = date.getFullYear();
        const month = date.getMonth();
        const firstDay = parseDateForMySQL(new Date(year, month - 1, 1));
        const lastDay = parseDateForMySQL(new Date(year, month + 1, 0));

        const data = {
            offset: 0,
            limit: 1000,
            date_from: firstDay,
            date_to: lastDay,
            type: 2,
        };

        ApiService.getAllContracts(data, token).then((res) => {
            if (res.success) {
                this.setState({ contracts: res.data, showLoader: false });
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevState.currentMonth !== this.state.currentMonth) {
            const { currentMonth } = this.state;
            const year = currentMonth.getFullYear();
            const month = currentMonth.getMonth();
            const firstOfMonth = parseDateForMySQL(
                new Date(year, month - 1, 1)
            );
            const lastOfMonth = parseDateForMySQL(new Date(year, month + 1, 0));
            const token = StoreService.getStoreProperty("token");

            ApiService.getAllContracts(
                {
                    offset: 0,
                    limit: 100,
                    date_from: firstOfMonth,
                    date_to: lastOfMonth,
                    type: 2,
                },
                token
            ).then((response) => {
                if (response.success) {
                    this.setState({
                        contracts: response.data,
                        showLoader: false,
                    });
                }
            });
        }
    }

    handleRedirect = (id) => {
        this.props.history.push(`/mentor/session-details/${id}/basic-info`);
    };

    getCurrentDayContracts = (date) => {
        const { contracts } = this.state;

        const active = contracts.filter((con) => {
            return (
                new Date(con.rent_start_date) < date &&
                new Date(con.rent_end_date) > date
            );
        }).length;

        const late = contracts.filter((con) => {
            return (
                !con.finished &&
                new Date(con.rent_end_date) < date &&
                date <= new Date()
            );
        }).length;

        const finished = contracts.filter((con) => {
            return (
                new Date(con.rent_start_date) < date &&
                new Date(con.rent_end_date) > date &&
                con.finished
            );
        }).length;

        const total = active + late + finished;

        return { active, late, total, finished };
    };

    renderWeekDays = () => {
        let days = 7;
        const { date, total, todayDate } = this.state;

        // for (let k: number = 0; k < contracts.length; k++) {
        let elements: any = [];
        const todayWeekNumber = date.getDay();

        for (let i: number = 1; i <= days - 2; i++) {
            let day: any = new Date(
                new Date(this.state.currentMonth).getFullYear(),
                new Date(this.state.currentMonth).getMonth(),
                1
            );
            //Needed this one in order for first day to be shown as '01'
            if (
                i === this.state.firstOfMonth &&
                this.renderingDayIterator === 0
            ) {
                day = new Date(day.setDate(day.getDate()));
                this.renderingDayIterator++;
            } else if (
                (this.renderingDayIterator <= total &&
                    i >= this.state.firstOfMonth &&
                    this.state.firstOfMonth !== 0) ||
                this.renderingDayIterator >= 1
            ) {
                day = new Date(
                    new Date(
                        new Date(this.state.currentMonth).getFullYear(),
                        new Date(this.state.currentMonth).getMonth(),
                        1
                    ).setDate(
                        new Date(
                            new Date(this.state.currentMonth).getFullYear(),
                            new Date(this.state.currentMonth).getMonth(),
                            1
                        ).getDate() + this.renderingDayIterator
                    )
                );
                this.renderingDayIterator++;
            }

            const contractsInfo = this.getCurrentDayContracts(day);

            elements.push(
                <div
                    // Provjera da li je danasnji dan zbog css-a
                    className={`${
                        todayWeekNumber > 0 &&
                        todayWeekNumber < 6 &&
                        this.renderingDayIterator <= total &&
                        this.renderingDayIterator >= 1 &&
                        parseDate(day) === parseDate(this.state.date)
                            ? "calendar-cell border-r-c flex-column today"
                            : "calendar-cell border-r-c flex-column"
                    } `}
                >
                    <div className="justify-content-end d-flex">
                        <div
                            className={`${
                                this.renderingDayIterator <= total &&
                                this.renderingDayIterator >= 1
                                    ? "d-flex light-5 day-number"
                                    : "d-none"
                            }`}
                        >
                            {this.renderingDayIterator >= 1
                                ? parseDate(day).split("/")[0]
                                : ""}
                        </div>
                    </div>
                    {/* Added scenario without mentors, although it
                        shouldn't be allowed */}
                    <div className="d-flex flex-column overflow-y-auto  mt-10 h-80-perc">
                        {/* Without these conditions it will show content of april 1st in march cell for example */}
                        {this.renderingDayIterator <= total &&
                            this.renderingDayIterator >= 1 && (
                                <ContractInfoTab
                                    contractsInfo={contractsInfo}
                                ></ContractInfoTab>
                            )}
                    </div>
                </div>
            );
        }
        //Case for weekend; separated because of css
        // Sunday and Saturday are separated because of css classes and JS Date methods regarding days in a week
        for (let i: any = 6; i > days - 2 && i <= 6; i++) {
            let day: any = new Date(
                new Date(this.state.currentMonth).getFullYear(),
                new Date(this.state.currentMonth).getMonth(),
                1
            );

            if (
                this.renderingDayIterator === 0 &&
                this.state.firstOfMonth === i
            ) {
                day = new Date(day.setDate(day.getDate()));
                this.renderingDayIterator++;
            } else if (
                this.renderingDayIterator <= total &&
                this.renderingDayIterator >= 1
            ) {
                day = new Date(
                    new Date(
                        new Date(this.state.currentMonth).getFullYear(),
                        new Date(this.state.currentMonth).getMonth(),

                        1
                    ).setDate(
                        new Date(
                            new Date(this.state.currentMonth).getFullYear(),
                            new Date(this.state.currentMonth).getMonth(),

                            1
                        ).getDate() + this.renderingDayIterator
                    )
                );

                this.renderingDayIterator++;
            }

            const contractsInfo = this.getCurrentDayContracts(day);

            elements.push(
                <div
                    className={`${
                        todayWeekNumber === 6 &&
                        i === 6 &&
                        day === parseInt(todayDate)
                            ? "today calendar-cell border-r-c flex-column "
                            : "calendar-cell border-r-c flex-column weekend "
                    } `}
                >
                    <div className="d-flex justify-content-end">
                        <div
                            className={`${
                                this.renderingDayIterator <= total &&
                                this.renderingDayIterator >= 1
                                    ? "d-flex light-5  day-number"
                                    : "d-none"
                            }`}
                        >
                            {parseDate(day).split("/")[0]}
                        </div>
                    </div>
                    <div className="d-flex flex-column overflow-y-auto  mt-10 h-80-perc">
                        {this.renderingDayIterator <= total &&
                            this.renderingDayIterator >= 1 && (
                                <ContractInfoTab
                                    contractsInfo={contractsInfo}
                                ></ContractInfoTab>
                            )}
                    </div>
                </div>
            );
        }
        // Isto kao gore (samo nedelja)
        for (let i: any = 7; i > days - 2 && i <= 7; i++) {
            let day: any = new Date(
                new Date(this.state.currentMonth).getFullYear(),
                new Date(this.state.currentMonth).getMonth(),
                1
            );

            if (
                this.renderingDayIterator === 0 &&
                this.state.firstOfMonth === 0 &&
                i === 7
            ) {
                day = new Date(day.setDate(day.getDate()));
                this.renderingDayIterator++;
            } else if (
                this.renderingDayIterator <= total &&
                this.renderingDayIterator >= 1
            ) {
                day = new Date(
                    new Date(
                        new Date(this.state.currentMonth).getFullYear(),
                        new Date(this.state.currentMonth).getMonth(),

                        1
                    ).setDate(
                        new Date(
                            new Date(this.state.currentMonth).getFullYear(),
                            new Date(this.state.currentMonth).getMonth(),

                            1
                        ).getDate() + this.renderingDayIterator
                    )
                );

                this.renderingDayIterator++;
            }

            const contractsInfo = this.getCurrentDayContracts(day);

            elements.push(
                <div
                    className={`${
                        todayWeekNumber === 0 &&
                        i === 7 &&
                        day === parseInt(todayDate)
                            ? "today calendar-cell flex-column "
                            : "calendar-cell flex-column weekend "
                    } `}
                >
                    <div className="d-flex justify-content-end">
                        <div
                            className={`${
                                this.renderingDayIterator <= total &&
                                this.renderingDayIterator >= 1
                                    ? "d-flex light-5 day-number"
                                    : "d-none"
                            }`}
                        >
                            {parseDate(day).split("/")[0]}
                        </div>
                    </div>
                    <div className="d-flex flex-column overflow-y-auto mt-10 h-80-perc">
                        {this.renderingDayIterator <= total &&
                            this.renderingDayIterator >= 1 && (
                                <ContractInfoTab
                                    contractsInfo={contractsInfo}
                                ></ContractInfoTab>
                            )}
                    </div>
                </div>
            );
        }
        return elements;
        // }
    };

    renderCalendarRows = (rows) => {
        let elements: any = [];

        for (let i: any = 0; i < rows; i++) {
            elements.push(
                <div
                    className={`${
                        rows
                            ? "calendar-row d-flex border-b-c justify-content-between"
                            : ""
                    }`}
                >
                    {this.renderWeekDays()}
                </div>
            );
        }
        return elements;
    };
    nextMonth = () => {
        const { currentMonth }: any = this.state;
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth();
        let nextMonth = new Date(
            currentMonth.setMonth(currentMonth.getMonth() + 1, 1)
        );
        const total = new Date(year, month, 0).getDate();
        const firstOfMonth = new Date(year, month + 1, 1).getDay();
        this.setState({
            currentMonth: nextMonth,
            total: total,
            firstOfMonth,
            monthIndexNew: currentMonth.getMonth(),
            currentYear: year,
            showLoader: true,
        });
    };

    prevMonth = () => {
        const { currentMonth }: any = this.state;
        let prevMonth = new Date(
            currentMonth.setMonth(currentMonth.getMonth() - 1, 1)
        );
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth() + 1;
        const total = new Date(year, month, 0).getDate();
        const firstOfMonth = new Date(year, month - 1, 1).getDay();
        this.setState({
            currentMonth: prevMonth,
            total: total,
            firstOfMonth,
            monthIndexNew: currentMonth.getMonth(),
            currentYear: year,
            showLoader: true,
        });
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    render() {
        this.renderingDayIterator = 0;
        const { currentMonth } = this.state;
        const currentMonthName = currentMonth.toLocaleString("sr-latn-SR", {
            month: "long",
        });
        const year = currentMonth.getFullYear();
        const month = currentMonth.getMonth() + 1;
        const firstOfMonth = new Date(year, month - 1, 1);
        const lastOfMonth = new Date(year, month, 0);
        const numberOfDaysInMonth = lastOfMonth.getDate();
        const firstWeekDay = (firstOfMonth.getDay() - 1 + 7) % 7;
        const used = firstWeekDay + numberOfDaysInMonth;
        const weeksInMonth = Math.ceil(used / 7);

        return (
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-between col-12 br-header mb-50">
                    <div className="mb-20 d-flex col-6 padding-0">
                        <span className="d-flex" onClick={this.handleBack}>
                            <LeftArrow width={18} className={"icon"} />
                        </span>
                        <span className="addReservation">
                            <Translate text="CONTRACT_CALENDAR" />
                        </span>
                    </div>
                </div>
                <div
                    className={`loaderContainer calendarLoader ${
                        this.state.showLoader ? "calendarLoader" : "d-none"
                    }`}
                >
                    <Loader width={200} />
                </div>

                <div
                    className={`${
                        this.state.showLoader ? "opaque" : ""
                    } contractCalendar d-flex flex-column flex-grow-1 border-c border-radius-4 margin-20`}
                >
                    <div className="d-flex align-items-center justify-content-between py-10 px-20  header">
                        <CustomButton
                            className="d-flex align-items-center p-5 br-r-4 bg-theme-main-1 light-6 f-s-14  pointer"
                            onClick={this.prevMonth}
                        >
                            <Translate text="PREVIOUS_MONTH" />
                        </CustomButton>

                        <span className="d-flex align-items-center justify-content-center  bold flex-grow-1 flex-shrink-1 f-s-20 text-color-primary capitalize">
                            {currentMonthName + " " + year}
                        </span>
                        <CustomButton
                            className="d-flex align-items-center p-5 br-r-4 bg-theme-main-1 light-6 f-s-14 pointer"
                            onClick={this.nextMonth}
                        >
                            <Translate text="NEXT_MONTH" />
                        </CustomButton>
                    </div>
                    <div className="d-flex header-row border-v-c">
                        <div className="calendar-cell border-r-c align-items-center justify-content-center f-s-14 bold  bg-light-8 dark-2 d-flex">
                            <Translate text="MONDAY" />
                        </div>
                        <div className="calendar-cell  border-r-c align-items-center justify-content-center f-s-14 bold bg-light-8 dark-2 d-flex">
                            <Translate text="TUESDAY" />
                        </div>
                        <div className="calendar-cell  border-r-c align-items-center justify-content-center f-s-14 bold bg-light-8 dark-2 d-flex">
                            <Translate text="WEDNESDAY" />
                        </div>
                        <div className="calendar-cell  border-r-c align-items-center justify-content-center f-s-14 bold bg-light-8 dark-2 d-flex">
                            <Translate text="THURSDAY" />
                        </div>
                        <div className="calendar-cell  border-r-c align-items-center justify-content-center f-s-14 bold bg-light-8 dark-2 d-flex">
                            <Translate text="FRIDAY" />
                        </div>
                        <div className="calendar-cell  border-r-c align-items-center justify-content-center f-s-14 bold bg-light-8 dark-2 d-flex">
                            <Translate text="SATURDAY" />
                        </div>
                        <div className="calendar-cell  align-items-center justify-content-center f-s-14 bold bg-light-8 dark-2 d-flex">
                            <Translate text="SUNDAY" />
                        </div>
                    </div>
                    {this.renderCalendarRows(weeksInMonth)}
                </div>
            </div>
        );
    }
}

export default ContractCalendar;
