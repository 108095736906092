import { PlusIcon } from "components/Icons/Icons";
import { CustomButton } from "devkit/Form/Form";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import ShowMore from "devkit/ShowMore/ShowMore";
import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import React, { Component } from "react";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import { parseDate } from "functions/utilFunctions/parsingFunctions";
import { Loader } from "devkit/Loader/Loader";
import Paginate from "devkit/Form/Pagination/Pagination";

class Managers extends Component<any> {
    state = {
        managersTableHeads: [
            {
                value: "FIRST_NAME",
                sortable: true,
                sortableName: "first_name",
            },
            {
                value: "LAST_NAME",
                sortable: true,
                sortableName: "last_name",
            },
            {
                value: "MANAGER_DATE_OF_BIRTH",
                sortable: true,
                sortableName: "date_of_birth",
            },
            {
                value: "MANAGER_DATE_OF_EMPLOYMENT",
                sortable: true,
                sortableName: "date_of_employment",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        managers: [],
        showDeleteModal: false,
        showDeleteFailedModal: false,
        showDeleteSuccessfulModal: false,

        isLoading: true,
        deleteID: "",
        totalPages: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 20,
        sorterName: "",
        sorterDirection: "",
        role: 3,
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        ApiService.getAllUsers({ limit: perPage, offset }, token).then(
            (response) => {
                if (response && response.success) {
                    this.setState({
                        managers: response.data,
                        totalPages: response.total,
                        isLoading: false,
                        role: StoreService.getStoreProperty("user").role,
                    });
                }
            }
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                isLoading: true,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.sorterName &&
            this.state.sorterName !== prevState.sorterName
        ) {
            this.setState({ sorterName: "", sorterDirection: "" });
        }
        if (prevState.currentPage !== this.state.currentPage) {
            const token = StoreService.getStoreProperty("token");
            const {
                currentPage,
                perPage,
                sorterDirection,
                sorterName,
            } = this.state;
            const offset = (parseInt(currentPage) - 1) * perPage;
            const data: any = { limit: perPage, offset };
            if (sorterDirection) {
                data.sorter_direction = sorterDirection;
            }
            if (sorterName) {
                data.sorter = sorterName;
            }
            ApiService.getAllUsers(data, token).then((response) => {
                if (response.success) {
                    const managers = response.data;
                    this.setState({
                        managers,
                        totalPages: response.total,
                        isLoading: false,
                    });
                }
            });
        }
    }

    handleAddNew = () => {
        this.props.history.push(`/new-manager`);
    };

    handleRedirect = (id) => {
        this.props.history.push(`/managers/editManager/${id}`);
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleDeleteItem = () => {
        this.setState({ showDeleteModal: !this.state.showDeleteModal });
        const token = StoreService.getStoreProperty("token");
        const {
            currentPage,
            perPage,
            deleteID,
            sorterDirection,
            sorterName,
        } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data: any = { limit: perPage, offset };
        if (sorterDirection) {
            data.sorter_direction = sorterDirection;
        }
        if (sorterName) {
            data.sorter = sorterName;
        }
        ApiService.deleteUser(parseInt(deleteID), token).then((response) => {
            if (response && response.success) {
                this.setState({
                    showDeleteSuccessfulModal: !this.state
                        .showDeleteSuccessfulModal,
                });

                ApiService.getAllUsers(data, token).then((response) => {
                    if (response && response.success) {
                        this.setState({
                            managers: response.data,
                        });
                    }
                });
            } else {
                this.setState({ showDeleteFailedModal: true });
            }
        });
    };

    handleSort = (sortableName) => {
        const { currentPage, perPage, sorterDirection } = this.state;
        const token = StoreService.getStoreProperty("token");
        const offset = (parseInt(currentPage) - 1) * perPage;
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";

        ApiService.getAllUsers(
            {
                limit: perPage,
                offset,
                sorter: sortableName,
                sorter_direction,
            },
            token
        ).then((response) => {
            if (response && response.success) {
                const managers = response.data;
                this.setState({
                    managers,
                    totalPages: response.total,
                    showLoader: false,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                });
            }
        });
    };
    handlePageClick = (pageNumber) => {
        this.props.history.push(`/managers/${pageNumber}`);
    };

    render() {
        const { totalPages, currentPage, perPage, role } = this.state;
        if (this.state.isLoading) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div>
                    <Modal
                        modalName="showDeleteModal"
                        toggleModal={this.toggleModal}
                        className={this.state.showDeleteModal ? "visible" : ""}
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="warning"
                            text="DELETE_MANAGER_?"
                            modalName="showDeleteModal"
                            title="DELETE_MANAGER"
                            toggleModal={this.toggleModal}
                            deleteItem={this.handleDeleteItem}
                            cancelDelete={this.toggleModal}
                        ></ModalContent>
                    </Modal>
                    <Modal
                        modalName="showDeleteFailedModal"
                        toggleModal={this.toggleModal}
                        className={
                            this.state.showDeleteFailedModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="DELETE_MANAGER_FAILED"
                            modalName="showDeleteFailedModal"
                            title="FAILED"
                            toggleModal={this.toggleModal}
                        ></ModalContent>
                    </Modal>
                    <Modal
                        modalName="showDeleteSuccessfulModal"
                        toggleModal={this.toggleModal}
                        className={
                            this.state.showDeleteSuccessfulModal
                                ? "visible"
                                : ""
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="success"
                            text="DELETE_MANAGER_SUCCESSFUL"
                            modalName="showDeleteSuccessfulModal"
                            title="SUCCESS"
                            toggleModal={this.toggleModal}
                        ></ModalContent>
                    </Modal>
                    <div className="d-flex w-100-perc border-b-1 pb-20">
                        <span className="pageHeader">
                            <Translate text="MANAGERS"></Translate>
                        </span>
                    </div>
                    <div className="d-flex pb-40 py-20 justify-content-end">
                        <CustomButton
                            className="btn-primary justify-content-center"
                            type="button"
                            onClick={() => this.handleAddNew()}
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <PlusIcon
                                    width={14}
                                    className="icon hover pointer mr-10 whiteFill"
                                />
                                <Translate text={"ADD_MANAGER"}></Translate>
                            </div>
                        </CustomButton>
                    </div>
                    <div className="f-s-14">
                        <Table
                            theads={this.state.managersTableHeads}
                            theadsClassname="customThead"
                            handleSort={this.handleSort}
                            sortName="managers"
                            arrayToSort={this.state.managers}
                        >
                            {!!this.state.managers &&
                                this.state.managers.map((e: any, i) => {
                                    return (
                                        <tr
                                            key={i}
                                            className="pointer"
                                            onClick={() =>
                                                this.handleRedirect(e.id)
                                            }
                                        >
                                            <td>{e.first_name}</td>
                                            <td>{e.last_name}</td>
                                            <td>
                                                {parseDate(e.date_of_birth)}
                                            </td>
                                            <td>
                                                {parseDate(
                                                    e.date_of_employment
                                                )}
                                            </td>
                                            {role === 3 && (
                                                <td>
                                                    <ShowMore>
                                                        <span
                                                            className="one-line-text d-flex justify-content-center"
                                                            onClick={() =>
                                                                this.handleRedirect(
                                                                    e.id
                                                                )
                                                            }
                                                        >
                                                            <Translate
                                                                text={"EDIT"}
                                                            />
                                                        </span>
                                                        <span
                                                            className="one-line-text d-flex justify-content-center"
                                                            onClick={() =>
                                                                // console.log("Delete")
                                                                this.handleDeleteModal(
                                                                    e.id
                                                                )
                                                            }
                                                        >
                                                            <span className="danger">
                                                                <Translate text="DELETE" />
                                                            </span>
                                                        </span>
                                                    </ShowMore>
                                                </td>
                                            )}
                                        </tr>
                                    );
                                })}
                        </Table>
                        <Paginate
                            totalItemsCount={totalPages}
                            onChange={this.handlePageClick}
                            activePage={parseInt(currentPage)}
                            perPage={perPage}
                            pageRangeDisplayed={5}
                        />
                    </div>
                </div>
            );
        }
    }
}

export default Managers;
