import { Translate } from "./translate";
import React from "react";

export const FilterOptions = {
    getCarsOptions: (cars, filter, defaultVal, categories?) => {
        let filterOptions: any = [];

        for (let car of cars) {
            if (!filterOptions.some((option) => option.value === car[filter])) {
                let title = "";
                if (filter === "category_id") {
                    const category: any = categories.find((cat: any) => {
                        return cat.id === car.category_id;
                    });
                    title = category ? category.name : "";
                }
                filterOptions.push({
                    value: car[filter],
                    label: filter === "category_id" ? title : car[filter],
                });
            }
        }

        filterOptions.unshift({
            value: "",
            label: <Translate text={`${defaultVal}`} />,
        });
        return filterOptions;
    },
    getCategoryOptions: (categories) => {
        return categories.map((category) => {
            return {
                value: category.id,
                label: category.name,
            };
        });
    },
    getCarBrandOptions: (cars) => {
        return cars.reduce((acc, curr) => {
            if (!acc.includes(curr.manufacturer)) {
                acc.push(curr.manufacturer);
            }
            return acc;
        }, []);
    },
    getCarModelOptions: (cars) => {
        return cars.reduce((acc, curr) => {
            if (!acc.includes(curr.model)) {
                acc.push(curr.model);
            }
            return acc;
        }, []);
    },
    getCarPlatesOptions: (cars) => {
        return cars.reduce((acc, curr) => {
            if (!acc.includes(curr.licence_plate)) {
                acc.push(curr.licence_plate);
            }
            return acc;
        }, []);
    },
    getAdditionalEquipmentOptions: (equipment, equipmentIds) => {
        const options = equipment.map((e: any) => ({
            value: e.id,
            label: e.name,
        }));
        if (equipmentIds[0]) {
            return options.filter((option) => {
                return equipmentIds.every((id) => {
                    return option.value !== id;
                });
            });
        } else return options;
    },
    getMileageOptions: () => {
        return [
            {
                value: "",
                label: <Translate text="ALL_MILEAGE" />,
            },
            {
                value: 1,
                label: "0 - 50.000km",
            },
            {
                value: 2,
                label: "50.000 - 100.000km",
            },
            {
                value: 3,
                label: "100.000 - 150.000km",
            },
            {
                value: 4,
                label: "150.000 - 200.000km",
            },
        ];
    },
    getRelativeDateOptions: (type) => {
        return [
            {
                value: "no_filter",
                label: <Translate text="ALL_TIME" />,
            },
            {
                value: "today",
                label: <Translate text="TODAY" />,
            },
            {
                value: "this_week",
                label: (
                    <Translate
                        text={type === "past" ? "LAST_WEEK" : "NEXT_WEEK"}
                    />
                ),
            },
            {
                value: "this_month",
                label: (
                    <Translate
                        text={type === "past" ? "LAST_30_DAYS" : "NEXT_30_DAYS"}
                    />
                ),
            },
        ];
    },
};
