import React, { Component } from "react";
import { StoreService } from "services/store.service";
import Translate from "functions/utilFunctions/translate";
import { NavLink } from "react-router-dom";
import BasicInfo from "./BasicInfo";
import { CustomButton } from "devkit/Form/Form";
import ApiService from "services/api.service";
import { UtilService } from "services/util.service";
import Modal from "devkit/Modal/Modal";
import { Loader } from "devkit/Loader/Loader";
import { ModalContent } from "../../../devkit/Modal/ModalContent";

class CategoryDetails extends Component<any> {
    state = {
        form: {
            id: {
                value: "",
                errors: [],
            },
            name: {
                value: "",
                errors: [],
            },
            type: {
                value: "",
                errors: [],
            },
            discount: {
                value: "",
                errors: [],
            },
            period_one: {
                value: "",
                errors: [],
            },
            period_two: {
                value: "",
                errors: [],
            },
            period_three: {
                value: "",
                errors: [],
            },
            period_four: {
                value: "",
                errors: [],
            },
            period_five: {
                value: "",
                errors: [],
            },
            remark: {
                value: "",
                errors: [],
            },
        },
        isNewCategory: !this.props.match.params.id,
        currentCategory: {},
        cars: [],
        showUpdateErrorModal: false,
        showUpdateSuccessModal: false,
        showCreateErrorModal: false,
        showCreateSuccessModal: false,
        showLoader: true,
    };

    componentDidMount() {
        if (!this.state.isNewCategory) {
            const categoryID = this.props.match.params.id;
            const token = StoreService.getStoreProperty("token");
            ApiService.getCategory(categoryID, token).then((response) => {
                if (response.success) {
                    const currentCategory = response.data;
                    delete currentCategory.created_at;
                    delete currentCategory.updated_at;
                    currentCategory.remark =
                        currentCategory.remark === null
                            ? ""
                            : currentCategory.remark;

                    const categoryFormated: any = {};
                    if (currentCategory) {
                        Object.keys(currentCategory).forEach((e) => {
                            categoryFormated[e] = {
                                value: currentCategory[e],
                                errors: [],
                            };
                        });
                    }
                    this.setState((prevState: any) => ({
                        ...prevState,
                        currentCategory: currentCategory,
                        form: {
                            ...categoryFormated,
                        },
                        showLoader: false,
                    }));
                }
            });
        } else {
            this.setState({ showLoader: false });
        }
    }

    handleBack = () => {
        this.props.history.goBack();
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleSave = (e) => {
        e.preventDefault();

        const { isNewCategory, currentCategory, form } = this.state;
        const categoryID = parseInt(this.props.match.params.id);
        const token = StoreService.getStoreProperty("token");
        const currentForm: any = form;

        const formErrors = {};
        Object.keys(currentForm).forEach((e) => {
            if (!currentForm[e].value) {
                if (e === "id" || e === "remark" || e === "discount") {
                    return;
                } else {
                    formErrors[e] = {
                        value: currentForm[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            }
        });
        if (Object.keys(formErrors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...formErrors,
                },
            }));
        } else {
            const newDetails = {
                id: categoryID,
                name: currentForm.name.value,
                type: currentForm.type.value,
                discount: currentForm.discount.value,
                period_one: currentForm.period_one.value,
                period_two: currentForm.period_two.value,
                period_three: currentForm.period_three.value,
                period_four: currentForm.period_four.value,
                period_five: currentForm.period_five.value,
                remark: currentForm.remark.value,
            };

            if (isNewCategory) {
                ApiService.createCategory(
                    { ...newDetails, id: categoryID },
                    token
                ).then((response) => {
                    if (response.success) {
                        this.setState({ showCreateSuccessModal: true });
                    } else {
                        this.setState({ showCreateErrorModal: true });
                    }
                });
            } else {
                if (!UtilService.areObjectsEqual(currentCategory, newDetails)) {
                    ApiService.updateCategory(newDetails, token).then(
                        (response) => {
                            if (response.success) {
                                this.setState({ showUpdateSuccessModal: true });
                            } else {
                                this.setState({ showUpdateErrorModal: true });
                            }
                        }
                    );
                } else return;
            }
        }
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
        if (
            name === "showCreateSuccessModal" ||
            name === "showUpdateSuccessModal"
        ) {
            this.props.history.goBack();
        }
    };

    handleRedirect = (id) => {
        this.props.history.push(`/car/${id}`);
    };

    handleDisabledLink = (e) => {
        e.preventDefault();
    };

    render() {
        const { showLoader } = this.state;
        return (
            <div>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showUpdateSuccessModal"
                    className={
                        this.state.showUpdateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        toggleModal={this.toggleModal}
                        text="UPDATE_GROUP_SUCCESS"
                        modalName="showUpdateSuccessModal"
                        title={"SUCCESS"}
                        type={"success"}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showUpdateErrorModal"
                    className={
                        this.state.showUpdateErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="UPDATE_GROUP_FAILURE"
                        modalName="showUpdateErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showCreateSuccessModal"
                    className={
                        this.state.showCreateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        toggleModal={this.toggleModal}
                        text="CREATE_GROUP_SUCCESS"
                        modalName="showCreateSuccessModal"
                        title={"SUCCESS"}
                        type={"success"}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showCreateErrorModal"
                    className={
                        this.state.showCreateErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="CREATE_GROUP_FAILURE"
                        modalName="showCreateErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <div className="d-flex border-b-1 pb-20">
                    <div className="pageHeader w-100-perc d-flex align-items-center justify-content-between">
                        <div>
                            <span className="pointer" onClick={this.handleBack}>
                                <Translate text="GROUPS" />
                            </span>
                            <span className="f-s-16 ml-10">{">"}</span>
                            <span className="f-s-16 ml-10">
                                {this.state.form.name.value}
                            </span>
                        </div>
                        <div>
                            <CustomButton
                                className="btn-primary"
                                type="button"
                                onClick={this.handleSave}
                            >
                                <Translate
                                    text={
                                        !this.state.isNewCategory
                                            ? "SAVE_CHANGES"
                                            : "ADD_GROUP"
                                    }
                                />
                            </CustomButton>
                        </div>
                    </div>
                </div>

                {showLoader ? (
                    <div className="loaderContainer">
                        <Loader width={200} />
                    </div>
                ) : (
                    <div>
                        {!this.state.isNewCategory && (
                            <div className="d-flex padding-0 mt-30 pl-10">
                                <NavLink
                                    to={`/group/${this.props.match.params.id}/basicInfo`}
                                    className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                    style={{
                                        textDecoration: "none",
                                    }}
                                    activeClassName="active"
                                >
                                    <Translate text="BASIC_INFO" />
                                </NavLink>
                                <NavLink
                                    to={`/group/${this.props.match.params.id}/categoryCars/1`}
                                    className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                    style={{
                                        textDecoration: "none",
                                    }}
                                    activeClassName="active"
                                >
                                    <Translate text="CATEGORY_CARS" />
                                </NavLink>
                            </div>
                        )}
                        <div className="mt-50">
                            <BasicInfo
                                formData={this.state.form}
                                handleInput={this.handleInput}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
export default CategoryDetails;
