export const SidebarItems = {
    manager: [
        {
            redirect: "manager-dashboard",
            nameKey: "yourDashboard",
        },
        {
            redirect: "manager-reservationsContracts",
            nameKey: "reservationsContracts",
        },
        {
            redirect: "manager-cars",
            nameKey: "allCars",
        },
        {
            redirect: "manager-managers",
            nameKey: "allManagers",
            adminOnly: true,
        },
    ],
};
