import { FileIcon, FilesIcon, MinusIcon } from "components/Icons/Icons";
import { CustomForm, CustomInput, CustomTextArea } from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";
import React from "react";

const BasicInfo = ({
    form,
    handleInput,
    isNewCompany,
    handleModal,
    documents,
    deleteFile,
}) => {
    return (
        <>
            <div></div>
            <div className="d-flex align-items-center flex-column mt-40 max-width-1000 pl-10">
                <CustomForm className="d-flex justify-content-between w-100-perc">
                    <div className="w-100-perc mr-40">
                        <div className="mb-30 align-self-center">
                            <CustomInput
                                name="name"
                                type="text"
                                handleInput={handleInput}
                                value={form.name.value}
                                errors={form.name.errors}
                                label="COMPANY_NAME"
                                required
                            />
                        </div>
                        <div className="mb-30 align-self-center">
                            <CustomInput
                                name="PIB"
                                type="number"
                                handleInput={handleInput}
                                value={form.PIB.value}
                                errors={form.PIB.errors}
                                label="PIB"
                                required
                            />
                        </div>
                        <div className="mb-30 align-self-center">
                            <CustomInput
                                name="email"
                                type="email"
                                handleInput={handleInput}
                                value={form.email.value}
                                errors={form.email.errors}
                                label="EMAIL"
                                required
                            />
                        </div>
                        <div className="mb-30 align-self-center">
                            <CustomInput
                                name="PDV"
                                type="number"
                                handleInput={handleInput}
                                value={form.PDV.value}
                                errors={form.PDV.errors}
                                label="PDV"
                                required
                            />
                        </div>
                    </div>
                    <div className="w-100-perc">
                        <div className="mb-30 align-self-center">
                            <CustomInput
                                name="address"
                                type="text"
                                handleInput={handleInput}
                                value={form.address.value}
                                errors={form.address.errors}
                                label="COMPANY_ADDRESS"
                                required
                            />
                        </div>
                        <div className="mb-30 align-self-center">
                            <CustomInput
                                name="phone"
                                type="number"
                                handleInput={handleInput}
                                value={form.phone.value}
                                errors={form.phone.errors}
                                label="PHONE_NUMBER"
                                required
                            />
                        </div>
                        <div className="mb-30 align-self-center">
                            <CustomTextArea
                                name="remark"
                                value={form.remark.value}
                                label="REMARK"
                                handleInput={handleInput}
                                className="company-remark"
                            ></CustomTextArea>
                        </div>
                    </div>
                </CustomForm>
                {!isNewCompany && (
                    <div className="align-self-start">
                        <div className="file-input d-flex w-100-perc f-s-16">
                            <div
                                className="addFile mt-20"
                                onClick={() => handleModal("fileUploadModal")}
                            >
                                <span className="icon">
                                    <FilesIcon
                                        className="FilesIcon fill mr-10"
                                        width={24}
                                    />
                                </span>
                                <Translate text="ADD_FILE" />
                            </div>
                        </div>
                        <div className="mt-10 d-flex flex-column align-self-start">
                            {documents.map((item: any, index) => {
                                return (
                                    item.path !== "" && (
                                        <div
                                            key={index}
                                            className="d-flex align-items-start mb-5 f-s-12 pointer"
                                        >
                                            <a
                                                href={`https://rentapi.oykos.dev/storage/${item.path}`}
                                                className="fileLink decoration-none"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span className="f-s-13 d-flex align-items-start file-path">
                                                    <span className="d-flex mr-10">
                                                        <FileIcon
                                                            width={20}
                                                            height={20}
                                                        />
                                                    </span>
                                                    <span className="align-self-end">
                                                        {item.path}
                                                    </span>
                                                    <span
                                                        className="minus-icon d-flex align-items-center"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();

                                                            deleteFile(index);
                                                        }}
                                                    >
                                                        <MinusIcon
                                                            className="ml-10 MinusIcon justify-self-end"
                                                            width={15}
                                                            height={15}
                                                        />
                                                    </span>
                                                </span>
                                            </a>
                                        </div>
                                    )
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default BasicInfo;
