import Translate from "functions/utilFunctions/translate";
import React from "react";

const ContractInfoTab = ({ contractsInfo }) => {
    return (
        <div className="d-flex flex-column contractsInfoTab">
            {!!contractsInfo.active && (
                <section className="d-flex mb-5">
                    <div className="banner active"></div>
                    <span className="ml-5">
                        <Translate text="IN_PROGRESS" />
                        {`:  ${contractsInfo.active}`}
                    </span>
                </section>
            )}
            {!!contractsInfo.late && (
                <section className="d-flex mb-5 align-items-center">
                    <div className="banner late"></div>
                    <span className="ml-5">
                        <Translate text="LATE" />
                        {`:  ${contractsInfo.late}`}
                    </span>
                </section>
            )}
            {!!contractsInfo.finished && (
                <section className="d-flex mb-5 align-items-center">
                    <div className="banner finished"></div>
                    <span className="ml-5">
                        <Translate text="FINISHED" />
                        {`:  ${contractsInfo.finished}`}
                    </span>
                </section>
            )}
            {!!contractsInfo.total && (
                <section className="d-flex mt-10 align-items-center">
                    <div className="banner total"></div>
                    <span className="ml-5">
                        <Translate text="TOTAL_CONTRACTS" />
                        {`:  ${contractsInfo.total}`}
                    </span>
                </section>
            )}
        </div>
    );
};

export default ContractInfoTab;
