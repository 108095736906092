import axios from "axios";
import { StoreService } from "./store.service";
import {ConstantsService} from "./constants.service";

const serverURL = ConstantsService.URL_BASE;

const UNAUTHORIZED = 401;
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === UNAUTHORIZED) {
            StoreService.clearStoreData();
            // @ts-ignore
            window.location = "/";
        }
        return Promise.reject(error);
    }
);

const ApiService = {
    login: (email: string, password: string) => {
        return axios({
            url: `${serverURL}/login`,
            method: "POST",
            data: {
                email,
                password,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    register: (
        first_name: string,
        last_name: string,
        email: string,
        password: string,
        role: number,
        pin: string
    ) => {
        return axios({
            url: `${serverURL}/register`,
            method: "POST",
            data: {
                first_name,
                last_name,
                email,
                password,
                role,
                pin,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    updateFCM: (id, fcm_token, token) => {
        return axios({
            url: `${serverURL}/fcm`,
            method: "POST",
            data: {
                id,
                fcm_token,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    pin: (pin: string, token: string) => {
        return axios({
            url: `${serverURL}/pin`,
            method: "POST",
            data: {
                pin,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updatePin: (data, token) => {
        return axios({
            url: `${serverURL}/users/updatePin`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updatePassword: (data, token) => {
        return axios({
            url: `${serverURL}/users/updatePas`,
            method: "PUT",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    resetPassword: (email) => {
        return axios({
            url: `${serverURL}/resetPassword`,
            method: "POST",
            data: {
                email,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateData: (data, token, url) => {
        return axios({
            url: `${serverURL}/${url}`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    logout: (token) => {
        return axios({
            url: `${serverURL}/logout`,
            method: "POST",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllUsers: (data, token) => {
        return axios({
            url: `${serverURL}/users/getAllUsers`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getUser: (id: number, token) => {
        return axios({
            url: `${serverURL}/users/getUser`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    createUser: (data, token) => {
        return axios({
            url: `${serverURL}/users/createUser`,
            method: "POST",
            data,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    deleteUser: (id: number, token) => {
        return axios({
            url: `${serverURL}/users/deleteUser`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    updateUser: (data, token) => {
        return axios({
            url: `${serverURL}/users/updateUser`,
            method: "POST",
            data: {
                ...data,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    checkResetToken: (data) => {
        return axios({
            url: `${serverURL}/checkResetToken`,
            method: "POST",
            data,
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    newPasswordWithToken: (data) => {
        return axios({
            url: `${serverURL}/newPasswordWithToken`,
            method: "POST",
            data,
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createCustomer: (data, token) => {
        return axios({
            url: `${serverURL}/customers/createCustomer`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllCustomers: (data, token) => {
        return axios({
            url: `${serverURL}/customers/getAllCustomers`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getCustomer: (id, token) => {
        return axios({
            url: `${serverURL}/customers/getCustomer`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    updateCustomer: (data, token) => {
        return axios({
            url: `${serverURL}/customers/updateCustomer`,
            method: "POST",
            data: {
                ...data,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    deleteCustomer: (id, token) => {
        return axios({
            url: `${serverURL}/customers/deleteCustomer`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getAllCompanies: (data, token) => {
        return axios({
            url: `${serverURL}/companies/getAllCompanies`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },

    getCompany: (id, token) => {
        return axios({
            url: `${serverURL}/companies/getCompany`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    createCompany: (data, token) => {
        return axios({
            url: `${serverURL}/companies/createCompany`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    updateCompany: (data, token) => {
        return axios({
            url: `${serverURL}/companies/updateCompany`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    deleteCompany: (id, token) => {
        return axios({
            url: `${serverURL}/companies/deleteCompany`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    updatePersonalAccount: (data, token) => {
        return axios({
            url: `${serverURL}/users/updateUser`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },

    uploadUserImage: (data, token, config) => {
        const onUploadProgress = config.onUploadProgress;
        return axios({
            method: "POST",
            url: `${serverURL}/users/updateProfileImage`,
            data,
            onUploadProgress,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => res.data);
    },

    uploadFile: (data, token, config) => {
        const onUploadProgress = config.onUploadProgress;
        return axios({
            method: "POST",
            url: `${serverURL}/files/uploadFile`,
            data,
            onUploadProgress,
            headers: {
                "content-type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
            },
        }).then((res) => res.data);
    },
    deleteFile: (data, token) => {
        return axios({
            url: `${serverURL}/files/deleteFile`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllEquipments: (data, token) => {
        return axios({
            url: `${serverURL}/additionalEquipment/getAllAdditionalEquipments`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },

    deleteEquipment: (id, token) => {
        return axios({
            url: `${serverURL}/additionalEquipment/deleteAdditionalEquipment`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateEquipment: (data, token) => {
        return axios({
            url: `${serverURL}/additionalEquipment/updateAdditionalEquipment`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createEquipment: (data, token) => {
        return axios({
            url: `${serverURL}/additionalEquipment/createAdditionalEquipment`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getEquipment: (id, token) => {
        return axios({
            url: `${serverURL}/additionalEquipment/getAdditionalEquipment`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getAllCategories: (data, token) => {
        return axios({
            url: `${serverURL}/categories/getAllCategories`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteCategory: (id, token) => {
        return axios({
            url: `${serverURL}/categories/deleteCategory`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createCategory: (data, token) => {
        return axios({
            url: `${serverURL}/categories/createCategory`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateCategory: (data, token) => {
        return axios({
            url: `${serverURL}/categories/updateCategory`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getCategory: (id, token) => {
        return axios({
            url: `${serverURL}/categories/getCategory`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateCar: (data, token) => {
        return axios({
            url: `${serverURL}/cars/updateCar`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createCar: (data, token) => {
        return axios({
            url: `${serverURL}/cars/createCar`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllCars: (data, token) => {
        return axios({
            url: `${serverURL}/cars/getAllCars`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getCar: (id, token) => {
        return axios({
            url: `${serverURL}/cars/getCar`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getCarFilters: (token) => {
        return axios({
            url: `${serverURL}/cars/carsFilters`,
            method: "POST",
            data: {},
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    getAllTyres: (car_id, token) => {
        return axios({
            url: `${serverURL}/tyres/getAllTyres`,
            method: "POST",
            data: {
                car_id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createTyre: (data, token) => {
        return axios({
            url: `${serverURL}/tyres/createTyre`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateTyre: (data, token) => {
        return axios({
            url: `${serverURL}/tyres/updateTyre`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteTyre: (id, token) => {
        return axios({
            url: `${serverURL}/tyres/deleteTyre`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    createService: (data, token) => {
        return axios({
            url: `${serverURL}/services/createService`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllServices: (car_id, token) => {
        return axios({
            url: `${serverURL}/services/getAllServices`,
            method: "POST",
            data: {
                car_id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    updateService: (data, token) => {
        return axios({
            url: `${serverURL}/services/updateService`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    deleteService: (id, token) => {
        return axios({
            url: `${serverURL}/services/deleteService`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getCarContracts: (data, token) => {
        return axios({
            url: `${serverURL}/cars/getCarContracts`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getCompanyContracts: (data, token) => {
        return axios({
            url: `${serverURL}/companies/getCompanyContracts`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getCustomerContracts: (data, token) => {
        return axios({
            url: `${serverURL}/customers/getCustomerContracts`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getAllContracts: (data, token) => {
        return axios({
            url: `${serverURL}/contracts/getAllContracts`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getContract: (id, token) => {
        return axios({
            url: `${serverURL}/contracts/getContract`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    createContract: (data, token) => {
        return axios({
            url: `${serverURL}/contracts/createContract`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    updateContract: (data, token) => {
        return axios({
            url: `${serverURL}/contracts/updateContract`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    deleteContract: (id, token) => {
        return axios({
            url: `${serverURL}/contracts/deleteContract`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    deleteCar: (id, token) => {
        return axios({
            url: `${serverURL}/cars/deleteCar`,
            method: "POST",
            data: {
                id,
            },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
    fetchClientFromCRPS: (data, token) => {
        return axios({
            url: `${serverURL}/customers/fetchClientFromCRPS`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    fetchCompanyStaffFromCRPS: (ID_PREDMET, token) => {
        return axios({
            url: `${serverURL}/companies/fetchCompanyStaffFromCRPS`,
            method: "POST",
            data: { ID_PREDMET },
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getDateRangeCars: (data, token) => {
        return axios({
            url: `${serverURL}/contracts/getDateRangeCars`,
            method: "POST",
            data,
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getContractsPDF: (data, token) => {
        return axios({
            url: `${serverURL}/contracts/downloadContractsPDF`,
            method: "POST",
            data,
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => e);
    },
    getContractPDF: (id, token) => {
        return axios({
            url: `${serverURL}/contracts/downloadContractPDF`,
            method: "POST",
            data: {
                id,
            },
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => res.data)
            .catch((e) => console.log(e));
    },
};

export default ApiService;
