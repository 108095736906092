import React, { Component } from "react";
import { StoreService } from "services/store.service";
import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import ShowMore from "devkit/ShowMore/ShowMore";
import {
    CustomForm,
    CustomInput,
    CustomDatePicker,
    CustomFileInput,
} from "devkit/Form/Form";
import {
    parseDateForMySQL,
    parseDateForCar,
} from "functions/utilFunctions/parsingFunctions";
import { CustomButton } from "devkit/Form/Form";
import {
    PlusIcon,
    SaveIcon,
    BackIcon,
    MinusIcon,
    FilesIcon,
    FileIcon,
    UploadIcon,
} from "components/Icons/Icons";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "../../../devkit/Modal/ModalContent";
import ApiService from "services/api.service";
import { ConstantsService } from "services/constants.service";
import { Loader } from "devkit/Loader/Loader";
import { UtilService } from "services/util.service";

class DamageServiceInfo extends Component<any> {
    state = {
        editDamageServiceInfo: {
            id: {
                value: "",
                errors: [],
            },
            car_id: {
                value: "",
                errors: [],
            },
            date: {
                value: null,
                errors: [],
            },
            file: {
                value: "",
                errors: [],
            },
            supplier: {
                value: "",
                errors: [],
            },
            price: {
                value: "",
                errors: [],
            },
            mileage: {
                value: "",
                errors: [],
            },
            name: {
                value: "",
                errors: [],
            },
            type: {
                value: "",
                errors: [],
            },
            remark: {
                value: "",
                errors: [],
            },
            created_at: {
                value: null,
                errors: [],
            },
            updated_at: {
                value: null,
                errors: [],
            },
        },
        emptyState: {
            id: {
                value: "",
                errors: [],
            },
            car_id: {
                value: "",
                errors: [],
            },
            date: {
                value: null,
                errors: [],
            },
            file: {
                value: "",
                errors: [],
            },
            supplier: {
                value: "",
                errors: [],
            },
            price: {
                value: "",
                errors: [],
            },
            mileage: {
                value: "",
                errors: [],
            },
            name: {
                value: "",
                errors: [],
            },
            type: {
                value: "",
                errors: [],
            },
            remark: {
                value: "",
                errors: [],
            },
            created_at: {
                value: null,
                errors: [],
            },
            updated_at: {
                value: null,
                errors: [],
            },
        },
        serviceTableHeads: [
            {
                value: "DATE",
                sortable: false,
                sortableType: "string",
                sortableName: "date",
            },
            {
                value: "PROVIDER_NAME",
                sortable: false,
                sortableType: "string",
                sortableName: "supplier",
            },
            {
                value: "PRICE",
                sortable: false,
                sortableType: "string",
                sortableName: "price",
            },
            {
                value: "KILOMETERS",
                sortable: false,
                sortableType: "date",
                sortableName: "mileage",
            },
            {
                value: "NOTE",
                sortable: false,
                sortableType: "string",
                sortableName: "remark",
            },
            {
                value: "TITLE",
                sortable: false,
                sortableType: "string",
                sortableName: "name",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        services: [],
        service: {},
        editService: false,
        newService: false,
        showButton: true,
        showUpdateErrorModal: false,
        showUpdateSuccessModal: false,
        showCreateErrorModal: false,
        showCreateSuccessModal: false,
        showDeleteModal: false,
        showDeleteErrorModal: false,
        showDeleteSuccessModal: false,
        fileUploadErrorModal: false,
        deleteID: "",
        showLoader: true,
        documents: [{ path: "", id: null, updated_at: null, created_at: null }],
        document: {
            value: null,
        },
        uploading: false,
        changeType: "",
        completed: 0,
        isOver: false,
        fileUploadModal: false,
    };

    componentDidMount() {
        if (!this.state.newService) {
            const token = StoreService.getStoreProperty("token");
            ApiService.getAllServices(this.props.carId, token).then(
                (response) => {
                    const allServices = response.data;
                    const services = allServices.filter(
                        (service) =>
                            parseInt(service.type) ===
                            ConstantsService.CAR_SERVICES.DAMAGE_SERVICE
                    );
                    this.setState(() => ({
                        services,
                        showLoader: false,
                    }));
                }
            );
        } else {
            this.setState({
                editService: false,
                showLoader: false,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.document.value !== this.state.document.value &&
            this.state.document.value
        ) {
            this.setState({ uploading: true });
            this.handleAddDocument();
        }
    }

    handleAddDocument = () => {
        const document: any = this.state.document.value;
        const token = StoreService.getStoreProperty("token");
        if (this.state.newService) {
            this.setState((prevState: any) => ({
                documents: [...prevState.documents, { path: document.name }],
                completed: 0,
                uploadComplete: false,
                uploading: false,
                fileUploadModal: false,
            }));
        } else {
            const formData = new FormData();
            formData.append("file", document);
            formData.append("type", "service");
            formData.append(
                "service_id",
                this.state.editDamageServiceInfo.id.value
            );
            const config = {
                onUploadProgress: (progressEvent) => {
                    const completed = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    this.setState({
                        completed,
                        uploadComplete: completed === 100,
                    });
                },
            };
            ApiService.uploadFile(formData, token, config).then((res) => {
                if (res.success) {
                    this.setState((prevState: any) => ({
                        documents: [...prevState.documents, res.data],
                        completed: 0,
                        uploadComplete: false,
                        uploading: false,
                        fileUploadModal: false,
                        document: {
                            value: null,
                            errors: [],
                        },
                    }));
                } else {
                    this.setState({
                        fileUploadErrorModal: true,
                        document: {
                            value: null,
                            errors: [],
                        },
                    });
                }
            });
        }
    };

    deleteFile = (index) => {
        const token = StoreService.getStoreProperty("token");
        if (this.state.newService) {
            const files = this.state.documents.filter((e, i) => i !== index);
            this.setState({
                documents: files,
            });
        } else {
            ApiService.deleteFile(
                {
                    id: this.state.documents[index].id,
                },
                token
            ).then((response) => {
                if (response.success) {
                    const files = this.state.documents.filter(
                        (e, i) => i !== index
                    );
                    this.setState({
                        documents: files,
                    });
                }
            });
        }
    };

    addNewService = () => {
        const emptyState = this.state.emptyState;
        this.setState((prevState: any) => ({
            editDamageServiceInfo: {
                ...prevState.editDamageServiceInfo,
                ...emptyState,
            },
            newService: true,
            editService: false,
            showButton: false,
            documents: [
                { path: "", id: null, updated_at: null, created_at: null },
            ],
        }));
    };

    editService = (id) => {
        const token = StoreService.getStoreProperty("token");
        ApiService.getAllServices(this.props.carId, token).then((response) => {
            const allServices = response.data;
            const services = allServices.filter(
                (service) =>
                    parseInt(service.type) ===
                    ConstantsService.CAR_SERVICES.DAMAGE_SERVICE
            );
            const service: any = services.find(
                (e: any) => e.id.toString() === id.toString()
            );
            let serviceFormated = {};
            if (service) {
                Object.keys(service).forEach((e) => {
                    if (e === "date") {
                        serviceFormated[e] = {
                            value: new Date(service[e]),
                            errors: [],
                        };
                    } else {
                        serviceFormated[e] = {
                            value: service[e],
                            errors: [],
                        };
                    }
                });
            }
            this.setState((prevState: any) => ({
                editDamageServiceInfo: {
                    ...prevState.editDamageServiceInfo,
                    ...serviceFormated,
                },
                editService: true,
                newService: false,
                showButton: false,
                service,
                documents: service.files ? service.files : [],
                showLoader: false,
            }));
        });
    };

    backToOverview = () => {
        this.setState({
            editService: false,
            newService: false,
            showButton: true,
        });
    };

    saveChanges = () => {
        const {
            date,
            supplier,
            price,
            mileage,
            name,
            remark,
        } = this.state.editDamageServiceInfo;
        const form = {
            date,
            supplier,
            price,
            mileage,
            name,
            remark,
        };
        const { services } = this.state;
        const token = StoreService.getStoreProperty("token");
        const errors = {};
        const damageServiceInfo = {
            id: this.state.editDamageServiceInfo.id.value,
            car_id: !this.state.newService
                ? this.state.editDamageServiceInfo.car_id.value
                : this.props.carId,
            date: parseDateForMySQL(
                this.state.editDamageServiceInfo.date.value
            ),
            file: this.state.documents,
            supplier: this.state.editDamageServiceInfo.supplier.value,
            price: this.state.editDamageServiceInfo.price.value,
            mileage: this.state.editDamageServiceInfo.mileage.value,
            name: this.state.editDamageServiceInfo.name.value,
            remark: this.state.editDamageServiceInfo.remark.value,
            type: ConstantsService.CAR_SERVICES.DAMAGE_SERVICE,
        };
        const changedDamageServiceInfo: any = damageServiceInfo;

        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });
        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                editDamageServiceInfo: {
                    ...prevState.editDamageServiceInfo,
                    ...errors,
                },
            }));
        } else {
            if (this.state.newService) {
                const document: any = this.state.document.value;
                const formData = new FormData();
                Object.keys(this.state.editDamageServiceInfo).forEach((e) => {
                    if (e === "date") {
                        formData.append(
                            e,
                            parseDateForMySQL(
                                this.state.editDamageServiceInfo[e].value
                            )
                        );
                    } else if (e === "car_id") {
                        formData.append(e, this.props.carId);
                    } else if (e === "type") {
                        formData.append(
                            e,
                            ConstantsService.CAR_SERVICES.DAMAGE_SERVICE.toString()
                        );
                    } else if (e === "file") {
                        formData.append(e, document);
                    } else {
                        formData.append(
                            e,
                            this.state.editDamageServiceInfo[e].value
                        );
                    }
                });

                ApiService.createService(formData, token).then((response) => {
                    if (response.success) {
                        const createdService = response.data;
                        this.setState((prevState: any) => ({
                            services: [...prevState.services, createdService],
                            showCreateSuccessModal: true,
                        }));
                    } else {
                        this.setState({
                            showCreateErrorModal: true,
                        });
                    }
                });
            } else {
                const currentDamageService: any = services.find(
                    (e: any) =>
                        e.id.toString() === damageServiceInfo.id.toString()
                );
                delete changedDamageServiceInfo.file;
                delete currentDamageService.created_at;
                delete currentDamageService.updated_at;
                delete currentDamageService.files;
                const equal = UtilService.areObjectsEqual(
                    changedDamageServiceInfo,
                    currentDamageService
                );
                if (!equal) {
                    ApiService.updateService(damageServiceInfo, token).then(
                        (response) => {
                            if (response.success) {
                                const updatedService = response.data;
                                let services: any = this.state.services.filter(
                                    (service: any) =>
                                        service.id !== updatedService.id
                                );
                                services.push(updatedService);

                                this.setState({
                                    services,
                                    showUpdateSuccessModal: true,
                                });
                            } else {
                                this.setState({
                                    showUpdateErrorModal: true,
                                });
                            }
                        }
                    );
                } else return;
            }
        }
    };

    handleDeleteService = () => {
        const token = StoreService.getStoreProperty("token");
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
        ApiService.deleteService(this.state.deleteID, token).then(
            (response) => {
                if (response.success) {
                    let services: any = this.state.services.filter(
                        (item: any) =>
                            parseInt(item.id) !== parseInt(this.state.deleteID)
                    );

                    this.setState({
                        services,
                        showDeleteSuccessModal: true,
                    });
                } else {
                    this.setState({
                        showDeleteErrorModal: true,
                    });
                    return;
                }
            }
        );
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            editDamageServiceInfo: {
                ...prevState.editDamageServiceInfo,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleSuccessModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
            editService: false,
            createdService: false,
            showButton: true,
            newService: false,
        }));
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleFileChange = (name, e, drop?) => {
        this.setState({
            [name]: UtilService.handleAddFile(name, e, drop),
        });
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };

    render() {
        const {
            date,
            supplier,
            price,
            mileage,
            remark,
            name,
        } = this.state.editDamageServiceInfo;
        return (
            <div className="h-100perc ">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="DELETE_DAMAGE_SERVICE_?"
                        modalName="showDeleteModal"
                        title="DELETE_DAMAGE_SERVICE"
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDeleteService}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteSuccessModal"
                    className={
                        this.state.showDeleteSuccessModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="DAMAGE_SERVICE_DELETE_SUCCESS"
                        modalName="showDeleteSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteErrorModal"
                    className={
                        this.state.showDeleteErrorModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="DAMAGE_SERVICE_DELETE_FAILURE"
                        modalName="showDeleteErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showUpdateSuccessModal"
                    className={
                        this.state.showUpdateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        toggleModal={this.handleSuccessModal}
                        text="UPDATE_DAMAGE_SERVICE_SUCCESS"
                        modalName="showUpdateSuccessModal"
                        title={"SUCCESS"}
                        type={"success"}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showUpdateErrorModal"
                    className={
                        this.state.showUpdateErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="UPDATE_DAMAGE_SERVICE_FAILURE"
                        modalName="showUpdateErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showCreateSuccessModal"
                    className={
                        this.state.showCreateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        toggleModal={this.handleSuccessModal}
                        text="CREATE_DAMAGE_SERVICE_SUCCESS"
                        modalName="showCreateSuccessModal"
                        title={"SUCCESS"}
                        type={"success"}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showCreateErrorModal"
                    className={
                        this.state.showCreateErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="CREATE_DAMAGE_SERVICE_FAILURE"
                        modalName="showCreateErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    modalName="fileUploadModal"
                    toggleModal={this.toggleModal}
                    className={this.state.fileUploadModal ? "visible" : ""}
                    modalWrapperClasses="fileUploadModal"
                >
                    {!this.state.uploading && (
                        <div className="pa-20 h-100-perc">
                            <div
                                onDrop={(e) =>
                                    this.handleFileChange("document", e, true)
                                }
                                className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                    this.state.isOver ? "dotted" : ""
                                }`}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    this.changeBorderType(true);
                                }}
                                onDragLeave={() => this.changeBorderType(false)}
                            >
                                <div className="text d-flex flex-column justify-content-center align-items-center">
                                    <span className="d-block mb-10">
                                        <UploadIcon
                                            width={40}
                                            className="uploadIcon"
                                        />
                                    </span>
                                    <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                        <Translate text="DRAG_AND_DROP" />
                                    </span>
                                    <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">
                                        <Translate text="OR" />
                                    </span>
                                </div>
                                <CustomFileInput
                                    name="document"
                                    className="mb-10"
                                    handleInput={this.handleFileChange}
                                    value={this.state.document.value}
                                    label={"CHOOSE_FILE"}
                                />
                            </div>
                        </div>
                    )}
                    {this.state.uploading && (
                        <div className="progress pa-20 h-100perc text-center border-radius-4">
                            <span className="f-s-20 mb-20">
                                <Translate text="UPLOAD_IN_PROGRESS" />
                            </span>
                            <span className="wrapper d-block">
                                <Loader width={200} />
                                <span className="loader-numbers">
                                    {this.state.completed}%
                                </span>
                            </span>
                        </div>
                    )}
                </Modal>
                {this.state.showButton && (
                    <div className="d-flex pb-20 justify-content-end">
                        <CustomButton
                            className="btn-primary"
                            type="button"
                            onClick={this.addNewService}
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <PlusIcon
                                    width={14}
                                    className="icon hover pointer mr-10 whiteFill"
                                />
                                <Translate text="ADD_DAMAGE_SERVICE" />
                            </div>
                        </CustomButton>
                    </div>
                )}
                <div className="mt-20 f-s-14">
                    {!this.state.editService && !this.state.newService ? (
                        <div>
                            {this.state.showLoader ? (
                                <Loader className="w-200" />
                            ) : (
                                <Table
                                    theads={this.state.serviceTableHeads}
                                    theadsClassname="customThead"
                                >
                                    {this.state.services.map((e: any, i) => {
                                        return (
                                            <tr
                                                className="pointer"
                                                onClick={() =>
                                                    this.editService(e.id)
                                                }
                                                key={i}
                                            >
                                                <td>
                                                    {parseDateForCar(e.date)}
                                                </td>
                                                <td>{e.supplier}</td>
                                                <td>{e.price}</td>
                                                <td>{e.mileage}</td>
                                                <td>{e.remark}</td>
                                                <td>{e.name}</td>
                                                <td>
                                                    <ShowMore>
                                                        <span
                                                            className="one-line-text d-flex justify-content-center"
                                                            onClick={() =>
                                                                this.editService(
                                                                    e.id
                                                                )
                                                            }
                                                        >
                                                            <Translate text="CHANGE_DATA" />
                                                        </span>
                                                        <span
                                                            className="one-line-text d-flex justify-content-center"
                                                            onClick={() =>
                                                                this.handleDeleteModal(
                                                                    e.id
                                                                )
                                                            }
                                                        >
                                                            <span className="danger">
                                                                <Translate text="DELETE" />
                                                            </span>
                                                        </span>
                                                    </ShowMore>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                            )}
                        </div>
                    ) : (
                        <div>
                            <CustomForm className="h-100perc py-10">
                                <div className="row">
                                    <div className="my-20 col-auto w-280">
                                        <CustomDatePicker
                                            name="date"
                                            label="DATE"
                                            handleInput={this.handleInput}
                                            value={date.value}
                                            errors={date.errors}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="supplier"
                                            label="PROVIDER_NAME"
                                            value={supplier.value}
                                            type="text"
                                            errors={supplier.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="price"
                                            label="PRICE"
                                            value={price.value}
                                            type="number"
                                            errors={price.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="mileage"
                                            label="KILOMETERS"
                                            value={mileage.value}
                                            type="number"
                                            errors={mileage.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="remark"
                                            label="NOTE"
                                            value={remark.value}
                                            type="text"
                                            errors={remark.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280 ">
                                        <CustomInput
                                            name="name"
                                            label="TITLE"
                                            value={name.value}
                                            type="text"
                                            errors={name.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                </div>
                                <div
                                    className="addFile mt-20"
                                    onClick={() =>
                                        this.toggleModal("fileUploadModal")
                                    }
                                >
                                    <span className="icon">
                                        <FilesIcon
                                            className="FilesIcon fill mr-20"
                                            width={24}
                                        />
                                    </span>
                                    <Translate text="ADD_FILE" />
                                </div>
                                <div className="mt-10 d-flex flex-column">
                                    {this.state.documents &&
                                        this.state.documents.map(
                                            (item: any, index) => {
                                                return (
                                                    item.path !== "" && (
                                                        <div
                                                            key={index}
                                                            className="d-flex align-items-center mb-5 f-s-12 pointer"
                                                        >
                                                            <a
                                                                href={`https://rentapi.oykos.dev/storage/${item.path}`}
                                                                className="fileLink decoration-none"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                download
                                                            >
                                                                <span className="f-s-13 d-flex">
                                                                    <span className="d-flex mr-10">
                                                                        <FileIcon
                                                                            width={
                                                                                20
                                                                            }
                                                                            height={
                                                                                20
                                                                            }
                                                                        />
                                                                    </span>
                                                                    <span>
                                                                        {
                                                                            item.path
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        className="minus-icon d-flex align-items-center"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            e.preventDefault();

                                                                            this.deleteFile(
                                                                                index
                                                                            );
                                                                        }}
                                                                    >
                                                                        <MinusIcon
                                                                            className="ml-10 MinusIcon"
                                                                            width={
                                                                                15
                                                                            }
                                                                            height={
                                                                                15
                                                                            }
                                                                        />
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </div>
                                                    )
                                                );
                                            }
                                        )}
                                </div>
                            </CustomForm>
                            <div className="d-flex align-items-center mt-20">
                                <CustomButton
                                    className="btn-primary mr-20"
                                    type="button"
                                    onClick={this.backToOverview}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <BackIcon
                                            width={15}
                                            className="icon hover pointer mr-10 whiteFill"
                                        />
                                        <Translate text="OVERVIEW" />
                                    </div>
                                </CustomButton>
                                <CustomButton
                                    className="btn-primary"
                                    type="button"
                                    onClick={this.saveChanges}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SaveIcon
                                            width={14}
                                            className="icon hover pointer mr-10 whiteFill"
                                        />
                                        <Translate text="SAVE_CHANGES" />
                                    </div>
                                </CustomButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default DamageServiceInfo;
