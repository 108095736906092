import Translate from "functions/utilFunctions/translate";
import React from "react";

const SummaryInfo = (props) => {
    return (
        <div className="d-flex justify-content-between mb-10 transformations">
            <span className="subTitle transformations">
                <Translate text={props.title} />
                {":"}
            </span>
            <span className="information">{props.information}</span>
        </div>
    );
};

export default SummaryInfo;
