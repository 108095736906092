import React, { Component } from "react";
import { StoreService } from "services/store.service";
import Translate from "functions/utilFunctions/translate";
import { CustomButton, CustomDropdown } from "devkit/Form/Form";
import {
    parseDate,
    parseDateForMySQL,
} from "functions/utilFunctions/parsingFunctions";
import ContractsList from "./CarContracts";
import CarBasicInfo from "./CarBasicInfo";
import CarInsuranceInfo from "./CarInsuranceInfo";
import TyreInfo from "./TyreInfo";
import RegularServiceInfo from "./RegularServiceInfo";
import UnplannedServiceInfo from "./UnplannedServiceInfo";
import DamageServiceInfo from "./DamageServiceInfo";
import DocumentsList from "./DocumentsList";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "../../../devkit/Modal/ModalContent";
import ApiService from "services/api.service";
import { Loader } from "devkit/Loader/Loader";
import { UtilService } from "services/util.service";
import { NavLink, Route, RouteComponentProps, Switch } from "react-router-dom";
import CarPhotos from "./CarPhotos";

class CarDetails extends Component<RouteComponentProps<{ id: string }>, any> {
    state = {
        form: {
            id: {
                value: "",
                errors: [],
            },
            manufacturer: {
                value: "",
                errors: [],
            },
            model: {
                value: "",
                errors: [],
            },
            mileage: {
                value: "",
                errors: [],
            },
            fuel_amount: {
                value: "",
                errors: [],
            },
            fuel_type: {
                value: "",
                errors: [],
            },
            engine_number: {
                value: "",
                errors: [],
            },
            unique_number: {
                value: "",
                errors: [],
            },
            engine_power: {
                value: "",
                errors: [],
            },
            engine_capacity: {
                value: "",
                errors: [],
            },
            transmission: {
                value: "",
                errors: [],
            },
            location: {
                value: "",
                errors: [],
            },
            licence_plate: {
                value: "",
                errors: [],
            },
            color: {
                value: "",
                errors: [],
            },
            registration_date: {
                value: null,
                errors: [],
            },
            purchase_date: {
                value: null,
                errors: [],
            },
            entry_date: {
                value: null,
                errors: [],
            },
            production_date: {
                value: null,
                errors: [],
            },
            category_id: {
                value: 0,
                errors: [],
            },
            created_at: {
                value: null,
                errors: [],
            },
            updated_at: {
                value: null,
                errors: [],
            },
        },
        insuranceInfo: {
            id: {
                value: "",
                errors: [],
            },
            car_id: {
                value: "",
                errors: [],
            },
            name: {
                value: "",
                errors: [],
            },
            supplier: {
                value: "",
                errors: [],
            },
            price: {
                value: "",
                errors: [],
            },
            mileage: {
                value: "",
                errors: [],
            },
            date: {
                value: null,
                errors: [],
            },
            remark: {
                value: "",
                errors: [],
            },
            created_at: {
                value: null,
                errors: [],
            },
            updated_at: {
                value: null,
                errors: [],
            },
        },
        insuranceInfoEmpty: {
            id: {
                value: "",
                errors: [],
            },
            car_id: {
                value: "",
                errors: [],
            },
            name: {
                value: "",
                errors: [],
            },
            supplier: {
                value: "",
                errors: [],
            },
            price: {
                value: "",
                errors: [],
            },
            mileage: {
                value: "",
                errors: [],
            },
            date: {
                value: null,
                errors: [],
            },
            remark: {
                value: "",
                errors: [],
            },
            created_at: {
                value: null,
                errors: [],
            },
            updated_at: {
                value: null,
                errors: [],
            },
        },
        tyreTableHeads: [
            {
                value: "TITLE",
                sortable: false,
                sortableType: "string",
                sortableName: "title",
            },
            {
                value: "MODEL",
                sortable: false,
                sortableType: "string",
                sortableName: "model",
            },
            {
                value: "MANUFACTURER",
                sortable: false,
                sortableType: "string",
                sortableName: "manufacturer",
            },
            {
                value: "TYPE",
                sortable: false,
                sortableType: "date",
                sortableName: "type",
            },
            {
                value: "SIZE",
                sortable: false,
                sortableType: "string",
                sortableName: "size",
            },
            {
                value: "PURCHASE_DATE",
                sortable: false,
                sortableType: "string",
                sortableName: "purchasedDate",
            },
            {
                value: "STATE",
                sortable: false,
                sortableType: "string",
                sortableName: "state",
            },
            {
                value: "NAME_OF_SHOP",
                sortable: false,
                sortableType: "string",
                sortableName: "nameOfShop",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        newCar: !this.props.match.params.id,
        categoriesArray: [],
        tires: [],
        showBasicInfo: true,
        showInsuranceInfo: false,
        showTyreInfo: false,
        showRegularServiceInfo: false,
        showUnplannedServiceInfo: false,
        showDamageServiceInfo: false,
        showUpdateErrorModal: false,
        showUpdateSuccessModal: false,
        showCreateErrorModal: false,
        showCreateSuccessModal: false,
        newCarId: "",
        newInsuranceId: "",
        showLoader: true,
        car: {},
        insurance: {
            mileage: "",
            name: "",
            price: "",
            remark: "",
            supplier: "",
            date: "",
        },
        showDocumentsList: false,
        showContractsList: false,
    };

    handleBack = () => {
        this.props.history.push("/cars/1");
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        if (!this.state.newCar) {
            const carId = this.props.match.params.id;
            ApiService.getAllCategories({ limit: 1000, offset: 0 }, token).then(
                (response) => {
                    if (response.success) {
                        const categories = response.data;
                        ApiService.getCar(carId, token).then((carResponse) => {
                            const car = carResponse.data;
                            const insurance = car.insurance;

                            const categoriesArray = categories.map(
                                (e: any) => ({
                                    value: e.id,
                                    label: e.name,
                                })
                            );

                            categoriesArray.unshift({
                                value: 0,
                                label: "Izaberi kategoriju",
                            });
                            const carFormated = {};
                            const carInsuranceFormated = {};
                            if (car) {
                                Object.keys(car).forEach((e) => {
                                    if (
                                        e === "entry_date" ||
                                        e === "production_date" ||
                                        e === "registration_date" ||
                                        e === "purchase_date"
                                    ) {
                                        carFormated[e] = {
                                            value: new Date(car[e]),
                                            errors: [],
                                        };
                                    } else if (e === "insurance") {
                                        Object.keys(car[e]).forEach((key) => {
                                            if (key === "date") {
                                                carInsuranceFormated[key] = {
                                                    value: new Date(
                                                        car[e].date
                                                    ),
                                                    errors: [],
                                                };
                                            } else {
                                                carInsuranceFormated[key] = {
                                                    value: car[e][key],
                                                    errors: [],
                                                };
                                            }
                                        });
                                    } else if (e === "category_id") {
                                        carFormated[e] = {
                                            value: parseInt(car[e]),
                                            errors: [],
                                        };
                                    } else {
                                        carFormated[e] = {
                                            value: car[e],
                                            errors: [],
                                        };
                                    }
                                });
                            }
                            this.setState((prevState: any) => ({
                                form: {
                                    ...prevState.form,
                                    ...carFormated,
                                },
                                insuranceInfo: {
                                    ...prevState.insuranceInfo,
                                    ...carInsuranceFormated,
                                },
                                categoriesArray,
                                showLoader: false,
                                car,
                                insurance,
                            }));
                        });
                    }
                }
            );
        } else {
            ApiService.getAllCategories({ limit: 1000, offset: 0 }, token).then(
                (response) => {
                    if (response.success) {
                        const carCategories = response.data;
                        const categoriesArray = carCategories.map((e: any) => ({
                            value: e.id,
                            label: e.name,
                        }));
                        this.setState({
                            categoriesArray,
                            showLoader: false,
                        });
                    }
                }
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            window.location.reload();
        }
    }

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleInsuranceInput = (name, data) => {
        this.setState((prevState: any) => ({
            insuranceInfo: {
                ...prevState.insuranceInfo,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleSave = () => {
        const token = StoreService.getStoreProperty("token");
        const { newCar, form, insuranceInfo, car, insurance } = this.state;
        let carFormated: any = car;
        delete carFormated.created_at;
        delete carFormated.updated_at;
        carFormated.insurance_date = parseDateForMySQL(insurance.date);
        carFormated.insurance_mileage = insurance.mileage;
        carFormated.insurance_name = insurance.name;
        carFormated.insurance_price = insurance.price;
        carFormated.insurance_remark = insurance.remark;
        carFormated.insurance_supplier = insurance.supplier;
        delete carFormated.insurance;
        const carInfo = {
            manufacturer: this.state.form.manufacturer.value,
            model: this.state.form.model.value,
            mileage: this.state.form.mileage.value,
            fuel_amount: this.state.form.fuel_amount.value,
            fuel_type: this.state.form.fuel_type.value,
            engine_number: this.state.form.engine_number.value,
            unique_number: this.state.form.unique_number.value,
            engine_power: this.state.form.engine_power.value,
            engine_capacity: this.state.form.engine_capacity.value,
            transmission: this.state.form.transmission.value,
            location: this.state.form.location.value,
            licence_plate: this.state.form.licence_plate.value,
            color: this.state.form.color.value,
            registration_date: parseDateForMySQL(
                this.state.form.registration_date.value
            ),
            purchase_date: parseDateForMySQL(
                this.state.form.purchase_date.value
            ),
            entry_date: parseDateForMySQL(this.state.form.entry_date.value),
            production_date: parseDateForMySQL(
                this.state.form.production_date.value
            ),
            category_id: this.state.form.category_id.value,
            insurance_name: this.state.insuranceInfo.name.value,
            insurance_supplier: this.state.insuranceInfo.supplier.value,
            insurance_price: this.state.insuranceInfo.price.value,
            insurance_mileage: this.state.insuranceInfo.mileage.value,
            insurance_date: parseDateForMySQL(
                this.state.insuranceInfo.date.value
            ),
            insurance_remark: this.state.insuranceInfo.remark.value,
            id: this.state.form.id.value,
        };
        if (newCar) {
            // delete carInfo.id;
        }
        const formErrors = {};
        const insuranceErrors = {};

        Object.keys(insuranceInfo).forEach((e) => {
            if (!insuranceInfo[e].value) {
                if (
                    e === "id" ||
                    e === "car_id" ||
                    e === "created_at" ||
                    e === "updated_at"
                ) {
                    return;
                } else {
                    insuranceErrors[e] = {
                        value: insuranceInfo[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            }
        });

        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                if (e === "id" || e === "created_at" || e === "updated_at") {
                    return;
                } else {
                    formErrors[e] = {
                        value: form[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            }
        });
        if (Object.keys(formErrors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...formErrors,
                },
            }));
        } else if (
            Object.keys(formErrors).length === 0 &&
            Object.keys(insuranceErrors).length !== 0
        ) {
            this.setState((prevState: any) => ({
                insuranceInfo: {
                    ...prevState.insuranceInfo,
                    ...insuranceErrors,
                },
                showBasicInfo: false,
                showInsuranceInfo: true,
            }));
        } else {
            if (newCar) {
                ApiService.createCar(carInfo, token).then((response) => {
                    if (response.success) {
                        this.setState({
                            showCreateSuccessModal: true,
                            newCarId: response.data.id,
                        });
                    } else {
                        if (response.message.includes("ENGINE_NUMBER_TAKEN")) {
                            this.setState((prevState: any) => ({
                                form: {
                                    ...prevState.form,
                                    engine_number: {
                                        ...prevState.form.engine_number,
                                        errors: ["ENGINE_NUMBER_TAKEN"],
                                    },
                                },
                            }));
                        }
                        if (response.message.includes("UNIQUE_NUMBER_TAKEN")) {
                            this.setState((prevState: any) => ({
                                form: {
                                    ...prevState.form,
                                    unique_number: {
                                        ...prevState.form.unique_number,
                                        errors: ["UNIQUE_NUMBER_TAKEN"],
                                    },
                                },
                            }));
                        }
                        if (
                            response.message.includes(
                                "REGISTRATION_PLATES_TAKEN"
                            )
                        ) {
                            this.setState((prevState: any) => ({
                                form: {
                                    ...prevState.form,
                                    licence_plate: {
                                        ...prevState.form.licence_plate,
                                        errors: ["REGISTRATION_PLATES_TAKEN"],
                                    },
                                },
                            }));
                        }
                        this.setState({
                            showCreateErrorModal: true,
                        });
                    }
                });
            } else {
                const equal = UtilService.areObjectsEqual(carFormated, carInfo);
                if (!equal) {
                    ApiService.updateCar(carInfo, token).then((response) => {
                        if (response) {
                            this.setState({
                                showUpdateSuccessModal: true,
                            });
                        } else {
                            this.setState({
                                showUpdateErrorModal: true,
                            });
                        }
                    });
                }
            }
        }
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleSuccessModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
        }));
        this.props.history.push("/cars/1");
    };

    redirectToNewCar = () => {
        if (this.state.newCarId) {
            this.setState((prevState: any) => ({
                showCreateSuccessModal: !prevState.showCreateSuccessModal,
                newCar: false,
                form: {
                    ...prevState.form,
                    id: {
                        value: this.state.newCarId,
                        errors: [],
                    },
                },
            }));
            this.props.history.push(`/car/${this.state.newCarId}/basic-info`);
        }
        if (this.state.newInsuranceId) {
            this.setState((prevState: any) => ({
                newCar: false,
                insuranceInfo: {
                    ...prevState.insuranceInfo,
                    id: {
                        value: this.state.newInsuranceId,
                        errors: [],
                    },
                    car_id: {
                        value: this.state.newCarId,
                        errors: [],
                    },
                },
            }));
            this.props.history.push(`/car/${this.state.newCarId}`);
        }
    };

    render() {
        const { newCar } = this.state;

        if (this.state.showLoader) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showUpdateSuccessModal"
                        className={
                            this.state.showUpdateSuccessModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.handleSuccessModal}
                            text="UPDATE_CAR_SUCCESS"
                            modalName="showUpdateSuccessModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showUpdateErrorModal"
                        className={
                            this.state.showUpdateErrorModal
                                ? "visible bg-info-3"
                                : ""
                        }
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="UPDATE_CAR_FAILURE"
                            modalName="showUpdateErrorModal"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCreateSuccessModal"
                        className={
                            this.state.showCreateSuccessModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.redirectToNewCar}
                            text="CREATE_CAR_SUCCESS"
                            modalName="showCreateSuccessModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCreateErrorModal"
                        className={
                            this.state.showCreateErrorModal
                                ? "visible bg-info-3"
                                : ""
                        }
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="CREATE_CAR_FAILURE"
                            modalName="showCreateErrorModal"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <div className="d-flex border-b-1 pb-20">
                        <div className="pageHeader w-100-perc d-flex align-items-center justify-content-between">
                            <div>
                                <span
                                    className="pointer"
                                    onClick={this.handleBack}
                                >
                                    <Translate text="CARS" />
                                </span>
                                <span className="f-s-16 ml-10">{">"}</span>
                                <span className="f-s-16 ml-10">
                                    {`${this.state.form.manufacturer.value} ${this.state.form.model.value}`}
                                </span>
                            </div>
                            <div>
                                <CustomButton
                                    className="btn-primary"
                                    type="button"
                                    onClick={this.handleSave}
                                >
                                    <Translate text={"SAVE_CHANGES"} />
                                </CustomButton>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex justify-content-around align-items-center border-radius-4 mt-40 personal-shadow flex-wrap">
                        <div className="py-20 w-200">
                            <CustomDropdown
                                data={this.state.categoriesArray}
                                name="category_id"
                                placeholder={<Translate text="CAR_CATEGORY" />}
                                value={this.state.categoriesArray.find(
                                    (e: any) =>
                                        e.value ===
                                        this.state.form.category_id.value
                                )}
                                handleChange={this.handleInput}
                                errors={this.state.form.category_id.errors}
                                label="CAR_CATEGORY"
                            />
                        </div>
                        <div className="py-20 d-flex flex-column">
                            <span className="car-details-label f-s-12">
                                <Translate text="MANUFACTURER" />
                            </span>
                            <span className="f-s-18 bold theme-main-6">
                                {this.state.form.manufacturer.value}
                            </span>
                        </div>
                        <div className="py-20 d-flex flex-column">
                            <span className="car-details-label f-s-12">
                                <Translate text="MODEL" />
                            </span>
                            <span className="f-s-18 bold theme-main-6">
                                {this.state.form.model.value}
                            </span>
                        </div>
                        <div className="py-20 d-flex flex-column">
                            <span className="car-details-label f-s-12">
                                <Translate text="REGISTRATION_DATE" />
                            </span>
                            <span className="f-s-18 bold theme-main-6">
                                {parseDate(
                                    this.state.form.registration_date.value
                                )}
                            </span>
                        </div>
                        <div className="py-20 d-flex flex-column">
                            <span className="car-details-label f-s-12">
                                <Translate text="REGISTRATION_PLATE" />
                            </span>
                            <span className="f-s-18 bold theme-main-6">
                                {this.state.form.licence_plate.value}
                            </span>
                        </div>
                    </div>
                    <div className="d-flex padding-0 mt-40 flex-wrap">
                        <NavLink
                            exact
                            to={`${
                                newCar
                                    ? "/new-car/basic-info"
                                    : `/car/${this.props.match.params.id}/basic-info`
                            }`}
                            className={`reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none 
                            }`}
                            activeClassName="active"
                        >
                            <span>
                                <Translate text="BASIC_INFO" />
                            </span>
                        </NavLink>
                        <NavLink
                            exact
                            to={`${
                                newCar
                                    ? "/new-car/insurance-info"
                                    : `/car/${this.props.match.params.id}/insurance-info`
                            }`}
                            className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                            activeClassName="active"
                        >
                            <span>
                                <Translate text="INSURANCE" />
                            </span>
                        </NavLink>
                        {!this.state.newCar && (
                            <>
                                <NavLink
                                    exact
                                    to={`/car/${this.props.match.params.id}/tyre-info`}
                                    className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                    activeClassName="active"
                                >
                                    <span>
                                        <Translate text="TYRE_TYPE" />
                                    </span>
                                </NavLink>
                                <NavLink
                                    exact
                                    to={`/car/${this.props.match.params.id}/regular-service-info`}
                                    className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                    activeClassName="active"
                                >
                                    <span>
                                        <Translate text="REGULAR_SERVICE" />
                                    </span>
                                </NavLink>
                                <NavLink
                                    exact
                                    to={`/car/${this.props.match.params.id}/unplanned-service-info`}
                                    className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                    activeClassName="active"
                                >
                                    <span>
                                        <Translate text="UNPLANNED_SERVICE" />
                                    </span>
                                </NavLink>
                                <NavLink
                                    exact
                                    to={`/car/${this.props.match.params.id}/damage-service-info`}
                                    className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                    activeClassName="active"
                                >
                                    <span>
                                        <Translate text="DAMAGES" />
                                    </span>
                                </NavLink>
                                <NavLink
                                    exact
                                    to={`/car/${this.props.match.params.id}/documents`}
                                    className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                    activeClassName="active"
                                >
                                    <span>
                                        <Translate text="DOCUMENTS" />
                                    </span>
                                </NavLink>
                                <NavLink
                                    exact
                                    to={`/car/${this.props.match.params.id}/photos`}
                                    className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                    activeClassName="active"
                                >
                                    <span>
                                        <Translate text="PHOTOS" />
                                    </span>
                                </NavLink>
                                <NavLink
                                    exact
                                    to={`/car/${this.props.match.params.id}/contracts/1`}
                                    className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                                    activeClassName="active"
                                >
                                    <span>
                                        <Translate text="CONTRACTS" />
                                    </span>
                                </NavLink>
                            </>
                        )}
                    </div>
                    <Switch>
                        <Route
                            exact
                            path={`/car/${this.props.match.params.id}/basic-info`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <CarBasicInfo
                                            {...props}
                                            data={this.state.form}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/new-car/basic-info`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <CarBasicInfo
                                            {...props}
                                            data={this.state.form}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/car/${this.props.match.params.id}/insurance-info`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <CarInsuranceInfo
                                            {...props}
                                            data={this.state.insuranceInfo}
                                            handleInput={
                                                this.handleInsuranceInput
                                            }
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/new-car/insurance-info`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <CarInsuranceInfo
                                            {...props}
                                            data={this.state.insuranceInfo}
                                            handleInput={
                                                this.handleInsuranceInput
                                            }
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/car/${this.props.match.params.id}/tyre-info`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <TyreInfo
                                            {...props}
                                            carId={this.state.form.id.value}
                                            newCar={this.state.newCar}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/car/${this.props.match.params.id}/regular-service-info`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <RegularServiceInfo
                                            {...props}
                                            carId={this.state.form.id.value}
                                            newCar={this.state.newCar}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/car/${this.props.match.params.id}/unplanned-service-info`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <UnplannedServiceInfo
                                            {...props}
                                            carId={this.state.form.id.value}
                                            newCar={this.state.newCar}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/car/${this.props.match.params.id}/damage-service-info`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <DamageServiceInfo
                                            {...props}
                                            carId={this.state.form.id.value}
                                            newCar={this.state.newCar}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/car/${this.props.match.params.id}/documents`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <DocumentsList
                                            {...props}
                                            carId={this.state.form.id.value}
                                            newCar={this.state.newCar}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/car/${this.props.match.params.id}/photos`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <CarPhotos
                                            {...props}
                                            carId={this.state.form.id.value}
                                            newCar={this.state.newCar}
                                        />
                                    </div>
                                );
                            }}
                        />
                        <Route
                            exact
                            path={`/car/${this.props.match.params.id}/contracts/:pageNo`}
                            render={(props) => {
                                return (
                                    <div className="mt-40">
                                        <ContractsList
                                            {...props}
                                            carId={this.state.form.id.value}
                                            newCar={this.state.newCar}
                                        />
                                    </div>
                                );
                            }}
                        />
                    </Switch>
                </div>
            );
        }
    }
}
export default CarDetails;
