import React from "react";
import Pagination from "react-js-pagination";

interface IPagination {
	totalItemsCount: number;
	onChange: (pageNumber) => void;
	activePage: number;
	perPage: number;
	itemsCountPerPage?: number;
	pageRangeDisplayed?: number;
}

const Paginate = ({
	totalItemsCount,
	onChange,
	activePage,
	perPage,
	pageRangeDisplayed,
}: IPagination) => {
	return (
		<div className="d-flex w-100-perc pagination justify-content-center pt-30">
			<div className="d-flex w-40-perc justify-content-center">
				<Pagination
					totalItemsCount={totalItemsCount}
					onChange={onChange}
					activePage={activePage}
					itemsCountPerPage={perPage}
					pageRangeDisplayed={pageRangeDisplayed}
					prevPageText="-"
					nextPageText="+"
					activeClass="activePage"
				/>
			</div>
		</div>
	);
};

export default Paginate;
