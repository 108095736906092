import { CustomButton, CustomForm, CustomInput } from "devkit/Form/Form";
import React, { Component } from "react";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import Translate from "../../functions/utilFunctions/translate";
import { validateEmail } from "functions/utilFunctions/validations";

class ResetPassword extends Component<any> {
    state = {
        form: {
            userName: {
                value: "",
                errors: [],
            },
            password: {
                value: "",
                errors: [],
            },
        },
        showSuccessModal: false,
        showErrorModal: false,
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.resetPassword(e);
    };

    resetPassword = (e) => {
        e.preventDefault();
        if (this.state.form.userName.value === "") {
            this.handleInput("userName", {
                value: "",
                errors: ["INPUT_REQUIRED"],
            });
            return;
        }

        if (!validateEmail(this.state.form.userName.value)) {
            this.handleInput("userName", {
                value: this.state.form.userName.value,
                errors: ["EMAIL_INVALID"],
            });
            return;
        }

        // ApiService.resetPassword(this.state.form.userName.value).then(
        //     (response) => {
        //         if (response.status === "OK") {
        //             this.setState({
        //                 showSuccessModal: true,
        //                 showErrorModal: false,
        //             });
        //         } else {
        //             this.setState({
        //                 showSuccessModal: false,
        //                 showErrorModal: true,
        //             });
        //         }
        //     }
        // );
    };
    handleSuccessModal = () => {
        this.setState((prevState: any) => ({
            showSuccessModal: !prevState.showSuccessModal,
        }));
        this.props.history.push("/");
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    render() {
        const { userName } = this.state.form;

        return (
            <div className="login d-flex flex-row">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessModal"
                    className={this.state.showSuccessModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        title="SUCCESS"
                        text="PASSWORD_CHANGE_SUCCESS"
                        modalName="showSuccessModal"
                        toggleModal={this.handleSuccessModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showErrorModal"
                    className={
                        this.state.showErrorModal ? "visible error" : "error"
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILURE"
                        text="NO_EMAIL"
                        modalName="showErrorModal"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <div id="left" className="leftSide w-50-perc h-vh-100" />
                <div
                    id="right"
                    className="d-flex w-50-perc h-vh-100 flex-column justify-content-center"
                >
                    <div className="align-self-center">
                        <img
                            src="../images/logo.png"
                            alt="logo"
                            className="logo"
                        />
                    </div>
                    <span className="f-s-16 align-self-center pt-30">
                        <Translate text="CHANGE_PASSWORD" />
                    </span>
                    <CustomForm
                        handleSubmit={this.handleSubmit}
                        className="d-flex flex-column"
                    >
                        <div className="padding-v-20 w-40-perc align-self-center">
                            <CustomInput
                                name="userName"
                                type="email"
                                handleInput={this.handleInput}
                                value={userName.value}
                                errors={userName.errors}
                                label="Email"
                                required
                            />
                        </div>
                        <div className="d-flex align-self-center pt-20">
                            <CustomButton
                                type="submit"
                                onClick={this.resetPassword}
                                className="btn-login"
                            >
                                <Translate text="RESET_PASSWORD" />
                            </CustomButton>
                        </div>
                    </CustomForm>
                    {/* <div
                            
                            onClick={this.resetPassword}
                            className="flex align-self-center pointer margin-t-20 f-s-14"
                        >
                            Reset password
                    </div> */}
                </div>
            </div>
        );
    }
}

export default ResetPassword;
