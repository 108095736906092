import React from "react";
import {
    CustomForm,
    CustomInput,
    CustomTextArea,
    CustomDatePicker,
} from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";
import { FileIcon, FilesIcon, MinusIcon } from "components/Icons/Icons";

const BasicInfo = ({
    form,
    handleInput,
    toggleModal,
    deleteFile,
    documents,
    isCreateNew,
}) => {
    return (
        <>
            <div className="d-flex flex-column mt-40 max-width-1000 pl-10">
                <CustomForm className="d-flex w-100-perc ">
                    <div className="mr-30 w-50-perc">
                        <div className="mb-30">
                            <CustomInput
                                name="first_name"
                                type="text"
                                handleInput={handleInput}
                                label="FIRST_NAME"
                                value={form.first_name.value}
                                errors={form.first_name.errors}
                                required
                            />
                        </div>
                        <div className="mb-30">
                            <CustomInput
                                name="last_name"
                                type="text"
                                label="LAST_NAME"
                                value={form.last_name.value}
                                errors={form.last_name.errors}
                                handleInput={handleInput}
                                required
                            />
                        </div>
                        <div className="mb-30">
                            <CustomInput
                                name="email"
                                type="email"
                                label="EMAIL"
                                value={form.email.value}
                                errors={form.email.errors}
                                handleInput={handleInput}
                                required
                            />
                        </div>
                        <div className="mb-30">
                            <CustomDatePicker
                                name="date_of_birth"
                                label="DATE_OF_BIRTH"
                                value={form.date_of_birth.value}
                                errors={form.date_of_birth.errors}
                                handleInput={handleInput}
                            />
                        </div>
                        <div className="mb-30">
                            <CustomInput
                                name="phone"
                                type="number"
                                label="PHONE_NUMBER"
                                value={form.phone.value}
                                errors={form.phone.errors}
                                handleInput={handleInput}
                                required
                            />
                        </div>
                        <div className="mb-30">
                            <CustomInput
                                name="country"
                                type="text"
                                label="COUNTRY"
                                value={form.country.value}
                                errors={form.country.errors}
                                handleInput={handleInput}
                            />
                        </div>
                    </div>
                    <div className="w-50-perc">
                        <div className="mb-30">
                            <CustomInput
                                name="licence_id"
                                type="number"
                                label="DRIVING_LICENCE_NUMBER"
                                value={form.licence_id.value}
                                errors={form.licence_id.errors}
                                handleInput={handleInput}
                                required
                            />
                        </div>
                        <div className="mb-30">
                            <CustomDatePicker
                                name="licence_id_expiration_date"
                                value={form.licence_id_expiration_date.value}
                                label="VALID_UNTIL"
                                errors={form.licence_id_expiration_date.errors}
                                handleInput={handleInput}
                            />
                        </div>
                        <div className="mb-30">
                            <CustomInput
                                name="personal_id"
                                type="number"
                                value={form.personal_id.value}
                                label="ID_NUMBER"
                                errors={form.personal_id.errors}
                                handleInput={handleInput}
                                required
                            />
                        </div>
                        <div className="mb-30">
                            <CustomDatePicker
                                name="personal_id_expiration_date"
                                label="VALID_UNTIL"
                                value={form.personal_id_expiration_date.value}
                                errors={form.personal_id_expiration_date.errors}
                                handleInput={handleInput}
                            />
                        </div>
                        <div className="mb-30 remark-input">
                            <CustomTextArea
                                name="remark"
                                value={form.remark.value}
                                label="REMARK"
                                handleInput={handleInput}
                                className="remark-input "
                            />
                        </div>
                    </div>
                </CustomForm>
                {!isCreateNew && (
                    <div className="">
                        <div className="file-input d-flex w-100-perc f-s-16">
                            <div
                                className="addFile mt-20"
                                onClick={() => toggleModal("fileUploadModal")}
                            >
                                <span className="icon">
                                    <FilesIcon
                                        className="FilesIcon fill mr-10"
                                        width={24}
                                    />
                                </span>
                                <Translate text="ADD_FILE" />
                            </div>
                        </div>
                        <div className="mt-10 d-flex flex-column align-self-start">
                            {Object.values(documents).map(
                                (item: any, index) => {
                                    return (
                                        item.path !== "" && (
                                            <div
                                                key={index}
                                                className="d-flex align-items-start mb-5 f-s-12 pointer"
                                            >
                                                <a
                                                    href={`https://rentapi.oykos.dev/storage/${item.path}`}
                                                    className="fileLink decoration-none"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    <span className="f-s-13 d-flex align-items-start file-path">
                                                        <span className="d-flex mr-10">
                                                            <FileIcon
                                                                width={20}
                                                                height={20}
                                                            />
                                                        </span>
                                                        <span className="align-self-end">
                                                            {item.path}
                                                        </span>
                                                        <span
                                                            className="minus-icon d-flex align-items-center"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.preventDefault();

                                                                deleteFile(
                                                                    index
                                                                );
                                                            }}
                                                        >
                                                            <MinusIcon
                                                                className="ml-10 MinusIcon justify-self-end"
                                                                width={15}
                                                                height={15}
                                                            />
                                                        </span>
                                                    </span>
                                                </a>
                                            </div>
                                        )
                                    );
                                }
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default BasicInfo;
