import React, { Component } from "react";
import Translate from "functions/utilFunctions/translate";
import { Avatar } from "components/Icons/Icons";

class PersonalAccountLeftSide extends Component<any> {
    state = {};

    renderRole = (role) => {
        if (parseInt(role) === 2) {
            return <Translate text="MANAGER" />;
        }
        if (parseInt(role) === 3) {
            return <Translate text="ADMIN" />;
        }
    };
    render() {
        return (
            <div>
                <div className="personal personal-shadow-darker d-flex justify-content-between flex-column mb-30">
                    <div className="d-flex flex-column align-items-center justify-content-center h-300">
                        <div
                            id="personal"
                            className="d-flex justify-content-center p-r"
                        >
                            {this.props.image ? (
                                <img
                                    className="image"
                                    src={`https://rentapi.oykos.dev/storage/${this.props.image}`}
                                    alt=""
                                />
                            ) : (
                                <Avatar className="image" />
                            )}

                            <div
                                className="d-flex camera-icon"
                                onClick={() =>
                                    this.props.toggleModal("imageUploadModal")
                                }
                            >
                                <i
                                    className="fas fa-camera fa-2x align-self-center margin-t--1"
                                    style={{
                                        color: "white",
                                    }}
                                />
                            </div>
                        </div>
                        <span className="d-flex justify-content-center user mt-20 f-s-18 align-items-center mt-35">
                            {this.props.first_name} {this.props.last_name}
                        </span>
                        <span className="d-flex justify-content-center admin f-s-14 align-items-center">
                            {this.renderRole(this.props.role)}
                        </span>
                    </div>
                    <div className="d-flex flex-column border-top-lightgray">
                        <div
                            className="d-flex col-12 justify-content-between pointer modal-hover"
                            onClick={() =>
                                this.props.toggleModal("showPasswordModal")
                            }
                        >
                            <div className="d-flex flex-column password ">
                                <Translate text="PASSWORD" />
                                <span>********</span>
                            </div>
                            <div className="d-flex ">
                                <i
                                    className="fas fa-chevron-right align-self-center"
                                    style={{
                                        color: "gray",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column border-top-lightgray">
                            <div
                                className="pin-password border-radius-bl-15 border-radius-br-15 d-flex col-12 justify-content-between pointer modal-hover"
                                onClick={() =>
                                    this.props.toggleModal("showPinModal")
                                }
                            >
                                <div className="d-flex flex-column pin">
                                    <Translate text="PIN_CODE" />
                                    <span>********</span>
                                </div>
                                <div className="d-flex ">
                                    <i
                                        className="fas fa-chevron-right align-self-center"
                                        style={{
                                            color: "gray",
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default PersonalAccountLeftSide;
