import Translate from "functions/utilFunctions/translate";
import i18n from "i18n/i18n";
import React from "react";

const Notification = ({ notification, onClick }: any) => {
    const generateNotifText = () => {
        if (notification.notif_type === "registration") {
            if (notification.days < 0) {
                return `${i18n.t("CAR_UNREGISTERED_FOR", {
                    days: Math.abs(notification.days),
                })} `;
            } else {
                return `${i18n.t("CAR_LICENCE_EXPIRES_IN", {
                    days: notification.days,
                })} `;
            }
        } else if (notification.notif_type === "service") {
            if (notification.days < 0) {
                return `${i18n.t("LAST_SERVICE_EXPIRED_FOR", {
                    days: Math.abs(notification.days),
                })} `;
            } else {
                return `${i18n.t("LAST_SERVICE_EXPIRES_IN", {
                    days: notification.days,
                })} `;
            }
        } else {
            return `${i18n.t("VEHICLE_MILEAGE_SINCE_LAST_SERVICE", {
                km: notification.mileageFromLastService,
            })} `;
        }
    };

    return (
        <div className="notification d-flex mb-10" onClick={onClick}>
            <div className={`type-indicator ${notification.notif_type}`}></div>
            <div>
                <div className="notif-info">{generateNotifText()}</div>
                <div className="car-info">
                    <span className="brand">{notification.manufacturer}</span>
                    <span className="model">{notification.model}</span>
                    <span className="bold mx-15">-</span>
                    <span className="licence-plate">
                        {notification.licence_plate}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Notification;
