export const tableHeads = {
    reservationsTableHeads: [
        {
            value: "CUSTOMER_NAME",
            sortable: true,
            sortableName: "customer_name",
        },
        {
            value: "CAR_CATEGORY",
            sortable: true,
            sortableName: "categories_name",
        },
        {
            value: "START_DATE",
            sortable: true,
            sortableName: "rent_start_date",
        },
        {
            value: "END_DATE",
            sortable: true,
            sortableName: "rent_end_date",
        },
        {
            value: "COMPANY_NAME",
            sortable: true,
            sortableName: "company_name",
        },
        {
            value: "CREATOR",
            sortable: true,
            sortableName: "user_name",
        },
        {
            value: "",
            sortable: false,
        },
    ],
    contractsTableHeads: [
        {
            value: "CUSTOMER_NAME",
            sortable: true,
            sortableName: "customer_namec",
        },
        {
            value: "CAR_CATEGORY",
            sortable: true,
            sortableName: "categories_namec",
        },
        {
            value: "START_DATE",
            sortable: true,
            sortableName: "rent_start_datec",
        },
        {
            value: "END_DATE",
            sortable: true,
            sortableName: "rent_end_datec",
        },
        {
            value: "COMPANY_NAME",
            sortable: true,
            sortableType: "string",
            sortableName: "company_namec",
        },
        {
            value: "CREATOR",
            sortable: true,
            sortableName: "user_namec",
        },
        {
            value: "",
            sortable: false,
        },
    ],
    overviewContractsTableHeads: [
        {
            value: "CUSTOMER_NAME",
            sortable: true,
            sortableType: "string",
            sortableName: "customer_name",
        },
        {
            value: "CAR_CATEGORY",
            sortable: true,
            sortableType: "string",
            sortableName: "category_name",
        },
        {
            value: "START_DATE",
            sortable: true,
            sortableType: "date",
            sortableName: "rent_start_date",
        },
        {
            value: "END_DATE",
            sortable: true,
            sortableType: "date",
            sortableName: "rent_end_date",
        },
    ],
    reservationsContractsTableHeads: [
        {
            value: "CUSTOMER_NAME",
            sortable: true,
            sortableType: "string",
            sortableName: "customer_name",
        },
        {
            value: "CAR_CATEGORY",
            sortable: true,
            sortableType: "string",
            sortableName: "category_name",
        },
        {
            value: "START_DATE",
            sortable: true,
            sortableType: "date",
            sortableName: "rent_start_date",
        },
        {
            value: "END_DATE",
            sortable: true,
            sortableType: "date",
            sortableName: "rent_end_date",
        },
    ],
};

export const filterForms = {
    date_from: {
        value: null,
        errors: [],
    },
    date_to: {
        value: null,
        errors: [],
    },
};

export const customerInfo = {
    dropdownCustomer: { value: 0, label: "" },
    first_name: { value: "", errors: [] },
    last_name: { value: "", errors: [] },
    date_of_birth: { value: null, errors: [] },
    licence_id: { value: "", errors: [] },
    licence_id_expiration_date: { value: null, errors: [] },
    personal_id: { value: "", errors: [] },
    personal_id_expiration_date: { value: null, errors: [] },
    country: { value: "", errors: [] },
    phone: { value: "", errors: [] },
    email: { value: "", errors: [] },
    remark: { value: "", errors: [] },
    id: { value: 0, errors: [] },
    type: { value: "", errors: [] },
    unregistered: { value: "", errors: [] },
    id_operator: { value: "", errors: [] },
};

export const rentInfo = {
    rent_start_place: {
        value: "",
        errors: [],
    },
    rent_end_place: { value: "", errors: [] },
    rent_start_date: {
        value: null,
        errors: [],
    },
    rent_end_date: { value: null, errors: [] },
    rent_start_remark: {
        value: "",
        errors: [],
    },
    rent_end_remark: { value: "", errors: [] },
    invoice_first_name: {
        value: "",
        errors: [],
    },
    invoice_last_name: {
        value: "",
        errors: [],
    },
    invoice_personal_id_number: {
        value: "",
        errors: [],
    },
    invoice_remark: {
        value: "",
        errors: [],
    },
    invoice_payment_method: {
        value: "",
        errors: [],
    },
    invoice_payment_method_remark: {
        value: "",
        errors: [],
    },
    paymentMethod: { value: 0, label: "" },
};

export const companyInfo = {
    dropdownCompany: { value: 0, label: "" },
    company_name: { value: "", errors: [] },
    PDV: { value: "", errors: [] },
    PIB: { value: "", errors: [] },
    company_address: { value: "", errors: [] },
    company_email: { value: "", errors: [] },
    company_phone: { value: "", errors: [] },
    company_remark: { value: "", errors: [] },
    id: { value: "", errors: [] },
};

export const vehicleInfo = {
    car_category_id: {
        value: 0,
        label: "",
    },
    car_id: { value: 0, errors: [] },
    carBrand: { value: "", label: "Select" },
    carModel: { value: "", label: "Select" },
    carPlates: { value: "", label: "Select" },
    car_remark: { value: "", errors: [] },
    fuel_amount: 0,
    mileage: 0,
    currentCar: {} as any,
};

export const additionalInfo = {};

export const paymentOptions = [
    { value: 0, label: "Virman" },
    { value: 1, label: "Keš" },
    { value: 2, label: "Kreditna kartica" },
    { value: 3, label: "Vaučer" },
    { value: 4, label: "Gratis" },
];

export const customerData = {
    first_name: "",
    last_name: "",
    date_of_birth: null,
    email: "",
    country: "",
    licence_id_expiration_date: null,
    personal_id_expiration_date: null,
    phone: "",
    remark: "",
    unregistered: 0,
    licence_id: "",
    personal_id: "",
};
