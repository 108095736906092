import React, { Component } from "react";
import { CustomForm, CustomInput, CustomDatePicker } from "devkit/Form/Form";

class CarInsuranceInfo extends Component<any> {
    render() {
        const {
            date,
            remark,
            mileage,
            price,
            supplier,
            name,
        } = this.props.data;
        return (
            <div className="h-100perc ">
                <CustomForm className="h-100perc py-10">
                    <div className="row">
                        <div className="my-20 col-auto w-280 ">
                            <CustomInput
                                name="name"
                                label="TITLE"
                                value={name.value}
                                type="text"
                                errors={name.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="supplier"
                                label="PROVIDER_NAME"
                                value={supplier.value}
                                type="text"
                                errors={supplier.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="price"
                                label="PRICE"
                                value={price.value}
                                type="number"
                                errors={price.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="remark"
                                label="NOTE"
                                value={remark.value}
                                type="text"
                                errors={remark.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="mileage"
                                label="KILOMETERS"
                                value={mileage.value}
                                type="number"
                                errors={mileage.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomDatePicker
                                name="date"
                                label="DATE"
                                handleInput={this.props.handleInput}
                                value={date.value}
                                errors={date.errors}
                            />
                        </div>
                    </div>
                </CustomForm>
            </div>
        );
    }
}

export default CarInsuranceInfo;
