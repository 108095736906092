import {
    handleDebouncing,
    handleThrottle,
} from "../functions/utilFunctions/debounceAndThrottle";

export const UtilService = {
    loopThroughItems: (items, callback) => {
        for (const itemKey in items) {
            callback(items[itemKey], itemKey);
        }
    },
    clone: (obj) => {
        if (obj === null || typeof obj !== "object") {
            return obj;
        }
        if (obj instanceof Date) {
            return new Date(obj.getTime());
        }
        if (Array.isArray(obj)) {
            const clonedArr = [];
            obj.forEach(function (element) {
                // @ts-ignore
                clonedArr.push(UtilService.clone(element));
            });
            return clonedArr;
        }
        const clonedObj = new obj.constructor();

        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                clonedObj[prop] = UtilService.clone(obj[prop]);
            }
        }

        return clonedObj;
    },
    replaceSerbianChars: (string) => {
        if (!string) return "";

        return string
            .replace(/ć/g, "c")
            .replace(/č/g, "c")
            .replace(/đ/g, "dj")
            .replace(/š/g, "s")
            .replace(/\\n/g, " ")
            .replace(/\\r/g, " ")
            .replace(/ž/g, "z");
    },
    handleDebouncing: (callbackKey, callback, period) => {
        return handleDebouncing(callbackKey, callback, period);
    },
    handleThrottle: (callbackKey, callback, period) => {
        return handleThrottle(callbackKey, callback, period);
    },
    truncateString: (string, limit) => {
        if (!string || !limit) return "";
        string = String(string);

        return string.length > limit
            ? string.substr(0, limit - 1) + "..."
            : string;
    },
    sortArray: (sortItems, sortableName, direction, type) => {
        if (type === "string") {
            if (direction) {
                return sortItems.sort((a, b) =>
                    UtilService.sortStringArray(a, b, sortableName)
                );
            } else {
                return sortItems
                    .sort((a, b) =>
                        UtilService.sortStringArray(a, b, sortableName)
                    )
                    .reverse();
            }
        }
        if (type === "date") {
            if (direction) {
                return sortItems.sort((a, b) =>
                    UtilService.sortDateArray(a, b, sortableName)
                );
            } else {
                return sortItems
                    .sort((a, b) =>
                        UtilService.sortDateArray(a, b, sortableName)
                    )
                    .reverse();
            }
        }
        if (type === "number") {
            if (direction) {
                return sortItems.sort((a, b) =>
                    UtilService.sortNumberArray(a, b, sortableName)
                );
            } else {
                return sortItems
                    .sort((a, b) =>
                        UtilService.sortNumberArray(a, b, sortableName)
                    )
                    .reverse();
            }
        }
    },
    sortStringArray: (curr, prev, name) => {
        const a = (curr[name] || "").toLowerCase();
        const b = (prev[name] || "").toLowerCase();
        return a > b ? -1 : b > a ? 1 : 0;
    },
    sortDateArray: (curr, prev, name) => {
        // @ts-ignore
        return new Date(prev[name]) - new Date(curr[name]);
    },
    sortNumberArray: (curr, prev, name) => {
        const a = parseInt(curr[name]);
        const b = parseInt(prev[name]);
        return a > b ? -1 : b > a ? 1 : 0;
    },
    handleAddFile: (name, e, drop?) => {
        if (drop) {
            e.preventDefault();
            if (e.dataTransfer.files.length > 1) {
                alert("only one file allowed");
            } else {
                return { value: e.dataTransfer.files[0], errors: [] };
            }
        } else {
            return { value: e.value[0], errors: [] };
        }
    },
    isNumber: (n) => {
        return !isNaN(parseFloat(n)) && isFinite(n);
    },
    isString: (s) => {
        return typeof s === "string";
    },
    areObjectsEqual: (object1, object2) => {
        const keys1 = Object.keys(object1);
        const keys2 = Object.keys(object2);
        if ((keys1 && !keys2) || (!keys1 && keys2) || (!keys1 && !keys2))
            return false;

        if (keys1.length !== keys2.length) {
            return false;
        }

        let equals = true;

        for (let key of keys1) {
            if (typeof object1[key] === "object") {
                const propertyEqual = UtilService.areObjectsEqual(
                    object1[key],
                    object2[key]
                );

                if (!propertyEqual) equals = false;
            } else if (object1[key] !== object2[key]) {
                equals = false;
            }
        }

        return equals;
    },
};
