import React, { Component } from "react";
import { StoreService } from "services/store.service";
import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import { parseDatetimeBack } from "functions/utilFunctions/parsingFunctions";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "../../devkit/Modal/ModalContent";
import ApiService from "services/api.service";
import { Loader } from "devkit/Loader/Loader";
import Paginate from "devkit/Pagination/Pagination";

class ClientsContracts extends Component<any> {
    state = {
        contractsTableHeads: [
            {
                value: "CUSTOMER_NAME",
                sortable: true,
                sortableName: "customer_name",
            },
            {
                value: "CAR_CATEGORY",
                sortable: true,
                sortableName: "categories_name",
            },
            {
                value: "START_DATE",
                sortable: true,
                sortableName: "rent_start_date",
            },
            {
                value: "END_DATE",
                sortable: true,
                sortableName: "rent_end_date",
            },
            {
                value: "COMPANY_NAME",
                sortable: true,
                sortableType: "string",
                sortableName: "company_name",
            },
            {
                value: "CREATOR",
                sortable: true,
                sortableName: "user_name",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        showDeleteModal: false,
        showDeleteSuccessModal: false,
        showDeleteErrorModal: false,
        deleteID: 0,
        contracts: [],
        showLoader: true,
        perPage: 20,
        currentPage: this.props.match.params.pageNo,
        totalPages: 0,
        sorterName: "",
        sorterDirection: "",
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        ApiService.getCustomerContracts(
            { limit: perPage, offset, id: this.props.clientId },
            token
        ).then((response) => {
            if (response.success) {
                const contracts = response.data;
                this.setState(() => ({
                    contracts,
                    totalPages: response.total,
                    showLoader: false,
                }));
            } else {
                this.setState(() => ({
                    showLoader: false,
                }));
            }
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.sorterName &&
            this.state.sorterName !== prevState.sorterName
        ) {
            this.setState({ sorterName: "", sorterDirection: "" });
        }
        if (prevState.currentPage !== this.state.currentPage) {
            const token = StoreService.getStoreProperty("token");
            const {
                currentPage,
                perPage,
                sorterName,
                sorterDirection,
            } = this.state;
            const offset = (parseInt(currentPage) - 1) * perPage;
            const data: any = {
                limit: perPage,
                offset,
                id: this.props.clientId,
            };
            if (sorterDirection) {
                data.sorter_direction = sorterDirection;
            }
            if (sorterName) {
                data.sorter = sorterName;
            }

            ApiService.getCustomerContracts(data, token).then((response) => {
                if (response.success) {
                    const contracts = response.data;
                    this.setState(() => ({
                        contracts,
                        totalPages: response.total,
                        showLoader: false,
                    }));
                } else {
                    this.setState(() => ({
                        showLoader: false,
                    }));
                }
            });
        }
    }

    handleSortContracts = (sortableName) => {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage, sorterDirection } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";

        ApiService.getCustomerContracts(
            {
                limit: perPage,
                offset,
                id: this.props.clientId,
                sorter: sortableName,
                sorter_direction,
            },
            token
        ).then((response) => {
            if (response && response.success) {
                const contracts = response.data;
                this.setState({
                    contracts,
                    totalPages: response.total,
                    showLoader: false,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                });
            }
        });
    };

    handleRedirect = (id) => {
        this.props.history.push(`/contract-details/${id}`);
    };

    handleDeleteModal = (id) => {
        this.setState({ showDeleteModal: true, deleteID: id });
    };

    handleDelete = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });

        const {
            deleteID,
            currentPage,
            perPage,
            sorterDirection,
            sorterName,
        } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const token = StoreService.getStoreProperty("token");

        ApiService.deleteContract(deleteID, token).then((response) => {
            if (response.success) {
                const data: any = {
                    limit: perPage,
                    offset,
                    id: this.props.clientId,
                };

                if (sorterDirection) {
                    data.sorter_direction = sorterDirection;
                }
                if (sorterName) {
                    data.sorter = sorterName;
                }

                ApiService.getCustomerContracts(data, token).then(
                    (response) => {
                        if (response && response.success) {
                            this.setState({
                                contracts: response.data,
                                showDeleteSuccessModal: true,
                            });
                        }
                    }
                );
            } else {
                this.setState({
                    showDeleteFailureModal: true,
                });
            }
        });
    };

    renderContractStatus = (time, finished, onlyContracts?) => {
        let now = new Date();
        let contractTime = new Date(time);
        if (finished) {
            return "finishedContract";
        }
        if (!onlyContracts && contractTime.getTime() < now.getTime()) {
            return "lateContract";
        }
    };

    handlePageClick = (pageNumber) => {
        this.props.history.push(
            `/clients/details/${this.props.clientId}/contracts/${pageNumber}`
        );
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    render() {
        const {
            showLoader,
            contractsTableHeads,
            contracts,
            perPage,
            totalPages,
            currentPage,
        } = this.state;
        return (
            <div>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="DELETE_CONTRACT_?"
                        modalName="showDeleteModal"
                        title="DELETE_CONTRACT"
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDelete}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteSuccessModal"
                    className={
                        this.state.showDeleteSuccessModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="DELETE_CONTRACT_SUCCESS"
                        modalName="showDeleteSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteErrorModal"
                    className={
                        this.state.showDeleteErrorModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="DELETE_CONTRACT_FAILURE"
                        modalName="showDeleteErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>

                {showLoader ? (
                    <Loader className="w-200" />
                ) : (
                    <div className="f-s-14 mt-40">
                        <Table
                            theads={contractsTableHeads}
                            theadsClassname="customThead"
                            handleSort={this.handleSortContracts}
                        >
                            {contracts &&
                                contracts.map((e: any, i) => {
                                    return (
                                        <tr
                                            className={`pointer ${this.renderContractStatus(
                                                e.rent_start_date,
                                                e.finished,
                                                true
                                            )}`}
                                            onClick={() =>
                                                this.handleRedirect(e.id)
                                            }
                                            key={i}
                                        >
                                            <td>
                                                {e.customer_name ? (
                                                    e.customer_name
                                                ) : (
                                                    <Translate text="NO_RELATED_CUSTOMER" />
                                                )}
                                            </td>
                                            <td>{e.category_name}</td>

                                            <td>
                                                {parseDatetimeBack(
                                                    e.rent_start_date
                                                )}
                                            </td>
                                            <td>
                                                {parseDatetimeBack(
                                                    e.rent_end_date
                                                )}
                                            </td>

                                            <td>
                                                {e.company_name ? (
                                                    e.company_name
                                                ) : (
                                                    <Translate text="NO_RELATED_COMPANY" />
                                                )}
                                            </td>
                                            <td>{e.user_name}</td>
                                        </tr>
                                    );
                                })}
                        </Table>
                        {contracts && Object.keys(contracts).length !== 0 && (
                            <Paginate
                                totalItemsCount={totalPages}
                                onChange={this.handlePageClick}
                                activePage={parseInt(currentPage)}
                                perPage={perPage}
                                pageRangeDisplayed={5}
                            />
                        )}
                    </div>
                )}
            </div>
        );
    }
}
export default ClientsContracts;
