import React, { Component } from "react";
import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import { StoreService } from "services/store.service";
import { CustomButton } from "devkit/Form/Form";
import ShowMore from "devkit/ShowMore/ShowMore";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import { PlusIcon } from "../../components/Icons/Icons";
import { Loader } from "devkit/Loader/Loader";
import Paginate from "devkit/Pagination/Pagination";
import ApiService from "services/api.service";

class AdditionalEquipment extends Component<any> {
    state = {
        additionalEquipmentTableHeads: [
            {
                value: "EQUIP_NAME",
                sortable: true,
                sortableName: "name",
            },
            {
                value: "EQUIP_PRICE",
                sortable: true,
                sortableName: "price",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        additionalEquipment: [],
        showDeleteModal: false,
        showDeleteFailedModal: false,
        showDeleteSuccessModal: false,
        showLoader: true,
        deleteID: "",
        totalPages: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 20,
        sorterDirection: "",
        sorterName: "",
    };

    componentDidMount() {
        const { currentPage, perPage } = this.state;
        const token = StoreService.getStoreProperty("token");
        const offset = (parseInt(currentPage) - 1) * perPage;

        ApiService.getAllEquipments({ limit: perPage, offset }, token).then(
            (response) => {
                if (response && response.success) {
                    const additionalEquipment = response.data;
                    this.setState({
                        additionalEquipment,
                        totalPages: response.total,
                        showLoader: false,
                    });
                }
            }
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.sorterName &&
            this.state.sorterName !== prevState.sorterName
        ) {
            this.setState({ sorterName: "", sorterDirection: "" });
        }
        if (prevState.currentPage !== this.state.currentPage) {
            const token = StoreService.getStoreProperty("token");
            const {
                currentPage,
                perPage,
                sorterName,
                sorterDirection,
            } = this.state;
            const offset = (parseInt(currentPage) - 1) * perPage;
            const data: any = { limit: perPage, offset };

            if (sorterDirection) {
                data.sorter_direction = sorterDirection;
            }
            if (sorterName) {
                data.sorter = sorterName;
            }

            ApiService.getAllEquipments(data, token).then((response) => {
                if (response && response.success) {
                    const equipments = response.data;
                    this.setState({
                        additionalEquipment: equipments,
                        totalPages: response.total,
                        showLoader: false,
                    });
                }
            });
        }
    }

    handleRedirect = (id) => {
        this.props.history.push(`/equipmentDetails/${id}`);
    };

    handleAddNew = () => {
        this.props.history.push(`/new-equipment`);
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleDeleteItem = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
        const token = StoreService.getStoreProperty("token");
        const {
            deleteID,
            perPage,
            currentPage,
            sorterName,
            sorterDirection,
        } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data: any = { limit: perPage, offset };

        if (sorterDirection) {
            data.sorter_direction = sorterDirection;
        }
        if (sorterName) {
            data.sorter = sorterName;
        }

        ApiService.deleteEquipment(deleteID, token).then((response) => {
            if (response.success) {
                ApiService.getAllEquipments(data, token).then((response) => {
                    if (response && response.success) {
                        this.setState({
                            additionalEquipment: response.data,
                            totalPages: response.total,
                            showDeleteSuccessModal: true,
                        });
                    }
                });
            } else {
                this.setState({
                    showDeleteFailedModal: true,
                });
            }
        });
    };

    handleSort = (sortableName) => {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage, sorterDirection } = this.state;
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";

        const offset = (parseInt(currentPage) - 1) * perPage;

        const data = {
            limit: perPage,
            offset,
            sorter_direction,
            sorter: sortableName,
        };

        ApiService.getAllEquipments(data, token).then((response) => {
            if (response && response.success) {
                const equipments = response.data;
                this.setState({
                    additionalEquipment: equipments,
                    totalPages: response.total,
                    showLoader: false,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                });
            }
        });
    };

    handlePageClick = (pageNumber) => {
        this.props.history.push(`/equipment/${pageNumber}`);
    };

    render() {
        const { showLoader, totalPages, currentPage, perPage } = this.state;
        return (
            <div>
                <Modal
                    modalName="showDeleteModal"
                    toggleModal={this.toggleModal}
                    className={this.state.showDeleteModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="warning"
                        text="DELETE_EQUIPMENT_?"
                        modalName="showDeleteModal"
                        title="DELETE_EQUIPMENT"
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDeleteItem}
                        cancelDelete={this.toggleModal}
                    ></ModalContent>
                </Modal>
                <Modal
                    modalName="showDeleteFailedModal"
                    toggleModal={this.toggleModal}
                    className={
                        this.state.showDeleteFailedModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="DELETE_EQUIPMENT_FAILED"
                        modalName="showDeleteFailedModal"
                        title="FAILED"
                        toggleModal={this.toggleModal}
                    ></ModalContent>
                </Modal>
                <Modal
                    modalName="showDeleteSuccessModal"
                    toggleModal={this.toggleModal}
                    className={
                        this.state.showDeleteSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        text="DELETE_EQUIPMENT_SUCCESSFUL"
                        modalName="showDeleteSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    ></ModalContent>
                </Modal>
                <div className="d-flex w-100-perc border-b-1 pb-15">
                    <span className="pageHeader">
                        <Translate text="ADDITIONAL_EQUIPMENT" />
                    </span>
                </div>
                <div className="d-flex pb-40 py-20 justify-content-end">
                    <CustomButton
                        className="btn-primary justify-content-center"
                        type="button"
                        onClick={this.handleAddNew}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PlusIcon
                                width={14}
                                className="icon hover pointer mr-10 whiteFill"
                            />
                            <Translate text={"ADD_EQUIPMENT"}></Translate>
                        </div>
                    </CustomButton>
                </div>
                <div className="f-s-14">
                    {showLoader ? (
                        <Loader className="w-200" />
                    ) : (
                        <>
                            <Table
                                theads={
                                    this.state.additionalEquipmentTableHeads
                                }
                                theadsClassname="customThead"
                                handleSort={this.handleSort}
                            >
                                {!!this.state.additionalEquipment &&
                                    this.state.additionalEquipment.map(
                                        (e: any, i) => {
                                            return (
                                                <tr
                                                    key={i}
                                                    className="pointer"
                                                    onClick={() =>
                                                        this.handleRedirect(
                                                            e.id
                                                        )
                                                    }
                                                >
                                                    <td>{e.name}</td>
                                                    <td>{e.price}</td>
                                                    <td>
                                                        <ShowMore>
                                                            <span
                                                                className="one-line-text d-flex justify-content-center"
                                                                onClick={() =>
                                                                    this.handleRedirect(
                                                                        e.id
                                                                    )
                                                                }
                                                            >
                                                                <Translate
                                                                    text={
                                                                        "EDIT"
                                                                    }
                                                                />
                                                            </span>
                                                            <span
                                                                className="one-line-text d-flex justify-content-center"
                                                                onClick={() =>
                                                                    this.handleDeleteModal(
                                                                        e.id
                                                                    )
                                                                }
                                                            >
                                                                <span className="danger">
                                                                    <Translate text="DELETE" />
                                                                </span>
                                                            </span>
                                                        </ShowMore>
                                                    </td>
                                                </tr>
                                            );
                                        }
                                    )}
                            </Table>
                            <div className="d-flex justify-content-center mt-30">
                                <Paginate
                                    totalItemsCount={totalPages}
                                    onChange={this.handlePageClick}
                                    activePage={parseInt(currentPage)}
                                    perPage={perPage}
                                    pageRangeDisplayed={5}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default AdditionalEquipment;
