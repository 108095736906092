/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, NavLink } from "react-router-dom";
import React, { Component } from "react";
import Translate from "../../functions/utilFunctions/translate";
import {
    ControlTableIcon,
    GroupsIcon,
    EmployeesIcon,
    CarsIcon,
    ProfileIcon,
    ClientIcon,
    AdditionalsIcon,
    CompaniesIcon,
} from "../Icons/Icons";
import { UtilService } from "../../services/util.service";

class Sidebar extends Component {
    navRef = React.createRef<HTMLDivElement>();
    handleSidebarHover = () => {
        const el = this.navRef.current;
        let timeout: any = null;
        if (el) {
            el.onmouseenter = () => {
                timeout = setTimeout(() => {
                    el.classList.add("open");
                }, 1000);
            };

            el.onmouseleave = () => {
                el.classList.remove("open");
                clearTimeout(timeout);
            };
        }
    };

    componentDidMount() {
        this.handleSidebarHover();

        UtilService.handleDebouncing("uniqueKey", () => {}, 300);
    }

    render() {
        return (
            <nav id="nav" ref={this.navRef}>
                <div className="logoContainer p-r d-flex justify-content-center align-items-center py-10">
                    <Link to="/" className="d-flex align-items-center">
                        <img src="../images/Group406.png" alt="logo" />
                    </Link>
                </div>
                <div className="navContainer d-flex flex-column justify-content-between">
                    <ul>
                        <li>
                            <NavLink
                                exact
                                to="/reservationsAndContracts"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center"
                                activeClassName="active"
                            >
                                <span className="side"></span>
                                <span className="icon">
                                    <ControlTableIcon
                                        width={24}
                                        className="fill"
                                    />
                                </span>
                                <span className="label">
                                    <Translate text="CONTROL_TABLE" />
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/cars/1"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center "
                                activeClassName="active"
                            >
                                <span className="side"></span>

                                <span className="icon">
                                    <CarsIcon className="fill" width={24} />
                                </span>
                                <span className="label">
                                    <Translate text="VEHICLES" />
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/groups/1"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center "
                                activeClassName="active"
                            >
                                <span className="side"></span>

                                <span className="icon">
                                    <GroupsIcon className="fill" width={24} />
                                </span>
                                <span className="label">
                                    <Translate text="GROUPS" />
                                </span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                to="/managers/1"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center "
                                activeClassName="active"
                            >
                                <span className="side"></span>

                                <span className="icon">
                                    <EmployeesIcon
                                        className="fill"
                                        width={24}
                                    />
                                </span>
                                <span className="label">
                                    <Translate text="MANAGERS" />
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/clients/1"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center "
                                activeClassName="active"
                            >
                                <span className="side"></span>

                                <span className="icon">
                                    <ClientIcon className="fill" width={24} />
                                </span>
                                <span className="label">
                                    <Translate text="CLIENTS" />
                                </span>
                            </NavLink>
                        </li>

                        <li>
                            <NavLink
                                to="/equipment/1"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center "
                                activeClassName="active"
                            >
                                <span className="side"></span>

                                <span className="icon">
                                    <AdditionalsIcon
                                        className="fill"
                                        width={24}
                                    />
                                </span>
                                <span className="label">
                                    <Translate text="ADDITIONAL_EQUIPMENT" />
                                </span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="/companies/1"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center"
                                activeClassName="active"
                            >
                                <span className="side"></span>

                                <span className="icon">
                                    <CompaniesIcon
                                        className="fill"
                                        width={24}
                                    />
                                </span>
                                <span className="label">
                                    <Translate text="COMPANIES" />
                                </span>
                            </NavLink>
                        </li>
                    </ul>

                    <ul>
                        <li className="mb-15">
                            <NavLink
                                to="/personal-account"
                                className="text-color-white f-s-16 h-100-perc d-flex align-items-center"
                                activeClassName="active"
                            >
                                <span className="side"></span>
                                <span className="icon">
                                    <ProfileIcon className="fill" width={24} />
                                </span>
                                <span className="label">
                                    <Translate text="PROFILE" />
                                </span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default Sidebar;
