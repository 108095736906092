import { CloseIcon } from "components/Icons/Icons";
import { CustomButton } from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Notification from "./Notification";

interface INotifications {
    notifications: any;
    isOpen: boolean;
    closeNotifs: () => void;
}

const Notifications = ({ notifications, isOpen, closeNotifs }: any) => {
    const [activeTab, setActiveTab] = useState<string>("registration");
    const history = useHistory();

    const setTab = (tab: string) => {
        setActiveTab(tab);
    };

    const closeSidebar = (e: React.MouseEvent) => {
        if (
            Array.from((e.target as HTMLDivElement).classList).includes(
                "notifications-wrapper"
            )
        ) {
            closeNotifs();
        }
    };

    const redirectToCar = (id: number) => {
        closeNotifs();
        history.push(`/car/${id}/basic-info`);
    };

    return (
        <div
            className={`notifications-wrapper ${isOpen ? "visible" : ""}`}
            onClick={closeSidebar}
        >
            <div className={`notifications-popup ${isOpen ? "visible" : ""}`}>
                <CloseIcon
                    width={25}
                    className="close-icon pointer"
                    onClick={closeNotifs}
                />
                <div className="header d-flex flex-column">
                    <h3>
                        <Translate text="NOTIFICATIONS" />
                    </h3>
                    <span className="total-number">
                        <Translate text="TOTAL_NOTIFICATIONS" />
                        {notifications && notifications.length}
                    </span>
                </div>

                <div className="notifications-list">
                    {notifications &&
                        !!notifications.length &&
                        notifications.map((notif) => {
                            return (
                                <Notification
                                    notification={notif}
                                    onClick={() => redirectToCar(notif.id)}
                                />
                            );
                        })}
                </div>

                <div className="d-flex color-info">
                    <div className="colors d-flex">
                        <div className="mr-15 d-flex align-items-center">
                            <div className="circle mr-5"></div>
                            <span className="type">
                                <Translate text="REGISTRATION" />
                            </span>
                        </div>
                        <div className="mr-15 d-flex align-items-center">
                            <div className="circle mr-5"></div>
                            <span className="type">
                                <Translate text="LAST_SERVICE" />
                            </span>
                        </div>
                        <div className="mr-15 d-flex align-items-center">
                            <div className="circle mr-5"></div>
                            <span className="type">
                                <Translate text="KM_FROM_LAST_SERVICE" />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notifications;
