import React, { Component } from "react";
import { CustomForm, CustomInput } from "devkit/Form/Form";

class BasicInfo extends Component<any> {
    render() {
        const { formData } = this.props;
        return (
            <div className="col-xl-8 mt-40 pl-25">
                <CustomForm>
                    <div className="row" style={{ marginLeft: "-30px" }}>
                        <div className="mb-30 col-md-4">
                            <CustomInput
                                name="name"
                                label="TITLE"
                                type="text"
                                handleInput={this.props.handleInput}
                                value={formData.name.value}
                                errors={formData.name.errors}
                            />
                        </div>
                        <div className="mb-30 col-md-4">
                            <CustomInput
                                name="type"
                                label="TYPE"
                                type="text"
                                handleInput={this.props.handleInput}
                                value={formData.type.value}
                                errors={formData.type.errors}
                            />
                        </div>
                        <div className="mb-30 col-md-4">
                            <CustomInput
                                name="discount"
                                label="DISCOUNT"
                                type="number"
                                handleInput={this.props.handleInput}
                                value={formData.discount.value}
                                errors={formData.discount.errors}
                            />
                        </div>
                        <div className="mb-30 col-md-4">
                            <CustomInput
                                name="period_one"
                                label="1_3_DAYS"
                                type="number"
                                handleInput={this.props.handleInput}
                                value={formData.period_one.value}
                                errors={formData.period_one.errors}
                            />
                        </div>
                        <div className="mb-30 col-md-4">
                            <CustomInput
                                name="period_two"
                                label="3_6_DAYS"
                                type="number"
                                handleInput={this.props.handleInput}
                                value={formData.period_two.value}
                                errors={formData.period_two.errors}
                            />
                        </div>
                        <div className="mb-30 col-md-4">
                            <CustomInput
                                name="period_three"
                                label="7_10_DAYS"
                                type="number"
                                handleInput={this.props.handleInput}
                                value={formData.period_three.value}
                                errors={formData.period_three.errors}
                            />
                        </div>
                        <div className="mb-30 col-md-4">
                            <CustomInput
                                name="period_four"
                                label="10_20_DAYS"
                                type="number"
                                handleInput={this.props.handleInput}
                                value={formData.period_four.value}
                                errors={formData.period_four.errors}
                            />
                        </div>
                        <div className="mb-30 col-md-4">
                            <CustomInput
                                name="period_five"
                                label="20_DAYS"
                                type="number"
                                handleInput={this.props.handleInput}
                                value={formData.period_five.value}
                                errors={formData.period_five.errors}
                            />
                        </div>
                    </div>
                </CustomForm>
            </div>
        );
    }
}
export default BasicInfo;
