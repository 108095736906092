import React, { Component } from "react";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import { Loader } from "devkit/Loader/Loader";
import { ConstantsService } from "services/constants.service";
import Translate from "functions/utilFunctions/translate";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import { UtilService } from "services/util.service";
import {
    FilesIcon,
    FileIcon,
    MinusIcon,
    PlusIcon,
    UploadIcon,
} from "components/Icons/Icons";
import { CustomFileInput } from "devkit/Form/Form";

class DocumentsList extends Component<any> {
    state = {
        form: {
            documents: [
                { path: "", id: null, updated_at: null, created_at: null },
            ],
        },
        showLoader: false,
        completed: 0,
        isOver: false,
        fileUploadModal: false,
        fileUploadErrorModal: false,
        uploading: false,
        services: [],
        regularServiceFiles: [],
        unplannedServiceFiles: [],
        damageServiceFiles: [],
        document: {
            value: null,
        },
        documents: [{ path: "", id: null, updated_at: null, created_at: null }],
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        ApiService.getAllServices(this.props.carId, token).then((response) => {
            if (response.success) {
                const services = response.data;
                const regularService = services.filter(
                    (service) =>
                        parseInt(service.type) ===
                        ConstantsService.CAR_SERVICES.REGULAR_SERVICE
                );
                const unplannerService = services.filter(
                    (service) =>
                        parseInt(service.type) ===
                        ConstantsService.CAR_SERVICES.UNPLANNED_SERVICE
                );
                const damageService = services.filter(
                    (service) =>
                        parseInt(service.type) ===
                        ConstantsService.CAR_SERVICES.DAMAGE_SERVICE
                );
                let regularServiceFiles: any = [];
                let unplannedServiceFiles: any = [];
                let damageServiceFiles: any = [];
                regularService.forEach((item) => {
                    item.files.length &&
                        item.files.map((file: any) => {
                            return regularServiceFiles.push(file);
                        });
                });
                unplannerService.forEach((item) => {
                    item.files.length &&
                        item.files.map((file: any) => {
                            return unplannedServiceFiles.push(file);
                        });
                });
                damageService.forEach((item) => {
                    item.files.length &&
                        item.files.map((file: any) => {
                            return damageServiceFiles.push(file);
                        });
                });
                ApiService.getCar(this.props.carId, token).then((response) => {
                    if (response.success) {
                        this.setState(() => ({
                            documents: response.data.files,
                        }));
                    }
                });

                this.setState(() => ({
                    services,
                    regularServiceFiles,
                    unplannedServiceFiles,
                    damageServiceFiles,
                    showLoader: false,
                }));
            }
        });
    }

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };
    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.document.value !== this.state.document.value &&
            this.state.document.value
        ) {
            this.setState({ uploading: true });
            this.handleAddDocument();
        }
    }
    handleAddDocument = () => {
        const document: any = this.state.document.value;
        const token = StoreService.getStoreProperty("token");
        const formData = new FormData();
        const id = this.props.carId;
        formData.append("car_id", id);
        formData.append("file", document);
        formData.append("type", "car_file");
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadFile(formData, token, config).then((res) => {
            if (res.success) {
                this.setState((prevState: any) => ({
                    documents: [...prevState.documents, res.data],
                    completed: 0,
                    uploadComplete: false,
                    uploading: false,
                    fileUploadModal: false,
                    document: {
                        value: null,
                        errors: [],
                    },
                    form: {
                        documents: [prevState.form.documents, res.data],
                    },
                }));
            } else {
                this.setState({
                    fileUploadErrorModal: true,
                    document: {
                        value: null,
                        errors: [],
                    },
                });
            }
        });
    };
    deleteFile = (index) => {
        const token = StoreService.getStoreProperty("token");

        ApiService.deleteFile(
            {
                id: this.state.documents[index].id,
            },
            token
        ).then((response) => {
            if (response.success) {
                const files = this.state.documents.filter(
                    (e, i) => i !== index
                );
                this.setState({
                    documents: files,
                });
            }
        });
    };
    handleFileChange = (name, e, drop?) => {
        this.setState({
            [name]: UtilService.handleAddFile(name, e, drop),
        });
    };
    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };
    render() {
        const {
            regularServiceFiles,
            unplannedServiceFiles,
            damageServiceFiles,
            documents,
        } = this.state;

        return (
            <div>
                <Modal
                    modalName="fileUploadModal"
                    toggleModal={this.toggleModal}
                    className={this.state.fileUploadModal ? "visible" : ""}
                    modalWrapperClasses="fileUploadModal"
                >
                    {!this.state.uploading && (
                        <div className="pa-20 h-100-perc">
                            <div
                                onDrop={(e) =>
                                    this.handleFileChange("document", e, true)
                                }
                                className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                    this.state.isOver ? "dotted" : ""
                                }`}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    this.changeBorderType(true);
                                }}
                                onDragLeave={() => this.changeBorderType(false)}
                            >
                                <div className="text d-flex flex-column justify-content-center align-items-center">
                                    <span className="d-block mb-10">
                                        <UploadIcon
                                            width={40}
                                            className="uploadIcon"
                                        />
                                    </span>
                                    <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                        <Translate text="DRAG_AND_DROP" />
                                    </span>
                                    <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">
                                        <Translate text="OR" />
                                    </span>
                                </div>
                                <CustomFileInput
                                    name="document"
                                    className="mb-10"
                                    handleInput={this.handleFileChange}
                                    value={this.state.document.value}
                                    label={"CHOOSE_FILE"}
                                    labelClass="f-s-16"
                                />
                            </div>
                        </div>
                    )}
                    {this.state.uploading && (
                        <div className="progress pa-20 h-100perc text-center border-radius-4">
                            <span className="f-s-20 mb-20">
                                <Translate text="UPLOAD_IN_PROGRESS" />
                            </span>
                            <span className="wrapper d-block">
                                <Loader width={200} />
                                <span className="loader-numbers">
                                    {this.state.completed}%
                                </span>
                            </span>
                        </div>
                    )}
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="fileUploadErrorModal"
                    className={
                        this.state.fileUploadErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="FILE_UPLOAD_ERROR"
                        modalName="fileUploadErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <div className="row padding-h-20">
                    <div className="col-lg-4">
                        <div className="d-flex flex-column">
                            <div className="serviceFiles d-flex align-items-center">
                                <FilesIcon
                                    width={17}
                                    height={17}
                                    className="mr-5 mb-5"
                                />

                                <Translate text="REGULAR_SERVICE" />
                            </div>
                            <div className="mt-10 d-flex flex-column">
                                {regularServiceFiles &&
                                    regularServiceFiles.map(
                                        (item: any, index) => {
                                            return (
                                                item.path !== "" && (
                                                    <div
                                                        key={index}
                                                        className="d-flex align-items-center mb-5 f-s-12 pointer"
                                                    >
                                                        <a
                                                            href={`https://rentapi.oykos.dev/storage/${item.path}`}
                                                            className="fileLink decoration-none"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            download
                                                        >
                                                            <span className="f-s-13 d-flex">
                                                                <span className="d-flex mr-10">
                                                                    <FileIcon
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                    />
                                                                </span>
                                                                <span>
                                                                    {item.path}
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                )
                                            );
                                        }
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 ">
                        <div className="d-flex flex-column">
                            <div className="serviceFiles d-flex align-items-center">
                                <FilesIcon
                                    width={17}
                                    height={17}
                                    className="mr-5 mb-5"
                                />

                                <Translate text="UNPLANNED_SERVICE" />
                            </div>
                            <div className="mt-10 d-flex flex-column">
                                {unplannedServiceFiles &&
                                    unplannedServiceFiles.map(
                                        (item: any, index) => {
                                            return (
                                                item.path !== "" && (
                                                    <div
                                                        key={index}
                                                        className="d-flex align-items-center mb-5 f-s-12 pointer"
                                                    >
                                                        <a
                                                            href={`https://rentapi.oykos.dev/storage/${item.path}`}
                                                            className="fileLink decoration-none"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            download
                                                        >
                                                            <span className="f-s-13 d-flex">
                                                                <span className="d-flex mr-10">
                                                                    <FileIcon
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                    />
                                                                </span>
                                                                <span>
                                                                    {item.path}
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                )
                                            );
                                        }
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 ">
                        <div className="d-flex flex-column">
                            <div className="serviceFiles d-flex align-items-center">
                                <FilesIcon
                                    width={17}
                                    height={17}
                                    className="mr-5 mb-5"
                                />

                                <Translate text="DAMAGES" />
                            </div>
                            <div className="mt-10 d-flex flex-column">
                                {damageServiceFiles &&
                                    damageServiceFiles.map(
                                        (item: any, index) => {
                                            return (
                                                item.path !== "" && (
                                                    <div
                                                        key={index}
                                                        className="d-flex align-items-center mb-5 f-s-12 pointer"
                                                    >
                                                        <a
                                                            href={`https://rentapi.oykos.dev/storage/${item.path}`}
                                                            className="fileLink decoration-none"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            download
                                                        >
                                                            <span className="f-s-13 d-flex">
                                                                <span className="d-flex mr-10">
                                                                    <FileIcon
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                    />
                                                                </span>
                                                                <span>
                                                                    {item.path}
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                )
                                            );
                                        }
                                    )}
                            </div>
                        </div>
                    </div>

                    <div className="col-xl-3 col-lg-3 mt-20">
                        <div className="d-flex flex-column">
                            <div className="serviceFiles d-flex align-items-center">
                                <FilesIcon
                                    width={17}
                                    height={17}
                                    className="mr-5 mb-5"
                                />

                                <Translate text="OTHER_DOCUMENTS" />
                            </div>
                            {documents[0] && documents[0].path === "" ? (
                                <Loader width={30} className="loader-cars " />
                            ) : (
                                <div className="mt-10 d-flex flex-column">
                                    {documents &&
                                        documents.map((item: any, index) => {
                                            return (
                                                item.path !== "" && (
                                                    <div
                                                        key={index}
                                                        className="d-flex align-items-center mb-5 f-s-12 pointer"
                                                    >
                                                        <a
                                                            href={`https://rentapi.oykos.dev/storage/${item.path}`}
                                                            className="fileLink decoration-none"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            download
                                                        >
                                                            <span className="f-s-13 d-flex">
                                                                <span className="d-flex mr-10">
                                                                    <FileIcon
                                                                        width={
                                                                            20
                                                                        }
                                                                        height={
                                                                            20
                                                                        }
                                                                    />
                                                                </span>
                                                                <span>
                                                                    {item.path}
                                                                </span>
                                                                <span
                                                                    className="minus-icon d-flex align-items-center"
                                                                    onClick={(
                                                                        e
                                                                    ) => {
                                                                        e.stopPropagation();
                                                                        e.preventDefault();

                                                                        this.deleteFile(
                                                                            index
                                                                        );
                                                                    }}
                                                                >
                                                                    <MinusIcon
                                                                        className="ml-10 MinusIcon"
                                                                        width={
                                                                            15
                                                                        }
                                                                        height={
                                                                            15
                                                                        }
                                                                    />
                                                                </span>
                                                            </span>
                                                        </a>
                                                    </div>
                                                )
                                            );
                                        })}
                                </div>
                            )}
                            <div
                                className="addFile start mt-30"
                                onClick={() =>
                                    this.toggleModal("fileUploadModal")
                                }
                            >
                                <span className="icon">
                                    <PlusIcon
                                        className="FilesIcon fill mr-20"
                                        width={13}
                                    />
                                </span>
                                <Translate text="ADD_FILE" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DocumentsList;
