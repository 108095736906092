import React from "react";
import { CustomInput, CustomButton, CustomForm } from "devkit/Form/Form";
import Translate from "functions/utilFunctions/translate";

const SearchCPRS = ({ handleInput, company_name, PIB, onClick }) => {
    return (
        <div className="d-flex align-items-center flex-column px-25  w-100-perc">
            <div className="f-s-20 bold my-30">
                <Translate text="SEARCH_COMPANIES_IN_CRPS" />
            </div>
            <CustomForm className="w-100-perc d-flex flex-column align-items-center">
                <div className="mb-30 w-100-perc">
                    <CustomInput
                        name="company_name"
                        type="text"
                        label="COMPANY_NAME"
                        handleInput={handleInput}
                        value={company_name.value}
                        errors={company_name.errors}
                        className="w-100-perc"
                    />
                </div>
                <div className="mb-30 w-100-perc">
                    <CustomInput
                        name="searchPIB"
                        type="text"
                        label="PIB"
                        handleInput={handleInput}
                        value={PIB.value}
                        errors={PIB.errors}
                    />
                </div>

                <CustomButton onClick={onClick} className="w-100 mb-30">
                    <div>
                        <Translate text="SEARCH" />
                    </div>
                </CustomButton>
            </CustomForm>
        </div>
    );
};

export default SearchCPRS;
