import Notifications from "components/Notifications/Notifications";
import React, { Component } from "react";
import { CustomButton } from "../../devkit/Form/Form";
import Translate from "../../functions/utilFunctions/translate";
import {
    BellIcon,
    LockIcon,
    LogoutIcon,
    MNEFlag,
    UKFlag,
} from "../Icons/Icons";

interface IHeader {
    breadcrumbs: string;
    lockApp: () => void;
    logout: () => void;
    language: () => void;
    lang: string | null;
    notifications: any[];
}

class Header extends Component<IHeader> {
    state = {
        logout: false,
        lang: localStorage.getItem("lang"),
        sidebar: false,
    };

    // toggleLanguage = () => {
    //     const lang = localStorage.getItem("lang");
    //     if (lang === "en") {
    //         i18next.changeLanguage("me");
    //         localStorage.setItem("lang", "me");
    //         this.setState({ lang: "me" });
    //     } else {
    //         i18next.changeLanguage("en");
    //         localStorage.setItem("lang", "en");
    //         this.setState({ lang: "en" });
    //     }
    // };

    toggleNotifs = () => {
        this.setState({ sidebar: !this.state.sidebar });
    };

    render() {
        return (
            <div id="header" className="d-flex justify-content-between">
                <div className="breadcrumbs p-r d-inline-block ml-40">
                    <span className="title center-r-v d-inline-block f-s-16 bold">
                        <Translate text="RENT A CAR" />
                    </span>
                </div>
                <div className="headerInfo d-flex p-r align-items-center">
                    <CustomButton
                        className="btn-header"
                        onClick={this.props.language}
                    >
                        <div className="lang">
                            {this.props.lang === "en" ? (
                                <div className="item d-flex flex-column align-items-center justify-content-center">
                                    <UKFlag height={15} />
                                </div>
                            ) : (
                                <MNEFlag width={30} />
                            )}
                        </div>
                    </CustomButton>
                    <CustomButton
                        className="btn-header bell fill"
                        onClick={this.toggleNotifs}
                    >
                        <BellIcon width={25} />
                    </CustomButton>
                    <CustomButton
                        className="btn btn-header fill lock f-s-16"
                        onClick={this.props.lockApp}
                    >
                        <LockIcon height={25} />
                    </CustomButton>
                    <CustomButton
                        className="btn btn-header fill logout f-s-16"
                        onClick={this.props.logout}
                    >
                        <LogoutIcon height={25} />
                    </CustomButton>
                </div>
                <Notifications
                    notifications={this.props.notifications}
                    isOpen={this.state.sidebar}
                    closeNotifs={this.toggleNotifs}
                />
            </div>
        );
    }
}

export default Header;
