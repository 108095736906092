import React, { Component } from "react";
import { Translate } from "../../functions/utilFunctions/translate";
import { CloseIcon } from "../../components/Icons/Icons";
import { CustomButton } from "../../devkit/Form/Form";

export default class FilterSidebar extends Component<any> {
    // capitalize = (s) => {
    // 	if (typeof s !== "string") return "";
    // 	return s.charAt(0).toUpperCase() + s.slice(1);
    // };

    render() {
        const {
            onClose,
            sidebarClass,
            wrapperClass,
            titleMargin,
            submitFilters,
            resetFilters,
        } = this.props;

        return (
            <div className={`${wrapperClass} filter-sidebar-wrapper`}>
                <div
                    className={`${sidebarClass} filter-sidebar d-flex flex-column form-box-shadow`}
                >
                    <div
                        className={`d-flex justify-content-between ${
                            titleMargin ? titleMargin : "mb-40"
                        } `}
                    >
                        <h1>
                            <Translate text="FILTERS"></Translate>
                        </h1>
                        <div
                            onClick={onClose}
                            className="d-flex align-items-center justify-content-center pointer"
                        >
                            <CloseIcon width={18} className=""></CloseIcon>
                        </div>
                    </div>
                    <div>
                        <div className="mt-20">{this.props.children}</div>

                        <CustomButton
                            className="w-100-perc mt-50"
                            onClick={(e) => submitFilters(e)}
                        >
                            <Translate text="APPLY"></Translate>
                        </CustomButton>
                        <CustomButton
                            className="w-100-perc mt-10 mb-30 reset-filters"
                            onClick={(e) => resetFilters(e)}
                        >
                            <Translate text="RESET_FILTERS"></Translate>
                        </CustomButton>
                    </div>
                </div>
            </div>
        );
    }
}
