import Translate from "functions/utilFunctions/translate";
import React from "react";
import {
    AdditionsIcon,
    CarsIcon,
    ControlTableIcon,
    ProfileIcon,
    WalletIcon,
    FilesIcon,
} from "../Icons/Icons";

const MenuTab = (props) => {
    const {
        handleTabs,
        currentTab,
        tabName,
        translateKey,
        sectionClasses,
        horizontal,
    } = props;
    return (
        <div
            className={`${
                horizontal ? "mr-40" : "mb-20 min-w-250"
            } pointer menuTab`}
            onClick={() => handleTabs(tabName)}
        >
            <span>
                {tabName === "userInformation" && (
                    <ProfileIcon
                        className={`${
                            currentTab !== tabName ? "fillIcon" : "fillClicked"
                        }`}
                        width={16}
                    />
                )}
                {tabName === "vehicleInfo" && (
                    <CarsIcon
                        className={`${
                            currentTab !== tabName ? "fillIcon" : "fillClicked"
                        }`}
                        width={16}
                    />
                )}
                {tabName === "rentInfo" && (
                    <ControlTableIcon
                        className={`${
                            currentTab !== tabName ? "fillIcon" : "fillClicked"
                        }`}
                        width={16}
                    />
                )}
                {tabName === "additionsInfo" && (
                    <AdditionsIcon
                        className={`${
                            currentTab !== tabName ? "fillIcon" : "fillClicked"
                        }`}
                        width={16}
                    />
                )}
                {tabName === "payingInfo" && (
                    <WalletIcon
                        className={`${
                            currentTab !== tabName ? "fillIcon" : "fillClicked"
                        }`}
                        width={16}
                    />
                )}
                {tabName === "personalAccount" && (
                    <ProfileIcon
                        className={`${
                            currentTab !== tabName ? "fillIcon" : "fillClicked"
                        }`}
                        width={16}
                    />
                )}
                {tabName === "privateFiles" && (
                    <FilesIcon
                        className={`${
                            currentTab !== tabName ? "fillIcon" : "fillClicked"
                        }`}
                        width={16}
                    />
                )}
                {tabName === "publicFiles" && (
                    <FilesIcon
                        className={`${
                            currentTab !== tabName ? "fillIcon" : "fillClicked"
                        }`}
                        width={16}
                    />
                )}
                {tabName === "adminFiles" && (
                    <FilesIcon
                        className={`${
                            currentTab !== tabName ? "fillIcon" : "fillClicked"
                        }`}
                        width={16}
                    />
                )}
            </span>
            <span
                className={`${
                    currentTab !== tabName ? "sections" : "sectionsClicked"
                } ${sectionClasses}`}
            >
                <Translate text={translateKey} />
            </span>
        </div>
    );
};

export default MenuTab;
