import React, { Component } from "react";
import Table from "devkit/Table/Table";
import { StoreService } from "services/store.service";
import ShowMore from "devkit/ShowMore/ShowMore";
import Translate from "functions/utilFunctions/translate";
import { CustomButton } from "devkit/Form/Form";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import ApiService from "services/api.service";
import { PlusIcon } from "../../components/Icons/Icons";
import { Loader } from "devkit/Loader/Loader";
import Paginate from "devkit/Pagination/Pagination";

class Categories extends Component<any> {
    state = {
        categoriesTableHeads: [
            {
                value: "TITLE",
                sortable: true,
                sortableName: "name",
            },
            {
                value: "TYPE",
                sortable: true,
                sortableName: "type",
            },
            {
                value: "NUMBER_OF_VEHICLES",
                sortable: true,
                sortableName: "cars_count",
            },
            {
                value: "DISCOUNT",
                sortable: true,
                sortableName: "discount",
            },
            {
                value: "1_3_DAYS",
                sortable: true,
                sortableName: "period_one",
            },
            {
                value: "3_6_DAYS",
                sortable: true,
                sortableName: "period_two",
            },
            {
                value: "7_10_DAYS",
                sortable: true,
                sortableName: "period_three",
            },
            {
                value: "10_20_DAYS",
                sortable: true,
                sortableName: "period_four",
            },
            {
                value: "20_DAYS",
                sortable: true,
                sortableName: "period_five",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        carCategories: [],
        showDeleteModal: false,
        showDeleteErrorModal: false,
        showDeleteSuccessModal: false,
        showFilterSidebar: false,
        showLoader: true,
        deleteID: "",
        totalPages: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 20,
        sorterName: "",
        sorterDirection: "",
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;

        ApiService.getAllCategories({ limit: perPage, offset }, token).then(
            (response) => {
                if (response && response.success) {
                    const carCategories = response.data;
                    this.setState({
                        carCategories,
                        totalPages: response.total,
                        showLoader: false,
                    });
                }
            }
        );
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.sorterName &&
            this.state.sorterName !== prevState.sorterName
        ) {
            this.setState({ sorterName: "", sorterDirection: "" });
        }
        if (prevState.currentPage !== this.state.currentPage) {
            const token = StoreService.getStoreProperty("token");
            const {
                currentPage,
                perPage,
                sorterName,
                sorterDirection,
            } = this.state;
            const offset = (parseInt(currentPage) - 1) * perPage;
            const data: any = { limit: perPage, offset };
            if (sorterDirection) {
                data.sorter_direction = sorterDirection;
            }
            if (sorterName) {
                data.sorter = sorterName;
            }

            ApiService.getAllCategories(data, token).then((response) => {
                if (response && response.success) {
                    const categories = response.data;
                    this.setState({
                        carCategories: categories,
                        totalPages: response.total,
                        showLoader: false,
                    });
                }
            });
        }
    }

    handleRedirect = (id) => {
        this.props.history.push(`/group/${id}/basicInfo`);
    };

    handleNewGroup = () => {
        this.props.history.push("/new-group");
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleDeleteGroup = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
        const token = StoreService.getStoreProperty("token");
        const {
            currentPage,
            perPage,
            deleteID,
            sorterDirection,
            sorterName,
        } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data: any = { limit: perPage, offset };
        if (sorterDirection) {
            data.sorter_direction = sorterDirection;
        }
        if (sorterName) {
            data.sorter = sorterName;
        }

        ApiService.deleteCategory(deleteID, token).then((response) => {
            if (response && response.success) {
                ApiService.getAllCategories(data, token).then((response) => {
                    this.setState({
                        carCategories: response.data,
                        showDeleteSuccessModal: true,
                    });
                });
            } else {
                this.setState({
                    showDeleteFailedModal: true,
                });
            }
        });
    };
    handleSort = (sortableName) => {
        const { currentPage, perPage, sorterDirection } = this.state;
        const token = StoreService.getStoreProperty("token");
        const offset = (parseInt(currentPage) - 1) * perPage;
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";

        ApiService.getAllCategories(
            { limit: perPage, offset, sorter: sortableName, sorter_direction },
            token
        ).then((response) => {
            if (response && response.success) {
                const carCategories = response.data;
                this.setState({
                    carCategories,
                    totalPages: response.total,
                    showLoader: false,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                });
            }
        });
    };

    handlePageClick = (pageNumber) => {
        this.props.history.push(`/groups/${pageNumber}`);
    };

    render() {
        const {
            showLoader,
            carCategories,
            totalPages,
            currentPage,
            perPage,
        } = this.state;
        return (
            <div>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-600 big-warning padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="ARE_YOU_SURE_DELETE_GROUP"
                        modalName="showDeleteModal"
                        title="DELETE_GROUP"
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDeleteGroup}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteSuccessModal"
                    className={
                        this.state.showDeleteSuccessModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="GROUP_DELETE_SUCCESS"
                        modalName="showDeleteSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteErrorModal"
                    className={
                        this.state.showDeleteErrorModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="GROUP_DELETE_FAILURE"
                        modalName="showDeleteErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <div className="d-flex w-100-perc border-b-1 pb-20">
                    <span className="pageHeader">
                        <Translate text="GROUPS" />
                    </span>
                </div>
                <div className="d-flex py-20 justify-content-end">
                    <CustomButton
                        className="btn-primary mr-10"
                        type="button"
                        onClick={this.handleNewGroup}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PlusIcon
                                width={14}
                                className="icon hover pointer mr-10 whiteFill"
                            />
                            <Translate text="ADD_GROUP" />
                        </div>
                    </CustomButton>
                </div>
                <div className="mt-20 f-s-14">
                    {carCategories && (
                        <>
                            {showLoader ? (
                                <Loader className="w-200" />
                            ) : (
                                <>
                                    <Table
                                        theads={this.state.categoriesTableHeads}
                                        theadsClassname="customThead"
                                        handleSort={this.handleSort}
                                    >
                                        {carCategories.map((e: any, i) => {
                                            return (
                                                <tr
                                                    className="pointer"
                                                    onClick={() =>
                                                        this.handleRedirect(
                                                            e.id
                                                        )
                                                    }
                                                    key={i}
                                                >
                                                    <td className="">
                                                        {e.name}
                                                    </td>
                                                    <td>{e.type}</td>
                                                    <td>{e.cars_count}</td>
                                                    <td>{e.discount}</td>
                                                    <td className="">
                                                        {e.period_one}
                                                    </td>
                                                    <td className="">
                                                        {e.period_two}
                                                    </td>
                                                    <td className="">
                                                        {e.period_three}
                                                    </td>
                                                    <td className="">
                                                        {e.period_four}
                                                    </td>
                                                    <td className="">
                                                        {e.period_five}
                                                    </td>

                                                    <td>
                                                        <ShowMore>
                                                            <span
                                                                className="one-line-text d-flex justify-content-center"
                                                                onClick={() =>
                                                                    this.handleRedirect(
                                                                        e.id
                                                                    )
                                                                }
                                                            >
                                                                <Translate text="CHANGE_DATA" />
                                                            </span>
                                                            <span
                                                                className="one-line-text d-flex justify-content-center"
                                                                onClick={() =>
                                                                    this.handleDeleteModal(
                                                                        e.id
                                                                    )
                                                                }
                                                            >
                                                                <span className="danger">
                                                                    <Translate text="DELETE" />
                                                                </span>
                                                            </span>
                                                        </ShowMore>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </Table>
                                    <div className="d-flex justify-content-center mt-30">
                                        <Paginate
                                            totalItemsCount={totalPages}
                                            onChange={this.handlePageClick}
                                            activePage={parseInt(currentPage)}
                                            perPage={perPage}
                                            pageRangeDisplayed={5}
                                        />
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
        );
    }
}

export default Categories;
