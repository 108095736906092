import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import ShowMore from "devkit/ShowMore/ShowMore";
import { CustomButton, CustomDropdown } from "devkit/Form/Form";
import { CalendarIcon, PlusIcon } from "../../components/Icons/Icons";
import {
    parseDate,
    parseDateForMySQL,
    parseDatetimeBack,
} from "functions/utilFunctions/parsingFunctions";
import { StoreService } from "../../services/store.service";
import ApiService from "services/api.service";
import FilterSidebar from "components/FilterSidebar/FilterSidebar";
import { Loader } from "devkit/Loader/Loader";
import { CustomDatePicker } from "devkit/Form/Form";
import { ConstantsService } from "services/constants.service";
import Paginate from "devkit/Pagination/Pagination";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import { FilterOptions } from "functions/utilFunctions/FilterOptions";
import {
    tableHeads,
    filterForms,
} from "./StatesAndInterfaces/ReservationForms";

class ReservationsContracts extends Component<any> {
    state = {
        reservationsTableHeads: tableHeads.reservationsTableHeads,
        contractsTableHeads: tableHeads.contractsTableHeads,
        overviewContractsTableHeads: tableHeads.overviewContractsTableHeads,
        reservationsContractsTableHeads:
            tableHeads.reservationsContractsTableHeads,
        reservations: [],
        contracts: [],
        reservationsAndContracts: [],
        overviewContracts: [],
        resAndConFilterForm: filterForms,
        contractsFilterForm: filterForms,
        reservationsFilterForm: filterForms,
        overviewConFilterForm: filterForms,
        resAndConFilters: {},
        contractsFilters: {},
        reservationsFilters: {},
        overviewConFilters: {},
        relativeTimeFilter: {
            value: "no_filter",
            errors: [],
        },
        relativeTimeFilterRC: {
            value: "today",
            errors: [],
        },
        relativePastOptions: [],
        relativeNextOptions: [],
        showFilterSidebar: false,
        showLoader: true,
        showDeleteModal: false,
        showDeleteSuccessModal: false,
        showDeleteErrorModal: false,
        deleteID: 0,
        path: this.props.match.path,
        isContracts: !!this.props.contracts,
        isReservations: !!this.props.reservations,
        filterTable: "",
        totalPagesRes: 0,
        totalPagesCon: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 20,
        customers: [],
        separatedCategoryName: "",
        sorterName: "",
        sorterDirection: "",
        fileDownloadModal: false,
    };

    componentDidMount() {
        const { currentPage, perPage } = this.state;
        const token = StoreService.getStoreProperty("token");
        let offset;
        if (typeof currentPage !== "undefined") {
            offset = (parseInt(currentPage) - 1) * perPage;
        } else {
            offset = 0;
        }
        ApiService.getAllContracts(
            {
                limit: 20,
                offset: 0,
                date_from: parseDateForMySQL(new Date()),
                date_to: parseDateForMySQL(new Date()),
            },
            token
        ).then((response) => {
            if (response.success) {
                const reservationsAndContracts = response.data;
                const newResAndCon = reservationsAndContracts
                    .filter((res) => {
                        return (
                            parseDatetimeBack(res.rent_start_date).slice(
                                0,
                                10
                            ) === parseDate(new Date())
                        );
                    })
                    .sort((a, b) => {
                        return (
                            new Date(a.rent_end_date).valueOf() -
                            new Date(b.rent_end_date).valueOf()
                        );
                    });
                ApiService.getAllContracts(
                    {
                        limit: perPage,
                        offset,
                        type: ConstantsService.CONTRACTS_TYPES.RESERVATIONS,
                    },
                    token
                ).then((response) => {
                    if (response.success) {
                        const reservations = response.data;
                        const totalPagesRes = response.total;

                        ApiService.getAllContracts(
                            {
                                limit: perPage,
                                offset,
                                type: ConstantsService.CONTRACTS_TYPES
                                    .CONTRACTS,
                            },
                            token
                        ).then((response) => {
                            if (response.success) {
                                const contracts = response.data;
                                const totalPagesCon = response.total;

                                ApiService.getAllContracts(
                                    {
                                        limit: 1000,
                                        offset: 0,
                                        type: ConstantsService.CONTRACTS_TYPES
                                            .CONTRACTS,
                                    },
                                    token
                                ).then((response) => {
                                    if (response.success) {
                                        const overviewContracts = response.data
                                            .filter((contract) => {
                                                return (
                                                    parseDatetimeBack(
                                                        contract.rent_end_date
                                                    ).slice(0, 10) ===
                                                    parseDate(new Date())
                                                );
                                            })
                                            .sort((a, b) => {
                                                return (
                                                    new Date(
                                                        a.rent_start_date
                                                    ).valueOf() -
                                                    new Date(
                                                        b.rent_start_date
                                                    ).valueOf()
                                                );
                                            });

                                        this.setState({
                                            relativePastOptions:
                                                FilterOptions.getRelativeDateOptions(
                                                    "past"
                                                ),
                                            relativeNextOptions:
                                                FilterOptions.getRelativeDateOptions(
                                                    "next"
                                                ),
                                            reservationsAndContracts:
                                                newResAndCon,
                                            overviewContracts,
                                            contracts,
                                            reservations,
                                            totalPagesCon,
                                            totalPagesRes,
                                            showLoader: false,
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
            }
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (
            nextProps.match.path !== prevState.path ||
            nextProps.contracts !== prevState.isContracts ||
            nextProps.reservations !== prevState.isReservations
        ) {
            if (nextProps.match.params.pageNo !== prevState.currentPage) {
                return {
                    currentPage: nextProps.match.params.pageNo,
                    // isContracts: nextProps.contracts,
                    // isReservations: nextProps.reservations,
                    showLoader: true,
                };
            } else {
                return {
                    isContracts: !!nextProps.contracts,
                    isReservations: !!nextProps.reservations,
                };
            }
        }

        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        const filtersChanged =
            prevState.resAndConFilters !== this.state.resAndConFilters ||
            prevState.contractsFilters !== this.state.contractsFilters ||
            prevState.reservationsFilters !== this.state.reservationsFilters ||
            prevState.overviewConFilters !== this.state.overviewConFilters;
        const currentPageChanged =
            prevState.currentPage !== this.state.currentPage;

        if (
            prevState.sorterName &&
            this.state.sorterName !== prevState.sorterName
        ) {
            this.setState({ sorterName: "", sorterDirection: "" });
        }
        if (
            prevState.isContracts !== this.state.isContracts ||
            prevState.isReservations !== this.state.isReservations
        ) {
            this.setState({
                relativeTimeFilter: { value: "no_filter", errors: [] },
            });
        }
        if (filtersChanged || currentPageChanged) {
            const {
                isContracts,
                isReservations,
                filterTable,
                resAndConFilters,
                contractsFilters,
                reservationsFilters,
                overviewConFilters,
                currentPage,
                perPage,
                sorterDirection,
                sorterName,
            } = this.state;
            const token = StoreService.getStoreProperty("token");
            let offset;
            // STAVITI DA SE UVIJEK ISTO LOADUJE JER AKO SE NA UGOVORE NESTO IZBRISE IZBRISACE SE NA PREGLEDU
            if (typeof currentPage !== "undefined") {
                offset = (parseInt(currentPage) - 1) * perPage;
            } else {
                offset = 0;
            }
            const data: any = { limit: perPage, offset };
            if (sorterDirection) {
                data.sorter_direction = sorterDirection;
            }
            if (sorterName) {
                data.sorter = sorterName;
            }

            if (isContracts) {
                ApiService.getAllContracts(
                    {
                        ...data,
                        type: ConstantsService.CONTRACTS_TYPES.CONTRACTS,
                        ...contractsFilters,
                    },
                    token
                ).then((response) => {
                    const contracts = response.data;

                    this.setState({
                        contracts,
                        showLoader: false,
                        totalPagesCon: response.total,
                    });
                });
            } else if (isReservations) {
                ApiService.getAllContracts(
                    {
                        ...data,
                        type: ConstantsService.CONTRACTS_TYPES.RESERVATIONS,
                        ...reservationsFilters,
                    },
                    token
                ).then((response) => {
                    if (response && response.success) {
                        const reservations = response.data;
                        this.setState({
                            reservations,
                            showLoader: false,
                            totalPagesRes: response.total,
                        });
                    }
                });
            } else if (filterTable === "reservationsAndContracts") {
                ApiService.getAllContracts(
                    {
                        limit: perPage,
                        offset,
                        ...resAndConFilters,
                    },
                    token
                ).then((response) => {
                    if (response && response.success) {
                        let reservationsAndContracts = response.data;
                        if (
                            resAndConFilters &&
                            Object.keys(resAndConFilters).length === 0
                        ) {
                            reservationsAndContracts =
                                reservationsAndContracts.filter((res) => {
                                    return (
                                        parseDatetimeBack(
                                            res.rent_start_date
                                        ).slice(0, 10) === parseDate(new Date())
                                    );
                                });
                        }
                        this.setState({
                            reservationsAndContracts,
                            showLoader: false,
                        });
                    }
                });
            } else {
                ApiService.getAllContracts(
                    {
                        limit: perPage,
                        offset,
                        type: ConstantsService.CONTRACTS_TYPES.CONTRACTS,
                        ...overviewConFilters,
                        end_date: true,
                    },
                    token
                ).then((response) => {
                    if (response && response.success) {
                        let overviewContracts = response.data;
                        if (
                            overviewConFilters &&
                            Object.keys(overviewConFilters).length === 0
                        ) {
                            overviewContracts = overviewContracts.filter(
                                (contract) => {
                                    return (
                                        parseDatetimeBack(
                                            contract.rent_end_date
                                        ).slice(0, 10) === parseDate(new Date())
                                    );
                                }
                            );
                        }
                        this.setState({
                            overviewContracts,
                            showLoader: false,
                        });
                    }
                });
            }
        }
    }

    handleRelativeDate = (stateVar, name, data) => {
        let date_from: Date | null = new Date();
        let date_to: Date | null = null;

        if (data.value === "no_filter") {
            date_from = null;
            date_to = null;

            this.setState((prevState: any) => ({
                ...prevState,
                [stateVar]: {
                    ...prevState[stateVar],
                    date_from: {
                        value: date_from,
                        errors: [],
                    },
                    date_to: {
                        value: date_to,
                        errors: [],
                    },
                },
            }));
        } else {
            if (stateVar === "resAndConFilterForm") {
                date_to =
                    data.value === "today"
                        ? new Date()
                        : data.value === "this_week"
                        ? new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                        : new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);
            } else {
                date_to = new Date();
                date_from =
                    data.value === "today"
                        ? new Date()
                        : data.value === "this_week"
                        ? new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
                        : new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
            }

            this.setState((prevState: any) => ({
                ...prevState,
                [stateVar]: {
                    ...prevState[stateVar],
                    date_from: {
                        value: date_from,
                        errors: [],
                    },
                    date_to: {
                        value: date_to,
                        errors: [],
                    },
                },
            }));

            if (stateVar === "resAndConFilterForm") {
                this.setState({
                    relativeTimeFilterRC: {
                        value: data.value,
                        errors: [],
                    },
                });
            } else {
                this.setState({
                    relativeTimeFilter: {
                        value: data.value,
                        errors: [],
                    },
                });
            }
        }
    };

    handleFilterChange = (name, data) => {
        const { isContracts, isReservations, filterTable } = this.state;
        if (name === "date_from" || "date_to") {
            this.setState({
                relativeTimeFilter: { value: "no_filter", errors: [] },
                relativeTimeFilterRC: { value: "no_filter", errors: [] },
            });
        }
        if (filterTable === "reservationsAndContracts") {
            if (name === "relativeTimeFilterRC") {
                this.handleRelativeDate("resAndConFilterForm", name, data);
            } else {
                this.setState((prevState: any) => ({
                    ...prevState,
                    resAndConFilterForm: {
                        ...prevState.resAndConFilterForm,
                        [name]: {
                            value: data.value,
                            errors: [],
                        },
                    },
                }));
            }
        } else if (isContracts) {
            if (name === "relativeTimeFilter") {
                this.handleRelativeDate("contractsFilterForm", name, data);
            } else {
                this.setState((prevState: any) => ({
                    ...prevState,
                    contractsFilterForm: {
                        ...prevState.contractsFilterForm,
                        [name]: {
                            value: data.value,
                            errors: [],
                        },
                    },
                }));
            }
        } else if (isReservations) {
            if (name === "relativeTimeFilter") {
                this.handleRelativeDate("reservationsFilterForm", name, data);
            } else {
                this.setState((prevState: any) => ({
                    ...prevState,
                    reservationsFilterForm: {
                        ...prevState.reservationsFilterForm,
                        [name]: {
                            value: data.value,
                            errors: [],
                        },
                    },
                }));
            }
        } else if (filterTable === "overviewContracts") {
            if (name === "relativeTimeFilter") {
                this.handleRelativeDate("overviewConFilterForm", name, data);
            } else {
                this.setState((prevState: any) => ({
                    ...prevState,
                    overviewConFilterForm: {
                        ...prevState.overviewConFilterForm,
                        [name]: {
                            value: data.value,
                            errors: [],
                        },
                    },
                }));
            }
        }
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    handleDelete = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
        const token = StoreService.getStoreProperty("token");
        const {
            deleteID,
            currentPage,
            perPage,
            isContracts,
            sorterDirection,
            sorterName,
        } = this.state;

        let offset =
            typeof currentPage !== "undefined"
                ? (parseInt(currentPage) - 1) * perPage
                : 0;

        const type = isContracts
            ? ConstantsService.CONTRACTS_TYPES.CONTRACTS
            : ConstantsService.CONTRACTS_TYPES.RESERVATIONS;

        const data: any = { limit: perPage, offset, type };
        if (sorterDirection) {
            data.sorter_direction = sorterDirection;
        }
        if (sorterName) {
            data.sorter = sorterName;
        }

        ApiService.deleteContract(deleteID, token).then((response) => {
            if (response && response.success) {
                ApiService.getAllContracts(data, token).then((response) => {
                    if (response && response.success) {
                        const data = response.data;

                        if (isContracts) {
                            this.setState({
                                contracts: data,
                                showLoader: false,
                                showDeleteSuccessModal: true,
                            });
                        } else {
                            this.setState({
                                reservations: data,
                                showLoader: false,
                                showDeleteSuccessModal: true,
                            });
                        }
                    }
                });
                ApiService.getAllContracts(
                    {
                        limit: 1000,
                        offset: 0,
                        type: ConstantsService.CONTRACTS_TYPES.CONTRACTS,
                    },
                    token
                ).then((response) => {
                    if (response.success) {
                        const overviewContracts = response.data.filter(
                            (contract) => {
                                return (
                                    parseDatetimeBack(
                                        contract.rent_end_date
                                    ).slice(0, 10) === parseDate(new Date())
                                );
                            }
                        );

                        this.setState({
                            overviewContracts,
                        });
                    }
                });
            } else {
                this.setState({
                    showDeleteFailureModal: true,
                });
            }
        });

        ApiService.getAllContracts(
            {
                data,
                token,
            },
            token
        ).then((response) => {
            if (response.success) {
                const reservationsAndContracts = response.data;
                const newResAndCon = reservationsAndContracts.filter((res) => {
                    return (
                        parseDatetimeBack(res.rent_start_date).slice(0, 10) ===
                        parseDate(new Date())
                    );
                });

                this.setState({ reservationsAndContracts: newResAndCon });
            }
        });
    };

    handleSortReservations = (sortableName) => {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage, sorterDirection } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";

        ApiService.getAllContracts(
            {
                limit: perPage,
                offset,
                type: ConstantsService.CONTRACTS_TYPES.RESERVATIONS,
                sorter: sortableName,
                sorter_direction,
            },
            token
        ).then((response) => {
            if (response && response.success) {
                const reservations = response.data;
                this.setState({
                    reservations,
                    totalPages: response.total,
                    showLoader: false,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                });
            }
        });
    };

    handleSortContracts = (sortableName) => {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage, sorterDirection } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        sortableName = sortableName.slice(0, -1);
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";
        ApiService.getAllContracts(
            {
                limit: perPage,
                offset,
                type: ConstantsService.CONTRACTS_TYPES.CONTRACTS,
                sorter: sortableName,
                sorter_direction,
            },
            token
        ).then((response) => {
            if (response && response.success) {
                const contracts = response.data;
                this.setState({
                    contracts,
                    totalPages: response.total,
                    showLoader: false,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                });
            }
        });
    };

    handleRedirect = (id, type) => {
        if (parseInt(type) === ConstantsService.CONTRACTS_TYPES.CONTRACTS) {
            this.props.history.push(`/contract-details/${id}`);
        } else if (
            parseInt(type) === ConstantsService.CONTRACTS_TYPES.RESERVATIONS
        ) {
            this.props.history.push(`/reservation-details/${id}`);
        }
    };
    handleNewContract = () => {
        this.state.isReservations
            ? this.props.history.push("/new-reservation")
            : this.props.history.push("/new-contract");
    };

    toggleFilterSidebar = (e, filterTable) => {
        e.preventDefault();
        this.setState({
            showFilterSidebar: !this.state.showFilterSidebar,
            filterTable,
        });

        if (
            filterTable === "reservationsAndContracts" ||
            filterTable === "overviewContracts"
        ) {
            this.setState({
                relativeTimeFilter: { value: "today", errors: [] },
            });
        }
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    submitFilters = (e) => {
        e.preventDefault();

        const {
            filterTable,
            isContracts,
            isReservations,
            resAndConFilterForm,
            overviewConFilterForm,
            contractsFilterForm,
            reservationsFilterForm,
        } = this.state;

        const filters: any = {};

        if (filterTable === "reservationsAndContracts") {
            for (let filter in resAndConFilterForm) {
                if (resAndConFilterForm[filter].value !== null) {
                    filters[filter] = parseDateForMySQL(
                        resAndConFilterForm[filter].value
                    );
                }
            }
            this.setState({ resAndConFilters: filters, showLoader: true });
        } else if (isContracts) {
            for (let filter in contractsFilterForm) {
                if (contractsFilterForm[filter].value !== null) {
                    filters[filter] = parseDateForMySQL(
                        contractsFilterForm[filter].value
                    );
                }
            }
            this.setState(
                { contractsFilters: filters, showLoader: true },
                () => {
                    this.props.history.push(
                        `${isContracts ? "/contracts/1" : "/reservations/1"}`
                    );
                }
            );
        } else if (isReservations) {
            for (let filter in reservationsFilterForm) {
                if (reservationsFilterForm[filter].value !== null) {
                    filters[filter] = parseDateForMySQL(
                        reservationsFilterForm[filter].value
                    );
                }
            }
            this.setState(
                { reservationsFilters: filters, showLoader: true },
                () => {
                    this.props.history.push(
                        `${isContracts ? "/contracts/1" : "/reservations/1"}`
                    );
                }
            );
        } else if (filterTable === "overviewContracts") {
            for (let filter in overviewConFilterForm) {
                if (overviewConFilterForm[filter].value !== null) {
                    filters[filter] = parseDateForMySQL(
                        overviewConFilterForm[filter].value
                    );
                }
            }
            this.setState({ overviewConFilters: filters, showLoader: true });
        }
    };

    resetFilters = () => {
        const { filterTable, isContracts, isReservations } = this.state;
        if (filterTable === "reservationsAndContracts") {
            this.setState({
                resAndConFilters: {},
                showLoader: true,
                resAndConFilterForm: {
                    date_from: {
                        value: null,
                        errors: [],
                    },
                    date_to: {
                        value: null,
                        errors: [],
                    },
                },
                relativeTimeFilterRC: {
                    value: "today",
                    errors: [],
                },
            });
        } else if (isContracts) {
            this.setState(
                {
                    contractsFilters: {},
                    showLoader: true,
                    contractsFilterForm: {
                        date_from: {
                            value: null,
                            errors: [],
                        },
                        date_to: {
                            value: null,
                            errors: [],
                        },
                    },
                    relativeTimeFilter: {
                        value: "no_filter",
                        errors: [],
                    },
                },
                () => {
                    this.props.history.push(
                        `${isContracts ? "/contracts/1" : "/reservations/1"}`
                    );
                }
            );
        } else if (isReservations) {
            this.setState(
                {
                    reservationsFilters: {},
                    showLoader: true,
                    reservationsFilterForm: {
                        date_from: {
                            value: null,
                            errors: [],
                        },
                        date_to: {
                            value: null,
                            errors: [],
                        },
                    },
                    relativeTimeFilter: {
                        value: "no_filter",
                        errors: [],
                    },
                },
                () => {
                    this.props.history.push(
                        `${isContracts ? "/contracts/1" : "/reservations/1"}`
                    );
                }
            );
        } else if (filterTable === "overviewContracts") {
            this.setState({
                overviewConFilters: {},
                showLoader: true,
                overviewConFilterForm: {
                    date_from: {
                        value: null,
                        errors: [],
                    },
                    date_to: {
                        value: null,
                        errors: [],
                    },
                },
                relativeTimeFilter: {
                    value: "today",
                    errors: [],
                },
            });
        }
    };

    handlePageClick = (pageNumber) => {
        if (this.state.isReservations) {
            this.props.history.push(`/reservations/${pageNumber}`);
        } else if (this.state.isContracts) {
            this.props.history.push(`/contracts/${pageNumber}`);
        }
    };

    renderStatus = (time, converted) => {
        let now = new Date();
        let contractTime = new Date(time);
        if (!converted && contractTime.getTime() < now.getTime()) {
            return "late";
        }
        if (converted) {
            return "converted";
        }
    };

    renderContractStatus = (time, finished, onlyContracts?) => {
        let now = new Date();
        let contractTime = new Date(time);
        if (finished) {
            return "finishedContract";
        }
        if (!onlyContracts && contractTime.getTime() < now.getTime()) {
            return "lateContract";
        }
    };

    redirectToCalendar = () => {
        this.props.history.push("/contract-calendar");
    };

    getReportPDF = () => {
        this.setState({ fileDownloadModal: true });
        const token = StoreService.getStoreProperty("token");
        const { isContracts } = this.state;
        const type = isContracts
            ? ConstantsService.CONTRACTS_TYPES.CONTRACTS
            : ConstantsService.CONTRACTS_TYPES.RESERVATIONS;

        const data = { type, limit: 100, offset: 0 };

        ApiService.getContractsPDF(data, token).then((res) => {
            let file = new Blob([res]);
            console.log(file);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = "report.pdf";
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            this.setState({ fileDownloadModal: false });
        });
    };

    render() {
        const {
            reservationsContractsTableHeads,
            overviewContractsTableHeads,
            contractsTableHeads,
            reservationsTableHeads,
            reservationsAndContracts,
            contracts,
            overviewContracts,
            reservations,
            showFilterSidebar,
            showLoader,
            isContracts,
            isReservations,
            filterTable,
            relativePastOptions,
            relativeNextOptions,
            resAndConFilterForm,
            overviewConFilterForm,
            contractsFilterForm,
            reservationsFilterForm,
            totalPagesRes,
            totalPagesCon,
            perPage,
            currentPage,
        } = this.state;

        return (
            <div>
                <Modal
                    modalName="fileDownloadModal"
                    toggleModal={this.toggleModal}
                    className={this.state.fileDownloadModal ? "visible" : ""}
                    modalWrapperClasses="fileDownloadModal"
                >
                    <div className="progress pa-30 w-500 text-center border-radius-4">
                        <span className="wrapper d-block mb-20">
                            <Loader width={200} />
                        </span>
                        <span className="f-s-20 mb-20">
                            <Translate text="DOWNLOAD_IN_PROGRESS" />
                        </span>
                    </div>
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text={`${
                            isContracts
                                ? "DELETE_CONTRACT_?"
                                : "DELETE_RESERVATION_?"
                        }`}
                        modalName="showDeleteModal"
                        title={`${
                            isContracts
                                ? "DELETE_CONTRACT"
                                : "DELETE_RESERVATION"
                        }`}
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDelete}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteSuccessModal"
                    className={
                        this.state.showDeleteSuccessModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text={`${
                            isContracts
                                ? "DELETE_CONTRACT_SUCCESS"
                                : "DELETE_RESERVATION_SUCCESS"
                        }`}
                        modalName="showDeleteSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteErrorModal"
                    className={
                        this.state.showDeleteErrorModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text={`${
                            isContracts
                                ? "DELETE_CONTRACT_FAILURE"
                                : "DELETE_RESERVATION_FAILURE"
                        }`}
                        modalName="showDeleteErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <FilterSidebar
                    onClose={this.toggleFilterSidebar}
                    submitFilters={this.submitFilters}
                    resetFilters={this.resetFilters}
                    sidebarClass={showFilterSidebar ? "filter-sidebar-on" : ""}
                    wrapperClass={
                        showFilterSidebar ? "filter-sidebar-wrapper-on" : ""
                    }
                >
                    <div>
                        {filterTable === "reservationsAndContracts" ? (
                            <div className="mb-30">
                                <CustomDropdown
                                    name="relativeTimeFilterRC"
                                    value={relativeNextOptions.find(
                                        (option: any) =>
                                            option.value ===
                                            this.state.relativeTimeFilterRC
                                                .value
                                    )}
                                    data={relativeNextOptions}
                                    handleChange={this.handleFilterChange}
                                    label="RELATIVE_DATE"
                                    className="text-center"
                                ></CustomDropdown>
                            </div>
                        ) : (
                            <div className="mb-30">
                                <CustomDropdown
                                    name="relativeTimeFilter"
                                    value={relativePastOptions.find(
                                        (option: any) =>
                                            option.value ===
                                            this.state.relativeTimeFilter.value
                                    )}
                                    data={relativePastOptions}
                                    handleChange={this.handleFilterChange}
                                    label="RELATIVE_DATE"
                                    className="text-center"
                                ></CustomDropdown>
                            </div>
                        )}

                        <div className="mb-30">
                            <CustomDatePicker
                                name="date_from"
                                value={
                                    isContracts
                                        ? contractsFilterForm.date_from.value
                                        : isReservations
                                        ? reservationsFilterForm.date_from.value
                                        : filterTable === "overviewContracts"
                                        ? overviewConFilterForm.date_from.value
                                        : resAndConFilterForm.date_from.value
                                }
                                handleInput={this.handleFilterChange}
                                label="DATE_START"
                            ></CustomDatePicker>
                        </div>
                        <div className="mb-30">
                            <CustomDatePicker
                                name="date_to"
                                value={
                                    isContracts
                                        ? contractsFilterForm.date_to.value
                                        : isReservations
                                        ? reservationsFilterForm.date_to.value
                                        : filterTable === "overviewContracts"
                                        ? overviewConFilterForm.date_to.value
                                        : resAndConFilterForm.date_to.value
                                }
                                handleInput={this.handleFilterChange}
                                label="DATE_END"
                            ></CustomDatePicker>
                        </div>
                    </div>
                </FilterSidebar>
                <div className="d-flex padding-0">
                    <NavLink
                        to="/reservationsAndContracts"
                        className={`${
                            !isReservations && !isContracts && "active"
                        } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                        activeClassName=""
                    >
                        <Translate text="OVERVIEW" />
                    </NavLink>
                    <NavLink
                        to="/reservations/1"
                        className={`${
                            isReservations && "active"
                        } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                        activeClassName=""
                    >
                        <Translate text="RESERVATIONS" />
                    </NavLink>
                    <NavLink
                        to="/contracts/1"
                        className={`${
                            isContracts && "active"
                        } reservations-title d-flex align-items-center justify-content-center tab border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                        activeClassName=""
                    >
                        <Translate text="CONTRACTS" />
                    </NavLink>
                </div>
                {!isContracts && !isReservations && (
                    <div className="d-flex py-20 justify-content-between">
                        <CustomButton
                            className="filter-btn"
                            type="button"
                            onClick={(e) =>
                                this.toggleFilterSidebar(
                                    e,
                                    "reservationsAndContracts"
                                )
                            }
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <Translate text="FILTERS" />
                            </div>
                        </CustomButton>

                        <CustomButton
                            className="filter-btn"
                            type="button"
                            onClick={(e) =>
                                this.toggleFilterSidebar(e, "overviewContracts")
                            }
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <Translate text="FILTERS" />
                            </div>
                        </CustomButton>
                    </div>
                )}
                <div className="mt-40 f-s-14">
                    {!isReservations && !isContracts ? (
                        showLoader ? (
                            <Loader className="w-200" />
                        ) : (
                            <div className="rc-overview d-flex justify-content-between">
                                <div className="table-wrapper w-50-perc mr-40">
                                    <Table
                                        theads={reservationsContractsTableHeads}
                                        theadsClassname="customThead"
                                    >
                                        {reservationsAndContracts &&
                                            reservationsAndContracts.map(
                                                (e: any, i) => {
                                                    return (
                                                        <tr
                                                            className={`pointer ${this.renderStatus(
                                                                e.rent_start_date,
                                                                e.converted
                                                            )}`}
                                                            onClick={() =>
                                                                this.handleRedirect(
                                                                    e.id,
                                                                    e.type
                                                                )
                                                            }
                                                            key={i}
                                                        >
                                                            <td className="">
                                                                {
                                                                    e.customer_name
                                                                }
                                                            </td>
                                                            <td>
                                                                {
                                                                    e.category_name
                                                                }
                                                            </td>

                                                            <td className="">
                                                                {parseDatetimeBack(
                                                                    e.rent_start_date
                                                                )}
                                                            </td>
                                                            <td className="">
                                                                {parseDatetimeBack(
                                                                    e.rent_end_date
                                                                )}
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )}
                                    </Table>
                                </div>
                                <div className="table-wrapper w-50-perc">
                                    <Table
                                        theads={overviewContractsTableHeads}
                                        theadsClassname="customThead one-line-text"
                                        className={`${
                                            !this.props.template
                                                ? "actionsTable"
                                                : "templateTable"
                                        }`}
                                    >
                                        {overviewContracts.map((e: any, i) => {
                                            return (
                                                <tr
                                                    className={`pointer ${this.renderContractStatus(
                                                        e.rent_end_date,
                                                        e.finished,
                                                        false
                                                    )}`}
                                                    onClick={() =>
                                                        this.handleRedirect(
                                                            e.id,
                                                            e.type
                                                        )
                                                    }
                                                    key={i}
                                                >
                                                    <td className="">
                                                        {e.customer_name ? (
                                                            e.customer_name
                                                        ) : (
                                                            <Translate text="NO_RELATED_CUSTOMER" />
                                                        )}
                                                    </td>
                                                    <td>
                                                        {e.category_name ? (
                                                            e.category_name
                                                        ) : (
                                                            <Translate text="NO_RELATED_CATEGORY" />
                                                        )}
                                                    </td>

                                                    <td className="">
                                                        {parseDatetimeBack(
                                                            e.rent_start_date
                                                        )}
                                                    </td>
                                                    <td>
                                                        {parseDatetimeBack(
                                                            e.rent_end_date
                                                        )}
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </Table>
                                </div>
                            </div>
                        )
                    ) : (
                        <div>
                            <div className="d-flex pb-40 justify-content-end">
                                <CustomButton
                                    className="filter-btn mr-10"
                                    type="button"
                                    onClick={(e) =>
                                        this.toggleFilterSidebar(e, "")
                                    }
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Translate text="FILTERS" />
                                    </div>
                                </CustomButton>
                                <CustomButton
                                    className="btn-primary btn mr-10"
                                    type="button"
                                    onClick={() => this.getReportPDF()}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Translate text="PDF_REPORT" />
                                    </div>
                                </CustomButton>
                                {isContracts && (
                                    <CustomButton
                                        onClick={this.redirectToCalendar}
                                        className="d-flex mr-10"
                                    >
                                        <CalendarIcon
                                            width={16}
                                            className="calendar-icon"
                                        ></CalendarIcon>
                                        <div className="ml-10">
                                            <Translate text="CALENDAR" />
                                        </div>
                                    </CustomButton>
                                )}

                                <CustomButton
                                    className="btn-primary"
                                    type="button"
                                    onClick={() => this.handleNewContract()}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <PlusIcon
                                            width={14}
                                            className="icon hover pointer mr-10 whiteFill"
                                        />
                                        <Translate
                                            text={
                                                isReservations
                                                    ? "ADD_RESERVATION"
                                                    : "ADD_CONTRACT"
                                            }
                                        />
                                    </div>
                                </CustomButton>
                            </div>
                            {isReservations ? (
                                showLoader ? (
                                    <Loader className="w-200" />
                                ) : (
                                    <>
                                        <Table
                                            theads={reservationsTableHeads}
                                            theadsClassname="customThead"
                                            handleSort={
                                                this.handleSortReservations
                                            }
                                        >
                                            {!!reservations &&
                                                reservations.map(
                                                    (e: any, i) => {
                                                        return (
                                                            <tr
                                                                className="pointer"
                                                                onClick={() =>
                                                                    this.handleRedirect(
                                                                        e.id,
                                                                        e.type
                                                                    )
                                                                }
                                                                key={i}
                                                            >
                                                                <td className="">
                                                                    {e.customer_name ? (
                                                                        e.customer_name
                                                                    ) : (
                                                                        <Translate text="NO_RELATED_CUSTOMER" />
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {e.category_name ? (
                                                                        e.category_name
                                                                    ) : (
                                                                        <Translate text="NO_RELATED_CATEGORY" />
                                                                    )}
                                                                </td>

                                                                <td className="">
                                                                    {parseDatetimeBack(
                                                                        e.rent_start_date
                                                                    )}
                                                                </td>
                                                                <td className="">
                                                                    {parseDatetimeBack(
                                                                        e.rent_end_date
                                                                    )}
                                                                </td>

                                                                <td className="">
                                                                    {e.company_name ? (
                                                                        e.company_name
                                                                    ) : (
                                                                        <Translate text="NO_RELATED_COMPANY" />
                                                                    )}
                                                                </td>
                                                                <td className="">
                                                                    {
                                                                        e.user_name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    <ShowMore>
                                                                        <span
                                                                            className="one-line-text d-flex justify-content-center"
                                                                            onClick={() =>
                                                                                this.handleRedirect(
                                                                                    e.id,
                                                                                    e.type
                                                                                )
                                                                            }
                                                                        >
                                                                            <Translate text="CHANGE_DATA" />
                                                                        </span>
                                                                        <span
                                                                            className="one-line-text d-flex justify-content-center"
                                                                            onClick={() =>
                                                                                this.handleDeleteModal(
                                                                                    e.id
                                                                                )
                                                                            }
                                                                        >
                                                                            <span className="danger">
                                                                                <Translate text="DELETE" />
                                                                            </span>
                                                                        </span>
                                                                    </ShowMore>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                        </Table>
                                        {reservations &&
                                            Object.keys(reservations).length !==
                                                0 && (
                                                <Paginate
                                                    totalItemsCount={
                                                        totalPagesRes
                                                    }
                                                    onChange={
                                                        this.handlePageClick
                                                    }
                                                    activePage={parseInt(
                                                        currentPage
                                                    )}
                                                    perPage={perPage}
                                                    pageRangeDisplayed={5}
                                                />
                                            )}
                                    </>
                                )
                            ) : showLoader ? (
                                <Loader className="w-200" />
                            ) : (
                                <>
                                    <Table
                                        theads={contractsTableHeads}
                                        theadsClassname="customThead"
                                        handleSort={this.handleSortContracts}
                                    >
                                        {contracts &&
                                            contracts.map((e: any, i) => {
                                                return (
                                                    <tr
                                                        className={`pointer ${this.renderContractStatus(
                                                            e.rent_start_date,
                                                            e.finished,
                                                            true
                                                        )}`}
                                                        onClick={() =>
                                                            this.handleRedirect(
                                                                e.id,
                                                                e.type
                                                            )
                                                        }
                                                        key={i}
                                                    >
                                                        <td>
                                                            {e.customer_name ? (
                                                                e.customer_name
                                                            ) : (
                                                                <Translate text="NO_RELATED_CUSTOMER" />
                                                            )}
                                                        </td>
                                                        <td>
                                                            {e.category_name}
                                                        </td>

                                                        <td>
                                                            {parseDatetimeBack(
                                                                e.rent_start_date
                                                            )}
                                                        </td>
                                                        <td>
                                                            {parseDatetimeBack(
                                                                e.rent_end_date
                                                            )}
                                                        </td>

                                                        <td>
                                                            {e.company_name ? (
                                                                e.company_name
                                                            ) : (
                                                                <Translate text="NO_RELATED_COMPANY" />
                                                            )}
                                                        </td>
                                                        <td>{e.user_name}</td>
                                                        <td>
                                                            <ShowMore>
                                                                <span
                                                                    className="one-line-text d-flex justify-content-center"
                                                                    onClick={() =>
                                                                        this.handleRedirect(
                                                                            e.id,
                                                                            e.type
                                                                        )
                                                                    }
                                                                >
                                                                    <Translate text="CHANGE_DATA" />
                                                                </span>
                                                                <span
                                                                    className="one-line-text d-flex justify-content-center"
                                                                    onClick={() =>
                                                                        this.handleDeleteModal(
                                                                            e.id
                                                                        )
                                                                    }
                                                                >
                                                                    <span className="danger">
                                                                        <Translate text="DELETE" />
                                                                    </span>
                                                                </span>
                                                            </ShowMore>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </Table>
                                    {contracts &&
                                        Object.keys(contracts).length !== 0 && (
                                            <Paginate
                                                totalItemsCount={totalPagesCon}
                                                onChange={this.handlePageClick}
                                                activePage={parseInt(
                                                    currentPage
                                                )}
                                                perPage={perPage}
                                                pageRangeDisplayed={5}
                                            />
                                        )}
                                </>
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default ReservationsContracts;
