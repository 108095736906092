import React, { Component } from "react";
import { Loader } from "devkit/Loader/Loader";
import { StoreService } from "services/store.service";
import ApiService from "services/api.service";
import {
    FilesIcon,
    UploadIcon,
    PlusIcon,
    TrashCanIcon,
    ShowPasswordIcon,
} from "components/Icons/Icons";
import Translate from "functions/utilFunctions/translate";
import { CustomFileInput, CustomButton } from "devkit/Form/Form";
import Modal from "devkit/Modal/Modal";
import { UtilService } from "services/util.service";

class CarPhotos extends Component<any> {
    state = {
        photos: [{ path: "", id: null }],
        showLoader: true,
        uploading: false,
        changeType: "",
        modalSuccessText: "",
        modalFailedText: "",
        isOver: false,
        car_image: {
            value: null,
            errors: [],
        },
        completed: 0,
        photo: {
            value: null,
        },
        fileUploadErrorModal: false,
        fileUploadModal: false,
        imagePreviewModal: false,
        previewImage: "",
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        ApiService.getCar(this.props.carId, token).then((response) => {
            if (response.success) {
                this.setState({
                    photos: response.data.images,
                    showLoader: false,
                });
            } else {
                this.setState({ showLoader: true });
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.car_image.value !== this.state.car_image.value &&
            this.state.car_image.value
        ) {
            this.setState({ uploading: true });
            this.handleAddPhoto();
        }
    }

    toggleModal = (name) => {
        if (this.state.uploading) {
            return;
        }
        let resetFields;
        let changeType = "";
        this.setState((prevState: any) => ({
            [name]: !this.state[name],
            modalSuccessText: name,
            modalFailedText: name,
            passwordsDontMatch: name,
            changeType,
            form: {
                ...prevState.form,
                ...resetFields,
            },
        }));
    };

    handleFileChange = (name, e, drop?) => {
        this.setState({
            [name]: UtilService.handleAddFile(name, e, drop),
        });
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };

    handleAddPhoto = () => {
        const photo: any = this.state.car_image.value;
        const token = StoreService.getStoreProperty("token");
        const formData = new FormData();
        formData.append("file", photo);
        formData.append("type", "car_image");
        formData.append("car_id", this.props.carId);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadFile(formData, token, config).then((res) => {
            if (res.success) {
                this.setState((prevState: any) => ({
                    photos: [...prevState.photos, res.data],
                    completed: 0,
                    uploadComplete: true,
                    uploading: false,
                    fileUploadModal: false,
                    photo: {
                        value: null,
                        errors: [],
                    },
                }));
            } else {
                this.setState({
                    fileUploadErrorModal: true,
                    photo: {
                        value: null,
                        errors: [],
                    },
                });
            }
        });
    };

    deletePhoto = (index) => {
        const token = StoreService.getStoreProperty("token");

        ApiService.deleteFile(
            {
                id: this.state.photos[index].id,
            },
            token
        ).then((response) => {
            if (response.success) {
                const files = this.state.photos.filter((e, i) => i !== index);
                this.setState({
                    photos: files,
                });
            }
        });
    };

    previewImage = (url) => {
        this.setState({ previewImage: url, imagePreviewModal: true });
    };

    render() {
        const { showLoader } = this.state;
        if (showLoader) {
            return <Loader className="w-200" />;
        } else {
            return (
                <div className="d-flex flex-column h-100perc py-20">
                    <div className="d-flex align-items-center justify-content-between w-100-perc border-b-1 pb-20 mb-10 pageHeader">
                        <span className="d-flex align-items-center">
                            <FilesIcon
                                width={17}
                                height={17}
                                className="mr-10"
                            />
                            <Translate text="PHOTOS" />
                        </span>
                        <CustomButton
                            className="d-flex align-items-center"
                            type="button"
                            onClick={() => this.toggleModal("fileUploadModal")}
                        >
                            <span className="icon d-flex align-items-center">
                                <PlusIcon
                                    className="fill mr-10 whiteFill"
                                    width={14}
                                />
                            </span>
                            <Translate text="ADD_PHOTO" />
                        </CustomButton>
                    </div>
                    <div className="row mx-0 mr-35">
                        {this.state.photos.map((e: any, index) => {
                            return (
                                e.path !== "" && (
                                    <div className="container col-12 col-sm-6 col-md-4 col-lg-3 max-height-200 mx-0 mb-20">
                                        <img
                                            key={index}
                                            src={`https://rentapi.oykos.dev/storage/${e.path}`}
                                            className="attachment border-1 margin-20 w-100-perc h-100-perc overflow-hidden cover border-radius-4 bg-pos-center bg-light-6 pointer"
                                            alt=""
                                        ></img>
                                        <div className="btn-group">
                                            <CustomButton
                                                className="btn mr-10"
                                                type="button"
                                                onClick={() =>
                                                    this.previewImage(e.path)
                                                }
                                            >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <ShowPasswordIcon
                                                        width={18}
                                                        className="eyeIcon"
                                                    ></ShowPasswordIcon>
                                                </div>
                                            </CustomButton>
                                            <CustomButton
                                                className="btn"
                                                type="button"
                                                onClick={() =>
                                                    this.deletePhoto(index)
                                                }
                                            >
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <TrashCanIcon
                                                        width={18}
                                                        className="trashIcon"
                                                    ></TrashCanIcon>
                                                </div>
                                            </CustomButton>
                                        </div>
                                    </div>
                                )
                            );
                        })}
                    </div>
                    <Modal
                        modalName="fileUploadModal"
                        toggleModal={this.toggleModal}
                        className={this.state.fileUploadModal ? "visible" : ""}
                        modalWrapperClasses="fileUploadModal"
                    >
                        {!this.state.uploading && (
                            <div className="pa-20 h-100-perc">
                                <div
                                    onDrop={(e) =>
                                        this.handleFileChange(
                                            "car_image",
                                            e,
                                            true
                                        )
                                    }
                                    className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                        this.state.isOver ? "dotted" : ""
                                    }`}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        this.changeBorderType(true);
                                    }}
                                    onDragLeave={() =>
                                        this.changeBorderType(false)
                                    }
                                >
                                    <div className="text d-flex flex-column justify-content-center align-items-center">
                                        <span className="d-block mb-10">
                                            <UploadIcon
                                                width={40}
                                                className="uploadIcon"
                                            />
                                        </span>
                                        <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                            <Translate text="DRAG_AND_DROP" />
                                        </span>
                                        <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">
                                            <Translate text="OR" />
                                        </span>
                                    </div>
                                    <CustomFileInput
                                        name="car_image"
                                        handleInput={this.handleFileChange}
                                        value={this.state.car_image.value}
                                        label={"CHOOSE_PHOTO"}
                                    />
                                </div>
                            </div>
                        )}

                        {this.state.uploading && (
                            <div className="progress pa-20 h-100perc text-center">
                                <span className="f-s-20 mb-20">
                                    <Translate text="UPLOAD_IN_PROGRESS" />
                                </span>
                                <span className="wrapper d-block">
                                    <Loader width={200} />
                                    <span className="loader-numbers">
                                        {this.state.completed}%
                                    </span>
                                </span>
                            </div>
                        )}
                    </Modal>
                    <Modal
                        modalName="imagePreviewModal"
                        toggleModal={this.toggleModal}
                        className={
                            this.state.imagePreviewModal ? "visible" : ""
                        }
                        modalWrapperClasses="d-flex justify-content-center align-items-center h-80-perc w-70-perc"
                    >
                        <img
                            className="cover w-100-perc h-100-perc"
                            src={`https://rentapi.oykos.dev/storage/${this.state.previewImage}`}
                            alt=""
                        ></img>
                    </Modal>
                </div>
            );
        }
    }
}

export default CarPhotos;
