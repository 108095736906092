import React from "react";
import CustomForm from "../../../../devkit/Form/CustomForm/CustomForm";
import CustomDropdown from "../../../../devkit/Form/CustomDropdown/CustomDropdown";
import CustomInput from "../../../../devkit/Form/CustomInput/CustomInput";
import { CustomTextArea } from "devkit/Form/Form";
const VehicleInformation = (props) => {
    const {
        contract,
        vehicleInfo,
        categoryOptions,
        carBrandOptions,
        carModelOptions,
        carPlatesOptions,
        handleDropdown,
        handleInput,
        platesDisabled,
    } = props;
    return (
        <div>
            <CustomForm>
                <div className="mb-29">
                    <CustomDropdown
                        data={categoryOptions}
                        label="CHOOSE_VEHICLE_GROUP"
                        name="categoryOptions"
                        handleChange={handleDropdown}
                        value={vehicleInfo.car_category_id}
                        errors={
                            !contract ? vehicleInfo.car_category_id.errors : []
                        }
                    />
                </div>

                <div className="d-flex justify-content-between mb-29">
                    <div className="col-6 pl-0">
                        <CustomDropdown
                            data={carBrandOptions}
                            label="CHOOSE_VEHICLE_COMPANY"
                            name="brand"
                            handleChange={handleDropdown}
                            disabled={!vehicleInfo.car_category_id.value}
                            value={vehicleInfo.carBrand}
                        />
                    </div>
                    <div className="col-6 pr-0">
                        <CustomDropdown
                            data={carModelOptions}
                            label="CHOOSE_VEHICLE_MODEL"
                            name="model"
                            handleChange={handleDropdown}
                            value={vehicleInfo.carModel}
                            disabled={!vehicleInfo.car_category_id.value}
                        />
                    </div>
                </div>
                <div className="mb-29">
                    <CustomDropdown
                        data={carPlatesOptions}
                        label="CHOOSE_REGISTRATION_PLATE"
                        name="plates"
                        handleChange={handleDropdown}
                        value={vehicleInfo.carPlates}
                        errors={vehicleInfo.car_id.errors}
                        disabled={platesDisabled}
                    />
                </div>
                <div className="d-flex justify-content-between mb-29">
                    <div className="col-6 pl-0">
                        <CustomInput
                            name="fuel_amount"
                            label="FUEL"
                            handleInput={handleInput}
                            type="text"
                            value={vehicleInfo.fuel_amount}
                        />
                    </div>
                    <div className="col-6 pr-0">
                        <CustomInput
                            name="mileage"
                            label="KILOMETERS"
                            handleInput={handleInput}
                            type="text"
                            value={vehicleInfo.mileage}
                        />
                    </div>
                </div>
                <div>
                    <CustomTextArea
                        name="car_remark"
                        label="REMINDER"
                        handleInput={handleInput}
                        value={vehicleInfo.car_remark.value}
                    />
                </div>
            </CustomForm>
        </div>
    );
};

export default VehicleInformation;
