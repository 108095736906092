import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AdditionalEquipment from "./AdditionalEquipment/AdditionalEquipment";
import AdditionalEquipmentDetails from "./AdditionalEquipment/AdditionalEquipmentDetails";
import CarDetails from "./Cars/CarDetails/CarDetails";
import Cars from "./Cars/CarsOverview";
import Categories from "./Categories/Categories";
import CategoryDetails from "./Categories/CategoryDetails/CategoryDetails";
import CategoryCars from "./Categories/CategoryDetails/CategoryCars";
import Companies from "pages/Companies/Companies";
import CompanyDetails from "pages/Companies/CompanyDetails";
import ReservationDetails from "./ReservationsAndContracts/ReservationDetails/ReservationDetails";
import ReservationsContracts from "pages/ReservationsAndContracts/ReservationsContracts";
import ManagerDetails from "./Managers/ManagerDetails";
import Managers from "./Managers/Managers";
import PersonalAccount from "./PersonalAccount/PersonalAccount";
import Clients from "./Clients/Clients";
import ClientsDetails from "./Clients/ClientsDetails";
import NewPassword from "./Login/NewPassword";
import Pin from "./Pin/Pin";
import React from "react";
import ResetPassword from "./Login/ResetPassword";
import Sidebar from "../components/Sidebar/Sidebar";
import { StoreService } from "../services/store.service";
import { UtilService } from "../services/util.service";
import i18next from "i18next";
import Header from "components/Header/Header";
import Login from "./Login/Login";
import ContractCalendar from "./ReservationsAndContracts/Calendar/ContractCalendar";
import CarsAvailability from "./Cars/CarsAvailability";

class App extends React.Component<any, any> {
    constructor(props) {
        super(props);
        StoreService.initialize();
    }

    state = {
        isLoggedIn: false,
        isPinValid: false,
        token: "",
        user: {},
        lang: localStorage.getItem("defaultLang"),
        language: true,
        notifications: [],
    };

    toggleLanguage = () => {
        const lang = localStorage.getItem("defaultLang");
        if (lang === "en") {
            i18next.changeLanguage("me");
            localStorage.setItem("defaultLang", "me");
            this.setState({ lang: "me", language: false });
        } else {
            i18next.changeLanguage("en");
            localStorage.setItem("defaultLang", "en");
            this.setState({ lang: "en", language: true });
        }
    };

    handlePinChange = (isPinValid) => {
        this.setState({ isPinValid });
    };

    handleLogin = (isLoggedIn, token, user, notifications) => {
        this.setState(
            {
                isLoggedIn,
                token,
                user,
                isPinValid: true,
                notifications,
            },
            () => {
                StoreService.updateStoreProperty("loginSuccess", true);
                StoreService.updateStoreProperty("user", this.state.user);
                StoreService.updateStoreProperty("token", this.state.token);
                StoreService.updateStoreProperty("isPinValid", new Date());
                StoreService.updateStoreProperty(
                    "notifications",
                    notifications
                );
            }
        );
    };

    handleThrottledEvent = () => {
        const isPinValid = this.checkIfPinValid();
        UtilService.handleThrottle(
            "allEvents",
            () => {
                if (isPinValid) {
                    StoreService.updateStoreProperty("isPinValid", new Date());
                } else {
                    this.setState({ isPinValid: false });
                }
            },
            30000
        );
    };

    checkIfLogged = () => {
        setInterval(() => {
            if (!this.checkIfPinValid()) {
                this.setState({ isPinValid: false });
            }
        }, 60000);
    };

    listenToAllUserEvents = () => {
        Object.keys(window).forEach((key) => {
            if (/^on(key|mouse)/.test(key)) {
                window.addEventListener(
                    key.slice(2),
                    this.handleThrottledEvent
                );
            }
        });
    };

    checkIfPinValid = () => {
        try {
            const pin = StoreService.getStoreProperty("isPinValid");
            if (!pin) {
                return false;
            }
            const pinTime: any = new Date(pin);
            const currTime: any = new Date();
            const difMS = currTime - pinTime;
            const diffMins = Math.round(((difMS % 86400000) % 3600000) / 60000);
            return diffMins <= 10;
        } catch (e) {}
    };

    componentDidMount() {
        this.listenToAllUserEvents();
        this.checkIfLogged();
        StoreService.hookOnStoreUpdate("updateStoreProperty", (e) => {
            if (!e.loginSuccess) {
                this.setState({ isLoggedIn: false });
            }
            if (!e.isPinValid) {
                this.setState({ isPinValid: false });
            }
        });
        const isLoggedIn = StoreService.getStoreProperty("loginSuccess");
        const notifications = StoreService.getStoreProperty("notifications");
        //const user = StoreService.getStoreProperty("user");
        const token = StoreService.getStoreProperty("token");
        this.setState({ isLoggedIn, token, notifications });
        const isPinValid = this.checkIfPinValid();
        if (isPinValid) {
            this.setState({
                isPinValid: true,
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.isPinValid !== this.state.isPinValid &&
            this.state.isPinValid
        ) {
            StoreService.updateStoreProperty("isPinValid", new Date());
        }
    }

    logout = () => {
        StoreService.clearStoreData();
        this.setState({
            isLoggedIn: false,
            isPinValid: false,
        });
    };

    lockApp = () => {
        StoreService.updateStoreProperty("isPinValid", false);
    };

    render() {
        //const user = StoreService.getStoreProperty("user");
        const isLoggedIn = StoreService.getStoreProperty("loginSuccess");
        const isPinValid = this.checkIfPinValid();
        if (!isLoggedIn) {
            return (
                <BrowserRouter>
                    <main>
                        <Switch>
                            {/* <Route exact path="/">
                                <Login handleLogin={this.handleLogin} />
                            </Route> */}
                            <Route
                                exact
                                path="/"
                                render={(props) => (
                                    <Login
                                        handleLogin={this.handleLogin}
                                        {...props}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/reset-password"
                                render={(props) => <ResetPassword {...props} />}
                            />
                            <Route
                                exact
                                path="/newPassword/:token"
                                render={(props) => <NewPassword {...props} />}
                            />

                            <Redirect from="/*" to="/" />
                        </Switch>
                    </main>
                </BrowserRouter>
            );
        } else if (isLoggedIn && !isPinValid) {
            return (
                <BrowserRouter>
                    <main>
                        <Switch>
                            <Route path="/pin">
                                <Pin
                                    handlePinChange={this.handlePinChange}
                                    token={this.state.token}
                                    logout={this.logout}
                                />
                            </Route>
                            <Redirect from="/*" to="/pin" />
                        </Switch>
                    </main>
                </BrowserRouter>
            );
        } else {
            return (
                <BrowserRouter>
                    <Sidebar />
                    <Header
                        breadcrumbs=""
                        lockApp={this.lockApp}
                        logout={this.logout}
                        language={this.toggleLanguage}
                        lang={this.state.lang}
                        notifications={this.state.notifications}
                    />
                    <main className="app">
                        <section className="content">
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={(props) => {
                                        return (
                                            <ReservationsContracts {...props} />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/reservations/:pageNo"
                                    render={(props) => {
                                        return (
                                            <ReservationsContracts
                                                {...props}
                                                reservations={true}
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/contracts/:pageNo"
                                    render={(props) => {
                                        return (
                                            <ReservationsContracts
                                                {...props}
                                                contracts={true}
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/new-reservation"
                                    render={(props) => {
                                        return (
                                            <ReservationDetails {...props} />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/new-contract"
                                    render={(props) => {
                                        return (
                                            <ReservationDetails
                                                {...props}
                                                contract
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/reservation-details/:id?"
                                    render={(props) => {
                                        return (
                                            <ReservationDetails
                                                {...props}
                                                reservations
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/contract-details/:id?"
                                    render={(props) => {
                                        return (
                                            <ReservationDetails
                                                {...props}
                                                contract
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/contract-calendar"
                                    render={(props) => {
                                        return <ContractCalendar {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/managers/:pageNo"
                                    render={(props) => {
                                        return <Managers {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/new-manager"
                                    render={(props) => {
                                        return <ManagerDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/managers/editManager/:id"
                                    render={(props) => {
                                        return <ManagerDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/groups/:pageNo"
                                    render={(props) => (
                                        <Categories {...props} />
                                    )}
                                />
                                //{" "}
                                <Route
                                    exact
                                    path="/new-group"
                                    render={(props) => {
                                        return <CategoryDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/group/:id/categoryCars/:pageNo"
                                    render={(props) => {
                                        return (
                                            <CategoryCars
                                                {...props}
                                                cars={true}
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/group/:id/basicInfo"
                                    render={(props) => {
                                        return (
                                            <CategoryDetails
                                                {...props}
                                                cars={false}
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/equipment/:pageNo"
                                    render={(props) => {
                                        return (
                                            <AdditionalEquipment {...props} />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/equipmentDetails/:id"
                                    render={(props) => {
                                        return (
                                            <AdditionalEquipmentDetails
                                                {...props}
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/new-equipment"
                                    render={(props) => {
                                        return (
                                            <AdditionalEquipmentDetails
                                                {...props}
                                            />
                                        );
                                    }}
                                />
                                <Route
                                    exact
                                    path="/personal-account"
                                    render={(props) => (
                                        <PersonalAccount {...props} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/companies/:pageNo"
                                    render={(props) => {
                                        return <Companies {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/companies/details/:id/basic-info"
                                    render={(props) => {
                                        return <CompanyDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/companies/details/:id/additional-info"
                                    render={(props) => {
                                        return <CompanyDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/companies/details/:id/contracts/:pageNo"
                                    render={(props) => {
                                        return <CompanyDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/new-company/:path"
                                    render={(props) => {
                                        return <CompanyDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/CRPS/:PIB/:path"
                                    render={(props) => {
                                        return <CompanyDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/cars/:pageNo"
                                    render={(props) => <Cars {...props} />}
                                />
                                <Route
                                    exact
                                    path="/cars-availability/:pageNo"
                                    render={(props) => (
                                        <CarsAvailability {...props} />
                                    )}
                                />
                                <Route
                                    exact
                                    path="/car/:id/:path"
                                    render={(props) => {
                                        return <CarDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/car/:id/contracts/:pageNo"
                                    render={(props) => {
                                        return <CarDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/new-car/:path"
                                    render={(props) => {
                                        return <CarDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/clients/:pageNo"
                                    render={(props) => {
                                        return <Clients {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/new-client/basic-info"
                                    render={(props) => {
                                        return <ClientsDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/clients/details/:id/basic-info"
                                    render={(props) => {
                                        return <ClientsDetails {...props} />;
                                    }}
                                />
                                <Route
                                    exact
                                    path="/clients/details/:id/contracts/:pageNo"
                                    render={(props) => {
                                        return <ClientsDetails {...props} />;
                                    }}
                                />
                                <Redirect from="/*" to="/" />
                            </Switch>
                        </section>
                    </main>
                </BrowserRouter>
            );
        }
    }
}

export default App;
