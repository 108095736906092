import {
    // CustomDatePicker,
    CustomForm,
    CustomInput,
    CustomDatePicker,
    CustomButton,
} from "devkit/Form/Form";
import React, { Component } from "react";
import Translate from "functions/utilFunctions/translate";

class CompanyAdditionalInfo extends Component<any> {
    render() {
        const {
            full_name,
            PIB,
            change_number,
            mailing_address,
            address2,
            update_date,
            founding_date,
            activity_name,
            id_subject,
            mailing_city,
            city,
            activity_code,
            status,
            capital,
            staff,
        } = this.props.form;

        const CEO =
            staff &&
            staff.find((el) => {
                return el.position === "Izvršni direktor";
            });

        const founders =
            staff &&
            staff.filter((el) => {
                return el.position === "Osnivač";
            });

        const representative =
            staff &&
            staff.find((el) => {
                return el.position === "Ovlašćeni zastupnik";
            });

        return (
            <div
                className="h-100perc mt-30 pl-10"
                style={{ marginLeft: "-30px" }}
            >
                <CustomForm className="w-100perc h-100perc d-flex">
                    <div className="col-xl-8 col-lg-8">
                        <div className="row margin-0">
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="full_name"
                                    label="FULL_NAME"
                                    value={full_name.value}
                                    type="text"
                                    errors={full_name.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="PIB"
                                    label="PIB"
                                    value={PIB.value}
                                    type="number"
                                    errors={PIB.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="change_number"
                                    label="CHANGE_NUMBER"
                                    value={change_number.value}
                                    type="number"
                                    errors={change_number.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>

                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="activity_code"
                                    label="ACTIVITY_CODE"
                                    value={activity_code.value}
                                    type="number"
                                    errors={activity_code.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="activity_name"
                                    label="ACTIVITY_NAME"
                                    value={activity_name.value}
                                    type="text"
                                    errors={activity_name.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="address2"
                                    label="ADDRESS_CRPS"
                                    value={address2.value}
                                    type="text"
                                    errors={address2.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>

                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="city"
                                    label="CITY"
                                    value={city.value}
                                    type="text"
                                    errors={city.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="mailing_address"
                                    label="MAILING_ADRESS"
                                    value={mailing_address.value}
                                    type="text"
                                    errors={mailing_address.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="mailing_city"
                                    label="MAILING_CITY"
                                    value={mailing_city.value}
                                    type="text"
                                    errors={mailing_city.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>

                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="capital"
                                    label="CAPITAL"
                                    value={capital.value}
                                    type="number"
                                    errors={capital.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>
                            {/* <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="website"
                                    label="WEBSITE"
                                    value={website.value}
                                    type="text"
                                    errors={website.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div> */}
                            {/* <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="email"
                                    label="Email"
                                    value={email.value}
                                    type="email"
                                    errors={email.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div> */}

                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="status"
                                    label="STATUS"
                                    value={status.value}
                                    type="text"
                                    errors={status.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomInput
                                    name="id_subject"
                                    label="ID_SUBJECT"
                                    value={id_subject.value}
                                    type="text"
                                    errors={id_subject.errors}
                                    handleInput={this.props.handleInput}
                                />
                            </div>
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomDatePicker
                                    name="founding_date"
                                    label="FOUNDING_DATE"
                                    handleInput={this.props.handleInput}
                                    value={founding_date.value}
                                    errors={founding_date.errors}
                                />
                            </div>
                            <div className="col-lg-6 col-xl-6 mb-30">
                                <CustomDatePicker
                                    name="update_date"
                                    label="UPDATE_DATE"
                                    handleInput={this.props.handleInput}
                                    value={update_date.value}
                                    errors={update_date.errors}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4">
                        <h2 className="f-s-20 company-staff  mb-30 h-45 d-flex align-items-center">
                            <Translate text="COMPANY_STAFF" />:
                        </h2>
                        <div className=" mb-30">
                            <CustomInput
                                name="ceo"
                                label="CEO"
                                value={
                                    CEO
                                        ? `${CEO.first_name} ${CEO.last_name}`
                                        : ""
                                }
                                type="text"
                                errors={[]}
                                handleInput={this.props.handleInput}
                            />
                        </div>

                        <div className=" mb-30">
                            <CustomInput
                                name="representative"
                                label="REPRESENTATIVE"
                                value={
                                    representative
                                        ? `${representative.first_name} ${representative.last_name}`
                                        : ""
                                }
                                type="text"
                                errors={[]}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div
                            className="mb-30 border-1 min-h-45 w-100-perc border-radius-4 d-flex flex-column foundersList"
                            style={{ borderColor: "#43425d" }}
                        >
                            <span className="f-s-14 p-a foundersListLabel">
                                <Translate text="FOUNDERS" />
                            </span>
                            <div className="mt-14">
                                {founders &&
                                    founders[0] &&
                                    founders.map((el) => {
                                        return (
                                            <div className="mb-14">{`${el.first_name} ${el.last_name}`}</div>
                                        );
                                    })}
                            </div>
                        </div>

                        <div className="mt-10">
                            <CustomButton
                                type="button"
                                onClick={this.props.updateCRPS}
                                className="h-45"
                            >
                                <Translate text="UPDATE_CRPS" />
                            </CustomButton>
                        </div>
                    </div>
                </CustomForm>
            </div>
        );
    }
}

export default CompanyAdditionalInfo;
