import React, { Component } from "react";
import {
    CustomButton,
    CustomForm,
    CustomFileInput,
    CustomInput,
} from "devkit/Form/Form";
import { StoreService } from "services/store.service";
import PersonalAccountAfter from "pages/PersonalAccount/PersonalAccountAfter/PersonalAccountAfter";
import PersonalAccountLeftSide from "./PersonalAccountLeftSide/PersonalAccountLeftSide";
import Modal from "devkit/Modal/Modal";
import Translate from "functions/utilFunctions/translate";
import ApiService from "services/api.service";
import { ModalContent } from "devkit/Modal/ModalContent";
import { Loader } from "devkit/Loader/Loader";
import { validateEmail } from "functions/utilFunctions/validations";
import {
    parseDateForCar,
    parseDateForMySQL,
} from "functions/utilFunctions/parsingFunctions";
import { UtilService } from "services/util.service";
import { UploadIcon } from "components/Icons/Icons";
import PrivateFiles from "./PrivateFiles";
class PersonalAccount extends Component<any> {
    state = {
        handleChange: false,
        form: {
            id: {
                value: "",
                errors: [],
            },
            first_name: {
                value: "",
                errors: [],
            },
            last_name: {
                value: "",
                errors: [],
            },
            email: {
                value: "",
                errors: [],
            },
            phone: {
                value: "",
                errors: [],
            },
            date_of_birth: {
                value: null,
                errors: [],
            },
            date_of_employment: {
                value: null,
                errors: [],
            },
            old_password: {
                value: "",
                errors: [],
            },
            new_password: {
                value: "",
                errors: [],
            },
            repeat_new_password: {
                value: "",
                errors: [],
            },
            old_pin: {
                value: "",
                errors: [],
            },
            new_pin: {
                value: "",
                errors: [],
            },
            repeat_new_pin: {
                value: "",
                errors: [],
            },
            role: {
                value: null,
                errors: [],
            },
            profile_image: {
                value: null,
                errors: [],
            },
            updated_at: {
                value: "",
                errors: [],
            },
            email_verified_at: {
                value: "",
                errors: [],
            },
            created_at: {
                value: "",
                errors: [],
            },
            private_files: [
                { path: "", id: null, updated_at: null, created_at: null },
            ],
            public_files: [
                { path: "", id: null, updated_at: null, created_at: null },
            ],
        },
        profile_image: {
            value: null,
            errors: [],
        },
        public_file: {
            value: null,
        },
        private_file: {
            value: null,
        },
        private_files: [
            { path: "", id: null, updated_at: null, created_at: null },
        ],
        public_files: [
            { path: "", id: null, updated_at: null, created_at: null },
        ],
        uploading: false,
        changeType: "",
        showPasswordModal: false,
        showSuccessModal: false,
        showSuccessModalData: false,
        showFailedModal: false,
        fileUploadErrorModal: false,
        showPinModal: false,
        modalSuccessText: "",
        modalFailedText: "",
        showSameValue: false,
        completed: 0,
        isOver: false,
        imageUploadModal: false,
        fileUploadModal: false,
        photoUploadModalFailure: false,
        allFilesModal: false,
        isLoading: true,
        currentTab: "personalAccount",
    };

    componentDidMount() {
        const user = StoreService.getStoreProperty("user");
        const token = StoreService.getStoreProperty("token");
        const userFormated = {};
        ApiService.getUser(user.id, token).then((response) => {
            this.setState({
                private_files: response.data.private_files,
                public_files: response.data.public_files,
                isLoading: false,
            });
        });
        Object.keys(user).forEach((e) => {
            if (e === "date_of_birth" || e === "date_of_employment") {
                userFormated[e] = {
                    value: new Date(user[e]),
                    errors: [],
                };
            } else {
                userFormated[e] = {
                    value: user[e],
                    errors: [],
                };
            }
        });
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                ...userFormated,
            },
        }));
    }
    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.profile_image.value !== this.state.profile_image.value &&
            this.state.profile_image.value
        ) {
            this.setState({ uploading: true });
            this.handleUploadImage();
        }
        if (
            prevState.private_file.value !== this.state.private_file.value &&
            this.state.private_file.value
        ) {
            this.setState({ uploading: true });
            this.handleAddDocument();
        }
        if (
            prevState.public_file.value !== this.state.public_file.value &&
            this.state.public_file.value
        ) {
            this.setState({ uploading: true });
            this.handleAddDocument();
        }
    }
    handleDataChange = (e) => {
        e.preventDefault();

        const { changeType } = this.state;
        let passwords;
        let resetFields;
        let oldItem;
        let newItem;
        let repeatNewItem;

        if (changeType === "password") {
            oldItem = this.state.form.old_password;
            newItem = this.state.form.new_password;
            repeatNewItem = this.state.form.repeat_new_password;
            passwords = {
                old_password: oldItem,
                new_password: newItem,
                repeat_new_password: repeatNewItem,
            };

            resetFields = {
                new_password: {
                    value: "",
                    errors: [],
                },
                old_password: {
                    value: "",
                    errors: [],
                },
                repeat_new_password: {
                    value: "",
                    errors: [],
                },
            };
        } else {
            oldItem = this.state.form.old_pin;
            newItem = this.state.form.new_pin;
            repeatNewItem = this.state.form.repeat_new_pin;

            passwords = {
                old_pin: oldItem,
                new_pin: newItem,
                repeat_new_pin: repeatNewItem,
            };
            resetFields = {
                old_pin: {
                    value: "",
                    errors: [],
                },
                new_pin: {
                    value: "",
                    errors: [],
                },
                repeat_new_pin: {
                    value: "",
                    errors: [],
                },
            };
        }

        const errors = {};
        Object.keys(passwords).forEach((e) => {
            if (!passwords[e].value) {
                errors[e] = {
                    value: passwords[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            } else if (
                changeType === "password" &&
                e !== "old_password" &&
                passwords[e].value.length < 6
            ) {
                errors[e] = {
                    value: passwords[e].value,
                    errors: ["PASSWORD_LENGTH_NOT_VALID"],
                };
            } else if (
                changeType === "password" &&
                newItem.value !== repeatNewItem.value
            ) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        new_password: {
                            value: prevState.form.new_password.value,
                            errors: ["PSW_DONT_MATCH"],
                        },

                        repeat_new_password: {
                            value: prevState.form.new_password.value,
                            errors: ["PSW_DONT_MATCH"],
                        },
                    },
                }));
            } else if (
                changeType !== "password" &&
                passwords[e].value.length !== 4
            ) {
                errors[e] = {
                    value: passwords[e].value,

                    errors: ["PIN_LENGTH_NOT_VALID"],
                };
            } else if (
                changeType !== "password" &&
                newItem.value !== repeatNewItem.value
            ) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        new_pin: {
                            value: passwords[e].value,
                            errors: ["PIN_DONT_MATCH"],
                        },
                        repeat_new_pin: {
                            value: prevState.form.new_password.value,
                            errors: ["PIN_DONT_MATCH"],
                        },
                    },
                }));
            }
        });

        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else {
            if (newItem.value !== repeatNewItem.value) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                    },
                }));
            } else {
                let data;
                let url;
                if (changeType === "password") {
                    data = {
                        old_password: oldItem.value,
                        new_password: newItem.value,
                    };
                    url = "users/updatePassword";
                } else {
                    data = { old_pin: oldItem.value, new_pin: newItem.value };
                    url = "users/updatePin";
                }
                const token = StoreService.getStoreProperty("token");

                ApiService.updateData(data, token, url).then((response) => {
                    if (response.success === true) {
                        this.setState((prevState: any) => ({
                            showPasswordModal: false,
                            showPinModal: false,
                            showSuccessModal: true,
                            form: {
                                ...prevState.form,
                                ...resetFields,
                            },
                        }));
                    } else if (
                        (response.success === false &&
                            response.message === "Old pin is not correct") ||
                        response.message === "Old password is not correct"
                    ) {
                        this.setState((prevState: any) => ({
                            showFailedModal: true,
                            form: {
                                ...prevState.form,
                                ...resetFields,
                            },
                        }));
                    } else {
                        this.setState(() => ({
                            showSameValue: true,
                        }));
                    }
                });
            }
        }
    };
    handleChange = () => {
        this.setState((prevState: any) => ({
            handleChange: !prevState.handleChange,
        }));
    };
    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };
    renderInputsModal = (type) => {
        const condition = type === "password";
        const modalName = condition ? "showPasswordModal" : "showPinModal";
        const translate = condition ? "CHANGE_PASSWORD" : "CHANGE_PIN";
        const old_item = condition ? "old_password" : "old_pin";
        const new_item = condition ? "new_password" : "new_pin";
        const repeat_new_item = condition
            ? "repeat_new_password"
            : "repeat_new_pin";
        return (
            <Modal
                toggleModal={this.toggleModal}
                modalName={modalName}
                className={this.state[modalName] ? "visible" : ""}
                modalWrapperClasses="w-400 h-400 padding-10"
            >
                <div className="col-10 offset-1 h-100perc padding-0">
                    <div className="h-60 col-12 d-flex row justify-content-between padding-0 margin-l-0">
                        <p className="d-flex align-self-center password-title">
                            <Translate text={translate} />
                        </p>
                        <i
                            className="fas fa-times fa-2x align-self-center pointer"
                            style={{ color: "#43425D" }}
                            onClick={() => this.toggleModal(modalName)}
                        />
                    </div>
                    <div className="col-12 padding-0 h-250">
                        <CustomForm className="d-flex col-12 h-100perc flex-column justify-content-around padding-0">
                            <CustomInput
                                name={old_item}
                                label={old_item}
                                type="password"
                                handleInput={this.handleInput}
                                value={this.state.form[old_item].value}
                                errors={this.state.form[old_item].errors}
                                maxLength={condition ? 20 : 4}
                                required={true}
                            />
                            <CustomInput
                                name={new_item}
                                label={new_item}
                                type="password"
                                handleInput={this.handleInput}
                                value={this.state.form[new_item].value}
                                errors={this.state.form[new_item].errors}
                                maxLength={condition ? 20 : 4}
                                required={true}
                            />
                            <CustomInput
                                name={repeat_new_item}
                                label={repeat_new_item}
                                type="password"
                                handleInput={this.handleInput}
                                value={this.state.form[repeat_new_item].value}
                                errors={this.state.form[repeat_new_item].errors}
                                maxLength={condition ? 20 : 4}
                                required={true}
                            />
                        </CustomForm>
                    </div>
                    <div className="d-flex justify-content-center mt-10">
                        <CustomButton
                            className="btn-save"
                            type="button"
                            onClick={this.handleDataChange}
                        >
                            <Translate text="SAVE" />
                        </CustomButton>
                    </div>
                </div>
            </Modal>
        );
    };
    toggleModal = (name) => {
        if (this.state.uploading) {
            return;
        }
        let resetFields;
        let changeType = "";
        if (name === "showPasswordModal") {
            changeType = "password";
        } else if (name === "showPinModal") {
            changeType = "pin";
        }
        resetFields = {
            new_password: {
                value: "",
                errors: [],
            },
            old_password: {
                value: "",
                errors: [],
            },
            repeat_new_password: {
                value: "",
                errors: [],
            },
            old_pin: {
                value: "",
                errors: [],
            },
            new_pin: {
                value: "",
                errors: [],
            },
            repeat_new_pin: {
                value: "",
                errors: [],
            },
        };
        this.setState((prevState: any) => ({
            [name]: !this.state[name],
            modalSuccessText: name,
            modalFailedText: name,
            passwordsDontMatch: name,
            changeType,
            form: {
                ...prevState.form,
                ...resetFields,
            },
        }));
    };
    handleSubmit = (e) => {
        e.preventDefault();
        const {
            phone,
            email,
            date_of_birth,
            date_of_employment,
            first_name,
            last_name,
            role,
            id,
            created_at,
        } = this.state.form;
        const form: any = {
            email,
            date_of_birth,
            date_of_employment,
            first_name,
            last_name,
            role,
            phone,
        };
        let currentUser = StoreService.getStoreProperty("user");
        const token = StoreService.getStoreProperty("token");
        currentUser.date_of_birth = parseDateForCar(currentUser.date_of_birth);
        currentUser.date_of_employment = parseDateForCar(
            currentUser.date_of_employment
        );
        let userFormated = currentUser;
        delete userFormated.profile_image;
        delete userFormated.email_verified_at;
        delete userFormated.remark;
        delete userFormated.updated_at;
        const changedUser = {
            phone: phone.value,
            email: email.value,
            date_of_birth: parseDateForCar(date_of_birth.value),
            date_of_employment: parseDateForCar(date_of_employment.value),
            first_name: first_name.value,
            last_name: last_name.value,
            role: role.value,
            id: id.value,
            created_at: created_at.value,
        };

        const errors: any = {};
        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
            if (e === "email" && !validateEmail(form[e].value)) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["EMAIL_INVALID"],
                };
            }
        });

        const equal = UtilService.areObjectsEqual(userFormated, changedUser);
        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                showInputsRequired: true,
                form: {
                    ...prevState.form,
                    ...errors,
                },
            }));
        } else if (equal) {
            return;
        } else {
            ApiService.updatePersonalAccount(
                {
                    id: id.value,
                    phone: phone.value,
                    email: email.value,
                    date_of_birth: parseDateForMySQL(date_of_birth.value),
                    date_of_employment: parseDateForMySQL(
                        date_of_employment.value
                    ),
                    first_name: first_name.value,
                    last_name: last_name.value,
                    role: role.value,
                },
                token
            ).then((response) => {
                if (response.success === true) {
                    StoreService.updateStoreProperty("user", response.data);
                    this.setState({
                        handleChange: false,
                        showSuccessModalData: true,
                    });
                } else {
                    this.setState({
                        showFailedModal: true,
                    });
                }
            });
        }
    };

    handleUploadImage = () => {
        const id = StoreService.getStoreProperty("user").id;
        const token = StoreService.getStoreProperty("token");
        const profile_image = this.state.profile_image.value || "";
        const formData = new FormData();
        formData.append("profile_image", profile_image);
        formData.append("id", id);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadUserImage(formData, token, config).then((res) => {
            if (res.success === true) {
                this.setState((prevState: any) => ({
                    form: {
                        ...prevState.form,
                        profile_image: {
                            value: res.data.profile_image,
                            errors: [],
                        },
                    },
                    uploadComplete: false,
                    imageUploadModal: false,
                    uploading: false,
                    profile_image: {
                        value: null,
                        errors: [],
                    },
                }));
                let user = StoreService.getStoreProperty("user");
                user = {
                    ...user,
                    profile_image: res.data.profile_image,
                };
                StoreService.updateStoreProperty("user", user);
            } else {
                this.setState({
                    photoUploadModalFailure: true,
                });
            }
        });
    };
    handleAddDocument = () => {
        const { currentTab } = this.state;
        const condition = currentTab === "private_files";
        const file = condition ? "private_file" : "public_file";
        const files = condition ? "private_files" : "public_files";
        const document: any = this.state[file].value;
        const token = StoreService.getStoreProperty("token");
        const formData = new FormData();
        const file_type = condition ? "2" : "1";
        formData.append("file", document);
        formData.append("type", "user");
        formData.append("file_type", file_type);
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadFile(formData, token, config).then((res) => {
            if (res.success) {
                this.setState((prevState: any) => ({
                    [files]: [...prevState[files], res.data],
                    completed: 0,
                    uploadComplete: false,
                    uploading: false,
                    fileUploadModal: false,
                    [file]: {
                        value: null,
                        errors: [],
                    },
                    form: {
                        ...prevState.form,
                        [files]: [...prevState.form[files], res.data],
                    },
                }));
            } else {
                this.setState({
                    fileUploadErrorModal: true,
                    [file]: {
                        value: null,
                        errors: [],
                    },
                });
            }
        });
    };
    deleteFile = (index) => {
        const token = StoreService.getStoreProperty("token");
        const { currentTab } = this.state;
        const condition = currentTab === "private_files";
        const files = condition ? "private_files" : "public_files";
        ApiService.deleteFile(
            {
                id: this.state[files][index].id,
            },
            token
        ).then((response) => {
            if (response.success) {
                const documents = this.state[files].filter(
                    (e, i) => i !== index
                );
                this.setState({
                    [files]: documents,
                });
            }
        });
    };

    handleFileChange = (name, e, drop?) => {
        this.setState({
            [name]: UtilService.handleAddFile(name, e, drop),
        });
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };
    handleTabs = (name) => {
        this.setState({ currentTab: name });
    };
    renderTabs = () => {
        const { currentTab } = this.state;
        return (
            <div className="d-flex">
                <div onClick={() => this.handleTabs("personalAccount")}>
                    <span
                        className={`${
                            currentTab === "personalAccount" && "active"
                        } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                    >
                        <Translate text="PERSONAL_ACCOUNT" />
                    </span>
                </div>
                <div onClick={() => this.handleTabs("private_files")}>
                    <span
                        className={`${
                            currentTab === "private_files" && "active"
                        } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                    >
                        <Translate text="PRIVATE_DOCUMENTS" />
                    </span>
                </div>
                <div onClick={() => this.handleTabs("public_files")}>
                    <span
                        className={`${
                            currentTab === "public_files" && "active"
                        } reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none`}
                    >
                        <Translate text="PUBLIC_DOCUMENTS" />
                    </span>
                </div>
            </div>
        );
    };
    render() {
        const { currentTab } = this.state;
        const condition = currentTab === "private_files";
        if (this.state.isLoading) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div className="d-flex flex-column  justify-content-center align-items-center h-100-perc">
                    <div className="d-flex w-100-perc border-b-1 mb-10">
                        <span>{this.renderTabs()}</span>
                    </div>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showSuccessModal"
                        className={this.state.showSuccessModal ? "visible" : ""}
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="success"
                            title="SUCCESSFULLY_CHANGED"
                            text={
                                this.state.modalSuccessText ===
                                "showPasswordModal"
                                    ? "PSW_SUCCESS"
                                    : "PIN_SUCCESS"
                            }
                            modalName="showSuccessModal"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showFailedModal"
                        className={
                            this.state.showFailedModal
                                ? "visible error"
                                : "error"
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            title="FAILED"
                            text={
                                this.state.modalFailedText ===
                                "showPasswordModal"
                                    ? "OLD_PSW_WRONG"
                                    : "OLD_PIN_WRONG"
                            }
                            modalName="showFailedModal"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="fileUploadErrorModal"
                        className={
                            this.state.fileUploadErrorModal
                                ? "visible bg-info-3"
                                : ""
                        }
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text="FILE_UPLOAD_ERROR"
                            modalName="fileUploadErrorModal"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showSameValue"
                        className={
                            this.state.showSameValue ? "visible error" : "error"
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            title="FAILED"
                            text={
                                this.state.modalFailedText ===
                                "showPasswordModal"
                                    ? "SAME_VALUE_PSW"
                                    : "SAME_VALUE_PIN"
                            }
                            modalName="showFailedModal"
                            toggleModal={() =>
                                this.toggleModal("showSameValue")
                            }
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showSuccessModalData"
                        className={
                            this.state.showSuccessModalData ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="success"
                            title="SUCCESSFULLY_CHANGED"
                            text={"DATA_CHANGE_SUCCESS"}
                            modalName="showSuccessModalData"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        modalName="imageUploadModal"
                        toggleModal={this.toggleModal}
                        className={this.state.imageUploadModal ? "visible" : ""}
                        modalWrapperClasses="fileUploadModal"
                    >
                        {!this.state.uploading && (
                            <div className="pa-20 h-100-perc">
                                <div
                                    onDrop={(e) =>
                                        this.handleFileChange(
                                            "profile_image",
                                            e,
                                            true
                                        )
                                    }
                                    className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                        this.state.isOver ? "dotted" : ""
                                    }`}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        this.changeBorderType(true);
                                    }}
                                    onDragLeave={() =>
                                        this.changeBorderType(false)
                                    }
                                >
                                    <div className="text d-flex flex-column justify-content-center align-items-center">
                                        <span className="d-block mb-10">
                                            <UploadIcon
                                                width={40}
                                                className="uploadIcon"
                                            />
                                        </span>
                                        <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                            <Translate text="DRAG_AND_DROP" />
                                        </span>
                                        <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">
                                            <Translate text="OR" />
                                        </span>
                                    </div>
                                    <CustomFileInput
                                        name="profile_image"
                                        handleInput={this.handleFileChange}
                                        value={this.state.profile_image.value}
                                        label={"CHOOSE_PHOTO"}
                                    />
                                </div>
                            </div>
                        )}

                        {this.state.uploading && (
                            <div className="progress pa-20 h-100perc text-center border-radius-4">
                                <span className="f-s-20 mb-20">
                                    <Translate text="UPLOAD_IN_PROGRESS" />
                                </span>
                                <span className="wrapper d-block">
                                    <Loader width={200} />
                                    <span className="loader-numbers">
                                        {this.state.completed}%
                                    </span>
                                </span>
                            </div>
                        )}
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="photoUploadModalFailure"
                        className={
                            this.state.photoUploadModalFailure
                                ? "visible error"
                                : "error"
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            title="FAILURE"
                            text="profile_image_UPLOAD_FAILURE"
                            modalName="photoUploadModalFailure"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        modalName="fileUploadModal"
                        toggleModal={this.toggleModal}
                        className={this.state.fileUploadModal ? "visible" : ""}
                        modalWrapperClasses="fileUploadModal "
                    >
                        {!this.state.uploading && (
                            <div className="pa-20 h-100-perc">
                                <div
                                    onDrop={(e) =>
                                        this.handleFileChange(
                                            currentTab === "private_files"
                                                ? "private_file"
                                                : "public_file",
                                            e,
                                            true
                                        )
                                    }
                                    className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                        this.state.isOver ? "dotted" : ""
                                    }`}
                                    onDragOver={(e) => {
                                        e.preventDefault();
                                        this.changeBorderType(true);
                                    }}
                                    onDragLeave={() =>
                                        this.changeBorderType(false)
                                    }
                                >
                                    <div className="text d-flex flex-column justify-content-center align-items-center">
                                        <span className="d-block mb-10">
                                            <UploadIcon
                                                width={40}
                                                className="uploadIcon"
                                            />
                                        </span>
                                        <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                            <Translate text="DRAG_AND_DROP" />
                                        </span>
                                        <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">
                                            <Translate text="OR" />
                                        </span>
                                    </div>
                                    <CustomFileInput
                                        name={
                                            currentTab === "private_files"
                                                ? "private_file"
                                                : "public_file"
                                        }
                                        className="mb-10"
                                        handleInput={this.handleFileChange}
                                        value={
                                            currentTab === "private_files"
                                                ? this.state.private_file.value
                                                : this.state.public_file.value
                                        }
                                        label={"CHOOSE_FILE"}
                                    />
                                </div>
                            </div>
                        )}
                        {this.state.uploading && (
                            <div className="progress pa-20 h-100perc text-center border-radius-4">
                                <span className="f-s-20 mb-20">
                                    <Translate text="UPLOAD_IN_PROGRESS" />
                                </span>
                                <span className="wrapper d-block">
                                    <Loader width={200} />
                                    <span className="loader-numbers">
                                        {this.state.completed}%
                                    </span>
                                </span>
                            </div>
                        )}
                    </Modal>

                    {this.renderInputsModal(this.state.changeType)}

                    <div
                        className="d-flex justify-content-center col-12 row backgroundPhoto h-vh-100"
                        style={{
                            backgroundImage:
                                "url(images/PersonalAccount/bckg.png)",
                            backgroundSize: "100% 33%",
                            backgroundPosition: "50% 0%",
                            backgroundRepeat: "no-repeat",
                        }}
                    >
                        {this.state.currentTab === "personalAccount" && (
                            <>
                                <div className="mt-100">
                                    <PersonalAccountLeftSide
                                        image={
                                            this.state.form.profile_image.value
                                        }
                                        toggleModal={this.toggleModal}
                                        first_name={
                                            this.state.form.first_name.value
                                        }
                                        last_name={
                                            this.state.form.last_name.value
                                        }
                                        role={this.state.form.role.value}
                                        data={this.state.form}
                                        handleInput={this.handleInput}
                                    />
                                </div>
                                <div className="personal-preview d-flex flex-column personal-shadow-darker mt-100">
                                    <PersonalAccountAfter
                                        data={this.state.form}
                                        handleInput={this.handleInput}
                                        edit={this.state.handleChange}
                                        handleSubmit={this.handleSubmit}
                                    />
                                </div>
                            </>
                        )}
                        {this.state.currentTab === "private_files" && (
                            <PrivateFiles
                                isPrivate={condition}
                                documents={this.state.private_files}
                                deleteFile={this.deleteFile}
                                toggleModal={this.toggleModal}
                                wrapperClasses="filesContainer"
                            />
                        )}
                        {this.state.currentTab === "public_files" && (
                            <PrivateFiles
                                isPrivate={condition}
                                documents={this.state.public_files}
                                deleteFile={this.deleteFile}
                                toggleModal={this.toggleModal}
                                wrapperClasses="filesContainer"
                            />
                        )}
                    </div>
                </div>
            );
        }
    }
}
export default PersonalAccount;
