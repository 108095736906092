import { LeftArrow } from "../../../components/Icons/Icons";
import React, { Component } from "react";
import {
    parseDate,
    dayDifference,
    parseDateForMySQL,
} from "functions/utilFunctions/parsingFunctions";
import Additionals from "./FormSections/Additionals";
import ApiService from "services/api.service";
import { CustomButton } from "devkit/Form/Form";
import PayingInformation from "./FormSections/PayingInformation";
import { StoreService } from "services/store.service";
import Translate from "../../../functions/utilFunctions/translate";
import UserInformation from "./FormSections/UserInformation";
import VehicleInformation from "./FormSections/VehicleInformation";
import RentInformation from "./FormSections/RentInformation";
import { ConstantsService } from "services/constants.service";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "../../../devkit/Modal/ModalContent";
import { Loader } from "devkit/Loader/Loader";
import SummaryInfo from "components/SummaryInfo/SummaryInfo";
import MenuTab from "components/MenuTab/MenuTab";
import {
    customerInfo,
    rentInfo,
    vehicleInfo,
    companyInfo,
    paymentOptions,
} from "pages/ReservationsAndContracts/StatesAndInterfaces/ReservationForms";
import { FilterOptions } from "functions/utilFunctions/FilterOptions";
import {
    HelperFunctions,
    Validations,
} from "pages/ReservationsAndContracts/ReservationDetails/Functions/ServiceFunctions";

class ReservationDetails extends Component<any> {
    state = {
        editReservation: !!this.props.match.params.id,
        editContract: this.props.contract
            ? !!this.props.match.params.id
            : false,
        contract: this.props.contract,
        customerInfo,
        rentInfo,
        vehicleInfo,
        companyInfo,
        customersOptions: [],
        categoryOptions: [],
        carModelOptions: [],
        carBrandOptions: [],
        carPlatesOptions: [],
        additionalEquipmentOptions: [],
        companyOptions: [],
        paymentOptions,
        allCustomers: [],
        allCategories: [],
        allCars: [],
        allEquipment: [],
        allCompanies: [],
        filteredCars: [],
        filteredCarBrands: [],
        rent_price: 0,
        additional_equipment_total_price: 0,
        total_price: 0,
        filteredCompanies: [],
        equipmentIds: [],
        contractEquipment: [],
        additionalEquipment: [],
        remark_eq: { value: "", errors: [] },
        numberOfRentDays: 0,
        referenceCustomer: {} as any,
        dropdownCustomerIds: { personal_id: "", licence_id: "" },
        id: 0,
        currentTab: "userInformation",
        contractConvert: false,
        showUpdateErrorModal: false,
        showUpdateSuccessModal: false,
        showCreateErrorModal: false,
        showCreateSuccessModal: false,
        createNewContract: false,
        showLoader: true,
        closeContract: false,
        closedContractModal: false,
        showCloseContractModal: false,
        checkbox: this.props.contract ? true : false,
        clicked: false,
        notEnough: false,
        contractOrReservation: {},
        relatedCar: {} as any,
        finished: false,
        fileDownloadModal: false,
        manualInvoiceInput: false,
        id_operator: "",
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        if (this.props.match.params.id) {
            ApiService.getContract(this.props.match.params.id, token).then(
                (response) => {
                    if (response.success) {
                        const contractOrReservation = response.data;

                        ApiService.getAllCustomers(
                            { limit: 1000, offset: 0 },
                            token
                        ).then((response) => {
                            if (response.success) {
                                const allCustomers = response.data;

                                let customersOptions = allCustomers.map(
                                    (e: any) => ({
                                        value: e.id,
                                        label: `${e.first_name} ${e.last_name}`,
                                    })
                                );

                                const { customer } = contractOrReservation;

                                const dropdownCustomer: any = {
                                    value: "",
                                    label: "",
                                };

                                if (!parseInt(customer.unregistered)) {
                                    dropdownCustomer.value =
                                        customer && customer.id
                                            ? customer.id
                                            : 0;

                                    dropdownCustomer.label =
                                        customer && customer.id
                                            ? `${customer.first_name} ${customer.last_name}`
                                            : "";
                                }

                                const filledCustomerInfo =
                                    HelperFunctions.fillCustomerInfo(customer);

                                this.setState({
                                    referenceCustomer: customer && customer,
                                    customersOptions,
                                    allCustomers,
                                    customerInfo: {
                                        ...filledCustomerInfo,
                                        dropdownCustomer,
                                    },
                                    finished: contractOrReservation.finished,
                                    id_operator:
                                        contractOrReservation.id_operator,
                                });
                            }
                        });
                        ApiService.getAllEquipments(
                            { limit: 1000, offset: 0 },
                            token
                        ).then((response) => {
                            if (response.success) {
                                const allEquipment = response.data;
                                const contractEquipment: any =
                                    contractOrReservation.additional_equipment;

                                const equipmentIds = contractEquipment.map(
                                    (eq: any) => {
                                        return eq.id;
                                    }
                                );
                                const additionalEquipmentOptions =
                                    FilterOptions.getAdditionalEquipmentOptions(
                                        allEquipment,
                                        equipmentIds
                                    );

                                this.setState({
                                    equipmentIds,
                                    contractEquipment,
                                    additionalEquipmentOptions,
                                    allEquipment,
                                });
                            }
                        });
                        ApiService.getAllCategories(
                            { limit: 1000, offset: 0 },
                            token
                        ).then((response) => {
                            if (response.success) {
                                const allCategories = response.data;
                                const relatedCategory: any = allCategories.find(
                                    (cat: any) =>
                                        cat.id ===
                                        contractOrReservation.car_category.id
                                );

                                let categoryOptions =
                                    FilterOptions.getCategoryOptions(
                                        allCategories
                                    );

                                const car_category_id =
                                    contractOrReservation.car_category_id;

                                this.setState((prev: any) => ({
                                    ...prev,
                                    categoryOptions,
                                    allCategories,
                                    vehicleInfo: {
                                        ...prev.vehicleInfo,
                                        car_category_id: {
                                            value: car_category_id,
                                            label: relatedCategory.name,
                                        },
                                    },
                                }));
                            }
                        });

                        const {
                            car_category_id,
                            car_manufacturer,
                            car_model,
                            car,
                            car_id,
                            car_remark,
                            rent_start_date,
                            rent_end_date,
                        } = contractOrReservation;

                        ApiService.getDateRangeCars(
                            {
                                offset: 0,
                                limit: 1000,
                                start_date: parseDateForMySQL(
                                    rent_start_date.value
                                ),
                                end_date: parseDateForMySQL(
                                    rent_end_date.value
                                ),
                                in_range: false,
                            },
                            token
                        ).then((res) => {
                            if (res.success) {
                                const availableCars = res.cars;

                                let filteredCars = availableCars.filter(
                                    (car) => car.category_id === car_category_id
                                );

                                const carBrandOptions = Array.from(
                                    new Set(
                                        filteredCars.map((car) => {
                                            return car.manufacturer;
                                        })
                                    )
                                ).map((el) => ({
                                    value: el,
                                    label: el,
                                }));

                                if (car_manufacturer) {
                                    filteredCars = filteredCars.filter(
                                        (car) =>
                                            car.manufacturer ===
                                            car_manufacturer
                                    );
                                }

                                const carModelOptions = Array.from(
                                    new Set(
                                        filteredCars.map((car) => {
                                            return car.model;
                                        })
                                    )
                                ).map((el) => ({
                                    value: el,
                                    label: el,
                                }));

                                if (car_model) {
                                    filteredCars = filteredCars.filter(
                                        (car) => car.model === car_model
                                    );
                                }

                                let carPlatesOptions;

                                if (car_manufacturer || car_model) {
                                    carPlatesOptions = filteredCars.map(
                                        (car) => {
                                            return {
                                                value: car.licence_plate,
                                                label: car.licence_plate,
                                            };
                                        }
                                    );
                                } else {
                                    carPlatesOptions = availableCars.map(
                                        (car) => {
                                            return {
                                                value: car.licence_plate,
                                                label: car.licence_plate,
                                            };
                                        }
                                    );
                                }

                                const fuel_amount = car ? car.fuel_amount : 0;
                                const mileage = car ? car.mileage : 0;

                                const carBrand = car_manufacturer
                                    ? {
                                          value: car_manufacturer,
                                          label: car_manufacturer,
                                      }
                                    : { value: "", label: "Select" };

                                const carModel = car_model
                                    ? {
                                          value: car_model,
                                          label: car_model,
                                      }
                                    : { value: "", label: "Select" };

                                const carPlates = car
                                    ? {
                                          value: car.licence_plate,
                                          label: car.licence_plate,
                                      }
                                    : { value: "", label: "Select" };

                                this.setState((prev: any) => ({
                                    ...prev,
                                    allCars: availableCars,
                                    relatedCar: availableCars.find(
                                        (car) => car.id === car_id
                                    ),
                                    carBrandOptions,
                                    carModelOptions,
                                    carPlatesOptions,
                                    vehicleInfo: {
                                        ...prev.vehicleInfo,
                                        car_id: {
                                            value: contractOrReservation.car_id,
                                            errors: [],
                                        },
                                        carBrand,
                                        carModel,
                                        carPlates,
                                        car_remark: {
                                            value: car_remark ? car_remark : "",
                                            errors: [],
                                        },
                                        fuel_amount,
                                        mileage,
                                    },
                                }));
                            }
                        });
                        ApiService.getAllCompanies(
                            { limit: 1000, offset: 0 },
                            token
                        ).then((response) => {
                            if (response.success) {
                                const allCompanies = response.data;
                                let companyOptions = allCompanies.map(
                                    (company: any) => ({
                                        value: company.id,
                                        label: company.name,
                                    })
                                );
                                const relatedCompany: any = allCompanies.find(
                                    (company: any) =>
                                        company.id ===
                                        contractOrReservation.company_id
                                );

                                const dropdownCompany = {
                                    value: 0,
                                    label: "",
                                };

                                if (relatedCompany) {
                                    dropdownCompany.value =
                                        contractOrReservation.company_id;
                                    dropdownCompany.label = relatedCompany.name;
                                }

                                const filledCompanyInfo =
                                    HelperFunctions.fillCompanyInfo(
                                        relatedCompany
                                    );

                                this.setState({
                                    companyOptions,
                                    allCompanies,
                                    companyInfo: {
                                        ...filledCompanyInfo,
                                        dropdownCompany,
                                    },
                                });
                            }
                        });

                        const filledRentInfo = HelperFunctions.fillRentInfo(
                            contractOrReservation
                        );

                        this.setState({
                            rentInfo: filledRentInfo,
                            additional_equipment_total_price:
                                contractOrReservation.additional_equipment_total_price,
                            rent_price: contractOrReservation.total_price,
                            id: contractOrReservation.id,
                            numberOfRentDays: dayDifference(
                                new Date(contractOrReservation.rent_start_date),
                                new Date(contractOrReservation.rent_end_date)
                            ),
                        });

                        setTimeout(() => {
                            this.setState({
                                showLoader: false,
                            });
                        }, 1000);
                    }
                }
            );
        } else {
            ApiService.getAllCustomers({ limit: 1000, offset: 0 }, token).then(
                (response) => {
                    if (response.success) {
                        const allCustomers = response.data;
                        let customersOptions = allCustomers.map((e: any) => ({
                            value: e.id,
                            label: `${e.first_name} ${e.last_name}`,
                        }));
                        this.setState({
                            customersOptions,
                            allCustomers: allCustomers,
                        });
                    }
                }
            );
            ApiService.getAllCategories({ limit: 1000, offset: 0 }, token).then(
                (response) => {
                    if (response.success) {
                        const allCategories = response.data;
                        let categoryOptions = allCategories.map((e: any) => ({
                            value: e.id,
                            label: e.name,
                        }));
                        this.setState({
                            categoryOptions: categoryOptions,
                            allCategories,
                        });
                    }
                }
            );
            ApiService.getAllCompanies({ limit: 1000, offset: 0 }, token).then(
                (response) => {
                    if (response.success) {
                        const allCompanies = response.data;
                        let companyOptions = allCompanies.map((e: any) => ({
                            value: e.id,
                            label: e.name,
                        }));
                        this.setState({
                            companyOptions,
                            allCompanies,
                        });
                    }
                }
            );
            ApiService.getAllEquipments({ limit: 1000, offset: 0 }, token).then(
                (response) => {
                    if (response.success) {
                        const allEquipment = response.data;
                        let additionalEquipmentOptions = allEquipment.map(
                            (e: any) => ({
                                value: e.id,
                                label: e.name,
                            })
                        );
                        this.setState({
                            additionalEquipmentOptions,
                            allEquipment,
                            showLoader: false,
                        });
                    }
                }
            );
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const token = StoreService.getStoreProperty("token");
        const {
            vehicleInfo: { car_category_id },
            rentInfo: { rent_start_date, rent_end_date },
        } = this.state;

        if (
            prevState.vehicleInfo.car_category_id.value !==
            car_category_id.value
        ) {
            if (rent_start_date && rent_end_date) {
                this.renderSaldoPrice();
            }
        }

        const datesChanged =
            prevState.rentInfo.rent_end_date.value !== rent_end_date.value ||
            prevState.rentInfo.rent_start_date.value !== rent_start_date.value;

        const isFirstUpdate =
            prevState.rentInfo.rent_end_date.value === null &&
            prevState.rentInfo.rent_start_date.value === null;

        if (datesChanged && !isFirstUpdate) {
            if (
                rent_end_date.value &&
                rent_start_date.value &&
                rent_end_date.value! > rent_start_date.value!
            ) {
                ApiService.getDateRangeCars(
                    {
                        offset: 0,
                        limit: 1000,
                        start_date: parseDateForMySQL(rent_start_date.value),
                        end_date: parseDateForMySQL(rent_end_date.value),
                        in_range: false,
                    },
                    token
                ).then((res) => {
                    if (res.success) {
                        const availableCars = res.cars;

                        let filteredCars = res.cars;

                        if (car_category_id.value) {
                            filteredCars = filteredCars.filter(
                                (car) =>
                                    car.category_id === car_category_id.value
                            );
                        }

                        const carBrandOptions = Array.from(
                            new Set(
                                filteredCars.map((car) => {
                                    return car.manufacturer;
                                })
                            )
                        ).map((el) => ({
                            value: el,
                            label: el,
                        }));

                        const carModelOptions = Array.from(
                            new Set(
                                filteredCars.map((car) => {
                                    return car.model;
                                })
                            )
                        ).map((el) => ({
                            value: el,
                            label: el,
                        }));

                        const carPlatesOptions = availableCars.map((car) => {
                            return {
                                value: car.licence_plate,
                                label: car.licence_plate,
                            };
                        });

                        const reset = { value: "", label: "Select" };

                        // }

                        this.setState((prev: any) => ({
                            ...prev,
                            vehicleInfo: {
                                ...prev.vehicleInfo,
                                carBrand: reset,
                                carModel: reset,
                                carPlates: reset,
                                fuel_amount: 0,
                                mileage: 0,
                            },
                            carBrandOptions,
                            carModelOptions,
                            carPlatesOptions,
                            allCars: availableCars,
                        }));
                    }
                });
            }
        }
    }

    handleTabs = (name) => {
        this.setState({ currentTab: name });
    };

    handleEmptyRequiredField = (
        customerErr,
        rentErr,
        vehicleErr,
        companyErr
    ) => {
        if (Object.keys(customerErr).length) {
            this.handleTabs("userInformation");
        } else if (Object.keys(vehicleErr).length) {
            this.handleTabs("vehicleInfo");
        } else if (Object.keys(rentErr).length) {
            this.handleTabs("rentInfo");
        } else if (Object.keys(companyErr).length) {
            this.handleTabs("payingInfo");
        }
    };

    checkIfNewCustomer = () => {
        const {
            editReservation,
            editContract,
            customerInfo,
            referenceCustomer,
            dropdownCustomerIds,
        } = this.state;

        const newResOrCon = !editReservation && !editContract;
        let isNewCustomer = false;

        const areIdsChangedFromRefCustomer =
            dropdownCustomerIds.personal_id !==
                customerInfo.personal_id.value ||
            dropdownCustomerIds.licence_id !== customerInfo.licence_id.value;

        const areIdsChangedFromPickedCustomer =
            referenceCustomer.personal_id !== customerInfo.personal_id.value ||
            referenceCustomer.licence_id !== customerInfo.licence_id.value;

        if (newResOrCon) {
            if (customerInfo.dropdownCustomer.value) {
                isNewCustomer = areIdsChangedFromRefCustomer;
            } else {
                isNewCustomer = true;
            }
        } else {
            if (
                !referenceCustomer.unregistered &&
                !dropdownCustomerIds.personal_id
            ) {
                isNewCustomer = areIdsChangedFromPickedCustomer;
            } else if (referenceCustomer.unregistered) {
                if (customerInfo.id.value !== referenceCustomer.id) {
                    isNewCustomer = areIdsChangedFromRefCustomer;
                } else {
                    isNewCustomer = areIdsChangedFromPickedCustomer;
                }
            }
        }

        return isNewCustomer;
    };

    createContract = (data, token) => {
        ApiService.createContract(data, token).then((response) => {
            if (response.success) {
                this.setState({
                    showCreateSuccessModal: true,
                });
            } else {
                this.setState({
                    showCreateErrorModal: true,
                });
            }
        });
    };

    updateContract = (data, token) => {
        ApiService.updateContract(data, token).then((response) => {
            if (response && response.success) {
                if (this.state.closeContract) {
                    this.setState({
                        closedContractModal: true,
                    });
                } else {
                    this.setState({
                        showUpdateSuccessModal: true,
                    });
                }
            } else {
                this.setState({
                    showUpdateErrorModal: true,
                });
            }
        });
    };

    handleSubmit = (e, type) => {
        e.preventDefault();

        this.setState({ clicked: false, notEnough: false });

        const token = StoreService.getStoreProperty("token");

        const {
            editReservation,
            editContract,
            customerInfo,
            rentInfo,
            vehicleInfo,
            equipmentIds,
            companyInfo,
            additional_equipment_total_price,
            rent_price,
            checkbox,
            contract,
        } = this.state;

        const newResOrCon = !editReservation && !editContract;
        const isNewCompany =
            !!companyInfo.company_name.value &&
            !companyInfo.dropdownCompany.value;
        const isNewCustomer = this.checkIfNewCustomer();

        const customerData = HelperFunctions.fillCustomerAPIData(
            customerInfo,
            checkbox
        );

        if (!isNewCustomer) {
            customerData.id = customerInfo.id.value;
        }

        const paymentMethod =
            rentInfo.invoice_payment_method.value === "Virman"
                ? 0
                : rentInfo.invoice_payment_method.value;

        const data: any = {
            rent_start_place: rentInfo.rent_start_place.value,
            rent_start_date: parseDateForMySQL(
                rentInfo.rent_start_date.value,
                true
            ),
            rent_start_remark: rentInfo.rent_start_remark.value,
            rent_end_place: rentInfo.rent_end_place.value,
            rent_end_date: parseDateForMySQL(
                rentInfo.rent_end_date.value,
                true
            ),
            rent_end_remark: rentInfo.rent_end_remark.value,
            invoice_first_name: rentInfo.invoice_first_name.value
                ? rentInfo.invoice_first_name.value
                : customerInfo.first_name.value,
            invoice_last_name: rentInfo.invoice_last_name.value
                ? rentInfo.invoice_last_name.value
                : customerInfo.last_name.value,
            invoice_personal_id_number: rentInfo.invoice_personal_id_number
                .value
                ? rentInfo.invoice_personal_id_number.value
                : customerInfo.personal_id.value,
            invoice_remark: rentInfo.invoice_remark.value
                ? rentInfo.invoice_remark.value
                : customerInfo.remark.value,
            invoice_payment_method: paymentMethod || "null",
            invoice_payment_method_remark:
                rentInfo.invoice_payment_method_remark.value,
            type: ConstantsService.CONTRACTS_TYPES[type],
            car_category_id: vehicleInfo.car_category_id.value,
            customer_id: customerInfo.id.value,
            car_id: vehicleInfo.car_id.value || null,
            company_id: companyInfo.id.value,
            additional_equipment: JSON.stringify(equipmentIds),
            additional_equipment_total_price: additional_equipment_total_price,
            total_price: rent_price,
            car_manufacturer: vehicleInfo.carBrand.value,
            car_model: vehicleInfo.carModel.value,
            car_remark: vehicleInfo.car_remark.value,
        };

        if (!newResOrCon) {
            data.id = this.props.match.params.id;

            if (type === "RESERVATIONS") {
                if (this.state.contractConvert) {
                    data.type = ConstantsService.CONTRACTS_TYPES.CONTRACTS;
                }
            } else {
                data.finished = this.state.closeContract ? true : false;
            }
        }

        const customerInfoErrors = Validations.customerInfoValidation(
            newResOrCon,
            type,
            customerInfo,
            checkbox
        );

        const rentInfoErrors = Validations.rentInfoValidation(rentInfo);

        const vehicleInfoErrors = Validations.vehicleInfoValidation(
            vehicleInfo,
            contract,
            this.state.contractConvert
        );

        const companyInfoErrors =
            Validations.companyInfoValidation(companyInfo);

        this.handleEmptyRequiredField(
            customerInfoErrors,
            rentInfoErrors,
            vehicleInfoErrors,
            companyInfoErrors
        );

        if (
            Object.keys(customerInfoErrors).length !== 0 ||
            Object.keys(rentInfoErrors).length !== 0 ||
            Object.keys(vehicleInfoErrors).length !== 0 ||
            Object.keys(companyInfoErrors).length !== 0
        ) {
            this.setState((prevState: any) => ({
                customerInfo: {
                    ...prevState.customerInfo,
                    ...customerInfoErrors,
                },
                rentInfo: {
                    ...prevState.rentInfo,
                    ...rentInfoErrors,
                },
                vehicleInfo: {
                    ...prevState.vehicleInfo,
                    ...vehicleInfoErrors,
                },
                companyInfo: {
                    ...prevState.companyInfo,
                    ...companyInfoErrors,
                },
            }));
        } else {
            if (isNewCompany) {
                const companyData = {
                    name: companyInfo.company_name.value,
                    PIB: companyInfo.PIB.value,
                    PDV: companyInfo.PDV.value,
                    address: companyInfo.company_address.value,
                    email: companyInfo.company_email.value,
                    phone: companyInfo.company_phone.value,
                    remark: companyInfo.company_remark.value,
                };

                ApiService.createCompany(companyData, token).then((res) => {
                    if (res.success) {
                        const newCompanyID = res.data.id;
                        data.company_id = newCompanyID;

                        if (isNewCustomer) {
                            ApiService.createCustomer(customerData, token).then(
                                (response) => {
                                    if (response.success) {
                                        this.setState({
                                            showSaveSuccessfulModal: true,
                                            customerInfo: {
                                                ...this.state.customerInfo,
                                                id: {
                                                    value: response.data.id,
                                                    errors: [],
                                                },
                                            },
                                        });

                                        data.customer_id = response.data.id;

                                        if (newResOrCon) {
                                            this.createContract(data, token);
                                        } else {
                                            this.updateContract(data, token);
                                        }

                                        return response.data.id;
                                    } else {
                                        this.checkForCustomerErrors(
                                            res.message
                                        );
                                    }
                                }
                            );
                        } else {
                            // VIDJETI KOJI MODAL DA SE PRIKAZUJE, ONAJ ZA UPDATE/CREATE CUSTOMER-A ILI ZA REZ/UGOVOR
                            if (!this.state.closeContract) {
                                ApiService.updateCustomer(
                                    customerData,
                                    token
                                ).then(() => {
                                    // DODATI MODAL ZA FAILED UPDATE CUSTOMER-A
                                });
                            }
                            if (newResOrCon) {
                                this.createContract(data, token);
                            } else {
                                this.updateContract(data, token);
                            }
                        }
                    } else {
                        if (res.message.includes("PIB_TAKEN")) {
                            this.setState((prev: any) => ({
                                ...prev,
                                companyInfo: {
                                    ...prev.companyInfo,
                                    PIB: {
                                        value: companyInfo.PIB.value,
                                        errors: ["PIB_TAKEN"],
                                    },
                                },
                            }));
                        }
                    }
                });
            } else {
                if (isNewCustomer) {
                    ApiService.createCustomer(customerData, token).then(
                        (response) => {
                            if (response.success) {
                                this.setState({
                                    showSaveSuccessfulModal: true,
                                    customerInfo: {
                                        ...this.state.customerInfo,
                                        id: {
                                            value: response.data.id,
                                            errors: [],
                                        },
                                    },
                                });

                                data.customer_id = response.data.id;

                                if (newResOrCon) {
                                    this.createContract(data, token);
                                } else {
                                    this.updateContract(data, token);
                                }

                                return response.data.id;
                            } else {
                                this.checkForCustomerErrors(response.message);
                            }
                        }
                    );
                } else {
                    if (!this.state.closeContract) {
                        ApiService.updateCustomer(customerData, token).then(
                            () => {}
                        );
                    }
                    if (newResOrCon) {
                        this.createContract(data, token);
                    } else {
                        this.updateContract(data, token);
                    }
                }
            }
        }
    };

    checkForCustomerErrors = (errors) => {
        const { customerInfo } = this.state;
        if (errors.includes("EMAIL_TAKEN")) {
            this.setState((prev: any) => ({
                ...prev,
                customerInfo: {
                    ...prev.customerInfo,
                    email: {
                        value: customerInfo.email.value,
                        errors: ["EMAIL_TAKEN"],
                    },
                },
            }));
        }
        if (errors.includes("PERSONAL_ID_TAKEN")) {
            this.setState((prev: any) => ({
                ...prev,
                customerInfo: {
                    ...prev.customerInfo,
                    personal_id: {
                        value: customerInfo.personal_id.value,
                        errors: ["PERSONAL_ID_TAKEN"],
                    },
                },
            }));
        }
        if (errors.includes("LICENCE_ID_TAKEN")) {
            this.setState((prev: any) => ({
                ...prev,
                customerInfo: {
                    ...prev.customerInfo,
                    licence_id: {
                        value: customerInfo.licence_id.value,
                        errors: ["LICENCE_ID_TAKEN"],
                    },
                },
            }));
        }
    };

    handleInput = (name, data) => {
        const { customerInfo, rentInfo, companyInfo, manualInvoiceInput } =
            this.state;

        const setChange = (section) => {
            this.setState((prevState: any) => ({
                [section]: {
                    ...prevState[section],
                    [name]: {
                        value: data.value,
                        errors: data.errors,
                    },
                },
            }));
        };

        if (customerInfo[name]) {
            setChange("customerInfo");
        } else if (rentInfo[name]) {
            setChange("rentInfo");
        } else if (vehicleInfo[name]) {
            setChange("vehicleInfo");
        } else if (companyInfo[name]) {
            setChange("companyInfo");
        } else if (name.match(/price/)) {
            this.setState({ [name]: data.value });
        } else {
            this.setState({ [name]: { value: data.value, errors: [] } });
        }

        if (
            name === "invoice_first_name" ||
            name === "invoice_last_name" ||
            name === "invoice_personal_id_number"
        ) {
            this.setState({ manualInvoiceInput: true });
        }

        if (
            (name === "first_name" ||
                name === "last_name" ||
                name === "personal_id") &&
            !manualInvoiceInput
        ) {
            this.setState((prev: any) => ({
                ...prev,
                rentInfo: {
                    ...prev.rentInfo,
                    [`${
                        name === "personal_id"
                            ? "invoice_personal_id_number"
                            : `invoice_${name}`
                    }`]: {
                        value: data.value,
                        errors: [],
                    },
                },
            }));
        }

        if (name === "rent_start_date") {
            const { rentInfo, editReservation } = this.state;

            this.setState(
                (prevState: any) => ({
                    rentInfo: {
                        ...prevState.rentInfo,
                        [name]: {
                            value: data.value,
                            errors: data.errors,
                        },
                    },
                    numberOfRentDays: editReservation
                        ? dayDifference(
                              data.value,
                              rentInfo.rent_end_date.value
                          )
                        : prevState.numberOfRentDays,
                }),
                this.renderSaldoPrice
            );
        }
        if (name === "rent_end_date") {
            const { rentInfo } = this.state;
            this.setState(
                (prevState: any) => ({
                    rentInfo: {
                        ...prevState.rentInfo,
                        [name]: {
                            value: data.value,
                            errors: data.errors,
                        },
                    },
                    numberOfRentDays: dayDifference(
                        rentInfo.rent_start_date.value,
                        data.value
                    ),
                }),
                this.renderSaldoPrice
            );
        }
    };

    updateVehicleDropdowns = (category, brand, model) => {
        const {
            allCars,
            vehicleInfo: { carBrand, carModel, carPlates, currentCar },
        } = this.state;

        let newCars = allCars.filter(
            (car: any) => car.category_id === category
        );

        const newBrandOptions = Array.from(
            new Set(
                newCars.map((car: any) => {
                    return car.manufacturer;
                })
            )
        ).map((e) => ({ value: e, label: e }));

        if (brand) {
            newCars = newCars.filter((car: any) => car.manufacturer === brand);
        }

        const newModelOptions = Array.from(
            new Set(
                newCars.map((car: any) => {
                    return car.model;
                })
            )
        ).map((e) => ({ value: e, label: e }));

        if (model) {
            newCars = newCars.filter((car: any) => car.model === model);
        }

        let newPlatesOptions;

        if (brand || model) {
            newPlatesOptions = newCars.map((car: any) => {
                return {
                    value: car.licence_plate,
                    label: car.licence_plate,
                };
            });
        } else {
            newPlatesOptions = allCars.map((car: any) => {
                return {
                    value: car.licence_plate,
                    label: car.licence_plate,
                };
            });
        }

        const currentBrand = newBrandOptions.every((option) => {
            return option.value !== carBrand.value;
        })
            ? { value: "", label: "Select" }
            : { value: carBrand.value, label: carBrand.value };

        const currentModel = newModelOptions.every((option) => {
            return option.value !== carModel.value;
        })
            ? { value: "", label: "Select" }
            : { value: carModel.value, label: carModel.value };

        const currentPlates = newPlatesOptions.every((option) => {
            return option.value !== carPlates.value;
        })
            ? { value: "", label: "Select" }
            : { value: carPlates.value, label: carPlates.value };

        const updatedFuelAmount =
            currentPlates.value === "" ? 0 : currentCar.fuel_amount;
        const updatedMileage =
            currentPlates.value === "" ? 0 : currentCar.mileage;

        this.setState((prev: any) => ({
            ...prev,
            vehicleInfo: {
                ...prev.vehicleInfo,
                carBrand: currentBrand,
                carModel: currentModel,
                carPlates: currentPlates,
                fuel_amount: updatedFuelAmount,
                mileage: updatedMileage,
            },
        }));

        return {
            newBrandOptions,
            newModelOptions,
            newPlatesOptions,
        };
    };

    handleDropdown = (name, data) => {
        if (name === "paymentOptions") {
            let { paymentOptions } = this.state;
            const method: any = paymentOptions.find(
                (e: any) => e.value === data.value
            );

            this.setState((prev: any) => ({
                ...prev,
                rentInfo: {
                    ...prev.rentInfo,
                    invoice_payment_method:
                        data.value === 0
                            ? { value: "Virman", label: "Virman" }
                            : method,
                },
            }));
        }
        if (name === "customers") {
            let { allCustomers } = this.state;
            const id = data.value;

            let chosenCustomer: any = allCustomers.find(
                (item: any) => item.id === id
            );
            const dropdownCustomer = {
                label: `${chosenCustomer.first_name} ${chosenCustomer.last_name}`,
                value: chosenCustomer.id,
            };

            const updatedCustomerInfo =
                HelperFunctions.fillCustomerInfo(chosenCustomer);

            this.setState({
                dropdownCustomerIds: {
                    personal_id: chosenCustomer.personal_id,
                    licence_id: chosenCustomer.licence_id,
                },
                customerInfo: {
                    ...updatedCustomerInfo,
                    dropdownCustomer,
                },

                chosenCustomer,
            });
        }
        if (name === "additionalEquipmentOptions") {
            let {
                allEquipment,
                equipmentIds,
                contractEquipment,
                additionalEquipmentOptions,
            } = this.state;

            const id = data.value;
            let totalPrice = 0;

            const addedEquipment = allEquipment.find(
                (item: any) => item.id === id
            );

            contractEquipment.push(addedEquipment!);

            contractEquipment.length &&
                contractEquipment.forEach((eq: any) => {
                    totalPrice += parseInt(eq.price);
                });

            const updatedEquipmentIds = [...equipmentIds, id];
            const updatedEquipmentOptions = additionalEquipmentOptions.filter(
                (eq: any) => {
                    return updatedEquipmentIds.every((id) => {
                        return eq.value !== id;
                    });
                }
            );

            this.setState({
                equipmentIds: updatedEquipmentIds,
                contractEquipment,
                additional_equipment_total_price: totalPrice,
                additionalEquipmentOptions: updatedEquipmentOptions,
            });
        }
        if (name === "categoryOptions") {
            let {
                allCars,
                categoryOptions,
                vehicleInfo: { car_id },
            } = this.state;
            const id = data.value;

            const car_category_id: any = categoryOptions.find(
                (category: any) => category.value === id
            );
            const newOptions = this.updateVehicleDropdowns(
                car_category_id.value,
                "",
                ""
            );

            const relatedCar: any = allCars.find((c: any) => c.id === car_id);
            let shouldResetCarId =
                relatedCar && relatedCar.category_id !== car_category_id.value;

            this.setState((prev: any) => ({
                ...prev,
                vehicleInfo: {
                    ...prev.vehicleInfo,
                    car_category_id,
                    car_id: shouldResetCarId
                        ? { value: 0, errors: [] }
                        : car_id,
                },
                carBrandOptions: newOptions.newBrandOptions,
                carModelOptions: newOptions.newModelOptions,
                carPlatesOptions: newOptions.newPlatesOptions,
            }));
        }
        if (name === "brand") {
            let {
                vehicleInfo: { car_category_id, car_id },
                allCars,
            } = this.state;

            const currentCarBrand = {
                label: data.value,
                value: data.value,
            };

            const newOptions = this.updateVehicleDropdowns(
                car_category_id.value,
                currentCarBrand.value,
                ""
            );

            const relatedCar: any = allCars.find(
                (c: any) => c.id === car_id.value
            );
            let shouldResetCarId =
                relatedCar && relatedCar.manufacturer !== currentCarBrand.value;

            this.setState((prev: any) => ({
                ...prev,
                vehicleInfo: {
                    ...prev.vehicleInfo,
                    carBrand: currentCarBrand,
                    car_id: shouldResetCarId
                        ? { value: 0, errors: [] }
                        : car_id,
                },
                carModelOptions: newOptions.newModelOptions,
                carPlatesOptions: newOptions.newPlatesOptions,
            }));
        }
        if (name === "model") {
            let {
                vehicleInfo: { car_category_id, carBrand, car_id },
                allCars,
            } = this.state;

            const currentCarModel = { value: data.value, label: data.value };
            const newOptions = this.updateVehicleDropdowns(
                car_category_id.value,
                carBrand.value,
                currentCarModel.value
            );

            const relatedCar: any = allCars.find(
                (c: any) => c.id === car_id.value
            );
            let shouldResetCarId =
                relatedCar && relatedCar.model !== currentCarModel.value;

            this.setState((prev: any) => ({
                ...prev,
                vehicleInfo: {
                    ...prev.vehicleInfo,
                    carModel: currentCarModel,
                    car_id: shouldResetCarId
                        ? { value: 0, errors: [] }
                        : car_id,
                },
                carPlatesOptions: newOptions.newPlatesOptions,
            }));
        }
        if (name === "plates") {
            const { allCars, allCategories } = this.state;

            const currentCar: any = allCars.find(
                (car: any) => car.licence_plate === data.value
            );

            const category: any = allCategories.find(
                (cat: any) => cat.id === parseInt(currentCar.category_id)
            );

            const newOptions = this.updateVehicleDropdowns(
                currentCar.category_id,
                currentCar.manufacturer,
                currentCar.model
            );
            this.setState((prev: any) => ({
                ...prev,
                vehicleInfo: {
                    ...prev.vehicleInfo,
                    carPlates: { value: data.value, label: data.value },
                    car_id: { value: currentCar.id, errors: [] },
                    fuel_amount: currentCar.fuel_amount,
                    mileage: currentCar.mileage,
                    currentCar,
                    car_category_id: {
                        value: currentCar.category_id,
                        label: category.name,
                    },
                    carBrand: {
                        value: currentCar.manufacturer,
                        label: currentCar.manufacturer,
                    },
                    carModel: {
                        value: currentCar.model,
                        label: currentCar.model,
                    },
                },
                carBrandOptions: newOptions.newBrandOptions,
                carModelOptions: newOptions.newModelOptions,
                carPlatesOptions: newOptions.newPlatesOptions,
            }));
        }
        if (name === "companyOptions") {
            let { allCompanies } = this.state;

            const id = data.value;

            let currentCompany: any = allCompanies.find(
                (company: any) => company.id === id
            );

            const dropdownCompany = {
                label: currentCompany.name,
                value: currentCompany.id,
            };

            this.setState({
                companyInfo: {
                    dropdownCompany,
                    id: { value: currentCompany.id, errors: [] },
                    company_name: { value: currentCompany.name, errors: [] },
                    PDV: { value: currentCompany.PDV, errors: [] },
                    PIB: { value: currentCompany.PIB, errors: [] },
                    company_address: {
                        value: currentCompany.address,
                        errors: [],
                    },
                    company_email: { value: currentCompany.email, errors: [] },
                    company_phone: { value: currentCompany.phone, errors: [] },
                    company_remark: {
                        value: currentCompany.remark || "",
                        errors: [],
                    },
                },
            });
        }
    };

    handleCheckbox = () => {
        const {
            customerInfo: { dropdownCustomer },
            customerInfo,
        } = this.state;
        if (this.state.checkbox === true) {
            this.setState({ checkbox: false });
            return;
        }
        const customerInfoErrors: any = {};
        Object.keys(customerInfo).forEach((e) => {
            if (!customerInfo[e].value) {
                if (
                    e === "first_name" ||
                    e === "last_name" ||
                    e === "licence_id" ||
                    e === "personal_id" ||
                    e === "phone" ||
                    e === "country" ||
                    e === "email" ||
                    e === "date_of_birth" ||
                    e === "licence_id_expiration_date" ||
                    e === "personal_id_expiration_date"
                ) {
                    customerInfoErrors[e] = {
                        value: customerInfo[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            }
        });

        if (!dropdownCustomer.value) {
            this.setState({ clicked: true });

            if (Object.keys(customerInfoErrors).length === 0) {
                this.setState({
                    checkbox: !this.state.checkbox,
                    notEnough: false,
                });
            } else {
                this.setState((prevState: any) => ({
                    notEnough: true,
                    customerInfo: {
                        ...prevState.customerInfo,
                        ...customerInfoErrors,
                    },
                }));
            }
        } else if (dropdownCustomer.value !== 0) {
            this.setState((prevState: any) => ({
                customerInfo: { ...prevState.customerInfo },
            }));
        }
    };

    renderTabs = () => {
        return (
            <div>
                <MenuTab
                    handleTabs={this.handleTabs}
                    currentTab={this.state.currentTab}
                    tabName="userInformation"
                    translateKey="USER_INFORMATION"
                />
                <MenuTab
                    handleTabs={this.handleTabs}
                    currentTab={this.state.currentTab}
                    tabName="rentInfo"
                    translateKey="RENT_INFORMATION"
                />
                <MenuTab
                    handleTabs={this.handleTabs}
                    currentTab={this.state.currentTab}
                    tabName="vehicleInfo"
                    translateKey="VEHICLE_INFORMATION"
                />
                <MenuTab
                    handleTabs={this.handleTabs}
                    currentTab={this.state.currentTab}
                    tabName="additionsInfo"
                    translateKey="ADDITIONS"
                />
                <MenuTab
                    handleTabs={this.handleTabs}
                    currentTab={this.state.currentTab}
                    tabName="payingInfo"
                    translateKey="PAYING_INFORMATION"
                />
            </div>
        );
    };

    renderSaldoPrice = () => {
        let {
            numberOfRentDays,
            vehicleInfo: { car_category_id },
            allCategories,
        } = this.state;

        const category: any = allCategories.filter((item: any) => {
            return parseInt(item.id) === car_category_id.value;
        })[0];

        let period_1 = category && category.period_one;
        let period_2 = category && category.period_two;
        let period_3 = category && category.period_three;
        let period_4 = category && category.period_four;
        let period_5 = category && category.period_five;
        if (category) {
            if (numberOfRentDays >= 1 && numberOfRentDays < 3) {
                this.setState({
                    rent_price: parseInt(period_1) * numberOfRentDays,
                });
            }
            if (numberOfRentDays >= 3 && numberOfRentDays <= 6) {
                this.setState({
                    rent_price: parseInt(period_2) * numberOfRentDays,
                });
            }
            if (numberOfRentDays >= 7 && numberOfRentDays < 10) {
                this.setState({
                    rent_price: parseInt(period_3) * numberOfRentDays,
                });
            }
            if (numberOfRentDays >= 10 && numberOfRentDays < 20) {
                this.setState({
                    rent_price: parseInt(period_4) * numberOfRentDays,
                });
            }
            if (numberOfRentDays >= 20) {
                this.setState({
                    rent_price: parseInt(period_5) * numberOfRentDays,
                });
            }
        }
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    renderSummaryInfo = () => {
        const {
            vehicleInfo,
            customerInfo,
            rentInfo,
            numberOfRentDays,
            companyInfo,
            rent_price,
            additional_equipment_total_price,
        } = this.state;

        return (
            <div>
                {customerInfo.first_name.value && (
                    <SummaryInfo
                        title="FIRST_LAST_NAME"
                        information={`${customerInfo.first_name.value} ${customerInfo.last_name.value}`}
                    />
                )}
                {customerInfo.phone.value && (
                    <SummaryInfo
                        title="PHONE"
                        information={customerInfo.phone.value}
                    />
                )}
                {!!vehicleInfo.car_category_id.value && (
                    <SummaryInfo
                        title="CARS_CATEGORY"
                        information={vehicleInfo.car_category_id.label}
                    />
                )}
                {rentInfo.rent_start_place.value && (
                    <SummaryInfo
                        title="LOCATION-PICK_UP"
                        information={rentInfo.rent_start_place.value}
                    />
                )}
                {rentInfo.rent_start_date.value && (
                    <SummaryInfo
                        title="DATE_FROM"
                        information={parseDate(rentInfo.rent_end_date.value)}
                    />
                )}
                {!!numberOfRentDays && (
                    <SummaryInfo
                        title="NUMBER_OF_RENT_DAYS"
                        information={numberOfRentDays}
                    />
                )}
                {companyInfo.company_name.value && (
                    <SummaryInfo
                        title="COMPANY"
                        information={companyInfo.company_name.value}
                    />
                )}
                {rentInfo.rent_end_date.value &&
                    rentInfo.rent_start_date.value && (
                        <SummaryInfo
                            title="RENT_PRICE"
                            information={`${rent_price} €`}
                        />
                    )}
                {!!additional_equipment_total_price && (
                    <SummaryInfo
                        title="ADDITIONALS_PRICE"
                        information={`${additional_equipment_total_price} €`}
                    />
                )}
                {rentInfo.rent_end_date.value &&
                    rentInfo.rent_start_date.value && (
                        <SummaryInfo
                            title="TOTAL"
                            information={`${
                                additional_equipment_total_price + rent_price
                            } €`}
                        />
                    )}
            </div>
        );
    };

    handleEquipmentDelete = (e, index, id) => {
        e.preventDefault();
        const {
            contractEquipment,
            equipmentIds,
            additional_equipment_total_price,
            additionalEquipmentOptions,
        } = this.state;

        const deletedEquipment: any = contractEquipment.find((item: any) => {
            return item.id === id;
        });

        const newAdditionalPrice =
            additional_equipment_total_price - deletedEquipment.price;

        let contractEquipmentNew = [
            ...contractEquipment.slice(0, index),
            ...contractEquipment.slice(index + 1),
        ];

        let updatedEquipmentIds: any = equipmentIds.filter((eqID) => {
            return id !== eqID;
        });

        const updatedEquipmentOptions = [
            { value: id, label: deletedEquipment.name },
            ...additionalEquipmentOptions,
        ];

        this.setState({
            contractEquipment: contractEquipmentNew,
            equipmentIds: updatedEquipmentIds,
            additional_equipment_total_price: newAdditionalPrice,
            additionalEquipmentOptions: updatedEquipmentOptions,
        });
    };

    convertToContract = (e) => {
        this.setState(
            {
                contractConvert: true,
                editContract: true,
            },
            () => {
                this.handleSubmit(e, "RESERVATIONS");
                this.props.history.push(
                    `/contract-details/${this.props.match.params.id}`
                );
            }
        );
    };

    closeContract = (e) => {
        this.setState(
            {
                closeContract: true,
            },
            () => {
                this.handleSubmit(e, "CONTRACTS");
            }
        );
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
        if (
            name === "showUpdateSuccessModal" ||
            name === "showCreateSuccessModal" ||
            name === "closedContractModal"
        ) {
            this.setState({ [name]: !this.state[name] });
            if (!this.state.contractConvert) {
                this.props.history.goBack();
            }
        }
    };

    convertToPDF = () => {
        this.setState({ fileDownloadModal: true });
        const token = StoreService.getStoreProperty("token");
        const id = this.props.match.params.id;

        ApiService.getContractPDF(id, token).then((res) => {
            let file = new Blob([res]);
            const link = document.createElement("a");
            link.href = URL.createObjectURL(file);
            link.download = "contract.pdf";
            document.body.append(link);
            link.click();
            link.remove();
            setTimeout(() => URL.revokeObjectURL(link.href), 7000);
            this.setState({ fileDownloadModal: false });
        });
    };

    render() {
        if (this.state.showLoader) {
            return (
                <div className="loaderContainer">
                    <Loader width={200} />
                </div>
            );
        } else {
            return (
                <div>
                    <Modal
                        modalName="fileDownloadModal"
                        toggleModal={this.toggleModal}
                        className={
                            this.state.fileDownloadModal ? "visible" : ""
                        }
                        modalWrapperClasses="fileDownloadModal"
                    >
                        <div className="progress pa-30 w-500 text-center border-radius-4">
                            <span className="wrapper d-block mb-20">
                                <Loader width={200} />
                            </span>
                            <span className="f-s-20 mb-20">
                                <Translate text="DOWNLOAD_IN_PROGRESS" />
                            </span>
                        </div>
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCloseContractModal"
                        className={
                            this.state.showCloseContractModal ? "visible " : ""
                        }
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            type="warning"
                            text="CLOSE_CONTRACT?"
                            buttonText="CLOSE"
                            modalName="showCloseContractModal"
                            title="CLOSE_CONTRACT"
                            toggleModal={this.toggleModal}
                            deleteItem={this.closeContract}
                            cancelDelete={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showUpdateSuccessModal"
                        className={
                            this.state.showUpdateSuccessModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.toggleModal}
                            text={
                                !this.props.contract
                                    ? "UPDATE_RESERVATION_SUCCESS"
                                    : "UPDATE_CONTRACT_SUCCESS"
                            }
                            modalName="showUpdateSuccessModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showUpdateErrorModal"
                        className={
                            this.state.showUpdateErrorModal
                                ? "visible bg-info-3"
                                : ""
                        }
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={
                                !this.props.contract
                                    ? "UPDATE_RESERVATION_FAILURE"
                                    : "UPDATE_CONTRACT_FAILURE"
                            }
                            modalName="showUpdateErrorModal"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCreateSuccessModal"
                        className={
                            this.state.showCreateSuccessModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.toggleModal}
                            text={
                                !this.props.contract
                                    ? "CREATE_RESERVATION_SUCCESS"
                                    : "CREATE_CONTRACT_SUCCESS"
                            }
                            modalName="showCreateSuccessModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showCreateErrorModal"
                        className={
                            this.state.showCreateErrorModal
                                ? "visible bg-info-3"
                                : ""
                        }
                        modalWrapperClasses="w-400  padding-10"
                    >
                        <ModalContent
                            type="failure"
                            text={
                                !this.props.contract
                                    ? "CREATE_RESERVATION_FAILURE"
                                    : "CREATE_CONTRACT_FAILURE"
                            }
                            modalName="showCreateErrorModal"
                            title="FAILURE"
                            toggleModal={this.toggleModal}
                        />
                    </Modal>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="closedContractModal"
                        className={
                            this.state.closedContractModal ? "visible" : ""
                        }
                        modalWrapperClasses="w-400 padding-10 "
                    >
                        <ModalContent
                            toggleModal={this.toggleModal}
                            text={"CONTRACT_CLOSED"}
                            modalName="closedContractModal"
                            title={"SUCCESS"}
                            type={"success"}
                        />
                    </Modal>
                    <div className="d-flex justify-content-between col-12 br-header mb-50">
                        <div className="mb-20 d-flex col-4 padding-0">
                            <span className="d-flex" onClick={this.handleBack}>
                                <LeftArrow width={18} className={"icon"} />
                            </span>
                            <span className="addReservation">
                                <Translate
                                    text={
                                        this.state.editReservation &&
                                        !this.props.contract
                                            ? "EDIT_RESERVATION"
                                            : !this.state.editReservation &&
                                              !this.props.contract
                                            ? "ADD_RESERVATION"
                                            : this.state.editContract &&
                                              this.props.contract
                                            ? "EDIT_CONTRACT"
                                            : "ADD_CONTRACT"
                                    }
                                />
                            </span>
                        </div>
                        <div className="d-flex col-8 padding-0 justify-content-end">
                            {(this.state.editContract ||
                                this.state.editReservation) && (
                                <div className="mr-10">
                                    <CustomButton
                                        className="reservationButton"
                                        type="button"
                                        onClick={() => this.convertToPDF()}
                                    >
                                        <Translate text={"CONVERT_TO_PDF"} />
                                    </CustomButton>
                                </div>
                            )}
                            {this.state.editReservation &&
                                !this.props.contract && (
                                    <div className="mr-10">
                                        <CustomButton
                                            className="contractButton"
                                            onClick={(e) =>
                                                this.convertToContract(e)
                                            }
                                        >
                                            <Translate
                                                text={"CONVERT_TO_CONTRACT"}
                                            />
                                        </CustomButton>
                                    </div>
                                )}
                            {this.state.editContract && !this.state.finished && (
                                <div className="mr-10">
                                    <CustomButton
                                        className="contractButton"
                                        onClick={(e) => this.closeContract(e)}
                                    >
                                        <Translate text={"CLOSE_CONTRACT"} />
                                    </CustomButton>
                                </div>
                            )}

                            {!this.state.finished && (
                                <div>
                                    <CustomButton
                                        className="reservationButton"
                                        onClick={
                                            this.state.editReservation &&
                                            !this.props.contract
                                                ? (e) =>
                                                      this.handleSubmit(
                                                          e,
                                                          "RESERVATIONS"
                                                      )
                                                : !this.state.editReservation &&
                                                  !this.props.contract
                                                ? (e) =>
                                                      this.handleSubmit(
                                                          e,
                                                          "RESERVATIONS"
                                                      )
                                                : this.state.editContract &&
                                                  this.props.contract
                                                ? (e) =>
                                                      this.handleSubmit(
                                                          e,
                                                          "CONTRACTS"
                                                      )
                                                : (e) =>
                                                      this.handleSubmit(
                                                          e,
                                                          "CONTRACTS"
                                                      )
                                        }
                                    >
                                        <Translate text={"SAVE"} />
                                    </CustomButton>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="d-flex col-12 max-w-1200 row justify-content-between margin-0 px-30">
                        <div className="d-flex flex-column">
                            <div className="id-operator f-s-20 bold mb-30">
                                {this.state.id_operator}
                            </div>
                            <div className="mb-50 min-w-250">
                                {this.renderTabs()}
                            </div>

                            <div className="transformations min-w-320">
                                {this.renderSummaryInfo()}
                            </div>
                        </div>
                        <div className="col-6 max-w-600 padding-0">
                            {this.state.currentTab === "userInformation" && (
                                <UserInformation
                                    handleInput={this.handleInput}
                                    customersOptions={
                                        this.state.customersOptions
                                    }
                                    customerInfo={this.state.customerInfo}
                                    handleDropdown={this.handleDropdown}
                                    editReservation={this.state.editReservation}
                                    editContract={this.state.editContract}
                                    handleCheckbox={this.handleCheckbox}
                                    checkbox={this.state.checkbox}
                                    clicked={this.state.clicked}
                                    contract={this.state.contract}
                                    notEnough={this.state.notEnough}
                                    contractOrReservation={
                                        this.state.contractOrReservation
                                    }
                                />
                            )}
                            {this.state.currentTab === "vehicleInfo" && (
                                <VehicleInformation
                                    editReservation={this.state.editReservation}
                                    handleInput={this.handleInput}
                                    handleDropdown={this.handleDropdown}
                                    categoryOptions={this.state.categoryOptions}
                                    car_category_id={
                                        this.state.vehicleInfo.car_category_id
                                    }
                                    carBrandOptions={this.state.carBrandOptions}
                                    carModelOptions={this.state.carModelOptions}
                                    carPlatesOptions={
                                        this.state.carPlatesOptions
                                    }
                                    vehicleInfo={this.state.vehicleInfo}
                                    filteredCars={this.state.filteredCars}
                                    contract={this.state.contract}
                                    platesDisabled={
                                        !this.state.rentInfo.rent_end_date
                                            .value ||
                                        !this.state.rentInfo.rent_start_date
                                            .value
                                    }
                                />
                            )}
                            {this.state.currentTab === "rentInfo" && (
                                <RentInformation
                                    handleInput={this.handleInput}
                                    rentInfo={this.state.rentInfo}
                                    editReservation={this.state.editReservation}
                                />
                            )}
                            {this.state.currentTab === "additionsInfo" && (
                                <Additionals
                                    additionalEquipmentOptions={
                                        this.state.additionalEquipmentOptions
                                    }
                                    additionalEquipment={
                                        this.state.additionalEquipment
                                    }
                                    handleInput={this.handleInput}
                                    equipment_price={
                                        this.state
                                            .additional_equipment_total_price
                                    }
                                    editReservation={this.state.editReservation}
                                    handleDropdown={this.handleDropdown}
                                    equipmentIds={this.state.equipmentIds}
                                    contractEquipment={
                                        this.state.contractEquipment
                                    }
                                    handleDelete={this.handleEquipmentDelete}
                                    remark_eq={this.state.remark_eq}
                                />
                            )}
                            {this.state.currentTab === "payingInfo" && (
                                <PayingInformation
                                    companyInfo={this.state.companyInfo}
                                    rentInfo={this.state.rentInfo}
                                    handleInput={this.handleInput}
                                    companies={this.state.filteredCompanies}
                                    companyOptions={this.state.companyOptions}
                                    handleDropdown={this.handleDropdown}
                                    contract={this.state.contract}
                                    equipment_price={
                                        this.state
                                            .additional_equipment_total_price
                                    }
                                    rent_price={this.state.rent_price}
                                    paymentOptions={this.state.paymentOptions}
                                />
                            )}
                        </div>
                    </div>
                </div>
            );
        }
    }
}
export default ReservationDetails;
