import { CustomForm, PinCode } from "devkit/Form/Form";
import React, { Component } from "react";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import { BackIcon } from "../../components/Icons/Icons";
import { StoreService } from "services/store.service";
import Translate from "../../functions/utilFunctions/translate";
import ApiService from "services/api.service";
import { Loader } from "devkit/Loader/Loader";

interface IPin {
    handlePinChange: (value: boolean) => void;
    token: string;
    logout: () => void;
}

class Pin extends Component<IPin> {
    state = {
        pin: ["", "", "", ""],
        errors: [],
        showErrorModal: false,
        logout: false,
        showLoader: false,
    };
    handleInput = (pin, errors) => {
        this.setState({ pin, errors });
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleErrorModal = () => {
        this.setState((prevState: any) => ({
            showErrorModal: !prevState.showErrorModal,
        }));
    };

    handlePinValidation = () => {
        const token = StoreService.getStoreProperty("token");
        ApiService.pin(this.state.pin.join(""), token).then((response) => {
            if (response && response.success) {
                this.setState({ showLoader: !this.state.showLoader });
                this.props.handlePinChange(true);
            } else {
                this.setState({
                    pin: ["", "", "", ""],
                    showErrorModal: true,
                    showLoader: false,
                });
            }
        });
    };

    componentDidUpdate(prevState) {
        const { pin } = this.state;
        if (prevState.pin !== this.state.pin && !this.state.showLoader) {
            const newArray = pin.filter((num) => num !== "");
            if (newArray.length === 4) {
                this.setState({ showLoader: !this.state.showLoader });
                this.handlePinValidation();
            }
        }
    }

    render() {
        const { pin, showLoader } = this.state;
        return (
            <div className=" w-100perc d-flex flex-row">
                <div>
                    <Modal
                        toggleModal={this.toggleModal}
                        modalName="showErrorModal"
                        className={
                            this.state.showErrorModal
                                ? "visible error"
                                : "error"
                        }
                        modalWrapperClasses="w-400 padding-10"
                    >
                        <ModalContent
                            type="failure"
                            title="FAILED"
                            text="WRONG_PIN"
                            modalName="showErrorModal"
                            toggleModal={this.handleErrorModal}
                        />
                    </Modal>
                </div>
                <div
                    id="pin-left-column"
                    className="pin-left-photo w-50-perc h-vh-100"
                />
                <div
                    id="pin-right-column"
                    className="w-50perc d-flex flex-column justify-content-center align-items-center"
                >
                    <div className="align-center m-t-60">
                        <img
                            src="../images/pin/406@2x.png"
                            alt="logo"
                            className="hydra-logo"
                        />
                    </div>
                    <div className="d-flex flex-column space-between">
                        <span className="welcomeMessage margin-h-10 align-self-center f-s-14 my-30">
                            <Translate text={"INSERT_PIN"} />
                        </span>
                        <div className="p-v-20 d-flex flex-column align-items-center">
                            <CustomForm className="p-r">
                                <>
                                    <PinCode
                                        value={pin}
                                        handleInput={this.handleInput}
                                    />
                                    {showLoader && (
                                        <div className="pinLoader w-100-perc d-flex justify-content-center">
                                            <span className="mr-10 f-s-14 ">
                                                <Translate text="CHECKING_PIN" />
                                            </span>
                                            <Loader
                                                width={20}
                                                className="pinLoader-loader"
                                            />
                                        </div>
                                    )}
                                </>
                            </CustomForm>
                            {/* <div

                                onClick={this.props.logout}
                                className="flex align-self-center pointer margin-t-20 f-s-14"
                            >
                                <Translate text="GOTO_LOGIN" />
                            </div> */}

                            <button
                                onClick={this.props.logout}
                                className="mt-50 f-s-14 pointer d-flex align-items-center goBack"
                            >
                                <span className="mr-5">
                                    <BackIcon width={20} />
                                </span>
                                <span className="pb-2">
                                    <Translate text="GOTO_LOGIN" />
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Pin;
