import React, { Component } from "react";
import { StoreService } from "services/store.service";
import Table from "devkit/Table/Table";
import Translate from "functions/utilFunctions/translate";
import ShowMore from "devkit/ShowMore/ShowMore";
import { CustomForm, CustomInput, CustomDatePicker } from "devkit/Form/Form";
import {
    parseDateForMySQL,
    parseDateForCar,
} from "functions/utilFunctions/parsingFunctions";
import { CustomButton } from "devkit/Form/Form";
import { PlusIcon, SaveIcon, BackIcon } from "components/Icons/Icons";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "../../../devkit/Modal/ModalContent";
import ApiService from "services/api.service";
import { Loader } from "devkit/Loader/Loader";
import { UtilService } from "services/util.service";
class TyreInfo extends Component<any> {
    state = {
        editTyreInfo: {
            id: {
                value: "",
                errors: [],
            },
            car_id: {
                value: "",
                errors: [],
            },
            purchase_date: {
                value: null,
                errors: [],
            },
            created_at: {
                value: null,
                errors: [],
            },
            manufacturer: {
                value: "",
                errors: [],
            },
            model: {
                value: "",
                errors: [],
            },
            type: {
                value: "",
                errors: [],
            },
            size: {
                value: "",
                errors: [],
            },
            name: {
                value: "",
                errors: [],
            },
            state: {
                value: "",
                errors: [],
            },
            shop_name: {
                value: "",
                errors: [],
            },
            updated_at: {
                value: null,
                errors: [],
            },
        },
        emptyState: {
            id: {
                value: "",
                errors: [],
            },
            car_id: {
                value: "",
                errors: [],
            },
            purchase_date: {
                value: null,
                errors: [],
            },
            created_at: {
                value: null,
                errors: [],
            },
            manufacturer: {
                value: "",
                errors: [],
            },
            model: {
                value: "",
                errors: [],
            },
            type: {
                value: "",
                errors: [],
            },
            size: {
                value: "",
                errors: [],
            },
            name: {
                value: "",
                errors: [],
            },
            state: {
                value: "",
                errors: [],
            },
            shop_name: {
                value: "",
                errors: [],
            },
            updated_at: {
                value: null,
                errors: [],
            },
        },
        tyreTableHeads: [
            {
                value: "TITLE",
                sortable: false,
                sortableType: "string",
                sortableName: "name",
            },
            {
                value: "MODEL",
                sortable: false,
                sortableType: "string",
                sortableName: "model",
            },
            {
                value: "MANUFACTURER",
                sortable: false,
                sortableType: "string",
                sortableName: "manufacturer",
            },
            {
                value: "TYPE",
                sortable: false,
                sortableType: "date",
                sortableName: "type",
            },
            {
                value: "SIZE",
                sortable: false,
                sortableType: "string",
                sortableName: "size",
            },
            {
                value: "PURCHASE_DATE",
                sortable: false,
                sortableType: "string",
                sortableName: "purchase_date",
            },
            {
                value: "STATE",
                sortable: false,
                sortableType: "string",
                sortableName: "state",
            },
            {
                value: "NAME_OF_SHOP",
                sortable: false,
                sortableType: "string",
                sortableName: "shop_name",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        tires: [],
        editTyre: false,
        newTyre: false,
        showButton: true,
        showUpdateErrorModal: false,
        showUpdateSuccessModal: false,
        showCreateErrorModal: false,
        showCreateSuccessModal: false,
        showDeleteModal: false,
        showDeleteErrorModal: false,
        showDeleteSuccessModal: false,
        deleteID: "",
        showLoader: true,
        tyre: "",
    };

    componentDidMount() {
        if (!this.state.newTyre) {
            const token = StoreService.getStoreProperty("token");
            ApiService.getAllTyres(this.props.carId, token).then((response) => {
                if (response.success) {
                    const tires = response.data;
                    this.setState(() => ({
                        tires,
                        showLoader: false,
                    }));
                }
            });
        } else {
            this.setState({
                editTyre: false,
                showLoader: false,
            });
        }
    }

    addNewTyre = () => {
        const emptyState = this.state.emptyState;
        this.setState((prevState: any) => ({
            editTyreInfo: {
                ...prevState.editTyreInfo,
                ...emptyState,
            },
            newTyre: true,
            editTyre: false,
            showButton: false,
        }));
    };

    editTyre = (id) => {
        const allTires = this.state.tires;
        const tyre: any = allTires.find(
            (e: any) => e.id.toString() === id.toString()
        );
        let tyreFormated = {};
        if (tyre) {
            Object.keys(tyre).forEach((e) => {
                if (e === "purchase_date") {
                    tyreFormated[e] = {
                        value: new Date(tyre[e]),
                        errors: [],
                    };
                } else {
                    tyreFormated[e] = {
                        value: tyre[e],
                        errors: [],
                    };
                }
            });
        }
        this.setState((prevState: any) => ({
            editTyreInfo: {
                ...prevState.editTyreInfo,
                ...tyreFormated,
            },
            editTyre: true,
            newTyre: false,
            showButton: false,
        }));
    };

    backToOverview = () => {
        this.setState({
            editTyre: false,
            newTyre: false,
            showButton: true,
        });
    };

    saveChanges = () => {
        const {
            manufacturer,
            model,
            type,
            size,
            name,
            state,
            shop_name,
            purchase_date,
        } = this.state.editTyreInfo;
        const form = {
            manufacturer,
            model,
            type,
            size,
            name,
            state,
            shop_name,
            purchase_date,
        };
        const { tires } = this.state;
        const token = StoreService.getStoreProperty("token");
        const errors = {};

        let tyreInfo = {
            id: this.state.editTyreInfo.id.value,
            car_id: this.props.carId,
            purchase_date: parseDateForMySQL(
                this.state.editTyreInfo.purchase_date.value
            ),
            manufacturer: this.state.editTyreInfo.manufacturer.value,
            model: this.state.editTyreInfo.model.value,
            type: this.state.editTyreInfo.type.value,
            size: this.state.editTyreInfo.size.value,
            name: this.state.editTyreInfo.name.value,
            state: this.state.editTyreInfo.state.value,
            shop_name: this.state.editTyreInfo.shop_name.value,
        };
        Object.keys(form).forEach((e) => {
            if (!form[e].value) {
                errors[e] = {
                    value: form[e].value,
                    errors: ["INPUT_REQUIRED"],
                };
            }
        });
        if (Object.keys(errors).length !== 0) {
            this.setState((prevState: any) => ({
                editTyreInfo: {
                    ...prevState.editTyreInfo,
                    ...errors,
                },
            }));
        } else {
            if (this.state.newTyre) {
                ApiService.createTyre(tyreInfo, token).then((response) => {
                    if (response.success) {
                        const createdTyre = response.data;
                        this.setState((prevState: any) => ({
                            tires: [...prevState.tires, createdTyre],
                            showCreateSuccessModal: true,
                        }));
                    } else {
                        this.setState({
                            showCreateErrorModal: true,
                        });
                    }
                });
            } else {
                const tyre: any = tires.find(
                    (e: any) => e.id.toString() === tyreInfo.id.toString()
                );
                delete tyre.created_at;
                delete tyre.updated_at;
                const equal = UtilService.areObjectsEqual(tyre, tyreInfo);
                if (!equal) {
                    ApiService.updateTyre(tyreInfo, token).then((response) => {
                        if (response.success) {
                            const updatedTyre = response.data;
                            let tires: any = this.state.tires.filter(
                                (tyre: any) => tyre.id !== updatedTyre.id
                            );
                            tires.push(updatedTyre);

                            this.setState({
                                tires,
                                showUpdateSuccessModal: true,
                            });
                        } else {
                            this.setState({
                                showUpdateErrorModal: true,
                            });
                        }
                    });
                } else return;
            }
        }
    };

    handleDeleteTyre = () => {
        const token = StoreService.getStoreProperty("token");
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
        ApiService.deleteTyre(this.state.deleteID, token).then((response) => {
            if (response.success) {
                let tires: any = this.state.tires.filter(
                    (item: any) =>
                        parseInt(item.id) !== parseInt(this.state.deleteID)
                );

                this.setState({
                    tires,
                    showDeleteSuccessModal: true,
                });
            } else {
                this.setState({
                    showDeleteErrorModal: true,
                });
                return;
            }
        });
    };

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            editTyreInfo: {
                ...prevState.editTyreInfo,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleSuccessModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
            editTyre: false,
            createTyre: false,
            showButton: true,
            newTyre: false,
        }));
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };

    render() {
        const {
            purchase_date,
            manufacturer,
            model,
            type,
            size,
            shop_name,
            state,
            name,
        } = this.state.editTyreInfo;
        return (
            <div className="h-100perc ">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="DELETE_TYRE_?"
                        modalName="showDeleteModal"
                        title="DELETE_TYRE"
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDeleteTyre}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteSuccessModal"
                    className={
                        this.state.showDeleteSuccessModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="TYRE_DELETE_SUCCESS"
                        modalName="showDeleteSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteErrorModal"
                    className={
                        this.state.showDeleteErrorModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="TYRE_DELETE_FAILURE"
                        modalName="showDeleteErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showUpdateSuccessModal"
                    className={
                        this.state.showUpdateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        toggleModal={this.handleSuccessModal}
                        text="UPDATE_TYRE_SUCCESS"
                        modalName="showUpdateSuccessModal"
                        title={"SUCCESS"}
                        type={"success"}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showUpdateErrorModal"
                    className={
                        this.state.showUpdateErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="UPDATE_TYRE_FAILURE"
                        modalName="showUpdateErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showCreateSuccessModal"
                    className={
                        this.state.showCreateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        toggleModal={this.handleSuccessModal}
                        text="CREATE_TYRE_SUCCESS"
                        modalName="showCreateSuccessModal"
                        title={"SUCCESS"}
                        type={"success"}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showCreateErrorModal"
                    className={
                        this.state.showCreateErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="CREATE_TYRE_FAILURE"
                        modalName="showCreateErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                {this.state.showButton && (
                    <div className="d-flex pb-20 justify-content-end">
                        <CustomButton
                            className="btn-primary"
                            type="button"
                            onClick={this.addNewTyre}
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <PlusIcon
                                    width={14}
                                    className="icon hover pointer mr-10 whiteFill"
                                />
                                <Translate text="ADD_TIRES" />
                            </div>
                        </CustomButton>
                    </div>
                )}
                <div className="mt-20 f-s-14">
                    {!this.state.editTyre && !this.state.newTyre ? (
                        <div>
                            {this.state.showLoader ? (
                                <Loader className="w-200" />
                            ) : (
                                <Table
                                    theads={this.state.tyreTableHeads}
                                    theadsClassname="customThead"
                                >
                                    {this.state.tires.map((e: any, i) => {
                                        return (
                                            <tr
                                                className="pointer"
                                                onClick={() =>
                                                    this.editTyre(e.id)
                                                }
                                                key={i}
                                            >
                                                <td>{e.name}</td>
                                                <td>{e.model}</td>
                                                <td>{e.manufacturer}</td>
                                                <td>{e.type}</td>
                                                <td>{e.size}</td>
                                                <td>
                                                    {parseDateForCar(
                                                        e.purchase_date
                                                    )}
                                                </td>
                                                <td>{e.state}</td>
                                                <td>{e.shop_name}</td>

                                                <td>
                                                    <ShowMore>
                                                        <span
                                                            className="one-line-text d-flex justify-content-center"
                                                            onClick={() =>
                                                                this.editTyre(
                                                                    e.id
                                                                )
                                                            }
                                                        >
                                                            <Translate text="CHANGE_DATA" />
                                                        </span>
                                                        <span
                                                            className="one-line-text d-flex justify-content-center"
                                                            onClick={() =>
                                                                this.handleDeleteModal(
                                                                    e.id
                                                                )
                                                            }
                                                        >
                                                            <span className="danger">
                                                                <Translate text="DELETE" />
                                                            </span>
                                                        </span>
                                                    </ShowMore>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </Table>
                            )}
                        </div>
                    ) : (
                        <div>
                            <CustomForm className="h-100perc py-10">
                                <div className="row">
                                    <div className="my-20 col-auto w-280 ">
                                        <CustomInput
                                            name="name"
                                            label="TITLE"
                                            value={name.value}
                                            type="text"
                                            errors={name.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="model"
                                            label="MODEL"
                                            value={model.value}
                                            type="text"
                                            errors={model.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="manufacturer"
                                            label="MANUFACTURER"
                                            value={manufacturer.value}
                                            type="text"
                                            errors={manufacturer.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="type"
                                            label="TYPE"
                                            value={type.value}
                                            type="text"
                                            errors={type.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="size"
                                            label="SIZE"
                                            value={size.value}
                                            type="text"
                                            errors={size.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="state"
                                            label="STATE"
                                            value={state.value}
                                            type="text"
                                            errors={state.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomInput
                                            name="shop_name"
                                            label="NAME_OF_SHOP"
                                            value={shop_name.value}
                                            type="text"
                                            errors={shop_name.errors}
                                            handleInput={this.handleInput}
                                        />
                                    </div>
                                    <div className="my-20 col-auto w-280">
                                        <CustomDatePicker
                                            name="purchase_date"
                                            label="PURCHASE_DATE"
                                            handleInput={this.handleInput}
                                            value={purchase_date.value}
                                            errors={purchase_date.errors}
                                        />
                                    </div>
                                </div>
                            </CustomForm>
                            <div className="d-flex align-items-center mt-20">
                                <CustomButton
                                    className="btn-primary mr-20"
                                    type="button"
                                    onClick={this.backToOverview}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <BackIcon
                                            width={15}
                                            className="icon hover pointer mr-10 whiteFill"
                                        />
                                        <Translate text="OVERVIEW" />
                                    </div>
                                </CustomButton>
                                <CustomButton
                                    className="btn-primary"
                                    type="button"
                                    onClick={this.saveChanges}
                                >
                                    <div className="d-flex justify-content-center align-items-center">
                                        <SaveIcon
                                            width={14}
                                            className="icon hover pointer mr-10 whiteFill"
                                        />
                                        <Translate text="SAVE_CHANGES" />
                                    </div>
                                </CustomButton>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default TyreInfo;
