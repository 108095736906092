import React, { Component } from "react";
import CustomForm from "../../../../devkit/Form/CustomForm/CustomForm";
import CustomInput from "devkit/Form/CustomInput/CustomInput";
import CustomDropdown from "../../../../devkit/Form/CustomDropdown/CustomDropdown";
import { CustomTextArea } from "devkit/Form/Form";

class PayingInformation extends Component<any> {
    render() {
        const {
            contract,
            rentInfo,
            handleInput,
            rent_price,
            equipment_price,
            paymentOptions,
            handleDropdown,
            companyOptions,
            companyInfo,
        } = this.props;
        return (
            <div>
                <CustomForm>
                    <div className="mb-29">
                        <CustomInput
                            name="invoice_first_name"
                            label="FIRST_NAME"
                            handleInput={handleInput}
                            type="text"
                            value={rentInfo.invoice_first_name.value}
                            errors={rentInfo.invoice_first_name.errors}
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="invoice_last_name"
                            label="LAST_NAME"
                            handleInput={handleInput}
                            type="text"
                            value={rentInfo.invoice_last_name.value}
                            errors={rentInfo.invoice_last_name.errors}
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="invoice_personal_id_number"
                            label="PASSPORT_NUMBER"
                            handleInput={handleInput}
                            type="text"
                            value={rentInfo.invoice_personal_id_number.value}
                            errors={
                                contract
                                    ? rentInfo.invoice_personal_id_number.errors
                                    : []
                            }
                        />
                    </div>
                    <div className="d-flex justify-content-between mb-29">
                        <div className="col-6 pl-0">
                            <CustomInput
                                name="rent_price"
                                label="RENT_PRICE"
                                value={rent_price}
                                handleInput={handleInput}
                                type="number"
                            />
                        </div>
                        <div className="col-6 pr-0">
                            <CustomInput
                                name="additional_equipment_total_price"
                                label="ADDITIONALS_PRICE"
                                value={equipment_price}
                                handleInput={handleInput}
                                type="number"
                            />
                        </div>
                    </div>
                    <div className="mb-29">
                        <CustomDropdown
                            data={paymentOptions}
                            label="CHOOSE_METHOD"
                            name="paymentOptions"
                            handleChange={handleDropdown}
                            value={rentInfo.invoice_payment_method}
                        />
                    </div>
                    <div className="mb-29">
                        <CustomTextArea
                            name="invoice_remark"
                            value={rentInfo.invoice_remark.value}
                            label="REMINDER"
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="mb-29">
                        <CustomDropdown
                            data={companyOptions}
                            label="CHOOSE_COMPANY"
                            name="companyOptions"
                            handleChange={handleDropdown}
                            value={companyInfo.dropdownCompany}
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="company_name"
                            label="NAME"
                            value={companyInfo.company_name.value}
                            errors={companyInfo.company_name.errors}
                            handleInput={handleInput}
                            type="text"
                        />
                    </div>
                    <div className="d-flex justify-content-between mb-29">
                        <div className="col-6 pl-0">
                            <CustomInput
                                name="PIB"
                                label="PIB"
                                value={companyInfo.PIB.value}
                                errors={companyInfo.PIB.errors}
                                handleInput={handleInput}
                                type="number"
                            />
                        </div>
                        <div className="col-6 pr-0">
                            <CustomInput
                                name="PDV"
                                label="PDV"
                                value={companyInfo.PDV.value}
                                errors={companyInfo.PDV.errors}
                                handleInput={handleInput}
                                type="number"
                            />
                        </div>
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="company_address"
                            label="ADDRESS"
                            value={companyInfo.company_address.value}
                            errors={companyInfo.company_address.errors}
                            handleInput={handleInput}
                            type="text"
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="company_phone"
                            label="PHONE"
                            value={companyInfo.company_phone.value}
                            errors={companyInfo.company_phone.errors}
                            handleInput={handleInput}
                            type="number"
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="company_email"
                            label="EMAIL"
                            value={companyInfo.company_email.value}
                            errors={companyInfo.company_email.errors}
                            handleInput={handleInput}
                            type="email"
                        />
                    </div>
                    <div className="mb-29">
                        <CustomTextArea
                            name="company_remark"
                            value={companyInfo.company_remark.value}
                            label="REMINDER"
                            handleInput={this.props.handleInput}
                        />
                    </div>
                </CustomForm>
            </div>
        );
    }
}
export default PayingInformation;
