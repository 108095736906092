import { CustomButton, CustomForm, CustomInput } from "devkit/Form/Form";
import React, { Component } from "react";
import Modal from "../../devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import Translate from "../../functions/utilFunctions/translate";

class NewPassword extends Component<any> {
    state = {
        form: {
            newPassword: {
                value: "",
                errors: [],
            },
            newPasswordRepeat: {
                value: "",
                errors: [],
            },
        },
        showSuccessModal: false,
        showPasswordErrorModal: false,
        showTokenErrorModal: false,
    };

    // componentDidMount() {
    //     ApiService.checkResetToken({
    //         token: this.props.match.params.token,
    //     }).then((response) => {
    //         if (response.status === "OK") {
    //         } else {
    //             this.setState({
    //                 showTokenErrorModal: true,
    //             });
    //         }
    //     });
    // }

    handleInput = (name, data) => {
        this.setState((prevState: any) => ({
            form: {
                ...prevState.form,
                [name]: {
                    value: data.value,
                    errors: data.errors,
                },
            },
        }));
    };

    handleSubmit = (e) => {
        e.preventDefault();
        // this.resetPassword(e);
    };

    resetPassword = (e) => {
        e.preventDefault();
        // let { newPassword, newPasswordRepeat } = this.state.form;
        // const token = this.props.match.params.token;

        // const form = {
        //     newPassword,
        //     newPasswordRepeat,
        // };
        // const errors = {};

        // Object.keys(form).forEach((e) => {
        //     if (!form[e].value) {
        //         errors[e] = {
        //             value: form[e].value,
        //             errors: ["INPUT_REQUIRED"],
        //         };
        //     } else if (form[e].value.length < 6) {
        //         errors[e] = {
        //             value: form[e].value,
        //             errors: ["PASSWORD_NOT_VALID"],
        //         };
        //     } else if (newPassword.value !== newPasswordRepeat.value) {
        //         errors[e] = {
        //             value: form[e].value,
        //             errors: ["PSWDontMatch"],
        //         };
        //     }
        // });
        // if (Object.keys(errors).length !== 0) {
        //     this.setState((prevState: any) => ({
        //         form: {
        //             ...prevState.form,
        //             ...errors,
        //         },
        //     }));
        // } else {
        //     ApiService.newPasswordWithToken({
        //         password: newPassword.value,
        //         token,
        //     }).then((response) => {
        //         console.log(response);
        //         if (response.status === "OK") {
        //             this.setState({
        //                 showSuccessModal: true,
        //             });
        //         } else {
        //             this.setState({
        //                 showPasswordErrorModal: true,
        //             });
        //         }
        //     });
        // }
    };

    handleSuccessModal = () => {
        this.setState((prevState: any) => ({
            showSuccessModal: !prevState.showSuccessModal,
        }));
        this.props.history.push("/");
    };

    handleErrorModal = (name) => {
        this.setState({ [name]: !this.state[name] });
        this.props.history.push("/reset-password");
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    render() {
        const { newPassword, newPasswordRepeat } = this.state.form;

        return (
            <div className="login d-flex flex-row">
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showSuccessModal"
                    className={this.state.showSuccessModal ? "visible" : ""}
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="success"
                        title="SUCCESS"
                        text="PASSWORD_SUCCESS"
                        modalName="showSuccessModal"
                        toggleModal={this.handleSuccessModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showTokenErrorModal"
                    className={
                        this.state.showTokenErrorModal
                            ? "visible error"
                            : "error"
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILURE"
                        text="TOKEN_ERROR"
                        modalName="showTokenErrorModal"
                        toggleModal={this.handleErrorModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showPasswordErrorModal"
                    className={
                        this.state.showPasswordErrorModal
                            ? "visible error"
                            : "error"
                    }
                    modalWrapperClasses="w-400 padding-10"
                >
                    <ModalContent
                        type="failure"
                        title="FAILURE"
                        text="PSWError"
                        modalName="showPasswordErrorModal"
                        toggleModal={this.handleErrorModal}
                    />
                </Modal>
                <div id="left" className="leftSide w-50-perc h-vh-100" />
                <div
                    id="right"
                    className="d-flex w-50-perc h-vh-100 flex-column justify-content-center"
                >
                    <div className="align-self-center">
                        <img
                            src="../images/logo.png"
                            alt="logo"
                            className="logo"
                        />
                    </div>
                    <span className="f-s-16 align-self-center pt-30">
                        <Translate text="INSERT_NEW_PASSWORD" />
                    </span>
                    <CustomForm
                        handleSubmit={this.handleSubmit}
                        className="d-flex flex-column"
                    >
                        <div className="padding-v-30 w-40-perc align-self-center">
                            <CustomInput
                                name="newPassword"
                                type="password"
                                handleInput={this.handleInput}
                                value={newPassword.value}
                                errors={newPassword.errors}
                                label="NEW_PASSWORD"
                                required
                            />
                        </div>
                        <div className="padding-b-20 w-40-perc align-self-center">
                            <CustomInput
                                name="newPasswordRepeat"
                                type="password"
                                handleInput={this.handleInput}
                                value={newPasswordRepeat.value}
                                errors={newPasswordRepeat.errors}
                                label="NEW_PASSWORD_REPEAT"
                                required
                            />
                        </div>
                        <div className="d-flex align-self-center pt-20">
                            <CustomButton
                                type="submit"
                                onClick={this.resetPassword}
                                className="btn-login"
                            >
                                <Translate text="RESET_PASSWORD" />
                            </CustomButton>
                        </div>
                    </CustomForm>
                    {/* <div
                            
                            onClick={this.resetPassword}
                            className="flex align-self-center pointer margin-t-20 f-s-14"
                        >
                            Reset password
                    </div> */}
                </div>
            </div>
        );
    }
}

export default NewPassword;
