import {
    customerInfo,
    rentInfo,
    companyInfo,
    customerData,
    paymentOptions,
} from "pages/ReservationsAndContracts/StatesAndInterfaces/ReservationForms";
import { UtilService } from "services/util.service";

export const HelperFunctions = {
    fillCustomerInfo: (customer) => {
        const filledCustomerInfo = UtilService.clone(customerInfo);

        Object.keys(customer).forEach((prop) => {
            console.log(prop);
            if (prop === "unregistered") {
                if (customer[prop]) {
                    filledCustomerInfo[prop].value = true;
                }
            } else if (customer[prop] && filledCustomerInfo[prop]) {
                if (prop.match(/date/)) {
                    filledCustomerInfo[prop].value = new Date(customer[prop]);
                } else {
                    if (prop === "id") {
                        console.log(customer[prop]);
                    }
                    filledCustomerInfo[prop].value = customer[prop];
                }
            }
        });

        return filledCustomerInfo;
    },
    fillRentInfo: (contractOrReservation) => {
        const filledRentInfo = UtilService.clone(rentInfo);

        Object.keys(contractOrReservation).forEach((prop) => {
            if (
                contractOrReservation.hasOwnProperty(`${prop}`) &&
                filledRentInfo[prop]
            ) {
                if (prop === "invoice_payment_method") {
                    const paymentMethod = paymentOptions.find(
                        (opt) => opt.value === contractOrReservation[prop]
                    );

                    filledRentInfo.invoice_payment_method = !paymentMethod
                        ? {
                              value: "",
                              label: "",
                          }
                        : paymentMethod;
                } else if (prop.match(/date/)) {
                    filledRentInfo[prop].value = new Date(
                        contractOrReservation[prop]
                    );
                } else {
                    filledRentInfo[prop].value = contractOrReservation[prop];
                }
            }
        });

        return filledRentInfo;
    },
    fillCompanyInfo: (company) => {
        const filledCompanyInfo = UtilService.clone(companyInfo);

        if (company) {
            Object.keys(company).forEach((prop) => {
                if (
                    company[prop] &&
                    (filledCompanyInfo[prop] ||
                        filledCompanyInfo[`company_${prop}`])
                ) {
                    if (prop === "PIB" || prop === "PDV" || prop === "id") {
                        filledCompanyInfo[prop].value = company[prop];
                    } else {
                        filledCompanyInfo[`company_${prop}`].value =
                            company[prop];
                    }
                }
            });
        }

        return filledCompanyInfo;
    },
    fillCustomerAPIData: (data, checkbox) => {
        const filledCustomerData = UtilService.clone(customerData);

        Object.keys(data).forEach((prop) => {
            if (filledCustomerData.hasOwnProperty(prop)) {
                if (prop === "unregistered") {
                    filledCustomerData[prop] =
                        !checkbox && !data.dropdownCustomer.value;
                } else {
                    filledCustomerData[prop] = data[prop].value;
                }
            }
        });

        return filledCustomerData;
    },
};

export const Validations = {
    customerInfoValidation: (isNew, type, customerInfo, checkbox) => {
        const customerInfoErrors: any = {};

        Object.keys(customerInfo).forEach((e) => {
            if (!customerInfo[e].value) {
                if (
                    isNew &&
                    checkbox &&
                    (e === "first_name" ||
                        e === "last_name" ||
                        e === "licence_id" ||
                        e === "personal_id" ||
                        e === "phone" ||
                        e === "country" ||
                        e === "date_of_birth" ||
                        e === "licence_id_expiration_date" ||
                        e === "personal_id_expiration_date")
                ) {
                    customerInfoErrors[e] = {
                        value: customerInfo[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                } else if (
                    (type === "RESERVATIONS" &&
                        (e === "first_name" || e === "last_name")) ||
                    (type === "CONTRACTS" &&
                        (e === "first_name" ||
                            e === "last_name" ||
                            e === "licence_id" ||
                            e === "personal_id" ||
                            e === "licence_id_expiration_date" ||
                            e === "personal_id_expiration_date"))
                ) {
                    customerInfoErrors[e] = {
                        value: customerInfo[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            }

            const { licence_id_expiration_date, personal_id_expiration_date } =
                customerInfo;

            if (
                e === "licence_id_expiration_date" &&
                licence_id_expiration_date.value !== null &&
                licence_id_expiration_date.value! < new Date()
            ) {
                customerInfoErrors[e] = {
                    value: licence_id_expiration_date.value,
                    errors: ["LICENCE_DATE_EXPIRED"],
                };
            }

            if (
                e === "personal_id_expiration_date" &&
                personal_id_expiration_date.value !== null &&
                personal_id_expiration_date.value! < new Date()
            ) {
                customerInfoErrors[e] = {
                    value: personal_id_expiration_date.value,
                    errors: ["PERSONAL_ID_DATE_EXPIRED"],
                };
            }
        });

        return customerInfoErrors;
    },
    rentInfoValidation: (rentInfo) => {
        const rentInfoErrors: any = {};

        Object.keys(rentInfo).forEach((e) => {
            if (!rentInfo[e].value) {
                if (
                    !e.match(/invoice/) &&
                    !e.match(/remark/) &&
                    e !== "paymentMethod"
                ) {
                    rentInfoErrors[e] = {
                        value: rentInfo[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            }
            if (
                rentInfo.rent_start_date.value! > rentInfo.rent_end_date.value!
            ) {
                if (e === "rent_start_date") {
                    rentInfoErrors[e] = {
                        value: rentInfo.rent_start_date.value,
                        errors: ["START_DATE_INVALID"],
                    };
                } else if (e === "rent_end_date") {
                    rentInfoErrors[e] = {
                        value: rentInfo.rent_start_date.value,
                        errors: ["END_DATE_INVALID"],
                    };
                }
            }
        });

        return rentInfoErrors;
    },
    vehicleInfoValidation: (vehicleInfo, contract, convert) => {
        const vehicleInfoErrors: any = {};

        Object.keys(vehicleInfo).forEach((e) => {
            if (!vehicleInfo[e].value) {
                if (
                    (e === "car_id" && (contract || convert)) ||
                    e === "car_category_id"
                ) {
                    vehicleInfoErrors[e] = {
                        value: vehicleInfo[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            }
        });

        return vehicleInfoErrors;
    },
    companyInfoValidation: (companyInfo) => {
        const companyInfoErrors: any = {};

        if (Object.keys(companyInfo).some((prop) => companyInfo[prop].value)) {
            Object.keys(companyInfo).forEach((e) => {
                if (
                    !companyInfo[e].value &&
                    e !== "id" &&
                    e !== "company_remark" &&
                    e !== "dropdownCompany"
                ) {
                    companyInfoErrors[e] = {
                        value: companyInfo[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            });
        }

        return companyInfoErrors;
    },
};
