import React, { Component } from "react";
import { StoreService } from "services/store.service";
import { CustomButton, CustomFileInput } from "devkit/Form/Form";
import { Translate } from "../../functions/utilFunctions/translate";
import {
    ArrowDownIcon,
    LeftArrow,
    UploadIcon,
} from "../../components/Icons/Icons";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import { UtilService } from "services/util.service";
import ApiService from "services/api.service";
import { Loader } from "devkit/Loader/Loader";
import { validateEmail } from "functions/utilFunctions/validations";
import { RouteComponentProps } from "react-router";
import { NavLink, Route, Switch } from "react-router-dom";
import BasicInfo from "./BasicInfo";
import CompanyContracts from "./CompanyContracts";
import CompanyAdditionalInfo from "./CompanyAdditionalInfo";
import { crpsForm, form } from "./CompanyStates";
import SearchCRPSForm from "./SearchCRPSForm";
import { parseDateForMySQL } from "functions/utilFunctions/parsingFunctions";

export default class CompanyDetails extends Component<
    RouteComponentProps<{ id: string; PIB: string }>,
    any
> {
    state = {
        isNewCompany: !this.props.match.params.id,
        currentCompanyID: this.props.match.params.id,
        currentCompany: {},
        form,
        document: {
            value: null,
        },
        documents: [{ path: "", id: null, updated_at: null, created_at: null }],
        showUpdateErrorModal: false,
        showUpdateSuccessModal: false,
        showCreateErrorModal: false,
        showCreateSuccessModal: false,
        showSearchCRPSModal: false,
        showUpdateCrpsErrorModal: false,
        showUpdateCrpsSuccessModal: false,
        showUpdateNoPIBModal: false,
        showLoader: true,
        completed: 0,
        isOver: false,
        fileUploadModal: false,
        fileUploadErrorModal: false,
        uploading: false,
        company_name: { value: "", errors: [] },
        searchPIB: { value: "", errors: [] },
        crpsDataList: [] as any,
        crpsData: { ...crpsForm },
        showCrpsModal: false,
        showModalLoader: false,
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        if (!this.state.isNewCompany) {
            const companyID = this.props.match.params.id;
            ApiService.getCompany(companyID, token).then((response) => {
                if (response.success) {
                    const currentCompany = response.data;
                    delete currentCompany.created_at;
                    delete currentCompany.updated_at;
                    currentCompany.remark = currentCompany.remark || "";

                    const companyFormated: any = {};
                    if (currentCompany) {
                        Object.keys(currentCompany).forEach((e) => {
                            if (e === "staff") {
                                companyFormated[e] = currentCompany[e];
                            } else if (/date/.test(e)) {
                                companyFormated[e] = {
                                    value: new Date(currentCompany[e]),
                                    errors: [],
                                };
                            } else {
                                companyFormated[e] = {
                                    value: currentCompany[e],
                                    errors: [],
                                };
                            }
                        });
                    }
                    this.setState((prevState: any) => ({
                        ...prevState,
                        currentCompany: currentCompany,
                        documents: response.data.files,
                        form: {
                            ...companyFormated,
                        },
                        showLoader: false,
                    }));
                }
            });
        } else {
            this.setState({ showLoader: false });
        }
    }

    handleInput = (name, data) => {
        if (name === "searchPIB" || name === "company_name") {
            this.setState({ [name]: { value: data.value, errors: [] } });
        } else {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    [name]: {
                        value: data.value,
                        errors: data.errors,
                    },
                },
            }));
        }
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.document.value !== this.state.document.value &&
            this.state.document.value
        ) {
            this.setState({ uploading: true });
            this.handleAddDocument();
        }
    }

    handleUpdateAndCreate = (e) => {
        e.preventDefault();
        const { isNewCompany, currentCompany, form } = this.state;
        const companyID = parseInt(this.props.match.params.id);
        const token = StoreService.getStoreProperty("token");
        const currentForm: any = form;

        const formErrors = {};
        Object.keys(currentForm).forEach((e) => {
            if (!currentForm[e].value) {
                if (
                    e === "phone" ||
                    e === "PIB" ||
                    e === "PDV" ||
                    e === "email" ||
                    e === "address" ||
                    e === "name"
                ) {
                    formErrors[e] = {
                        value: currentForm[e].value,
                        errors: ["INPUT_REQUIRED"],
                    };
                }
            }
            if (e === "email" && !validateEmail(currentForm[e].value)) {
                formErrors[e] = {
                    value: currentForm[e].value,
                    errors: ["EMAIL_INVALID"],
                };
            }
        });

        if (Object.keys(formErrors).length !== 0) {
            this.setState((prevState: any) => ({
                form: {
                    ...prevState.form,
                    ...formErrors,
                },
            }));

            this.props.history.push("/new-company/basic-info");
        } else {
            const newDetails = {
                PDV: currentForm.PDV.value,
                PIB: currentForm.PIB.value,
                address: currentForm.address.value,
                email: currentForm.email.value,
                name: currentForm.name.value,
                phone: currentForm.phone.value,
                remark: currentForm.remark.value,
                id: companyID,
                full_name: currentForm.full_name.value,
                mailing_address: currentForm.mailing_address.value,
                address2: currentForm.address2.value,
                change_number: currentForm.change_number.value,
                update_date: parseDateForMySQL(currentForm.update_date.value),
                founding_date: parseDateForMySQL(
                    currentForm.founding_date.value
                ),
                activity_name: currentForm.activity_name.value,
                id_subject: currentForm.id_subject.value,
                types_of_organization: currentForm.types_of_organization.value,
                mailing_city: currentForm.mailing_city.value,
                city: currentForm.city.value,
                reg_no: currentForm.reg_no.value,
                reg_no2: currentForm.reg_no2.value,
                activity_code: currentForm.activity_code.value,
                status: currentForm.status.value,
                capital: currentForm.capital.value,
                staff: JSON.stringify(currentForm.staff),
            };

            if (isNewCompany) {
                ApiService.createCompany(newDetails, token).then((response) => {
                    if (response.success) {
                        this.setState({ showCreateSuccessModal: true });
                    } else {
                        if (response.message.includes("PDV_TAKEN")) {
                            this.setState((prevState: any) => ({
                                form: {
                                    ...prevState.form,
                                    PDV: {
                                        ...prevState.form.PDV,
                                        errors: ["PDV_TAKEN"],
                                    },
                                },
                            }));
                        }
                        if (response.message.includes("PIB_TAKEN")) {
                            this.setState((prevState: any) => ({
                                form: {
                                    ...prevState.form,
                                    PIB: {
                                        ...prevState.form.PIB,
                                        errors: ["PIB_TAKEN"],
                                    },
                                },
                            }));
                        }
                        if (response.message.includes("EMAIL_TAKEN")) {
                            this.setState((prevState: any) => ({
                                form: {
                                    ...prevState.form,
                                    email: {
                                        ...prevState.form.email,
                                        errors: ["EMAIL_TAKEN"],
                                    },
                                },
                            }));
                        }
                        this.setState({ showCreateErrorModal: true });
                    }
                });
            } else {
                if (!UtilService.areObjectsEqual(currentCompany, newDetails)) {
                    ApiService.updateCompany(
                        { ...newDetails, id: companyID },
                        token
                    ).then((response) => {
                        if (response.success) {
                            this.setState({ showUpdateSuccessModal: true });
                        } else {
                            this.setState({ showUpdateErrorModal: true });
                        }
                    });
                } else return;
            }
        }
    };

    handleModal = (name) => {
        this.setState((prevState: any) => ({
            [name]: !prevState[name],
        }));
        if (
            name === "showCreateSuccessModal" ||
            name === "showUpdateSuccessModal"
        ) {
            this.props.history.push("/companies/1");
        }
    };

    handleSearchCRPS = (e) => {
        e.preventDefault();
        const { company_name, searchPIB } = this.state;

        const token = StoreService.getStoreProperty("token");
        const data = {};

        if (company_name.value) {
            data["clientName"] = company_name.value;
        }
        if (searchPIB.value) {
            data["PIB"] = searchPIB.value;
        }

        if (!company_name.value && !searchPIB.value) {
            this.setState({
                company_name: {
                    value: company_name.value,
                    errors: ["ONE_FIELD_MINIMUM"],
                },
                PIB: {
                    value: searchPIB.value,
                    errors: ["ONE_FIELD_MINIMUM"],
                },
            });
        } else {
            this.setState({
                showSearchCRPSModal: false,
                showCrpsModal: true,
                showModalLoader: true,
                company_name: { value: "", errors: [] },
                PIB: { value: "", errors: [] },
            });

            ApiService.fetchClientFromCRPS(data, token).then((res) => {
                if ((res.status = "OK")) {
                    this.setState({
                        crpsDataList: res.data,
                        showModalLoader: false,
                    });
                }
            });
        }
    };

    returnToSearch = () => {
        this.setState({ showCrpsModal: false, showSearchCRPSModal: true });
    };

    fillCrpsData = (PIB, update) => {
        const token = StoreService.getStoreProperty("token");
        this.setState({ showLoader: true, showCrpsModal: false });
        const { crpsDataList, crpsData } = this.state;

        const selectedCompany = crpsDataList.find((item) => {
            return item.maticnI_BROJ_DRUSTVA === PIB;
        });

        ApiService.fetchCompanyStaffFromCRPS(
            selectedCompany.iD_PREDMET,
            token
        ).then((res) => {
            if (res.success) {
                const staffData = res.data;
                const staff = staffData.map((el) => {
                    return {
                        first_name: el.ime,
                        last_name: el.prezime,
                        position: el.uloga,
                        share: el.udio,
                        id: el.iD_PREDMET,
                        responsibility: el.odgovornost,
                    };
                });

                this.setState((prev) => ({
                    ...prev,
                    crpsData,
                    form: {
                        ...prev.form,
                        name: {
                            value: selectedCompany.skracenI_NAZIV_DRUSTVA,
                            errors: [],
                        },
                        address: {
                            value: selectedCompany.adresA_SJEDISTE,
                            errors: [],
                        },
                        PIB: {
                            value: selectedCompany.maticnI_BROJ_DRUSTVA,
                            errors: [],
                        },
                        full_name: {
                            value: selectedCompany.naziV_DRUSTVA || "",
                            errors: [],
                        },
                        mailing_address: {
                            value: selectedCompany.adresA_PRIJEM || "",
                            errors: [],
                        },
                        address2: {
                            value: selectedCompany.adresA_SJEDISTE || "",
                            errors: [],
                        },
                        change_number: {
                            value: selectedCompany.broJ_PROMJENE || "",
                            errors: [],
                        },
                        founding_date: {
                            value:
                                new Date(selectedCompany.datuM_REGISTRACIJE) ||
                                null,
                            errors: [],
                        },
                        update_date: {
                            value:
                                new Date(selectedCompany.datuM_PROMJENE) ||
                                null,
                            errors: [],
                        },
                        activity_name: {
                            value: selectedCompany.djelatnost || "",
                            errors: [],
                        },
                        id_subject: {
                            value: selectedCompany.iD_PREDMET || "",
                            errors: [],
                        },
                        types_od_organization: {
                            value: selectedCompany.obliK_ORGANIZOVANJA || "",
                            errors: [],
                        },
                        mailing_city: {
                            value: selectedCompany.opstinA_PRIJEM || "",
                            errors: [],
                        },
                        city: {
                            value: selectedCompany.opstinA_SJEDISTE || "",
                            errors: [],
                        },
                        reg_no: {
                            value: selectedCompany.rbr || "",
                            errors: [],
                        },
                        reg_no2: {
                            value: selectedCompany.reG_BROJ || "",
                            errors: [],
                        },
                        activity_code: {
                            value: selectedCompany.sifrA_DJELATNOSTI || "",
                            errors: [],
                        },
                        status: {
                            value: selectedCompany.statuS_DRUSTVA || "",
                            errors: [],
                        },
                        capital: {
                            value: selectedCompany.ukupaN_KAPITAL || "",
                            errors: [],
                        },
                        staff: staff,
                    },
                    showLoader: false,
                    showUpdateCrpsSuccessModal: update ? true : false,
                }));
            }
        });
    };

    refreshCrpsData = () => {
        const token = StoreService.getStoreProperty("token");

        if (!this.state.form.PIB.value) {
            this.setState({ showUpdateNoPIBModal: true });
        } else {
            this.setState({
                showLoader: true,
            });

            ApiService.fetchClientFromCRPS(
                { PIB: this.state.form.PIB.value },
                token
            ).then((res) => {
                if (res.success) {
                    if (res.data.errors || !res.data[0]) {
                        this.setState({
                            showUpdateCrpsErrorModal: true,
                            showLoader: false,
                        });
                    } else {
                        this.setState(
                            {
                                updateCRPS: true,
                                crpsDataList: res.data,
                            },
                            () => {
                                this.fillCrpsData(
                                    res.data[0].maticnI_BROJ_DRUSTVA,
                                    true
                                );
                            }
                        );
                    }
                }
            });
        }
    };

    handleAddDocument = () => {
        const document: any = this.state.document.value;
        const token = StoreService.getStoreProperty("token");
        const formData = new FormData();
        const id = this.props.match.params.id;
        formData.append("company_id", id);
        formData.append("file", document);
        formData.append("type", "company");
        const config = {
            onUploadProgress: (progressEvent) => {
                const completed = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                this.setState({
                    completed,
                    uploadComplete: completed === 100,
                });
            },
        };
        ApiService.uploadFile(formData, token, config).then((res) => {
            if (res.success) {
                this.setState((prevState: any) => ({
                    documents: [...prevState.documents, res.data],
                    completed: 0,
                    uploadComplete: false,
                    uploading: false,
                    fileUploadModal: false,
                    document: {
                        value: null,
                        errors: [],
                    },
                    form: {
                        ...prevState.form,
                        documents: [prevState.form.documents, res.data],
                    },
                }));
            } else {
                this.setState({
                    fileUploadErrorModal: true,
                    document: {
                        value: null,
                        errors: [],
                    },
                });
            }
        });
    };

    deleteFile = (index) => {
        const token = StoreService.getStoreProperty("token");

        ApiService.deleteFile(
            {
                id: this.state.documents[index].id,
            },
            token
        ).then((response) => {
            if (response.success) {
                const files = this.state.documents.filter(
                    (e, i) => i !== index
                );
                this.setState({
                    documents: files,
                });
            }
        });
    };

    handleFileChange = (name, e, drop?) => {
        this.setState({
            [name]: UtilService.handleAddFile(name, e, drop),
        });
    };

    changeBorderType = (isOver) => {
        this.setState({ isOver });
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    render() {
        const {
            form,
            showLoader,
            isNewCompany,
            currentCompanyID,
            company_name,
            searchPIB,
        } = this.state;
        return (
            <div>
                <Modal
                    modalName="showSearchCRPSModal"
                    toggleModal={this.handleModal}
                    className={`${
                        this.state.showSearchCRPSModal ? "visible " : ""
                    } row`}
                    modalWrapperClasses={
                        "d-flex align-items-center flex-column w-350"
                    }
                >
                    <SearchCRPSForm
                        handleInput={this.handleInput}
                        onClick={this.handleSearchCRPS}
                        company_name={company_name}
                        PIB={searchPIB}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleModal}
                    modalName="showCrpsModal"
                    className={this.state.showCrpsModal ? "visible" : ""}
                    modalWrapperClasses="w-800 d-flex flex-column align-items-center crpsModal"
                >
                    <>
                        <div className="crpsModalHeader d-flex align-items-center justify-content-between px-30">
                            <h1 className="f-s-22 d-flex justify-content-center margin-0">
                                <Translate text="CHOOSE_COMPANY" />
                            </h1>

                            <CustomButton
                                onClick={this.returnToSearch}
                                className="d-flex padding-0"
                            >
                                <div className="d-flex align-items-center justify-content-center">
                                    <ArrowDownIcon className="icon ml-5" />
                                </div>

                                <div className="ml-5">
                                    <Translate text="BACK" />
                                </div>
                            </CustomButton>
                        </div>

                        {this.state.showModalLoader ? (
                            <div className="d-flex align-items-center flex-column">
                                <Loader width={200} />
                                <span className="mb-30 mt-10 f-s-16 one-line-text">
                                    <Translate text="COMPANIES_SEARCH" />
                                    ...
                                </span>
                            </div>
                        ) : (
                            <div className="pt-30 d-flex flex-wrap px-20 w-100-perc justify-content-center companies">
                                {this.state.crpsDataList[0] ? (
                                    this.state.crpsDataList.map((item) => {
                                        return (
                                            <div
                                                className="mb-20 col-6"
                                                onClick={() =>
                                                    this.fillCrpsData(
                                                        item.maticnI_BROJ_DRUSTVA,
                                                        false
                                                    )
                                                }
                                            >
                                                <div className="crpsCompany d-flex flex-column justify-content-center align-items-center pointer min-h-150">
                                                    <div className="f-s-17 crpsCompanyName">
                                                        {
                                                            item.skracenI_NAZIV_DRUSTVA
                                                        }
                                                    </div>
                                                    <div className="f-s-14 mt-20 city">
                                                        {item.opstinA_SJEDISTE}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                ) : (
                                    <div className="f-s-18 mt-25">
                                        <Translate text="NO_DATA" />
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                </Modal>
                <Modal
                    toggleModal={this.handleModal}
                    modalName="showUpdateCrpsErrorModal"
                    className={
                        this.state.showUpdateCrpsErrorModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="CRPS_UPDATE_FAILURE"
                        modalName="showUpdateCrpsErrorModal"
                        title="FAILURE"
                        toggleModal={this.handleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleModal}
                    modalName="showUpdateNoPIBModal"
                    className={
                        this.state.showUpdateNoPIBModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="CRPS_UPDATE_FAILURE_NO_PIB"
                        modalName="showUpdateNoPIBModal"
                        title="FAILURE"
                        toggleModal={this.handleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleModal}
                    modalName="showUpdateCrpsSuccessModal"
                    className={
                        this.state.showUpdateCrpsSuccessModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="CRPS_UPDATE_SUCCESS"
                        modalName="showUpdateCrpsSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.handleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleModal}
                    modalName="showUpdateSuccessModal"
                    className={
                        this.state.showUpdateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        toggleModal={this.handleModal}
                        text="UPDATE_COMPANY_SUCCESS"
                        modalName="showUpdateSuccessModal"
                        title={"SUCCESS"}
                        type={"success"}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleModal}
                    modalName="showUpdateErrorModal"
                    className={
                        this.state.showUpdateErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="UPDATE_COMPANY_FAILURE"
                        modalName="showUpdateErrorModal"
                        title="FAILED"
                        toggleModal={this.handleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleModal}
                    modalName="showCreateSuccessModal"
                    className={
                        this.state.showCreateSuccessModal ? "visible" : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        toggleModal={this.handleModal}
                        text="CREATE_COMPANY_SUCCESS"
                        modalName="showCreateSuccessModal"
                        title={"SUCCESS"}
                        type={"success"}
                    />
                </Modal>
                <Modal
                    toggleModal={this.handleModal}
                    modalName="showCreateErrorModal"
                    className={
                        this.state.showCreateErrorModal
                            ? "visible bg-info-3"
                            : ""
                    }
                    modalWrapperClasses="w-400  padding-10"
                >
                    <ModalContent
                        type="failure"
                        text="CREATE_COMPANY_FAILURE"
                        modalName="showCreateErrorModal"
                        title="FAILED"
                        toggleModal={this.handleModal}
                    />
                </Modal>
                <Modal
                    modalName="fileUploadModal"
                    toggleModal={this.handleModal}
                    className={this.state.fileUploadModal ? "visible" : ""}
                    modalWrapperClasses="fileUploadModal"
                >
                    {!this.state.uploading && (
                        <div className="pa-20 h-100-perc">
                            <div
                                onDrop={(e) =>
                                    this.handleFileChange("document", e, true)
                                }
                                className={`dropImage d-flex justify-content-center align-items-center flex-column h-100-perc ${
                                    this.state.isOver ? "dotted" : ""
                                }`}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                    this.changeBorderType(true);
                                }}
                                onDragLeave={() => this.changeBorderType(false)}
                            >
                                <div className="text d-flex flex-column justify-content-center align-items-center">
                                    <span className="d-block mb-10">
                                        <UploadIcon
                                            width={40}
                                            className="uploadIcon"
                                        />
                                    </span>
                                    <span className="d-block mb-10 f-s-20 f-w-400 text-color-font-l2">
                                        <Translate text="DRAG_AND_DROP" />
                                    </span>
                                    <span className=" d-block mb-20 f-s-14 f-w-400 text-color-font-l2">
                                        <Translate text="OR" />
                                    </span>
                                </div>
                                <CustomFileInput
                                    name="document"
                                    className="mb-10"
                                    handleInput={this.handleFileChange}
                                    value={this.state.document.value}
                                    label={"CHOOSE_FILE"}
                                    labelClass="f-s-16"
                                />
                            </div>
                        </div>
                    )}
                    {this.state.uploading && (
                        <div className="progress pa-20 h-100perc text-center border-radius-4">
                            <span className="f-s-20 mb-20">
                                <Translate text="UPLOAD_IN_PROGRESS" />
                            </span>
                            <span className="wrapper d-block">
                                <Loader width={200} />
                                <span className="loader-numbers">
                                    {this.state.completed}%
                                </span>
                            </span>
                        </div>
                    )}
                </Modal>
                <div className="d-flex justify-content-between border-b-1 pb-15">
                    <div className="pageHeader d-flex align-items-center">
                        <span
                            onClick={this.handleBack}
                            className="pointer d-flex align-items-center goBack mr-10"
                        >
                            <LeftArrow width={18} className="" />
                        </span>
                        <Translate text="COMPANY_DETAILS"></Translate>
                    </div>
                    <div className="d-flex">
                        <CustomButton
                            onClick={() =>
                                this.handleModal("showSearchCRPSModal")
                            }
                            type="button"
                            className="d-flex align-items-center mr-20"
                        >
                            <div className="d-flex justify-content-center align-items-center">
                                <Translate text="SEARCH_CRPS"></Translate>
                            </div>
                        </CustomButton>
                        <CustomButton
                            onClick={this.handleUpdateAndCreate}
                            type="submit"
                            className="d-flex align-items-center"
                        >
                            {isNewCompany ? (
                                <Translate text="SAVE" />
                            ) : (
                                <Translate text="SAVE_CHANGES" />
                            )}
                        </CustomButton>
                    </div>
                </div>

                <nav className="d-flex mt-30 pl-10">
                    <NavLink
                        exact
                        to={`${
                            isNewCompany
                                ? `/new-company/basic-info`
                                : `/companies/details/${currentCompanyID}/basic-info`
                        }`}
                        className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                        activeClassName="active"
                    >
                        <span>
                            <Translate text="BASIC_INFO" />
                        </span>
                    </NavLink>
                    <NavLink
                        exact
                        to={`${
                            isNewCompany
                                ? `/new-company/additional-info`
                                : `/companies/details/${currentCompanyID}/additional-info`
                        }`}
                        className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                        activeClassName="active"
                    >
                        <span>
                            <Translate text="ADDITIONAL_INFO" />
                        </span>
                    </NavLink>
                    {!isNewCompany && (
                        <NavLink
                            exact
                            to={`/companies/details/${currentCompanyID}/contracts/1`}
                            className="reservations-title d-flex align-items-center justify-content-center tab mr-10 border-radius-br-0 border-radius-bl-0 text-decoration-none"
                            activeClassName="active"
                        >
                            <span>
                                <Translate text="CONTRACTS" />
                            </span>
                        </NavLink>
                    )}
                </nav>

                <Switch>
                    <Route
                        exact
                        path={`${
                            isNewCompany
                                ? `/new-company/basic-info`
                                : `/companies/details/${currentCompanyID}/basic-info`
                        }`}
                    >
                        {showLoader ? (
                            <div className="d-flex justify-content-between align-items-center h-400">
                                <Loader className="w-200"></Loader>
                            </div>
                        ) : (
                            <BasicInfo
                                form={form}
                                handleInput={this.handleInput}
                                handleModal={this.handleModal}
                                documents={this.state.documents}
                                isNewCompany={this.state.isNewCompany}
                                deleteFile={this.deleteFile}
                            ></BasicInfo>
                        )}
                    </Route>
                    <Route
                        exact
                        path={`${
                            isNewCompany
                                ? `/new-company/additional-info`
                                : `/companies/details/${currentCompanyID}/additional-info`
                        }`}
                    >
                        {showLoader ? (
                            <div className="d-flex justify-content-between align-items-center h-400">
                                <Loader className="w-200"></Loader>
                            </div>
                        ) : (
                            <CompanyAdditionalInfo
                                form={form}
                                updateCRPS={this.refreshCrpsData}
                                handleInput={this.handleInput}
                            />
                        )}
                    </Route>

                    <Route
                        exact
                        path={`/companies/details/${currentCompanyID}/contracts/:pageNo`}
                        render={(props) => {
                            return (
                                <>
                                    {showLoader ? (
                                        <div className="d-flex justify-content-between align-items-center h-400">
                                            <Loader className="w-200"></Loader>
                                        </div>
                                    ) : (
                                        <CompanyContracts
                                            companyId={
                                                this.props.match.params.id
                                            }
                                            {...props}
                                        ></CompanyContracts>
                                    )}
                                </>
                            );
                        }}
                    />
                </Switch>
            </div>
        );
    }
}
