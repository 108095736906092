import { MinusIcon, FileIcon, PlusIcon } from "components/Icons/Icons";
import React, { Component } from "react";
import Translate from "functions/utilFunctions/translate";

class PrivateFiles extends Component<any> {
    render() {
        const {
            documents,
            deleteFile,
            toggleModal,
            noShadow,
            wrapperClasses,
        } = this.props;
        return (
            <div
                className={`${
                    noShadow ? "shadow-light" : "personal-shadow-darker"
                } ${wrapperClasses} flex justify-content-center min-w-400 min-h-300`}
            >
                <div
                    className="addFile"
                    onClick={() => toggleModal("fileUploadModal")}
                >
                    <span className="icon">
                        <PlusIcon className="filesIcon fill mr-20" width={14} />
                    </span>
                    <span className="pb-5">
                        <Translate text="ADD_FILE" />
                    </span>
                </div>
                <div className="mt-10 d-flex flex-column flex-wrap">
                    {documents.map((item: any, index) => {
                        return (
                            item.path !== "" && (
                                <div
                                    key={index}
                                    className="d-flex align-items-center mb-5 f-s-12 pointer"
                                >
                                    <a
                                        href={`https://rentapi.oykos.dev/storage/${item.path}`}
                                        className="fileLink decoration-none"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                    >
                                        <span className="f-s-13 d-flex width-400 file-path">
                                            <span className="d-flex mr-10">
                                                <FileIcon
                                                    width={20}
                                                    height={20}
                                                />
                                            </span>
                                            <span>{item.path}</span>
                                            <span
                                                className="minus-icon d-flex align-items-center"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();

                                                    deleteFile(index);
                                                }}
                                            >
                                                <MinusIcon
                                                    className="ml-10 MinusIcon"
                                                    width={15}
                                                    height={15}
                                                />
                                            </span>
                                        </span>
                                    </a>
                                </div>
                            )
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default PrivateFiles;
