import React, { Component } from "react";
import { Translate } from "../../functions/utilFunctions/translate";
import Table from "devkit/Table/Table";
import { StoreService } from "services/store.service";
import ShowMore from "devkit/ShowMore/ShowMore";
import { CustomButton } from "devkit/Form/Form";
import { Loader } from "devkit/Loader/Loader";
import ApiService from "../../services/api.service";
import Modal from "devkit/Modal/Modal";
import { ModalContent } from "devkit/Modal/ModalContent";
import { PlusIcon } from "../../components/Icons/Icons";
import Paginate from "devkit/Pagination/Pagination";
export default class Companies extends Component<any> {
    state = {
        companiesTHeads: [
            {
                value: "COMPANY_NAME",
                sortable: true,
                sortableName: "name",
            },
            {
                value: "COMPANY_ADDRESS",
                sortable: true,
                sortableName: "address",
            },
            {
                value: "PDV",
                sortable: false,
                sortableName: "PDV",
            },
            {
                value: "PIB",
                sortable: false,
                sortableName: "PIB",
            },
            {
                value: "EMAIL_ADDRESS",
                sortable: true,
                sortableName: "email",
            },
            {
                value: "PHONE_NUMBER",
                sortable: false,
                sortableName: "phone",
            },
            {
                value: "",
                sortable: false,
            },
        ],
        companies: [],
        showDeleteModal: false,
        showDeleteErrorModal: false,
        showDeleteSuccessModal: false,
        showLoader: true,
        deleteID: "",
        totalPages: 0,
        currentPage: this.props.match.params.pageNo,
        perPage: 20,
        sorterDirection: "",
        sorterName: "",
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data = { limit: perPage, offset };

        ApiService.getAllCompanies(data, token).then((response) => {
            if (response && response.success) {
                const companies = response.data;
                this.setState({
                    companies,
                    totalPages: response.total,
                    showLoader: false,
                });
            }
        });
    }

    // currentPage u state-u treba da dobija vrijednost iz dinamickog dijela url path-a, ali kad na paginaciji promjenim stranu, redirectuje me na // tu stranu ali mi i ne promjeni state kad se promjeni this.props.match.params.pageNo
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.sorterName &&
            this.state.sorterName !== prevState.sorterName
        ) {
            this.setState({ sorterName: "", sorterDirection: "" });
        }
        if (prevState.currentPage !== this.state.currentPage) {
            const token = StoreService.getStoreProperty("token");
            const {
                currentPage,
                perPage,
                sorterDirection,
                sorterName,
            } = this.state;
            const offset = (parseInt(currentPage) - 1) * perPage;

            const data: any = { limit: perPage, offset, token };
            if (sorterName) {
                data.sorter = sorterName;
            }
            if (sorterDirection) {
                data.sorter_direction = sorterDirection;
            }

            ApiService.getAllCompanies(data, token).then((response) => {
                if (response && response.success) {
                    const companies = response.data;
                    this.setState({
                        companies,
                        totalPages: response.total,
                        showLoader: false,
                    });
                }
            });
        }
    }

    handleRedirect = (id) => {
        this.props.history.push(`/companies/details/${id}/basic-info`);
    };

    handleAddNew() {
        this.props.history.push("/new-company/basic-info");
    }

    handleDelete = () => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
        });
        const {
            deleteID,
            currentPage,
            perPage,
            sorterDirection,
            sorterName,
        } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const token = StoreService.getStoreProperty("token");
        const data: any = { limit: perPage, offset };

        if (sorterDirection) {
            data.sorter_direction = sorterDirection;
        }
        if (sorterName) {
            data.sorter = sorterName;
        }

        ApiService.deleteCompany(deleteID, token).then((response) => {
            if (response.success) {
                ApiService.getAllCompanies(data, token).then((response) => {
                    if (response && response.success) {
                        this.setState({
                            companies: response.data,
                            showDeleteSuccessModal: true,
                        });
                    }
                });
            } else {
                this.setState({
                    showDeleteFailureModal: true,
                });
            }
        });
    };

    handleInput = (name, data) => {
        this.setState({ [name]: { value: data.value, errors: [] } });
    };

    toggleModal = (name) => {
        this.setState({ [name]: !this.state[name] });
    };

    handleDeleteModal = (id) => {
        this.setState({
            showDeleteModal: true,
            deleteID: id,
        });
    };
    handleSort = (sortableName) => {
        const token = StoreService.getStoreProperty("token");
        const { currentPage, perPage, sorterDirection } = this.state;
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";
        const offset = (parseInt(currentPage) - 1) * perPage;
        const data = {
            limit: perPage,
            offset,
            sorter: sortableName,
            sorter_direction,
        };

        ApiService.getAllCompanies(data, token).then((response) => {
            if (response && response.success) {
                const companies = response.data;
                this.setState({
                    companies,
                    totalPages: response.total,
                    showLoader: false,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                });
            }
        });
    };

    handlePageClick = (pageNumber) => {
        this.props.history.push(`/companies/${pageNumber}`);
    };

    returnToSearch = () => {
        this.setState({ showCrpsModal: false, showSearchCRPSModal: true });
    };

    render() {
        const {
            companiesTHeads,
            showLoader,
            currentPage,
            totalPages,
            perPage,
        } = this.state;
        return (
            <div>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteModal"
                    className={this.state.showDeleteModal ? "visible " : ""}
                    modalWrapperClasses="w-600 big-warning padding-10 "
                >
                    <ModalContent
                        type="warning"
                        text="ARE_YOU_SURE_DELETE_COMPANY"
                        modalName="showDeleteModal"
                        title="DELETE_COMPANY"
                        toggleModal={this.toggleModal}
                        deleteItem={this.handleDelete}
                        cancelDelete={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteSuccessModal"
                    className={
                        this.state.showDeleteSuccessModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="success"
                        text="DELETE_COMPANY_SUCCESS"
                        modalName="showDeleteSuccessModal"
                        title="SUCCESS"
                        toggleModal={this.toggleModal}
                    />
                </Modal>
                <Modal
                    toggleModal={this.toggleModal}
                    modalName="showDeleteErrorModal"
                    className={
                        this.state.showDeleteErrorModal ? "visible " : ""
                    }
                    modalWrapperClasses="w-400 padding-10 "
                >
                    <ModalContent
                        type="failure"
                        text="DELETE_COMPANY_FAILURE"
                        modalName="showDeleteErrorModal"
                        title="FAILURE"
                        toggleModal={this.toggleModal}
                    />
                </Modal>

                <div className="d-flex justify-content-between border-b-1 pb-15">
                    <div className="pageHeader d-flex align-items-center">
                        <Translate text="COMPANIES"></Translate>
                    </div>
                </div>
                <div className="d-flex py-20 justify-content-end">
                    <CustomButton
                        onClick={() => this.handleAddNew()}
                        type="button"
                        className="d-flex align-items-center"
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PlusIcon
                                width={14}
                                className="icon hover pointer mr-10 whiteFill"
                            />
                            <Translate text="ADD_COMPANY"></Translate>
                        </div>
                    </CustomButton>
                </div>

                <div className="mt-40">
                    {showLoader ? (
                        <Loader className="w-200" />
                    ) : (
                        <>
                            <Table
                                theads={companiesTHeads}
                                theadsClassname="customThead"
                                handleSort={this.handleSort}
                            >
                                {!!this.state.companies &&
                                    this.state.companies.map((c: any) => {
                                        const {
                                            name,
                                            address,
                                            PDV,
                                            PIB,
                                            email,
                                            phone,
                                            id,
                                        } = c;
                                        const showMore = (
                                            <ShowMore>
                                                <span
                                                    className="d-flex justify-content-center"
                                                    onClick={() =>
                                                        this.handleRedirect(
                                                            id.toString()
                                                        )
                                                    }
                                                >
                                                    <Translate text="CHANGE_DATA"></Translate>
                                                </span>
                                                <span
                                                    className="d-flex justify-content-center"
                                                    onClick={() =>
                                                        this.handleDeleteModal(
                                                            id.toString()
                                                        )
                                                    }
                                                    style={{ color: "red" }}
                                                >
                                                    <Translate text="DELETE"></Translate>
                                                </span>
                                            </ShowMore>
                                        );
                                        const keyArr = [
                                            name,
                                            address,
                                            PDV,
                                            PIB,
                                            email,
                                            phone,
                                            showMore,
                                        ];
                                        return (
                                            <tr
                                                key={id}
                                                className="pointer"
                                                onClick={() =>
                                                    this.handleRedirect(c.id)
                                                }
                                            >
                                                {keyArr.map((el: any, i) => {
                                                    return (
                                                        <td
                                                            key={i}
                                                            className="padding-h-1 padding-v-2 f-s-14"
                                                        >
                                                            {el}
                                                        </td>
                                                    );
                                                })}
                                            </tr>
                                        );
                                    })}
                            </Table>
                            <div className="d-flex justify-content-center mt-30">
                                <Paginate
                                    totalItemsCount={totalPages}
                                    onChange={this.handlePageClick}
                                    activePage={parseInt(currentPage)}
                                    perPage={perPage}
                                    pageRangeDisplayed={5}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
