import {
    CustomCheckbox,
    CustomDatePicker,
    CustomTextArea,
} from "devkit/Form/Form";
import React, { Component } from "react";
import CustomDropdown from "../../../../devkit/Form/CustomDropdown/CustomDropdown";
import CustomForm from "../../../../devkit/Form/CustomForm/CustomForm";
import CustomInput from "devkit/Form/CustomInput/CustomInput";
import Translate from "functions/utilFunctions/translate";

class UserInformation extends Component<any> {
    render() {
        const {
            checkbox,
            handleCheckbox,
            handleDropdown,
            handleInput,
            customerInfo,
            customersOptions,
            notEnough,
            clicked,
            editReservation,
            editContract,
        } = this.props;

        const edit = editContract || editReservation;

        return (
            <div>
                <CustomForm>
                    <div className="mb-29">
                        <CustomDropdown
                            data={customersOptions}
                            label="CHOOSE_CLIENT"
                            name="customers"
                            handleChange={handleDropdown}
                            value={customerInfo.dropdownCustomer}
                            placeholder={
                                !editReservation ? "Izaberite klijenta" : ""
                            }
                            //errors={customerInfo.id.errors}
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="first_name"
                            label="FIRST_NAME"
                            handleInput={handleInput}
                            type="text"
                            errors={customerInfo.first_name.errors}
                            value={customerInfo.first_name.value}
                            required
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="last_name"
                            label="LAST_NAME"
                            handleInput={handleInput}
                            type="text"
                            value={customerInfo.last_name.value}
                            errors={customerInfo.last_name.errors}
                            required
                        />
                    </div>
                    <div className="mb-29">
                        <CustomDatePicker
                            name="date_of_birth"
                            value={customerInfo.date_of_birth.value}
                            label="DATE_OF_BIRTH"
                            handleInput={handleInput}
                            errors={
                                clicked ? customerInfo.date_of_birth.errors : []
                            }
                        />
                    </div>
                    <section className="idSection d-flex justify-content-between row">
                        <div className="d-flex flex-column col-12 col-xl-6 justify-content-between mb-29">
                            <div className="mb-29">
                                <CustomInput
                                    name="licence_id"
                                    label="DRIVING_LICENCE_NUMBER"
                                    value={customerInfo.licence_id.value}
                                    handleInput={handleInput}
                                    type="number"
                                    errors={customerInfo.licence_id.errors}
                                />
                            </div>
                            <div>
                                <CustomDatePicker
                                    name="licence_id_expiration_date"
                                    label="VALID_UNTIL"
                                    value={
                                        customerInfo.licence_id_expiration_date
                                            .value
                                    }
                                    handleInput={handleInput}
                                    errors={
                                        customerInfo.licence_id_expiration_date
                                            .errors
                                    }
                                />
                            </div>
                        </div>
                        <div className="d-flex flex-column col-12 col-xl-6 justify-content-between mb-29">
                            <div className="mb-29">
                                <CustomInput
                                    name="personal_id"
                                    label="PASSPORT_NUMBER"
                                    value={customerInfo.personal_id.value}
                                    handleInput={handleInput}
                                    type="text"
                                    errors={customerInfo.personal_id.errors}
                                />
                            </div>
                            <div className="">
                                <CustomDatePicker
                                    name="personal_id_expiration_date"
                                    label="VALID_UNTIL"
                                    value={
                                        customerInfo.personal_id_expiration_date
                                            .value
                                    }
                                    handleInput={handleInput}
                                    errors={
                                        customerInfo.personal_id_expiration_date
                                            .errors
                                    }
                                />
                            </div>
                        </div>
                    </section>

                    <div className="mb-29">
                        <CustomInput
                            name="country"
                            label="COUNTRY"
                            handleInput={handleInput}
                            type="text"
                            value={customerInfo.country.value}
                            errors={clicked ? customerInfo.country.errors : []}
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="phone"
                            label="PHONE"
                            handleInput={handleInput}
                            type="number"
                            value={customerInfo.phone.value}
                            errors={clicked ? customerInfo.phone.errors : []}
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="email"
                            label="EMAIL"
                            handleInput={handleInput}
                            type="email"
                            value={customerInfo.email.value}
                            errors={customerInfo.email.errors}
                        />
                    </div>
                    <div>
                        <CustomTextArea
                            name="remark"
                            value={customerInfo.remark.value}
                            label="REMINDER"
                            handleInput={handleInput}
                        />
                    </div>
                </CustomForm>
                {((edit && customerInfo.unregistered.value) || !edit) && (
                    <div className="d-flex">
                        <div className="mt-20 w-30">
                            <CustomCheckbox
                                name="create_customer"
                                checked={checkbox}
                                handleChange={handleCheckbox}
                            />
                        </div>
                        <span className="d-flex column align-items-center mt-20 ml-10 f-s-15">
                            <span className="mr-10">
                                <Translate text="SAVE_CLIENT" />
                            </span>
                            {notEnough && (
                                <span className="must-enter">
                                    <Translate text="MUST_ENTER_ALL_INFO" />
                                </span>
                            )}
                        </span>
                    </div>
                )}
            </div>
        );
    }
}
export default UserInformation;
