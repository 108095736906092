import React, { Component } from "react";
import { CustomForm, CustomInput, CustomDatePicker } from "devkit/Form/Form";

class CarBasicInfo extends Component<any> {
    render() {
        const {
            color,
            entry_date,
            fuel_type,
            fuel_amount,
            mileage,
            location,
            production_date,
            manufacturer,
            model,
            engine_number,
            engine_power,
            engine_capacity,
            purchase_date,
            registration_date,
            licence_plate,
            transmission,
            unique_number,
        } = this.props.data;
        return (
            <div className="h-100perc ">
                <CustomForm className="h-100perc py-10">
                    <div className="row">
                        <div className="my-20 col-auto w-280 ">
                            <CustomInput
                                name="manufacturer"
                                label="MANUFACTURER"
                                value={manufacturer.value}
                                type="text"
                                errors={manufacturer.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="model"
                                label="MODEL"
                                value={model.value}
                                type="text"
                                errors={model.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="mileage"
                                label="KILOMETERS"
                                value={mileage.value}
                                type="number"
                                errors={mileage.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="fuel_amount"
                                label="FUEL_AMOUNT"
                                value={fuel_amount.value}
                                type="number"
                                errors={fuel_amount.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="licence_plate"
                                label="REGISTRATION_PLATE"
                                value={licence_plate.value}
                                type="text"
                                errors={licence_plate.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="location"
                                label="LOCATION"
                                value={location.value}
                                type="text"
                                errors={location.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="engine_number"
                                label="MOTOR_NUMBER"
                                value={engine_number.value}
                                type="text"
                                errors={engine_number.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="unique_number"
                                label="UNIQUE_NUMBER"
                                value={unique_number.value}
                                type="text"
                                errors={unique_number.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="engine_power"
                                label="MOTOR_POWER"
                                value={engine_power.value}
                                type="number"
                                errors={engine_power.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="engine_capacity"
                                label="MOTOR_VOLUME"
                                value={engine_capacity.value}
                                type="number"
                                errors={engine_capacity.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="transmission"
                                label="TRANSMISSION"
                                value={transmission.value}
                                type="text"
                                errors={transmission.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="fuel_type"
                                label="FUEL_TYPE"
                                value={fuel_type.value}
                                type="text"
                                errors={fuel_type.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomInput
                                name="color"
                                label="COLOR"
                                value={color.value}
                                type="text"
                                errors={color.errors}
                                handleInput={this.props.handleInput}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomDatePicker
                                name="registration_date"
                                label="REGISTRATION_DATE"
                                handleInput={this.props.handleInput}
                                value={registration_date.value}
                                errors={registration_date.errors}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomDatePicker
                                name="purchase_date"
                                label="PURCHASE_DATE"
                                handleInput={this.props.handleInput}
                                value={purchase_date.value}
                                errors={purchase_date.errors}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomDatePicker
                                name="entry_date"
                                label="DATE_OF_ENTRY"
                                handleInput={this.props.handleInput}
                                value={entry_date.value}
                                errors={entry_date.errors}
                            />
                        </div>
                        <div className="my-20 col-auto w-280">
                            <CustomDatePicker
                                name="production_date"
                                label="MANUFACTURE_DATE"
                                handleInput={this.props.handleInput}
                                value={production_date.value}
                                errors={production_date.errors}
                            />
                        </div>
                    </div>
                </CustomForm>
            </div>
        );
    }
}

export default CarBasicInfo;
