import React, { Component } from "react";
import CustomForm from "../../../../devkit/Form/CustomForm/CustomForm";
import CustomInput from "devkit/Form/CustomInput/CustomInput";
import CustomDropdown from "../../../../devkit/Form/CustomDropdown/CustomDropdown";
import CustomTextArea from "../../../../devkit/Form/CustomTextArea/CustomTextArea";
import { MinusIcon } from "../../../../components/Icons/Icons";

class Additionals extends Component<any> {
    render() {
        const equipmentTitles = this.props.contractEquipment.map((e: any) => ({
            value: e.id,
            label: e.name,
        }));

        return (
            <div>
                <CustomForm>
                    <div className="mb-29">
                        <CustomDropdown
                            data={this.props.additionalEquipmentOptions}
                            label="ADDITIONAL_EQUIPMENT"
                            name="additionalEquipmentOptions"
                            handleChange={this.props.handleDropdown}
                            value={this.props.additionalEquipment.value}
                        />
                    </div>
                    <div className="d-flex flex-column mb-29">
                        {equipmentTitles.map((item: any, index) => (
                            <span className="liAdditions">
                                {item.label}
                                <span
                                    key={index}
                                    onClick={(e) =>
                                        this.props.handleDelete(
                                            e,
                                            index,
                                            item.value
                                        )
                                    }
                                    className="d-flex"
                                >
                                    <MinusIcon width={20} className={"icon"} />
                                </span>
                            </span>
                        ))}
                    </div>

                    <div className="mb-29">
                        <CustomTextArea
                            name="remark_eq"
                            value={this.props.remark_eq.value}
                            label="REMINDER"
                            handleInput={this.props.handleInput}
                        />
                    </div>
                    <div className="mb-29">
                        <CustomInput
                            name="additional_equipment_total_price"
                            label="PRICE"
                            handleInput={this.props.handleInput}
                            type="number"
                            value={this.props.equipment_price}
                        />
                    </div>
                </CustomForm>
            </div>
        );
    }
}
export default Additionals;
