import React, { Component } from "react";
import Table from "devkit/Table/Table";
import { Loader } from "devkit/Loader/Loader";
import Paginate from "devkit/Pagination/Pagination";
import ApiService from "services/api.service";
import { StoreService } from "services/store.service";
import Translate from "functions/utilFunctions/translate";

class CategoryCars extends Component<any> {
    state = {
        carsTableHeads: [
            {
                value: "MANUFACTURER",
                sortable: true,
                sortableType: "string",
                sortableName: "manufacturer",
            },
            {
                value: "MODEL",
                sortable: true,
                sortableType: "string",
                sortableName: "model",
            },
            {
                value: "REGISTRATION_PLATE",
                sortable: false,
                sortableType: "string",
                sortableName: "registrationPlate",
            },
            {
                value: "REGISTRATION_DATE",
                sortable: true,
                sortableType: "date",
                sortableName: "registrationDate",
            },
            {
                value: "UNIQUE_NUMBER",
                sortable: false,
                sortableType: "string",
                sortableName: "uniqueNumber",
            },
            {
                value: "FUEL_TYPE",
                sortable: true,
                sortableType: "string",
                sortableName: "fuelType",
            },
            {
                value: "KILOMETERS",
                sortable: false,
                sortableType: "string",
                sortableName: "kilometers",
            },
        ],
        cars: [],
        perPage: 20,
        totalPages: 0,
        currentPage: this.props.match.params.pageNo,
        category_id: this.props.match.params.id,
        showLoader: true,
        categoryName: "",
        sorterName: "",
        sorterDirection: "",
    };

    componentDidMount() {
        const token = StoreService.getStoreProperty("token");
        const { perPage, currentPage, category_id } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;

        ApiService.getAllCars(
            { limit: perPage, offset, category_id: parseInt(category_id) },
            token
        ).then((response) => {
            if (response.success) {
                this.setState({
                    cars: response.data,
                    totalPages: response.total,
                });
            }
        });
        ApiService.getCategory(category_id, token).then((response) => {
            if (response.success) {
                this.setState({
                    showLoader: false,
                    categoryName: response.data.name,
                });
            }
        });
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.match.params.pageNo !== prevState.currentPage) {
            return {
                currentPage: nextProps.match.params.pageNo,
                showLoader: true,
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.sorterName &&
            this.state.sorterName !== prevState.sorterName
        ) {
            this.setState({ sorterName: "", sorterDirection: "" });
        }
        if (prevState.currentPage !== this.state.currentPage) {
            const token = StoreService.getStoreProperty("token");
            const {
                currentPage,
                perPage,
                category_id,
                sorterName,
                sorterDirection,
            } = this.state;
            const offset = (parseInt(currentPage) - 1) * perPage;
            const data: any = {
                limit: perPage,
                offset,
                category_id: parseInt(category_id),
            };
            if (sorterDirection) {
                data.sorter_direction = sorterDirection;
            }
            if (sorterName) {
                data.sorter = sorterName;
            }

            ApiService.getAllCars(data, token).then((response) => {
                if (response.success) {
                    const cars = response.data;
                    this.setState({
                        cars,
                        totalPages: response.total,
                        showLoader: false,
                    });
                }
            });
        }
    }

    handleSort = (sortableName) => {
        const token = StoreService.getStoreProperty("token");
        const {
            currentPage,
            perPage,
            sorterDirection,
            category_id,
        } = this.state;
        const offset = (parseInt(currentPage) - 1) * perPage;
        const sorter_direction = sorterDirection
            ? sorterDirection === "desc"
                ? "asc"
                : "desc"
            : "asc";

        ApiService.getAllCars(
            {
                limit: perPage,
                offset,
                category_id,
                sorter: sortableName,
                sorter_direction,
            },
            token
        ).then((response) => {
            if (response && response.success) {
                const cars = response.data;
                this.setState({
                    cars,
                    totalPages: response.total,
                    showLoader: false,
                    sorterDirection: sorter_direction,
                    sorterName: sortableName,
                });
            }
        });
    };

    handlePageClick = (pageNumber) => {
        this.props.history.push(
            `/group/${this.state.category_id}/categoryCars/${pageNumber}`
        );
    };

    handleBack = () => {
        this.props.history.goBack();
    };

    redirectToCar = (id) => {
        this.props.history.push(`/car/${id}/basic-info`);
    };

    render() {
        const {
            carsTableHeads,
            categoryName,
            totalPages,
            currentPage,
            showLoader,
            perPage,
            cars,
        } = this.state;
        return (
            <>
                <div className="d-flex border-b-1 pb-20">
                    <div className="pageHeader w-100-perc d-flex align-items-center justify-content-between">
                        <div>
                            <span className="pointer" onClick={this.handleBack}>
                                <Translate text="GROUPS" />
                            </span>
                            <span className="f-s-16 ml-10">{">"}</span>
                            <span className="f-s-16 ml-10">{categoryName}</span>
                        </div>
                    </div>
                </div>
                <div className="f-s-14">
                    {showLoader ? (
                        <div className="mt-100">
                            <Loader className="w-200" />
                        </div>
                    ) : (
                        <>
                            <Table
                                theads={carsTableHeads}
                                theadsClassname="customThead"
                                className="mt-80"
                                handleSort={this.handleSort}
                            >
                                {cars &&
                                    cars.map((e: any, i) => {
                                        return (
                                            <tr
                                                className="pointer"
                                                onClick={() =>
                                                    this.redirectToCar(e.id)
                                                }
                                                key={i}
                                            >
                                                <td className="">
                                                    {e.manufacturer}
                                                </td>
                                                <td>{e.model}</td>
                                                <td>{e.licence_plate}</td>
                                                <td className="">
                                                    {e.registration_date}
                                                </td>
                                                <td className="">
                                                    {e.unique_number}
                                                </td>
                                                <td className="">
                                                    {e.fuel_type}
                                                </td>
                                                <td className="">
                                                    {e.mileage}
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </Table>
                            {cars && cars[0] && (
                                <Paginate
                                    totalItemsCount={totalPages}
                                    onChange={this.handlePageClick}
                                    activePage={parseInt(currentPage)}
                                    perPage={perPage}
                                    pageRangeDisplayed={5}
                                />
                            )}
                        </>
                    )}
                </div>
            </>
        );
    }
}
export default CategoryCars;
